import { useState } from 'react';
import { LocalisationService } from '../../../../../service/models/localisation';
import { useFormContext } from 'react-hook-form';
import { ButtonSpinner } from '../../../buttonSpinner';
import { MapInput } from './MapInput';

export const AdresseInput = ({ style, element, errors, register, control }) => {
	const [currentAdress, setCurrentAdress] = useState([-0.827172, 45.26432]);
	const { setValue, getValues } = useFormContext();
	const [showLoading, setShowLoading] = useState(false);
	const [searchResult, SetsearchResult] = useState([]);
	const [showChoices, setShowChoices] = useState(false);
	const getAddress = async () => {
		const value = getValues(element.name);
		const showChoices = value.length > 3 ? true : false;

		if (showChoices) {
			setShowLoading(true);
			await LocalisationService.searchLocalisation(value)
				.then(({ data }) => SetsearchResult(data))
				.catch(() => {})
				.finally(() => {
					setShowChoices(showChoices);
				});
		} else setShowChoices(false);
		setShowLoading(false);
	};
	const setAddress = (search) => {
		const data = search.properties;
		setCurrentAdress(search.geometry.coordinates);
		setValue(element.name, data.label, {
			shouldValidate: true,
		});

		for (let i = 0; i < element.target?.length; i++) {
			const elts = element.target[i];

			if (elts !== undefined) {
				if (elts.country !== undefined) {
					setValue(elts.country, 'France', {
						shouldValidate: true,
					});
				} else if (elts.city !== undefined) {
					setValue(elts.city, data.city, {
						shouldValidate: true,
					});
				} else if (elts.adress !== undefined) {
					const doc = document.querySelector(`input[name=${elts.adress}]`);
					setValue(elts.adress, `${data.label}, ${data.context}`, {
						shouldValidate: true,
					});
				} else if (elts.zip !== undefined) {
					setValue(elts.zip, data.postcode, {
						shouldValidate: true,
					});
				}
				setValue(element.name, `${data.label}, ${data.context}`, {
					shouldValidate: true,
				});
			}
		}
		setShowChoices(false);
	};

	return (
		<>
			<div className='flex items-stretch w-full justify-between'>
				<input
					type='text'
					{...register(element.name, {
						...element.validation,
					})}
					className={`${style.simple__input} w-11/12`}
				/>
				<div className='w-1/12 flex justify-center items-center'>
					{!showLoading && (
						<i
							className='fa fa-search cursor-pointer p-4 rounded-lg bg-black/40'
							onClick={getAddress}></i>
					)}

					{showLoading && <ButtonSpinner showLoading={showLoading} />}
				</div>
			</div>

			{showChoices && (
				<div className='mt-0 z-5 h-64 overflow-y-auto  border w-full border-gray-300 text-white text-sm rounded-lg'>
					<ul className='w-full'>
						{searchResult.map((search, index) => (
							<li
								key={index}
								className={`p-5 cursor-pointer text-black hover:bg-[#666633] hover:text-white my-1 ${element.name}`}
								onClick={() => {
									setAddress(search, index);
								}}>
								{search.properties.label} de {search.properties.context}
							</li>
						))}
					</ul>
				</div>
			)}

			{element.target?.map((elem, index) => {
				return (
					<div className='w-full' key={index}>
						<label className='capitalize'>{Object.values(elem)[1]}</label>
						<input
							type='text'
							readOnly={true}
							disabled={true}
							{...register(Object.values(elem)[0])}
							className={`${style.simple__input} read-only:bg-gray-600 !text-white`}
						/>
					</div>
				);
			})}
			{element.withMap && <MapInput currentAddress={currentAdress} />}
		</>
	);
};
