import style from './step-form.module.css';
import { ElementForm } from './elements/ElementForm';
import { useFormContext } from 'react-hook-form';

export const StepForm = ({ index, step, currentStep, errors, formState }) => {
	const { register, watch, unregister, setValue } = useFormContext();
	return (
		<div className={`${currentStep !== index && 'hidden'}`}>
			<h1 className={`blue-color ${style.step__title}`}>{step.title}</h1>
			<div className=''>
				{step.elements?.map((element, index) => {
					return (
						<ElementForm
							control={watch}
							element={element}
							formState={formState}
							key={index}
							setValue={setValue}
							style={style}
							unregister={unregister}
							register={register}
							errors={errors}
						/>
					);
				})}
			</div>
			{step.substeps?.map((substep, subIndex) => (
				<StepForm
					step={substep}
					key={subIndex}
					formState={formState}
					errors={errors}
					register={register}
					index={index}
					currentStep={currentStep}
				/>
			))}
		</div>
	);
};
