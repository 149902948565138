import { createHttp } from '../../http';

const http = createHttp();

export const useAgent = {
	getAllAgents: async () => await http.get('/user/get-agents'),
	createAgent: async (data) => await http.post('/user/create', data),
	updateAgent: async (data) => await http.post('/user/update', data),
	removeAgent: async (userId) => await http.delete('/user/delete/' + userId),
	checkAvailability: async (search) =>
		await http.get('/user/check-availability', {
			params: {
				search,
			},
		}),
};
