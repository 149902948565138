import { useEffect, useState } from 'react';
import {
	useAnnonce,
	usePublication,
} from '../../../service/annonce/useAnnonce';
import { successToast } from '../../../service/toast/showToast';
import { ButtonSpinner } from '../../commons/buttonSpinner';

export const PublicationCard = ({ publication, bien }) => {
	const [portal, setPortal] = useState();
	const [publish, setPublish] = useState(false);
	useEffect(() => {
		usePublication
			.getPortal(publication.advertiserPublication.portal.id)
			.then((res) => setPortal(res.data));
	}, []);

	const publishEstate = async () => {
		setPublish(true);
		await usePublication
			.publishOnPortal(bien, publication.id)
			.then(({ data }) => {
				successToast('Bien publié sur ' + portal.name);
				setTimeout(() => window.location.reload(), 500);
			})
			.catch(() => {})
			.finally(() => setPublish(false));
	};

	return (
		<>
			{!portal && (
				<div className='border border-[#2d5c6a] shadow rounded-md p-4 w-1/3 mx-auto'>
					<div className='animate-pulse flex space-x-4'>
						<div className='rounded-full bg-slate-700 h-20 w-20'></div>
						<div className='flex-1 space-y-6 py-1'>
							<div className='h-2 bg-slate-700 rounded'></div>
							<div className='space-y-3'>
								<div className='grid grid-cols-3 gap-4'>
									<div className='h-2 bg-slate-700 rounded col-span-2'></div>
									<div className='h-2 bg-slate-700 rounded col-span-1'></div>
								</div>
								<div className='h-2 bg-slate-700 rounded'></div>
							</div>
						</div>
					</div>
				</div>
			)}
			{portal && (
				<>
					<div className='border border-[#2d5c6a] shadow rounded-md p-4 w-1/3 mx-auto'>
						<div className='flex items-center'>
							<div className='rounded-full h-20 w-1/4'>
								<img
									src={portal.logoUrl}
									alt='Portal logo'
									className='h-full object-contain'
								/>
							</div>
							<div className='flex justify-end items-center w-3/4 gap-4'>
								<div className='font-bold'>Portail : {portal.name}</div>
								<div className='flex items-center gap-4 blue-color p-2 rounded-md text-white text-sm cursor-pointer'>
									<div
										title={
											(publication.publishable && 'Publiable') ||
											'Non publiable'
										}
										className={`w-4 h-4 rounded-full ${
											(publication.publishable && 'bg-green-500') ||
											'bg-red-500'
										}`}></div>

									<div
										title={(publication.selected && 'Publié') || 'Non publié'}
										className={`w-4 h-4 rounded-full ${
											(publication.selected && 'bg-green-500') || 'bg-red-500'
										}`}></div>
								</div>
							</div>
						</div>
						{!publication.selected && publication.publishable && (
							<div className='w-full flex justify-center'>
								<button
									className='default-color text-white items-center py-2 gap-3 px-4 flex font-bold rounded-md'
									onClick={publishEstate}
									disabled={publish}>
									PUBLIER{' '}
									<ButtonSpinner showLoading={publish} dimension='h-3 w-3' />
								</button>
							</div>
						)}
						{!publication.publishable &&
							publication.publicationIncompatibilities.length > 0 && (
								<div className='w-full px-4'>
									<h1 className='font-bold underline'>
										Les erreurs rencontrées:
									</h1>
									<ul className='list-disc'>
										{publication.publicationIncompatibilities.map(
											(item, index) => {
												return <li key={index}>{item.description}</li>;
											}
										)}
									</ul>
								</div>
							)}
					</div>
				</>
			)}
		</>
	);
};
