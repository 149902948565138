import style from './portals.module.css';
export const PortalCard = ({ portal }) => {
	return (
		<div className={style.portal__card}>
			<div className={style.portal__header}>
				<h1 className='text-center'>{portal.name}</h1>
				<div className='flex gap-2 items-center'>
					<div
						title={`${portal.active ? 'Active' : 'Inactive'}`}
						className={`${
							portal.active ? 'bg-green-500' : 'bg-red-500'
						} w-2 h-2 rounded-full`}></div>
					<div
						title={`${portal.free ? 'Gratuit' : 'Payant'}`}
						className={`${
							portal.free ? 'bg-blue-500' : 'bg-red-500'
						} w-2 h-2 rounded-full`}></div>
				</div>
			</div>
			<div className={style.portal__img}>
				<img
					src={portal.logoUrl}
					alt={`Portal ${portal.code}`}
					className='object-scale-down h-full'
				/>
			</div>
		</div>
	);
};
