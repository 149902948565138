import { useEffect, useState } from 'react';
import { Model1 } from './Model1';
import { Model2 } from './Model2';
import { Model3 } from './Model3';
import { Model4 } from './Model4';
import { Model5 } from './Model5';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GeneralSpinner } from '../../../../components/commons/general-spinner';

export const Model = ({ bien, model }) => {
	const [isDownloading, setIsDownloading] = useState(false);
	const generatePDF = () => {
		setIsDownloading(true);
		html2canvas(document.querySelector('#content-model'), {
			useCORS: true,
			allowTaint: false,
			logging: true,
		})
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF(model.disposition, 'mm', 'a4');
				const width = pdf.internal.pageSize.getWidth();
				const height = pdf.internal.pageSize.getHeight();
				pdf.addImage(imgData, 'PNG', -1, -1, width, height);

				pdf.save(model.title + '.pdf');
			})
			.finally(() => setIsDownloading(false));
	};

	useEffect(() => {}, []);

	return (
		<>
			<div id='content-model' className='p-5 text-2xl'>
				{model.name === 'model1' && <Model1 bien={bien} />}
				{model.name === 'model2' && <Model2 bien={bien} />}
				{model.name === 'model3' && <Model3 bien={bien} />}
				{model.name === 'model4' && <Model4 bien={bien} />}
				{model.name === 'model5' && <Model5 bien={bien} />}
			</div>
			<div className='text-right'>
				<button
					className='blue-color text-white uppercase font-bold px-4 py-2 rounded-xl'
					onClick={generatePDF}>
					Imprimer
				</button>
			</div>

			{isDownloading && <GeneralSpinner />}
		</>
	);
};
