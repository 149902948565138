import { useState } from 'react';
import { LocalisationGlobalService } from '../../../../service/models/localisation';
import { ButtonSpinner } from '../../buttonSpinner';
import { MapInput } from '../builder/elements/MapInput';
import { useFormContext } from 'react-hook-form';

export const SelectInputCountry = ({ element, style, register }) => {
	const { setValue, getValues } = useFormContext();
	const [showLoading, setShowLoading] = useState(false);
	const [searchResult, SetsearchResult] = useState([]);
	const [showChoices, setShowChoices] = useState(false);

	const getAddress = async () => {
		const value = getValues(element.name);
		const showChoices = value.length > 3 ? true : false;

		if (showChoices) {
			setShowLoading(true);
			try {
				const { data } =
					await LocalisationGlobalService.searchLocallisationGlobal(value);
				SetsearchResult(data);
				setShowLoading(false); // Mettre à false lorsque la recherche est terminée
			} catch (error) {
				console.error('Error fetching data:', error);
				setShowChoices(false);
				SetsearchResult([]);
				setShowLoading(false); // Mettre à false en cas d'erreur
			} finally {
				setShowChoices(showChoices);
			}
		} else {
			setShowChoices(false);
		}
	};

	const setAddress = (search) => {
		const data = search.name.common;
		setValue(element.name, data, {
			shouldValidate: true,
		});
		setShowChoices(false);
	};
	return (
		<>
			<div className='flex items-stretch w-full justify-between'>
				<input
					type='text'
					{...register(element.name, {
						...element.validation,
					})}
					className={`${style.simple__input} w-11/12`}
				/>
				<div className='w-1/12 flex justify-center items-center'>
					{!showLoading && (
						<i
							className='fa fa-search cursor-pointer p-4 rounded-lg bg-black/40'
							onClick={getAddress}></i>
					)}

					{showLoading && <ButtonSpinner showLoading={showLoading} />}
				</div>
			</div>

			{showChoices && (
				<div className='mt-0 z-5 h-64 overflow-y-auto  border w-full border-gray-300 text-white text-sm rounded-lg'>
					<ul className='w-full'>
						{Array.isArray(searchResult) && searchResult.length > 0 ? (
							searchResult.map((search, index) => (
								<li
									key={index}
									className={`p-5 cursor-pointer text-black hover:bg-[#666633] hover:text-white my-1 ${element.name}`}
									onClick={() => {
										setAddress(search, index);
									}}>
									{search.name.common}
								</li>
							))
						) : (
							<li className='p-5 text-gray-500'>Aucun résultat trouvé</li>
						)}
					</ul>
				</div>
			)}
		</>
	);
};
