import { useEffect, useState } from 'react';
import { Carousel } from '../../../commons/Carousel';
import {
	imageBienCouverture,
	imageBienSlide,
} from '../../../../helpers/image.source.';

export const QuickView = ({ bien, allowBackdrop, closeModal }) => {
	const [selectedImage, setSelectedImage] = useState(null);

	const handleImageClick = (image) => {
		if (allowBackdrop) {
			// Seulement fermer le modal si allowBackdrop est vrai
			setSelectedImage(image);
			closeModal();
		} else {
			// Ne pas fermer le modal si allowBackdrop est faux
			setSelectedImage(image);
		}
	};

	const [images, setImages] = useState([]);
	const fetchImage = async () => {
		const couvertureImagePromises = bien.photos.photos_couvert.map(
			async (photo) => {
				const imageUrl = await imageBienCouverture(photo.photo);
				return imageUrl;
			}
		);
		const couvertureImageUrls = await Promise.all(couvertureImagePromises);
		const slideImagePromises = bien.photos.photos_slide.map(async (photo) => {
			const imageUrl = await imageBienSlide(photo.photo);
			return imageUrl;
		});
		const slideImageUrls = await Promise.all(slideImagePromises);

		const allImageUrls = [...couvertureImageUrls, ...slideImageUrls];
		const multipliedImageUrls = Array.from(
			{ length: 5 },
			() => allImageUrls
		).flat();

		setImages(multipliedImageUrls);
	};

	useEffect(() => {
		fetchImage();
	}, []);

	return (
		<>
			<div className='flex lg:flex-row  sm:flex-col  flex-col justify-between p-2'>
				<div className='border-4 shadow-md w-full mt-4 border-solid bg-white p-3 rounded-md'>
					<h2 className='justify-center bg-slate-300 rounded-md p-2 text-2xl'>
						A propos du bien
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
							fill='currentColor'
							className='mr-1 inline h-8 w-8 mb-1 fill-blue-500'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z'
								clipRule='evenodd'></path>
						</svg>
					</h2>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Annonce :
						</label>
						<span className=' -text-xl'>
							<ul>
								<li className='flex mb-2 mt-2 ml-2'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span>Titre de l'annonce : {bien.advertisement.title}</span>
								</li>
							</ul>
						</span>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Prix du bien :
						</label>
						<ul className='mb-2 mt-2'>
							<li className='flex mb-2 mt-2 ml-2'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='mr-1 h-6 w-6 text-green-800'>
									<path
										fillRule='evenodd'
										d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
										clipRule='evenodd'
									/>
								</svg>

								<span> Prix du bien public : {bien.publish_price} Euro</span>
							</li>
							<li className='flex ml-2'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='mr-1 h-6 w-6 text-green-800'>
									<path
										fillRule='evenodd'
										d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
										clipRule='evenodd'
									/>
								</svg>
								<span> Prix du bien vendeur : {bien.selling_price} Euro</span>
							</li>
						</ul>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Type du bien :
						</label>
						<span className=' -text-xl'>
							<ul>
								<li className='flex mb-2 mt-2 ml-2'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span>Type du bien : {bien.type_estate.designation}</span>
								</li>
							</ul>
						</span>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Type d'offre :
						</label>
						<span className=' -text-xl'>
							<ul>
								<li className='flex mb-2 mt-2 ml-2'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span> Type d'offre : {bien.type_offert.designation}</span>
								</li>
							</ul>
						</span>
					</div>
				</div>
				<div className='border-4 mt-4 w-full shadow-md border-solid bg-white p-3 rounded-md'>
					<h2 className='justify-center bg-slate-300 rounded-md p-2 text-2xl'>
						Caractéristique du bien
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
							fill='currentColor'
							className='mr-1 inline h-8 w-8 mb-1 fill-blue-500'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z'
								clipRule='evenodd'></path>
						</svg>
					</h2>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Salle de bain :
						</label>
						<span className=' -text-xl'>
							<ul className='mb-2 mt-2'>
								<li className='flex ml-2'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span>
										Nombre de salle de bain :{' '}
										{bien.interior_detail?.nbOfBathroom}
									</span>
								</li>
							</ul>
						</span>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Salle d'eau :
						</label>
						<span className=' -text-xl'>
							<ul className='mb-2 mt-2 ml-2'>
								<li className='flex'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span>
										Nombre de salle d'eau : {bien.interior_detail?.nbOfRoomWater}
									</span>
								</li>
							</ul>
						</span>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Jardin :
						</label>
						<span className=' -text-xl'>
							<ul className='mb-2 mt-2 ml-2'>
								<li className='flex'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span>Jardin : {bien.garden_exist}</span>
								</li>
							</ul>
						</span>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Parking :
						</label>
						<ul className='mb-2 mt-2'>
							<li className='flex mb-2 mt-2 ml-2'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='mr-1 h-6 w-6 text-green-800'>
									<path
										fillRule='evenodd'
										d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
										clipRule='evenodd'
									/>
								</svg>

								<span> Interieur : {bien.indoor_parking}</span>
							</li>
							<li className='flex ml-2'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									fill='currentColor'
									className='mr-1 h-6 w-6 text-green-800'>
									<path
										fillRule='evenodd'
										d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
										clipRule='evenodd'
									/>
								</svg>
								<span> Exterieur : {bien.outdoor_parking}</span>
							</li>
						</ul>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Garage :
						</label>
						<span className=' -text-xl'>
							<ul className='mb-2 mt-2 ml-2'>
								<li className='flex'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span> Garage : {bien.number_garage}</span>
								</li>
							</ul>
						</span>
					</div>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Surface :
						</label>
						<span className=' -text-xl'>
							<ul className='mb-2 mt-2 ml-2'>
								<li className='flex'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-1 h-6 w-6 text-green-800'>
										<path
											fillRule='evenodd'
											d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
											clipRule='evenodd'
										/>
									</svg>

									<span> Surface :{bien.type_offert.designation}</span>
								</li>
							</ul>
						</span>
					</div>
				</div>
			</div>
			<div className='flex flex-col justify-between p-2 '>
				<div className='border-4 mt-2 shadow-md border-solid bg-white p-3 rounded-md'>
					<h2 className='justify-center bg-slate-300 rounded-md p-2 text-2xl'>
						Photos du bien
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
							fill='currentColor'
							className='mr-1 inline h-8 w-8 mb-1 fill-blue-500'>
							<path
								fillRule='evenodd'
								d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z'
								clipRule='evenodd'></path>
						</svg>
					</h2>
					<div className=' mt-3'>
						<label htmlFor='annonce' className=' font-bold rounded-md'>
							Photos :
						</label>
						<div className='flex flex-wrap gap-4 p-2 items-center justify-center'>
							<Carousel images={images} onClick={handleImageClick} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
