import { createHttp } from '../../http';
const http = createHttp();

export const FavorieService = {
	removeFavory: (favoryId) => {
		return http.post('/piges/favorie', { favory_id: favoryId });
	},
	createFavory: (pigeId) => {
		return http.post('/piges/favorie', { pige_id: pigeId });
	},
};
