export const TEMPLATE = {
	aucune: {
		mail: '',
		sms: '',
		title: '',
	},
	confirmation_visite: {
		title: 'Confirmation de Visite Immobilière - [Annonce_bien]',
		mail: `<p>Cher(e) [Nom_du_Client],</p><p>Nous sommes ravis de confirmer la visite du bien suivant [Annonce_bien] que nous avons planifié. Voici les détails de notre visite :</p><p>Date : [Date_du_rdv]</p>
        <p>    Heure : [Heure_de_rdv]</p>

        <p>Lieu : [Adresse_agence]</p>
        <p>Vous trouverez toutes les informations pertinentes concernant le bien dans l'annonce ci-dessous :
        [Insérer le lien cliquable vers l'annonce du bien]</p>
        <p>N'oubliez pas de prévoir suffisamment de temps pour la visite et de vous présenter ponctuellement à l'adresse convenu.</p>
        <p>Si vous avez besoin d'annuler ou de reporter la visite, veuillez nous contacter dès que possible afin que nous puissions réorganiser votre rendez-vous.</p>
        <p>Je suis impatient de vous rencontrer et de vous faire découvrir ce bien immobilier. Si vous avez des questions avant la visite, n'hésitez pas à me contacter.</p>
        <p>Cordialement,</p>
        <p>[Nom_Agent], [Nom_Agence]</p>`,
		sms: 'Bonjour [Nom_du_Client] ! Votre visite pour le bien situé à [Adresse_du_Bien] est confirmée pour [Date_du_rdv] à [Heure_de_rdv]. Rendez-vous à [Adresse_agence]. À très bientôt ! [Nom_Agent], [Nom_Agence].',
	},
	confirmation_rdv_signature_achat: {
		title:
			"Confirmation de Rendez-vous pour Signature de l'Offre d'Achat - [Nom_du_Client] - [Numero_de_mandat]",
		mail: `
<p>        Cher(e) [Nom_du_Client],</p>
<p>Nous sommes ravis de vous confirmer votre rendez-vous pour la signature de l'offre d'achat concernant le bien immobilier situé au [Adresse_du_Bien] - [Numero_de_mandat]. Voici les détails de votre rendez-vous :</p>
<p>Date : [Date_du_rdv]</p>
<p>Heure : [Heure_de_rdv]</p>
<p>Lieu : [Adresse_agence]</p>
<p>Nous vous accueillerons à notre agence immobilière à l'heure convenue pour finaliser l'offre d'achat pour le bien en question.</p>
<p>Si vous avez besoin d'informations supplémentaires avant la réunion ou si vous souhaitez apporter des modifications à ce rendez-vous, n'hésitez pas à nous contacter dès que possible.</p>
<p>Nous sommes impatients de vous accompagner dans ce processus. Merci de votre confiance en notre agence pour cette transaction immobilière importante.</p>
<p>Cordialement,</p>
<p>[Nom_Agent], [Nom_Agence]</p>
        `,
		sms: "Bonjour [Nom_du_Client] ! La signature de l'offre d'achat pour le bien situé à [Adresse_du_Bien] est confirmée pour [Date_du_rdv] à [Heure_de_rdv]. Rendez-vous à notre agence, [Adresse_agence]. Nous avons hâte de vous voir ! L'Équipe de [Nom_Agence].",
	},
	confirmation_rdv_signature_compromis_vente: {
		title:
			'Confirmation de Rendez-vous pour Signature de Compromis de Vente - [Nom_du_Client] - [Numero_de_mandat]',
		mail: `
        <p>Cher(e) [Nom_du_Client],</p>
        <p>Nous sommes heureux de vous confirmer votre rendez-vous pour la signature du compromis de vente concernant le bien immobilier situé au [Adresse_du_Bien] - [Numero_de_mandat]. Voici les détails de votre rendez-vous :</p>
        <p>Date : [Date_du_rdv]</p>
        <p>Heure : [Heure_de_rdv]</p>
        <p>Lieu : [Adresse_agence]</p>
        <p>Nous vous accueillerons chaleureusement à notre agence immobilière à l'heure convenue pour finaliser le compromis de vente pour le bien en question.</p>
        <p>Si vous avez des questions ou des préoccupations avant la réunion, n'hésitez pas à nous contacter. Nous sommes là pour vous aider et pour rendre ce processus aussi fluide que possible.</p>
        <p>Nous sommes impatients de vous voir et de concrétiser cette transaction immobilière importante avec vous.</p>
        <p>Cordialement,</p>
        <p>[Nom_Agent], [Nom_Agence]
</p>
        `,
		sms: "Bonjour [Nom_du_Client] ! La signature du compromis de vente pour le bien situé à [Adresse_du_Bien] est confirmée pour [Date_du_rdv] à [Heure_de_rdv]. Rendez-vous à [Adresse_agence]. À très bientôt ! L'Équipe de [Nom_Agence].",
	},
	confirmation_rdv_signature_bail_location: {
		title:
			'Confirmation de Rendez-vous pour Signature de Bail de Location - [Nom_du_Client] - [Numero_de_mandat]',
		mail: `
        <p>Cher(e) [Nom_du_Client],
</p><p>Nous sommes heureux de vous confirmer votre rendez-vous pour la signature du bail de location concernant le bien immobilier situé au [Adresse_du_Bien] - [Numero_de_mandat]. Voici les détails de votre rendez-vous :
</p><p>Date : [Date_du_rdv]
</p><p>Heure : [Heure_de_rdv]
</p><p>Lieu : [Adresse_agence]
</p><p>Nous vous accueillerons à notre agence immobilière à l'heure convenue pour finaliser la signature du bail de location pour le bien en question.
</p><p>Avant la réunion, veuillez-vous assurer de vous munir de tous les documents nécessaires et de poser toutes les questions que vous pourriez avoir concernant le bail.
</p><p>Si vous avez des préoccupations ou des questions supplémentaires avant la réunion, n'hésitez pas à nous contacter. Nous sommes là pour vous aider et pour rendre ce processus aussi fluide que possible.
</p><p>Nous sommes impatients de vous rencontrer et de conclure cette location avec vous.
</p><p>Cordialement,
</p><p>[Nom_Agent], [Nom_Agence]
</p>

        `,
		sms: 'Bonjour [Nom_du_Client] ! La signature du bail pour le bien situé à [Adresse_du_Bien] est confirmée pour [Date_du_rdv] à [Heure_de_rdv]. Rendez-vous à notre agence, [Adresse_agence]. À très bientôt ! [Nom_Agent], [Nom_Agence].',
	},
};

export const changeVariable = (text, variable, value) =>
	text.replace(variable, value);

export const VARIABLES = [
	'[Date_du_rdv]',
	'[Heure_de_rdv]',
	'[Adresse_agence]',
	'[Nom_Agent]',
	'[Nom_Agence]',
];

export const changeVariableForClient = (defaultValue, client) => {
	let title = changeVariable(
		defaultValue,
		`[Nom_du_Client]`,
		`${client[1].firstname} ${client[1].lastname}`
	);
	title = changeVariable(
		title,
		`${client[0].firstname} ${client[0].lastname}`,
		`${client[1].firstname} ${client[1].lastname}`
	);
	return title;
};

export const changeVariableForEstate = (defaultValue, estate) => {
	let value = changeVariable(
		defaultValue,
		`[Adresse_du_Bien]`,
		`${estate[1].city} - ${estate[1].zap_country}, ${estate[1].name_country}`
	);
	value = changeVariable(
		value,
		`${estate[0].city} - ${estate[0].zap_country}, ${estate[0].name_country}`,
		`${estate[1].city} - ${estate[1].zap_country}, ${estate[1].name_country}`
	);

	value = changeVariable(
		value,
		`[Numero_de_mandat]`,
		`${estate[1].num_folder}`
	);
	value = changeVariable(
		value,
		`${estate[0].num_folder}`,
		`${estate[1].num_folder}`
	);

	value = changeVariable(
		value,
		`[Annonce_bien]`,
		`${estate[1].advertisement.title}`
	);
	value = changeVariable(
		value,
		`${estate[0].advertisement.title}`,
		`${estate[1].advertisement.title}`
	);

	return value;
};
