import { BienText } from "./text/BienText";
import { ContactText } from "./text/ContactText";
import { EstimationText } from "./text/EstimationText";
import { UserText } from "./text/UserText";

export const GlobalNews = (data) => {
   
    const formEntity = (news) => {
    return news.entity.split('\\')[news.entity.split('\\').length - 1];
    };
    return (
       <>
       
       { formEntity(data.news) === 'Contact' && <ContactText actus={data.news} />}

       { formEntity(data.news) === 'Bien' && <BienText actus={data.news} />}

       { formEntity(data.news) === 'Estimation' && <EstimationText actus={data.news} />}

       {  formEntity(data.news) === 'User' && <UserText actus={data.news } />}
       </>
        
    )
}