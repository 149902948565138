import React, { useState, useEffect } from 'react';

export const Carousel = ({ images, onClick }) => {
	const [startIndex, setStartIndex] = useState(0);
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		// Vérifier si l'écran est de petite taille
		const handleResize = () => {
			setIsMobile(window.innerWidth < 640);
		};

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const nextSlide = () => {
		if (startIndex + (isMobile || images.length < 3 ? 1 : 3) < images.length) {
			setStartIndex((prevIndex) => prevIndex + 1);
		}
	};

	const prevSlide = () => {
		if (startIndex > 0) {
			setStartIndex((prevIndex) => prevIndex - 1);
		}
	};

	return (
		<div className='relative overflow-hidden'>
			<div className='flex items-center justify-center'>
				{images
					.slice(
						startIndex,
						startIndex + (isMobile || images.length < 3 ? 1 : 3)
					)
					.map((image, index) => (
						<div
							key={index}
							className={`relative mx-auto w-full transition-transform`}>
							<img
								src={image}
								alt={`Image ${index}`}
								onClick={() => onClick(image)}
								className='w-full object-cover rounded-md h-48'
							/>
						</div>
					))}
			</div>
			{startIndex > 0 && (
				<button
					className='absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-full z-10 opacity-50 transition-opacity duration-300 hover:opacity-100'
					onClick={prevSlide}>
					Prev
				</button>
			)}
			{startIndex + (isMobile || images.length < 3 ? 1 : 3) < images.length && (
				<button
					className='absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-full z-10 opacity-50 transition-opacity duration-300 hover:opacity-100'
					onClick={nextSlide}>
					Next
				</button>
			)}
		</div>
	);
};
