import { createHttp } from '../../http';

const http = createHttp();

const register = async (registerForm) => {
	return await http.post(`/auth/register`, registerForm);
};

const login = async (loginForm) => {
	return http.post(`/auth/login`, loginForm);
};

const resetPassword = async (data) => {
	return http.post('/auth/reset-password', data);
};
const reset = async (data) => {
	return http.post('/auth/reset', data);
};
const validateRegistrationCode = async (data) => {
	return http.post('/auth/two-factor', data);
};

export { register, login, resetPassword, reset, validateRegistrationCode };
