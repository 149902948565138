import { BlocCard } from '../../components/generals/card/bloc';

export const HomePige = () => {
	return (
		<>
			<div className='flex flex-wrap justify-center items-center mb-8'>
				<div className='w-fullbg-white'>
					<img
						className='w-full object-cover h-full'
						alt='Ma pige'
						src='/images/illustration_logiciel_pige2.jpg'
					/>
				</div>
			</div>
			<div className='flex flex-wrap justify-between items-center w-full gap-1'>
				<BlocCard
					blocTitle={'Dashboard'}
					link={'dashboard'}
					className={'w-full sm:w-1/5'}
				/>
				<BlocCard blocTitle={'Recherche'} className={'w-full sm:w-1/5'} />
				<BlocCard
					blocTitle={'Alerte'}
					link={'alerte'}
					className={'w-full sm:w-1/5'}
				/>
				<BlocCard
					blocTitle={'Agenda'}
					link='angeda'
					className={'w-full sm:w-1/5'}
				/>
			</div>
		</>
	);
};
