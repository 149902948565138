import { useEffect, useState } from 'react';
import { FilterSection } from '../../components/generals/biens/section/filter.section';
import { useParams } from 'react-router-dom';
import { useContact } from '../../service/contact/useContact';
import { RapprochementSection } from './details/RapprochementSection';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { Pagination } from '../../components/commons/pagination';

export const RapprochementContactPage = () => {
	const [contacts, setContact] = useState([]);
	const [shouldRerender, setShouldRerender] = useState(false);
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const { filter } = useParams();
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);
	const fetchContact = () => {
		setLoading(true);
		const params = Object.entries(search)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');
		const filterParams = filter ? `filter=${filter}` : '';
		useContact
			.allContact(currentPage, filterParams, params)
			.then((response) => {
				setContact(response.data.data);
				setPagination({
					links: response.data.links,
					per_page: response.data.per_page,
					total: response.data.total,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const reload = () => {
		setShouldRerender(true);
	};

	useEffect(() => {
		fetchContact();
	}, [currentPage, filter, search, shouldRerender]);

	const handleChangePage = (page) => setCurrentPage(page);

	const handleFilterChange = (filterValue) => {
		setSearch(filterValue);
	};

	return (
		<div className=''>
			<FilterSection onFilterChange={handleFilterChange} />
			<div className='border-1 border-solid border-black m-1 w-full'>
				{contacts &&
					contacts.length !== 0 &&
					contacts.map((contact, index) => {
						return <RapprochementSection contact={contact} key={index} />;
					})}
				{loading ? (
					<div className='flex items-center justify-center w-full'>
						<ButtonSpinner dimension='w-24 h-24' showLoading={loading} />
					</div>
				) : (
					contacts.length === 0 && (
						<div className='flex flex-wrap justify-center h-full items-center'>
							<h1 className='text-4xl'>Aucun contact n'est disponible</h1>
						</div>
					)
				)}

				{contacts.length !== 0 &&
					pagination.links &&
					pagination.links.length !== 0 && (
						<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
							{pagination.links
								.slice(1, pagination.links.length)
								.slice(0, -1)
								.map((link, index) => {
									return (
										<Pagination
											changePage={handleChangePage}
											key={index}
											link={link}></Pagination>
									);
								})}
						</div>
					)}
			</div>
		</div>
	);
};
