import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MainRouter } from './router';
import { Toaster } from 'react-hot-toast';
import 'survey-core/defaultV2.min.css';
import 'survey-core/i18n/french';
import Pusher from 'pusher-js';
import { getStorageUser } from './service/localStorage';
import { NotificationStep } from './components/generals/suivi/NotificationStep';

const App = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState();
	useEffect(() => {
		var pusher = new Pusher('4a993c974f24c4047378', {
			cluster: 'mt1',
		});

		var channel = pusher.subscribe('notification');
		channel.bind('incomplete', function (data) {
			setIsOpen(true);
			setStep(data[0]);
		});
	}, []);

	return (
		<div className='App'>
			<HelmetProvider>
				<BrowserRouter>
					<MainRouter />
				</BrowserRouter>
			</HelmetProvider>
			<Toaster
				toastOptions={{
					duration: 2000,
					position: 'top-right',
				}}
			/>
			{isOpen && (
				<NotificationStep
					isOpen={isOpen}
					handleClose={() => setIsOpen(false)}
					step={step}
				/>
			)}
		</div>
	);
};

export default App;
