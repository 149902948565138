import { useEffect, useState } from 'react';
import { Button } from '../../components/commons/button';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { Modal } from '../../components/commons/modal/modal';
import { configService } from '../../service/api/config';
import { errorToast, successToast } from '../../service/toast/showToast';

export const PigeConfiguration = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [isCreation, setIsCreation] = useState(true);
	const [currentPostal, setCurrentPostal] = useState(null);
	const [codePostaux, setCodePostaux] = useState();
	const [codePostal, setCodePostal] = useState();

	const clickRemove = (code) => {
		setCodePostal(code);
		setIsOpenDelete(true);
	};
	const registerPostalCode = async () => {
		try {
			configService
				.postConfiguration(currentPostal)
				.then(() => {
					setCurrentPostal(null);
					setIsOpen(false);
					successToast('Enregistrement du code postal avec succès');
					configService
						.getConfiguration()
						.then(({ data }) => setCodePostaux(data.configurations));
				})
				.catch((e) => console.log(e));
		} catch {}
	};

	const validatePostalCode = (value) => {
		if (value.length >= 5) {
			if (/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/.test(value))
				return setCurrentPostal(value);
			errorToast('Le code postal que vous avez saisi est incorrect');
		}
	};
	const removePostalCode = async () => {
		try {
			configService
				.removeConfiguration(codePostal?.id)
				.then(() => {
					setCodePostal(null);
					setIsOpenDelete(false);
					successToast('Suppression du code postal avec succès');
					configService
						.getConfiguration()
						.then(({ data }) => setCodePostaux(data.configurations));
				})
				.catch((e) => console.log(e));
		} catch {}
	};

	useEffect(() => {
		configService
			.getConfiguration()
			.then(({ data }) => setCodePostaux(data.configurations));
	}, []);
	return (
		<>
			<Breadcumbs title={'Configuration Ma Pige'} />
			<div className='border-2 border-solid border-black/40 p-4'>
				<div className='w-full flex justify-between items-center'>
					<h1 className='font-bold underline w-1/2'>
						Codes postaux associés :
					</h1>
					<Button
						onClick={() => setIsOpen(true)}
						className={
							'py-4 bg-green-700 text-white rounded-xl px-8 font-bold'
						}>
						Nouveau
					</Button>
				</div>
				<div className='w-full mt-8'>
					<table className='border-collapse border border-slate-400 w-full text-center'>
						<thead>
							<tr>
								<th className='font-bold blue-color text-white border border-slate-300 h-12'>
									Code postal
								</th>
								<th className='font-bold blue-color text-white border border-slate-300 h-12'>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{codePostaux &&
								codePostaux.map((code, index) => (
									<tr key={index}>
										<td className='border border-slate-300 font-bold'>
											{code.code_postal}
										</td>
										<td className='border border-slate-300'>
											<i
												onClick={() => clickRemove(code)}
												className='fa fa-trash text-orange-500 cursor-pointer'
												title='Supprimer ce code postal'></i>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
			<Modal
				id={'code-postal'}
				width='w-3/4 sm:w-1/2'
				height=''
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				allowBackdrop={false}
				allowForceClose={true}
				modalTitle={'Ajouter un nouveau code postal'}>
				{isCreation && (
					<h1 className='text-green-800 my-4'>
						( * ) Vous pouvez enregistrer un nouveau code postal ici
					</h1>
				)}
				<div className='flex gap-4 items-center w-full'>
					<label htmlFor='code_postal' className='w-1/4'>
						Code postal :
					</label>
					<input
						id='code_postal'
						type='text'
						onChange={(e) => validatePostalCode(e.target.value)}
						className='border-2 h-[40px] border-solid border-black/70 w-2/3'
					/>
					<button
						onClick={() => registerPostalCode()}
						className='blue-color py-2 rounded px-4 disabled:bg-slate-400 text-white uppercase font-bold'
						disabled={currentPostal === null || currentPostal === ''}>
						{(isCreation && 'Enregistrer') || 'Modifier'}
					</button>
				</div>
			</Modal>
			{codePostal && (
				<Modal
					id={'remove-code-postal'}
					width='w-3/4 sm:w-1/3'
					height=''
					isOpen={isOpenDelete}
					handleClose={() => {
						setCodePostal(null);
						setIsOpenDelete(false);
					}}
					allowBackdrop={false}
					modalTitle={'Suppression de code postal'}
					allowForceClose={true}>
					<div className='flex flex-wrap justify-center items-center my-4'>
						<h1 className='text-center font-bold text-red-600 w-full my-4'>
							Etes-vous sûr de vouloir supprimer ce code postal ?{' '}
							<span className='grey-color p-2'>{codePostal.code_postal}</span>
						</h1>
						<div className='my-4 w-full'>
							En supprimant ce code postal, vous ne recevrez plus d'annonce sur
							ce secteur.
						</div>
						<Button
							onClick={() => removePostalCode()}
							className={
								'px-6 py-2 font-bold rounded-md text-white blue-color'
							}>
							Supprimer
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};
