import { BlocCard } from '../../components/generals/card/bloc';
import style from './export.module.css';
const PARTS = [
	{ text: 'PORTAILS ACTIFS', link: 'portals' },
	{ text: 'BIENS À DIFFUSER', link: 'lists' },
	{ text: 'ETAT DE DIFFUSION', link: 'state' },
];
export const ExportDashboard = () => {
	return (
		<div className={style.dashboard}>
			{PARTS.map((item, index) => (
				<BlocCard
					key={index}
					blocTitle={item.text}
					link={item.link}
					className={style.card}
				/>
			))}
		</div>
	);
};
