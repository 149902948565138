import { useEffect, useState } from 'react';
import { clearStorage, getStorageUser } from '../../../service/localStorage';
import { imageBackend } from '../../../helpers/image.source.';

export const Logo = () => {
	const [fullname] = useState(getStorageUser()?.user.name || 'Thomas MARECHAL');

	const DATE = window.moment ? window.moment() : null;

	const [time, setTime] = useState(new Date());

	const logout = () => {
		clearStorage();
		window.location.href = '/login';
	};
	useEffect(() => {
		let timer = setInterval(() => setTime(new Date()), 1000);

		return () => clearInterval(timer);
	});
	return (
		<div className='w-full mx-auto mb-6 p-0 pr-4'>
			<div className='lg:absolute sm:inherit lg:top-0 lg:right-4 w-full  text-right capitalize'>
				{DATE?.format('dddd D MMMM YYYY')}
			</div>
			<div
				className='lg:absolute sm:inherit lg:right-4 lg:w-1/5 sm:w-full  text-right'
				style={{ top: '5%' }}>
				{time.toLocaleTimeString()}
			</div>
			<img
				src={
					getStorageUser().user.agency.agency_logo
						? imageBackend(getStorageUser().user.agency.agency_logo)
						: '/images/LOGO_MAPIM_IMMO_BLANC.jpg'
				}
				className='mx-auto w-[200px] h-[200px] rounded-full'
				alt='Logo of society'
			/>
			<div className='text-center'>
				<h1>
					BIENVENUE {fullname}
					{'  '}
					<span
						onClick={() => logout()}
						className='text-blue-500 hover:underline cursor-pointer'>
						( Déconnexion )
					</span>
				</h1>
			</div>
		</div>
	);
};
