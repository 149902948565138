export const CommentPige = ({ comment }) => {
	return (
		<div className='absolute top-10 -left-2   text-center  text-white cursor-pointer flex flex-wrap'>
			<div className='w-full bg-[#2577ff] p-2 rounded-t-xl rounded-br-xl'>
				<i className='fa-solid fa-comment-dots mr-2'></i> {comment}
			</div>
			<div
				className='w-full'
				style={{
					backgroundImage:
						"url('https://app.pige-online.fr/images/featured.svg')",
					backgroundRepeat: 'no-repeat',
					width: '16px',
					height: '16px',
				}}></div>
		</div>
	);
};
