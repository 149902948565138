import { useState } from 'react';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { resetPassword } from '../../service/auth/authentication';

export const ResetPassword = () => {
	const [showLoading, setShowLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [submited, setSubmited] = useState(false);
	const submitForm = async (e) => {
		setShowLoading(true);
		e.preventDefault();
		const email = e.target.elements[0].value;
		await resetPassword({ email })
			.then(() => setSubmited(true))
			.catch(() => {});
	};
	return (
		<div className='flex items-center justify-around gap-1 flex-wrap pt-20'>
			<div className='basis-full lg:basis-1/2'>
				<div className='flex flex-col w-full mx-auto gap-6 p-4 lg:p-20 bg-slate-200 rounded-lg justify-center'>
					{!submited && (
						<div>
							<img
								src='/favicon.ico'
								className='w-1/4 mx-auto rounded-full'
								alt='Logo'
							/>
							<p className='my-4 text-center'>
								Entrez votre email ici et on va vous envoyer un email pour
								pouvoir récuperer votre compte si ce compte existe chez nous.
							</p>
							<form action='' onSubmit={submitForm}>
								<input
									type='email'
									name=''
									onChange={(e) => setEmail(e.target.value)}
									required
									className='w-full border-[2px] border-solid p-2 rounded-xl outline-none focus:border-[#104278]'
								/>
								<button className='w-full text-center mt-2 default-color py-2 rounded-xl text-white flex justify-center gap-2 items-center'>
									ENVOYER LE MAIL DE RÉCUPERATION
									<ButtonSpinner showLoading={showLoading} />
								</button>
							</form>
						</div>
					)}
					{submited && (
						<div className='flex flex-wrap justify-center'>
							<i className='fa-6x fa-solid fa-circle-check text-green-500'></i>
							<p className='text-center my-4 text-2xl w-full'>
								Un mail a été envoyé à {email}, si ce compte existe, vous allez
								recevoir un email sous peu, sinon, ce que ce compte n'existe pas
								chez nous.
							</p>
							<button
								onClick={() => (window.location.href = '/login')}
								className='w-full text-center mt-2 default-color py-2 rounded-xl text-white flex justify-center gap-2 items-center'
								type='button'>
								Revenir à la page de connexion
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
