import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { AuthenticationRoute } from './modules/AuthenticationRoute';

export const MainRouter = () => {
	return (
		<HelmetProvider>
			<Routes>
				<Route path='/*' element={<AuthenticationRoute />}></Route>
			</Routes>
		</HelmetProvider>
	);
};
