import { BlocCard } from '../../components/generals/card/bloc';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';

const BLOC = [
	{
		text: 'Dossier de vente',
		link: 'filter/vente',
	},
	{
		text: 'Dossier de location',
		link: 'filter/location',
	},
	{
		text: 'Mes suivis de dossier',
		link: 'index',
	},
];

export const Suivi = () => {
	return (
		<>
			<Breadcumbs title={'Mes suivis de dossier'} />
			<div className='flex gap-2 gap-y-4 justify-between items-center flex-wrap-reverse'>
				{BLOC.map((item, index) => (
					<BlocCard
						blocTitle={item.text}
						link={item.link}
						key={index}
						className={'h-64 sm:w-1/3 w-3/4'}
					/>
				))}
			</div>
		</>
	);
};
