import { useEffect, useState } from 'react';
import { Modal } from '../../commons/modal/modal';
import { useSuiviDossier } from '../../../service/suivi/useSuiviDossier';
import { ButtonSpinner } from '../../commons/buttonSpinner';
import { Spinner } from '../../commons/Spinner';

export const GestionElement = ({
	steps,
	handleClose,
	showModal,
	updatedBien,
}) => {
	const [updatedStep, setUpdatedStep] = useState(false);
	const updateStep = async (action, step) => {
		setShowLoading(true);
		await useSuiviDossier
			.updateOrRemoveStep(step, action)
			.then(() => {
				updatedBien();
			})
			.finally(() => {
				setTimeout(() => setShowLoading(false), 1500);
			});
	};
	const [showLoading, setShowLoading] = useState(false);
	// useEffect(() => {}, [setUpdatedStep]);

	return (
		<>
			<Modal
				width='w-11/12 sm:w-10/12 md:w-11/12 xl:w-2/3 '
				height='min-h-2/3 sm:min-h-1/2'
				isOpen={showModal}
				handleClose={handleClose}
				allowForceClose={true}
				modalTitle={'Gestion des élements'}
				allowBackdrop={false}>
				<div className='my-4 flex flex-wrap gap-x-2 gap-y-4 justify-between'>
					{steps.map((item, index) => (
						<div
							key={index}
							className='flex w-full sm:w-1/3 md:w-1/4 items-start justify-between px-4 py-2 rounded-md border-2 border-solid'>
							<i
								onClick={() => updateStep('update', item)}
								className={`fa cursor-pointer fa-toggle-${
									item.active ? 'on' : 'off'
								}`}></i>
							<h1 className='font-bold break-words px-4'>{item.step_name}</h1>
							<div className='blue-color text-white p-2 rounded-md cursor-pointer flex gap-2 items-center'>
								<i
									className='fa fa-trash'
									onClick={() => updateStep('delete', item)}></i>
							</div>
						</div>
					))}
				</div>
				{showLoading && (
					<div className='w-screen h-screen flex items-center justify-center bg-black/90 absolute top-0 left-0'>
						<ButtonSpinner dimension='w-24 h-24' showLoading={true} />
					</div>
				)}
			</Modal>
		</>
	);
};
