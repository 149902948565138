import { requiredField } from '../../validation/validation';
const CritaireBien = {
	//Critère
	group: 'info_personnelle',
	type: 'select',
	tagName: 'classification_estate.id',
	name: 'criteria',
	className: 'w-full ',
	validation: {
		validate: {
			requiredField,
		},
	},
	title: 'Critère  :',
	choices: [
		{
			title: 'Maison',
			value: '1',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Villa',
			value: '2',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Mas',
			value: '3',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Bastide',
			value: '4',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Propriété',
			value: '5',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Chateau',
			value: '6',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Rez de villa',
			value: '7',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},

		{
			title: 'Ferme',
			value: '8',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Longère',
			value: '9',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['1'] },
			],
			show: false,
		},
		{
			title: 'Appartement',
			value: '10',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['2'] },
			],
			show: false,
		},
		{
			title: 'Studio',
			value: '11',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['2'] },
			],
			show: true,
		},
		{
			title: 'Duplex',
			value: '12',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['2'] },
			],
		},
		{
			title: 'Triplex',
			value: '13',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['2'] },
			],
			show: false,
		},
		{
			title: 'Rez de jardin',
			value: '14',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['2'] },
			],
			show: false,
		},
		{
			title: 'Loft',
			value: '15',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['2'] },
			],
			show: false,
		},
		{
			title: 'Terrain',
			value: '16',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},
		{
			title: 'Terrain agricole',
			value: '17',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},
		{
			title: 'Terrain de loisir',
			value: '18',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},
		{
			title: 'Terrain à batir',
			value: '19',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},
		{
			title: 'Terrain en lotissement',
			value: '20',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},
		{
			title: 'Terrain à diffus',
			value: '21',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},

		{
			title: 'Terrain viabilisé',
			value: '22',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},

		{
			title: 'Terrain naturel et forestier',
			value: '23',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['3'] },
			],
			show: false,
		},

		{
			title: 'Autre',
			value: '24',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: false,
		},
		{
			title: 'Cabanon',
			value: '25',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: false,
		},
		{
			title: 'Chalet',
			value: '26',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: false,
		},
		{
			title: 'Cave',
			value: '27',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: false,
		},
		{
			title: 'Garage',
			value: '28',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: false,
		},
		{
			title: 'Parking',
			value: '29',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2', '3'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: true,
		},
		{
			title: 'Immeuble',
			value: '30',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: true,
		},

		{
			title: 'Viager',
			value: '31',
			visible: [
				{ condition: 'EQUAL', field: 'type_offert_id', value: ['1'] },
				{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['2', '1'] },
				{ condition: 'EQUAL', field: 'type_estate_id', value: ['4'] },
			],
			show: false,
		},
	],
	visible: [
		{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
		{
			condition: 'EQUAL',
			field: 'type_offer_suite_id',
			value: ['1', '2', '3'],
		},
		{
			condition: 'EQUAL',
			field: 'type_estate_id',
			value: ['1', '2', '3', '4'],
		},
	],
};

export { CritaireBien };
