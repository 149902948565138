export const AdvertissmentSection = ({ pige }) => {
	return (
		<div className='my-4'>
			<h1 className='text-2xl font-bold mb-4'>Informations sur l'annonce</h1>
			<div
				className='p-4 bg-[#fbfcff] w-full flex flex-wrap justify-between items-center'
				style={{ border: '1px solid #E9F1FF' }}>
				<div className='w-full flex justify-center items-center gap-4 cursor-pointer'>
					<div className='w-10 h-10 grey-color flex justify-center items-center rounded-full'>
						<i className={`fa-solid text-blue-600 fa-desktop`}></i>
					</div>
					<div>
						Relevé sur {Object.values(JSON.parse(pige.sources)[0])[1]} le{' '}
						{new Date(pige.date).toLocaleDateString('fr-FR')}
					</div>
					<div
						className='w-10 h-10 grey-color flex justify-center items-center rounded-full'
						onClick={() =>
							(window.location.href = Object.values(
								JSON.parse(pige.sources)[0]
							)[0])
						}>
						<i className={`fa-solid text-blue-600  fa-link`}></i>
					</div>
				</div>
			</div>
		</div>
	);
};
