import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BienRapprochementSection } from './details/BienRapprochementSection';
import { useEffect, useState } from 'react';
import { BienSection } from '../../components/generals/biens/section/bien.section';
import StatusSection from '../../components/generals/biens/section/status.section';
import BienService from '../../service/bien/BienService';

export const BienRapprochementPage = () => {
	const location = useLocation();
	const [bien, setBien] = useState();
	const { bienId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		
		if (!bien)
			BienService.getBienById(bienId)
				.then((res) => {
					setBien(res.data);
				})
				.catch(() => {});
	}, [bienId, bien, location]);

	return (
		bien && (
			<>
				<div className=''>
					<BienSection bien={bien} />
				</div>

				<div className='border-2 border-solid border-[#2d5c6a]'>
					<StatusSection location={location} bien={bien} />

					<BienRapprochementSection bien={bien} />
				</div>
			</>
		)
	);
};
