import { useState } from 'react';
import { Input } from '../../commons/input';
import { useNavigate } from 'react-router-dom';

export const SearchBar = () => {
	const [search, setSearch] = useState('');
	const navigate = useNavigate();
	const searchData = () => {
		navigate('/search?q=' + search);
	};
	return (
		<div
			className='mb-5 flex mx-auto rounded items-stretch lg:w-3/4 sm:w-full'
			style={{ border: '3px solid rgb(45, 92, 106)' }}>
			<Input
				type='text'
				className={'w-11/12 mx-auto h-6 outline-none pl-1'}
				value={search}
				onKeyUp={(e) => {
					if (e.code === 'Enter') searchData();
				}}
				onChange={(e) => setSearch(e.target.value)}
			/>
			<div
				onClick={searchData}
				className='w-1/12 flex justify-center items-center cursor-pointer'
				style={{ backgroundColor: 'rgb(45, 92, 106)' }}>
				<i className='fa-solid fa-magnifying-glass text-white'></i>
			</div>
		</div>
	);
};
