import { useEffect, useState } from 'react';
import { RadioInput } from './RadioInput';
import { SelectInput } from './SelectInput';
import { useWatch } from 'react-hook-form';
import { FileInput } from './FileInput';
import { AdresseInput } from './AdresseInput';
import { SelectInputCountry } from '../../elements/SelectInputCountry';
import { MapInput } from './MapInput';
import { Button } from '../../../button';
import { PricingInput } from './PricingInput';
import { ButtonSpinner } from '../../../buttonSpinner';
import { LocalityCity } from './CityLocality';
import { getStorageUser } from '../../../../../service/localStorage';
import { SelectCity } from '../../elements/selectCity';

const simpleInputType = [
	'text',
	'password',
	'date',
	'number',
	'email',
	'tel',
	'url',
	'search',
];
export const ElementForm = ({
	element,
	style,
	setValue,
	register,
	unregister,
	control,
	errors,
	formState,
	dataUpdate,
}) => {
	const [shouldVisibleNow, setShouldVisibleNow] = useState(true);

	const shouldVisible = () => {
		const isAdmin = getStorageUser()?.user.role === '777';
		if (element.is_admin_only && !isAdmin) {
			setShouldVisibleNow(false);
			return;
		}
		let shouldShow = [];
		if (element.visible) {
			element.visible.forEach((visible) => {
				// Si c'est un radio, alors mettre le checked dans le querySelector
				let doc = document.querySelector(`[name=${visible.field}]`);
				if (doc?.type === 'radio')
					doc = document.querySelector(`[name=${visible.field}]:checked`);

				// LA CONDITION DE VISIBILITÉ EST FAIT DE SORTE QUE LA VALEUR DU FIELD SOIT EGALE À LA VALEUR DONNÉE
				// TRAITER LES VALEURS AVEC UNE CONDITION AND

				if (visible.condition === 'INEQUAL') {
					shouldShow.push(!visible.value.includes(doc?.value));
				} else {
					if (doc?.type === 'file') shouldShow.push(doc?.files.length > 0);
					else shouldShow.push(visible.value.includes(doc?.value));
				}
			});
		}

		setShouldVisibleNow(shouldShow.every((val) => val === true));
	};
	// Watch all fields
	const watchAllFields = control();
	useEffect(() => {
		shouldVisible();
	}, [watchAllFields]);

	return (
		shouldVisibleNow && (
			<div className={style.container}>
				{element.title && (
					<label htmlFor={element.name}>
						{element.title}{' '}
						{element.validation?.validate?.requiredField && <>(*)</>}
					</label>
				)}
				{simpleInputType.includes(element.type) && (
					<>
						<input
							id={element.name}
							type={element.type}
							className={style.simple__input}
							{...register(element.name, { ...element.validation })}
						/>
					</>
				)}
				{element.type === 'radio' && (
					<RadioInput
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={watchAllFields}
					/>
				)}

				{element.type === 'select' && (
					<SelectInput
						style={style}
						errors={errors}
						element={element}
						register={register}
						control={watchAllFields}
					/>
				)}

				{element.type === 'selectInput' && (
					<AdresseInput
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
					/>
				)}

				{element.type === 'locality_city' && (
					<LocalityCity
						style={style}
						element={element}
						errors={errors}
						register={register}
						unregister={unregister}
						control={control}
					/>
				)}
				{element.type === 'seletCity' && (
					<SelectCity
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
					/>
				)}

				{element.type === 'selectInputCountry' && (
					<SelectInputCountry
						element={element}
						register={register}
						control={control}
						style={style}
					/>
				)}

				{element.type === 'map' && (
					<MapInput
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={watchAllFields}
					/>
				)}

				{element.type === 'button' && (
					<button
						type='button'
						className='w-[20%] text-white bg-blue-500 hover:bg-blue-700 rounded-md p-3 flex flex-wrap items-start justify-center'
						onClick={() => element.click(watchAllFields)}>
						{element.children}
						<ButtonSpinner showLoading={element.loading} />
					</button>
				)}
				{element.type === 'pricing' && (
					<PricingInput
						formState={watchAllFields}
						style={style}
						element={element}
						errors={errors}
						register={register}
						setValue={setValue}
						control={control}
					/>
				)}

				{element.type === 'file' && (
					<FileInput
						style={style}
						element={element}
						errors={errors}
						register={register}
						control={control}
					/>
				)}
				{element.type === 'textarea' && (
					<textarea
						className={style.text__area}
						{...register(element.name, { ...element.validation })}></textarea>
				)}

				{errors[element.name]?.message && (
					<div className='text-red-600 w-full'>
						{errors[element.name]?.message}
					</div>
				)}
			</div>
		)
	);
};
