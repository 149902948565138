import { useEffect, useState } from 'react';
import { BackgroundColor } from '../../../../helpers/backgroundcolor';
import { useNavigate } from 'react-router-dom';

export const BienText = (actus) => {
	const [contentText, setContentText] = useState('');

	const navigation = useNavigate();

	const navigate = () =>
		navigation(
			'/mes-biens/details-bien/' + JSON.parse(actus.actus.content).uuid
		);
	useEffect(() => {
		const dataNew = actus.actus;

		switch (dataNew.action) {
			case 'created':
				var text = `Ajout du nouveau bien n° ${
					dataNew.content.mandate
						? JSON.parse(dataNew.content)?.mandate.num_mandat
						: JSON.parse(dataNew.content).num_folder
				}`;
				var bgColor = 'green';
				return setContentText({ text, bgColor });

			case 'associed':
				var text = `Association  du bien n° ${
					dataNew.content.mandate
						? JSON.parse(dataNew.content)?.mandate.num_mandat
						: JSON.parse(dataNew.content).num_folder
				}  à l'agent ${JSON.parse(dataNew.content).agent?.name}`;
				var bgColor = 'yellow';
				return setContentText({ text, bgColor });

			case 'updated':
				var text = `Mise à jour  bien n° ${
					dataNew.content.mandate
						? JSON.parse(dataNew.content)?.mandate.num_mandat
						: JSON.parse(dataNew.content).num_folder
				} par ${dataNew.agent.name}`;
				var bgColor = 'orange';
				return setContentText({ text, bgColor });

			case 'deleted':
				var text = `Desactivation du bien  n° ${
					dataNew.content.mandate
						? JSON.parse(dataNew.content)?.mandate.num_mandat
						: JSON.parse(dataNew.content).num_folder
				} par ${dataNew.agent.name}`;
				var bgColor = 'red';
				return setContentText({ text, bgColor });

			default:
				break;
		}
	}, []);

	return (
		contentText && (
			<div
				className='flex items-stretch justify-between py-2 mx-2'
				onClick={navigate}>
				<div className='text-center'>{contentText.text}</div>
				<ColorText color={BackgroundColor(contentText.bgColor)} />
			</div>
		)
	);
};

export const ColorText = (color) => {
	return (
		<div>
			<div className={`${color.color} w-3 h-3 mr-2 rounded-full`}></div>
		</div>
	);
};
