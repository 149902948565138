import { useEffect, useState } from 'react';
import { EstimationService } from '../../service/estimation';
import { Pagination } from '../../components/commons/pagination';
import { CardEstimation } from '../../components/generals/estimation/CardEstimation';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { configService } from '../../service/api/config';
import { FIlterEstimation } from './FilterEstimation';

export const ListEstimationPage = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [estimationResponse, setEstimationResponse] = useState();
	const [estimationUpdated, setEstimationUpdated] = useState();
	const [agents, setAgents] = useState();
	const [filters, setFilters] = useState([]);
	const handleChangeTypeBien = (value) => {
		setFilters((prev) => ({ ...prev, ...value }));
	};

	useEffect(() => {
		configService.getConfiguration().then(({ data }) => {
			setAgents(data.users.filter((agent) => agent.role !== '777'));
		});
	}, []);

	useEffect(() => {
		let timeout;
		EstimationService.getEstimations(currentPage, filters).then(({ data }) => {
			timeout = setTimeout(() => setEstimationResponse(data), 1000);
		});

		return () => clearTimeout(timeout);
	}, [currentPage, estimationUpdated, filters]);

	return (
		(estimationResponse && (
			<div className='border-[1px] border-solid border-black/30 flex flex-wrap h-auto p-4 items-stretch'>
				<div className='w-1/4 hidden sm:hidden md:block border-2 border-solid'>
					<FIlterEstimation handleChangeTypeBien={handleChangeTypeBien} />
				</div>
				<div className='w-full sm:w-full md:w-3/4 px-2'>
					{estimationResponse.data.map((estimation, index) => (
						<CardEstimation
							key={index}
							estimation={estimation}
							agents={agents}
							setEstimationUpdated={setEstimationUpdated}
						/>
					))}
					{estimationResponse.data.length === 0 && (
						<div className='font-bold text-3xl flex w-full items-center justify-center'>
							Il n'y a aucune estimation
						</div>
					)}

					{estimationResponse.data.length !== 0 && (
						<div className='h-12 flex flex-wrap w-full gap-1 justify-center items-center'>
							{estimationResponse.links &&
								estimationResponse.links
									.slice(1, estimationResponse.links.length)
									.slice(0, -1)
									.map((link, item) => (
										<Pagination
											link={link}
											changePage={(page) => setCurrentPage(page)}
											key={item}
										/>
									))}
						</div>
					)}
				</div>
			</div>
		)) || <GeneralSpinner />
	);
};
