import { useEffect, useState } from 'react';
import { BienFilter } from '../../components/generals/biens/filter/BienFilter';

export const FilterSection = ({ filter, onFilterChange }) => {
	const [filters, setFilters] = useState({});

	useEffect(() => {
		onFilterChange(filters);
	}, [filters]);

	const handlePiecesChange = (pieces) => {
		setFilters((prev) => ({
			...prev,
			piece_min: pieces[0],
			piece_max: pieces[1],
		}));
	};

	const handleSurfaceChange = (surface) => {
		setFilters((prev) => ({
			...prev,
			surface_min: surface[0],
			surface_max: surface[1],
		}));
	};

	const handleChambreChange = (chambre) => {
		setFilters((prev) => ({
			...prev,
			chambre_min: chambre[0],
			chambre_max: chambre[1],
		}));
	};

	const handleFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, values: filter.join(',') }));
	};
	const handleTypeFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, type: filter }));
	};
	const handleTypeBienFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, bien: filter }));
	};
	const handleLocalFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, local: filter }));
	};

	const handlePriceMaxFilterChange = (budget_max) => {
		setFilters((prev) => ({ ...prev, budget_max }));
	};

	const handleAgentChange = (agent) => {
		setFilters((prev) => ({ ...prev, agent }));
	};

	const handlePriceMinFilterChange = (budget_min) => {
		setFilters((prev) => ({ ...prev, budget_min }));
	};

	const handleTypeCriteriaFilterChange = (criteria) => {
		setFilters((prev) => ({ ...prev, criteria }));
	};

	const handleTypeOfferChange = (typeOffer) => {
		setFilters((prev) => ({ ...prev, class: typeOffer }));
	};

	return (
		<>
			<div className='w-full hidden lg:block border-2 min-h-full m-1 p-2 rounded bg-black/10'>
				<BienFilter
					defaultType={filter}
					handleTypeOfferChange={handleTypeOfferChange}
					handleCriteriaFilterChange={handleFilterChange}
					handleTypeFilterChange={handleTypeFilterChange}
					handleTypeBienFilterChange={handleTypeBienFilterChange}
					handlePriceMinFilterChange={handlePriceMinFilterChange}
					handlePiecesChange={handlePiecesChange}
					handleSurfaceChange={handleSurfaceChange}
					handleLocalFilterChange={handleLocalFilterChange}
					handleChambreChange={handleChambreChange}
					handleAgentChange={handleAgentChange}
					handlePriceMaxFilterChange={handlePriceMaxFilterChange}
					handleTypeCriteriaFilterChange={handleTypeCriteriaFilterChange}
				/>
			</div>
		</>
	);
};
