export const SectionPige = ({ title, children }) => {
	return (
		<div className='border-2 border-solid border-gray-400/75 rounded-xl mb-4'>
			<h1 className='text-2xl font-bold mb-4 text-center grey-color py-4 rounded-t-xl  w-full'>
				{title}
			</h1>
			<>{children}</>
		</div>
	);
};
