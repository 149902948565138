import style from './step-form.module.css';
import { useFormContext } from 'react-hook-form';
import { ElementFormUpdate } from './elements/ElementFormUpdate';
import { useEffect } from 'react';

export const StepFormUpdate = ({
	index,
	step,
	currentStep,
	errors,
	formState,
	dataUpdate,
}) => {
	const { register, watch, unregister, setValue, getValues, control } =
		useFormContext();
	const formSubSteps = step.substeps?.filter((step) => step.title !== 'Mandat');

	return (
		<div className={`${currentStep !== index && 'hidden'}`}>
			<h1 className={`blue-color ${style.step__title}`}>{step.title}</h1>
			<div className=''>
				{step.elements?.map((element, index) => {
					return (
						<ElementFormUpdate
							control={watch}
							element={element}
							formState={formState}
							watch={control}
							key={index}
							style={style}
							unregister={unregister}
							getValues={getValues}
							register={register}
							setValue={setValue}
							errors={errors}
							dataUpdate={dataUpdate}
						/>
					);
				})}
			</div>
			{formSubSteps?.map((substep, subIndex) => (
				<StepFormUpdate
					step={substep}
					key={subIndex}
					formState={formState}
					errors={errors}
					register={register}
					index={index}
					setValue={setValue}
					currentStep={currentStep}
					dataUpdate={dataUpdate}
				/>
			))}
		</div>
	);
};
