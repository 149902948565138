import { BlocCard } from '../../components/generals/card/bloc';

const BLOC = [
	{
		text: 'Mes nouvelles estimations',
		link: 'new',
	},
	{
		text: 'Créer une estimation',
		link: 'create',
	},
];
export const EstimationPage = () => {
	return (
		<div className='flex flex-wrap gap-8 justify-between items-center  border-2 border-solid border-[#2d5c6a] p-10'>
			{BLOC.map((item, index) => (
				<BlocCard
					blocTitle={item.text}
					link={item.link}
					key={index}
					className={'h-52 sm:w-1/3 w-3/4'}
				/>
			))}
		</div>
	);
};
