import { Link } from 'react-router-dom';

export const BlocCard = ({
	blocTitle,
	className,
	link = '',
	onClick = () => {},
}) => {
	return (
		<>
			<div
				onClick={onClick}
				className={`grey-color text-black hover:text-white border-2 border-solid border-[#2d5c6a] hover-default-color p-6 mx-auto flex items-center justify-center opacity-75 rounded cursor-pointer ${className}`}>
				<h1 className='text-center text-xl uppercase font-semibold'>
					{(link && <Link to={link}>{blocTitle}</Link>) || blocTitle}
				</h1>
			</div>
		</>
	);
};
