export function optionsContacts(contact) {
	if (contact.target === 'single person') {
		return {
			title: contact.lastname || contact.firstname,
			value: contact.id_contact,
		};
	} else if (contact.target === 'couple') {
		return {
			title:
				contact.man_info.lastname +
				' ' +
				contact.man_info.firstname +
				' et ' +
				(contact.woman_info.lastname + ' ' + contact.woman_info.firstname),
			value: contact.id_contact,
		};
	}
	return null;
}

export const CIVILITY = {
	sir: 'Monsieur',
	madam: 'Madame',
	'sir/madam': 'Monsieur/Madame',
	'sir/sir': 'Monsieur/Monsieur',
	'madam/madam': 'Madame/Madame',
};

export const CONTACT_FORM = {
	couple: 'couple',
	single: 'single person',
	corporation: 'corporation',
};

export const PARSE_CONTACT_NAME = (contact) => {
	let fullDenomination = '';
	switch (contact.target) {
		case CONTACT_FORM.single:
			fullDenomination = `${contact.firstname ?? ''} ${contact.lastname ?? ''}`;
			break;

		case CONTACT_FORM.couple:
			fullDenomination = `${contact.man_info.firstname ?? ''} ${
				contact.man_info.lastname ?? ''
			} - ${contact.woman_info.firstname ?? ''} ${
				contact.woman_info.lastname ?? ''
			}`;
			break;

		case CONTACT_FORM.corporation:
			fullDenomination = `${contact.company_name ?? ''}`;
			break;

		default:
			fullDenomination = `${contact.firstname ?? ''} ${contact.lastname ?? ''}`;
			break;
	}

	return fullDenomination;
};
