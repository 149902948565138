import { useEffect, useState } from 'react';
import { RangeInput } from '../../../commons/form/range';

const FILTER = [
	// {
	// 	icon: 'fa-utensils',
	// 	label: 'Cuisine équipée',
	// 	value: 'TypeOfKitchen',
	// },
	{ icon: 'fa-building-wheat', label: 'Balcon', value: 'balcony' },
	{ icon: 'fa-building', label: 'Terrasse', value: 'teracce' },
	{ icon: 'fa-warehouse', label: 'Garage', value: 'garage_number' },
	{ icon: 'fa-square-parking', label: 'Parking', value: 'parking' },
	{ icon: 'fa-tree', label: 'Jardin', value: 'garden_exist' },
	{ icon: 'fa-fire', label: 'Chauffage gaz', value: 'gaz' },
	{
		icon: 'fa-bolt-lightning',
		label: 'Chauffage électrique',
		value: 'heating_energy',
	},
	{ icon: 'fa-house-chimney', label: 'Cheminée', value: 'fireplace' },
	{ icon: 'fa-couch', label: 'Plain-pied', value: 'ground_floor' },
];

export const ContactFilter = ({
	handleCriteriaFilterChange,
	handlePriceMaxFilterChange,
	handleTypeFilterChange,
	handleChambreChange,
	handleTypeBienFilterChange,
	handlePriceMinFilterChange,
	handlePiecesChange,
	handleAgentChange,
	handleTypeCriteriaFilterChange,
	handleLocalFilterChange,
	handleSurfaceChange,
}) => {
	const [showPiece, setShowPiece] = useState(false);
	const [showSurface, setShowSurface] = useState(false);
	const [showChambre, setShowChambre] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [configuration, setConfiguration] = useState([]);
	const [currentMoreFilter, setCurrentMoreFilter] = useState([]);
	const [minValue, setMinValue] = useState(0);
	const [maxValue, setMaxValue] = useState(10);
	const [minChambreValue, setMinChambreValue] = useState(0);
	const [maxChambreValue, setMaxChambreValue] = useState(10);

	const [minSurface, setMinSurface] = useState(0);
	const [maxSurface, setMaxSurface] = useState(1000);

	const handleSurfacesChangeFilter = (result) => {
		setMinSurface(result[0]);
		setMaxSurface(result[1]);
		handleSurfaceChange(result);
	};

	const handlePiecesChangeFilter = (result) => {
		setMinValue(result[0]);
		setMaxValue(result[1]);
		handlePiecesChange(result);
	};

	const handleChambreChangeFilter = (result) => {
		setMinChambreValue(result[0]);
		setMaxChambreValue(result[1]);
		handleChambreChange(result);
	};

	const handleMoreCriteria = (item) => {
		const value = item.value ? item.value : item.label;
		const isPresent = currentMoreFilter.includes(value);
		if (isPresent) {
			setCurrentMoreFilter(() => [
				...currentMoreFilter.filter((elem) => elem !== value),
			]);
		} else {
			setCurrentMoreFilter((prev) => [...prev, value]);
		}
	};

	useEffect(() => {
		handleCriteriaFilterChange(currentMoreFilter);
	}, [
		currentMoreFilter,
		maxValue,
		minValue,
		maxSurface,
		minSurface,
		minChambreValue,
		maxChambreValue,
	]);
	return (
		<div className='shadow-lg flex flex-wrap w-full my-4 p-12 border-2 border-solid rounded-md justify-center items-center sticky top-1 z-20 bg-white'>
			<div className='p-4 flex  justify-center items-center flex-wrap sm:w-1/5'>
				<h1 className='w-full text-gray-500'>Type de recherche</h1>
				<select
					className='w-full p-4 rounded-md mt-4 cursor-pointer border-2 border-solid'
					onChange={(e) => handleTypeFilterChange(e.target.value)}>
					<option value=''>Tous</option>
					<option value='1'>Vente</option>
					<option value='2'>Location</option>
				</select>
			</div>
			<div className='p-4 flex  justify-center items-center flex-wrap sm:w-1/5 sm:border-l-2'>
				<h1 className='w-full text-gray-500'>Type de bien</h1>
				<select
					className='w-full p-4 rounded-md mt-4 cursor-pointer border-2 border-solid'
					onChange={(e) => handleTypeBienFilterChange(e.target.value)}>
					<option value=''>Tous</option>
					<option value='2'>Appartement</option>
					<option value='1'>Maison</option>
					<option value='3'>Terrain</option>
					<option value='4'>Autres</option>
				</select>
			</div>

			<div className='p-4 flex  justify-center items-center flex-wrap sm:w-1/5 sm:border-l-2'>
				<h1 className='w-full text-gray-500'>Négociateur</h1>
				<select
					className='w-full p-4 rounded-md mt-4 cursor-pointer border-2 border-solid'
					onChange={(e) => handleAgentChange(e.target.value)}>
					<option value=''>Tous</option>
				</select>
			</div>

			<div className='p-4 flex  justify-center items-center flex-wrap sm:w-1/5 sm:border-l-2'>
				<h1 className='w-full text-gray-500'>Localité</h1>
				<input
					type='text'
					placeholder='Ville ou code postal'
					className='w-full p-4 rounded-md mt-4 border-2 border-solid'
					onChange={(e) => handleLocalFilterChange(e.target.value)}
				/>
			</div>

			<div className='p-4 flex  justify-center items-center flex-wrap sm:w-1/5 sm:border-l-2'>
				<h1 className='w-full text-gray-500'>Critère</h1>
				<select
					className='w-full p-4 rounded-md mt-4 cursor-pointer border-2 border-solid'
					onChange={(e) => handleTypeCriteriaFilterChange(e.target.value)}>
					<option value=''>Tous</option>
					<option value='Maison'>Maison</option>
					<option value='Villa'>Villa</option>
					<option value='Propriété'>Propriété</option>
					<option value='Ferme'>Ferme</option>
					<option value='Bastide'>Bastide</option>
					<option value='Mas'>Mas</option>
					<option value='Rez de villa'>Rez de villa</option>
					<option value='Appartement'>Appartement</option>
					<option value='Rez de jardin'>Rez de jardin</option>
					<option value='Duplex'>Duplex</option>
					<option value='Triplex'>Triplex</option>
					<option value='Loft'>Loft</option>
					<option value='Studio'>Studio</option>
					<option value='Terrain'>Terrain</option>
					<option value='Terrain agricole'>Terrain agricole</option>
					<option value='Terrain de loisir'>Terrain de loisir</option>
					<option value='Terrain à bâtir'>Terrain à bâtir</option>
					<option value='Autre'>Autre</option>
					<option value='Cabanon'>Cabanon</option>
					<option value='Chalet'>Chalet</option>
					<option value='Cave'>Cave</option>
					<option value='Garage'>Garage</option>
					<option value='Parking'>Parking</option>
					<option value='Immeuble'>Immeuble</option>
					<option value='Viager'>Viager</option>
				</select>
			</div>
			<div className='p-4 flex  justify-center items-center flex-wrap sm:w-1/5 sm:border-l-2'>
				<h1 className='w-full text-gray-500'>
					Surface m<sup>2</sup>
				</h1>
				<div className='relative inline-block text-left w-full'>
					<div className='mt-4'>
						<button
							type='button'
							className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
							id='menu-button'
							aria-expanded='true'
							aria-haspopup='true'
							onClick={() => setShowSurface(!showSurface)}>
							{minSurface} - {maxSurface} m<sup className='text-[10px]'>2</sup>
							<svg
								className='-mr-1 h-5 w-5 text-gray-400'
								viewBox='0 0 20 20'
								fill='currentColor'
								aria-hidden='true'>
								<path
									fillRule='evenodd'
									d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
									clipRule='evenodd'
								/>
							</svg>
						</button>
					</div>
					<div
						className={`absolute right-0 z-10 p-2 mt-2 min-h-full w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
							showSurface ? '' : 'hidden'
						}`}
						role='menu'
						aria-orientation='vertical'
						aria-labelledby='menu-button'
						tabIndex='-1'>
						<RangeInput
							min={0}
							max={1000}
							handleChange={handleSurfacesChangeFilter}
							textForShow={' m2'}
						/>
					</div>
				</div>
			</div>
			<div className='p-4 sm:w-1/5 sm:border-l-2 flex justify-center items-center flex-wrap'>
				<h1 className='w-full text-gray-500'>Pièces</h1>
				<div className='relative inline-block text-left w-full'>
					<div className='mt-4'>
						<button
							type='button'
							className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
							id='menu-button'
							aria-expanded='true'
							aria-haspopup='true'
							onClick={() => setShowPiece(!showPiece)}>
							{minValue} - {maxValue} pièces
							<svg
								className='-mr-1 h-5 w-5 text-gray-400'
								viewBox='0 0 20 20'
								fill='currentColor'
								aria-hidden='true'>
								<path
									fillRule='evenodd'
									d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
									clipRule='evenodd'
								/>
							</svg>
						</button>
					</div>
					<div
						className={`absolute right-0 z-10 p-2 mt-2 min-h-full w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
							showPiece ? '' : 'hidden'
						}`}
						role='menu'
						aria-orientation='vertical'
						aria-labelledby='menu-button'
						tabIndex='-1'>
						<RangeInput
							min={0}
							max={10}
							handleChange={handlePiecesChangeFilter}
							textForShow={' pièce(s)'}
						/>
					</div>
				</div>
			</div>

			<div className='p-4 sm:w-1/5 sm:border-l-2 flex justify-center items-center flex-wrap'>
				<h1 className='w-full text-gray-500'>Nombre de chambres</h1>
				<div className='relative inline-block text-left w-full'>
					<div className='mt-4'>
						<button
							type='button'
							className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
							id='menu-button'
							aria-expanded='true'
							aria-haspopup='true'
							onClick={() => setShowChambre(!showChambre)}>
							{minValue} - {maxValue} chambres
							<svg
								className='-mr-1 h-5 w-5 text-gray-400'
								viewBox='0 0 20 20'
								fill='currentColor'
								aria-hidden='true'>
								<path
									fillRule='evenodd'
									d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
									clipRule='evenodd'
								/>
							</svg>
						</button>
					</div>
					<div
						className={`absolute right-0 z-10 p-2 mt-2 min-h-full w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
							showChambre ? '' : 'hidden'
						}`}
						role='menu'
						aria-orientation='vertical'
						aria-labelledby='menu-button'
						tabIndex='-1'>
						<RangeInput
							min={0}
							max={10}
							handleChange={handleChambreChangeFilter}
							textForShow={' chambre(s)'}
						/>
					</div>
				</div>
			</div>

			<div className='w-full px-4 mt-8 text-center'>
				<div
					style={{
						background:
							'linear-gradient(180deg, rgba(0,0,0,0) calc(50% - 1px), #E9F1FF calc(50%), rgba(0,0,0,0) calc(50% + 1px) )',
					}}>
					<span
						className='text-blue-400 font-bold inline-block bg-white px-4 cursor-pointer'
						onClick={() => setShowMore(!showMore)}>
						Afficher {!showMore ? 'plus' : 'moins'} de critères
					</span>
				</div>
			</div>

			{
				<div className={` ${showMore ? '' : 'hidden'}`}>
					<div className={`flex flex-wrap w-full gap-4 mt-8 justify-center`}>
						<div className='sm:w-[16%]'>
							<h1 className='text-gray-400 w-full'>Budget min</h1>
							<div className='flex justify-start items-center rounded-md border-[1px] h-12 p-2'>
								<input
									type='text'
									onChange={(e) => {
										if (e.target.value.length === 0)
											handlePriceMinFilterChange('');
										else if (e.target.value.length > 2)
											handlePriceMinFilterChange(e.target.value);
										else {
										}
									}}
									className='focus:outline-none h-full w-[90%] text-gray-500'
								/>
								<i className='fa fa-euro-sign p-2 text-blue-400'></i>
							</div>
						</div>
						<div className='sm:w-[16%]'>
							<h1 className='text-gray-400 w-full'>Budget max</h1>
							<div className='flex justify-start items-center rounded-md border-[1px] h-12 p-2'>
								<input
									type='text'
									onChange={(e) => {
										if (e.target.value.length === 0)
											handlePriceMaxFilterChange('');
										else if (e.target.value.length > 2)
											handlePriceMaxFilterChange(e.target.value);
										else {
										}
									}}
									className='focus:outline-none h-full w-[90%] text-gray-500'
								/>
								<i className='fa fa-euro-sign p-2 text-blue-400'></i>
							</div>
						</div>
					</div>
					<div className='flex flex-wrap justify-center items-center gap-4 mt-8'>
						{FILTER.map((item, index) => {
							return (
								<div
									className={`flex flex-wrap justify-center items-center p-4 w-1/6 rounded-md cursor-pointer ${
										currentMoreFilter.includes(
											item.value ? item.value : item.label
										)
											? 'grey-color text-black'
											: 'blue-color text-white '
									}`}
									key={index}
									onClick={() => handleMoreCriteria(item)}>
									<i className={`fa-solid  mr-4 ${item.icon}`}></i>
									<span>{item.label}</span>
								</div>
							);
						})}
					</div>
				</div>
			}
		</div>
	);
};
