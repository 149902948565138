export const  BackgroundColor = (value) => {
	switch (value) {
        case 'red':
            return 'bg-red-400';

        case 'yellow':
            return 'bg-yellow-400';

        case 'green':
            return 'bg-green-400'

        case 'blue':
            return 'bg-blue-400'

        case 'orange':
            return  'bg-orange-400'    
            
        default:

            break;
    }
}