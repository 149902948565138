export const imageBackend = (imageUrl) =>
	process.env.REACT_APP_PHOTO_AGENT + imageUrl;

export const imageAgent = (imageUrl) =>
	process.env.REACT_APP_PHOTO_AGENT + imageUrl;

export const imageBien = (imageUrl) =>
	process.env.REACT_APP_BASE_URL_IMAGE + imageUrl;

export const imageBienSlide = (imageUrl) =>
	process.env.REACT_APP_BASE_URL_IMAGE_SLIDE + imageUrl;

export const imageBienCouverture = (imageUrl) =>
	process.env.REACT_APP_BASE_URL_IMAGE_COUVERTURE + imageUrl;

export const coverImageFromProxy = (imageUrl) =>
	process.env.REACT_APP_PROXY_URL + '/photos_couvert' + imageUrl;

export const slideImageFromProxy = (imageUrl) =>
	process.env.REACT_APP_PROXY_URL + '/photos_slide' + imageUrl;

export const imageFromProxy = (imageUrl) =>
	process.env.REACT_APP_PROXY_URL + '/photos' + imageUrl;
