import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { RapprochementPage } from '../../pages/rapprochements/Rapprochement.page';
import { RapprochementBienPage } from '../../pages/rapprochements/RapprochementBienPage';
import { RapprochementContactPage } from '../../pages/rapprochements/RapprochementContactPage';
import { BienActionPage } from '../../pages/biens/BienActionPage';
import { ContactAction } from '../../pages/contact/ContactAction';
import { BienRapprochementPage } from '../../pages/biens/BienRapprochementPage';
import { ContactRapprochementPage } from '../../pages/rapprochements/details/ContactRapprochementPage';


export const  RapprochementRoute = () => {
    return (
        <HelmetProvider>
            <Routes>
                <Route path={''} element={<RapprochementPage/>}></Route>
                <Route path={'/biens/:filter'} element={<RapprochementBienPage children={<BienActionPage/>}/>} ></Route>
                <Route path={'/contacts'} element={<RapprochementContactPage/>}></Route>
                <Route path={'/rapprochement-bien/:bienId'} element={<BienRapprochementPage />}></Route>
                <Route path={'/rapprochement-contact/:contactId'} element={<ContactRapprochementPage />}></Route>
            </Routes>
        </HelmetProvider>

    )
} 