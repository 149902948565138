import { CONTACT_FORM } from '../../../../helpers/optionsContact';

export const ContactIndicator = ({ contact }) => {
	return (
		<>
			{contact?.target === CONTACT_FORM.single && (
				<>
					<li className=''>
						<b>Nom :</b> {contact?.lastname}
					</li>
					<li className=''>
						<b>Prénom :</b> {contact?.firstname}
					</li>
					<li className=''>
						<b>Pays :</b> {contact?.country_contact}
					</li>
					<li className=''>
						<b>Adresse : </b>
						{contact?.adress_contact && contact.adress_contact.split(',')[0]}
					</li>
					<li className=''>
						<b>Nom agence : </b>
						{contact?.agency?.nameAgency && contact.agency.nameAgency}
					</li>
				</>
			)}

			{contact?.target === CONTACT_FORM.couple && (
				<>
					<li className=''>
						<b>Nom :</b> {contact?.man_info?.lastname}
					</li>
					<li className=''>
						<b>Prénom :</b> {contact?.man_info?.firstname}
					</li>
					<li className=''>
						<b>Pays :</b> {contact?.man_info_compl.country}
					</li>
					<li className=''>
						<b>Adresse : </b>
						{contact?.man_info_compl &&
							contact.man_info_compl.adress.split(',')[0]}
					</li>
					<li className=''>
						<b>Nom :</b> {contact?.woman_info?.lastname}
					</li>
					<li className=''>
						<b>Prénom :</b> {contact?.woman_info?.fistname}
					</li>
					<li className=''>
						<b>Pays :</b> {contact?.woman_info_compl.country}
					</li>
					<li className=''>
						<b>Adresse : </b>
						{contact?.woman_info_compl &&
							contact.woman_info_compl.adress.split(',')[0]}
					</li>
				</>
			)}

			{contact?.target === CONTACT_FORM.corporation && (
				<>
					<li className=''>
						<b>Nom de la compagnie :</b> {contact?.company_name}
					</li>

					<li className=''>
						<b>Pays :</b> {contact?.woman_info_compl.country}
					</li>
					<li className=''>
						<b>Adresse : </b>
						{contact?.woman_info_compl &&
							contact.woman_info_compl.adress.split(',')[0]}
					</li>
				</>
			)}
		</>
	);
};
