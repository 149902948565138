import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PigeService } from '../../service/models/pige';
import { errorToast, successToast } from '../../service/toast/showToast';
import { SectionPige } from '../../components/generals/pige/section';
import { DetailsSectionPige } from './informations/info';
import { GeolocalisationSection } from './informations/geolocalisation';
import { AdvertissmentSection } from './informations/advertissment';
import { GeneralSection } from './informations/general';
import { DimensionSection } from './informations/dimension';
import { MorePige } from '../../components/generals/pige/more-pige';
import { Breadcumbs } from './../../components/generals/headers/breadcumbs';
import { Note } from '../../components/generals/pige/note';
import { FavorieService } from '../../service/models/favories';
import { getStorageUser } from '../../service/localStorage';
import { Button } from '../../components/commons/button';
import { Modal } from '../../components/commons/modal/modal';
import { GeneralSpinner } from '../../components/commons/general-spinner';

export const PigeDetails = () => {
	const user = getStorageUser().user;
	const { pigeId } = useParams();
	const [showScenario, setShowScenario] = useState(false);
	const [pige, setPige] = useState();
	const [seeMore, setSeeMore] = useState(false);
	const [favory, setFavory] = useState();
	const [itemFavory, setItemFavory] = useState();
	const [commentPosted, setCommentPosted] = useState();
	const handleComs = () => {
		setCommentPosted(Math.random());
	};

	const detectArticle = (name) => {
		return [];
	};
	const createOrRemoveFavory = async () => {
		if (!favory)
			await FavorieService.createFavory(pige.id).then(() => {
				setFavory(true);
				successToast('Annonce ajouté aux favories');
			});
		else {
			await FavorieService.removeFavory(itemFavory.id).then(() => {
				successToast('Annonce supprimée du favoris');
				setFavory(false);
			});
		}
	};
	useEffect(() => {
		PigeService.getPigeById(pigeId)
			.then(({ data }) => {
				setPige(data);

				if (
					data.favories.filter((elem) => elem.user_id === user.id).length !== 0
				) {
					setFavory(true);
					setItemFavory(
						data.favories.filter((elem) => elem.user_id === user.id)[0]
					);
				}
			})
			.catch((e) => errorToast(e));
	}, [pigeId, favory, setFavory, setCommentPosted, commentPosted]);

	return (
		<>
			<Breadcumbs title={'Détail'} />
			{(pige && (
				<>
					<div className='w-full border-2 border-solid border-black/75 min-h-[400px] flex flex-wrap justify-between p-2 rounded-md'>
						<div className='p-4 w-full sm:w-3/5'>
							<GeneralSection pige={pige} />
							<div className='my-4 text-gray-500'>
								<i className='fa-solid fa-calendar-check color-text mr-5'></i>{' '}
								Publiée le {new Date(pige.date).toLocaleDateString('fr-FR')}
							</div>
							<div className='grid grid-cols-4 grid-rows-2 gap-4 items-stretch justify-center'>
								<div
									className={`${
										pige.imgs !== '[]' ? 'sm:col-span-3' : 'sm:col-span-4'
									} col-span-4 row-span-4`}>
									<img
										className='object-cover h-[97%] w-full sm:rounded-xl'
										alt='Pige img'
										src={pige.img}
									/>
								</div>
								{pige.imgs !== '[]' && (
									<>
										<div className='sm:col-span-1 col-span-4 row-span-1'>
											<img
												className='object-cover h-[97%] w-full sm:rounded-xl'
												alt='Pige img'
												src={JSON.parse(pige.imgs)[0]}
											/>
										</div>
										<div className='sm:col-span-1 col-span-4 row-span-1'>
											<img
												className='object-cover h-[97%] w-full sm:rounded-xl'
												alt='Pige img'
												src={JSON.parse(pige.imgs)[1]}
											/>
										</div>
									</>
								)}
							</div>
							<AdvertissmentSection pige={pige} />

							<div className='my-4'>
								<h1 className='capitalize text-2xl font-bold text-black/80 text-justify'>
									{pige.titre}
								</h1>
							</div>
							<DimensionSection pige={pige} />
							<div className='my-4'>
								<h1 className='text-2xl font-bold'>A propos du bien</h1>

								<p className='py-4'>
									{seeMore ? pige.texte : pige.texte.substring(0, 400) + '...'}
								</p>
								<div className='relative h-16 flex items-center justify-center'>
									<div
										className='blue-color w-12 h-12 flex justify-center items-center rounded-full z-10 cursor-pointer'
										onClick={() => setSeeMore(!seeMore)}>
										<i
											className={`fa text-white ${
												seeMore ? 'fa-minus' : 'fa-plus'
											}`}></i>
									</div>
									<hr
										className='absolute top-[50%] w-full'
										style={{ border: '1px solid rgba(0,0,0,0.15)' }}
									/>
								</div>
							</div>

							<DetailsSectionPige
								title={'Information sur le bien'}
								pige={pige}
							/>
							<GeolocalisationSection pige={pige} />
						</div>
						<div className='p-4 w-full sm:w-2/5 rounded-xl'>
							<div className='flex flex-wrap justify-center items-center min-h-12 my-4 w-full gap-4'>
								<div className='cursor-pointer p-2 rounded-md hover-blue-color hover:text-white'>
									<i className='fa-solid fa-print fa-xl'></i>
								</div>
								<div
									className={`cursor-pointer p-2 rounded-md ${
										favory ? 'bg-pink-600 text-white' : 'text-black'
									}`}
									onClick={() => createOrRemoveFavory()}>
									<i className='fa-regular fa-heart fa-xl mr-2'></i>

									{
										pige.favories.filter(
											(favorie) => favorie.user.agency_id === user.agency_id
										).length
									}
								</div>
							</div>
							{pige.tel_1 && (
								<SectionPige title={'Contact'}>
									<div className='bg-green-500 rounded px-4 py-5 text-center cursor-pointer'>
										<i className='fa-solid fa-xl text-white fa-square-phone'></i>{' '}
										<span className='text-white font-bold '>{pige.tel_1}</span>
									</div>
								</SectionPige>
							)}

							<SectionPige title={'Scénario de prospection'}>
								<div className='p-2 flex items-center justify-center'>
									<Button
										className='px-4 py-2 blue-color rounded-md text-white font-semibold'
										onClick={() => setShowScenario(true)}>
										Voir les scénarios
									</Button>
								</div>
							</SectionPige>
							<SectionPige title={'Historique'}>
								<div className='p-2'>
									{pige.commentaires
										.filter(
											(commentaire) =>
												commentaire.user.agency_id === user.agency_id
										)
										.sort(
											(a, b) =>
												new Date(b.created_at).getTime() -
												new Date(a.created_at).getTime()
										)
										.map((item, index) => {
											return (
												<div
													key={index}
													className='p-4 text-center my-2  border-solid border-[1px] border-blue-600 rounded-md '>
													<h1 className='font-bold'>{item.title}</h1>
													<div className='flex text-white blue-color flex-wrap p-4 rounded-sm justify-center items-center my-4'>
														<div className='w-full sm:w-1/2'>
															<i className='fa fa-calendar'></i>{' '}
															{new Date(item.created_at).toLocaleDateString(
																'fr-FR'
															)}
														</div>
														<div className='w-full sm:w-1/2 '>
															<i className='fa fa-user'></i> {item.user.name}
														</div>
													</div>
													<p>{item.comments}</p>
												</div>
											);
										})}
								</div>
							</SectionPige>
							<SectionPige title={'Laisser un commentaire'}>
								<Note pigeId={pige.id} handleComs={handleComs} />
							</SectionPige>
						</div>
						<MorePige piges={pige.correspondants.slice(0, 3)} />
						<Modal
							allowBackdrop={false}
							allowForceClose={true}
							isOpen={showScenario}
							handleClose={() => setShowScenario(false)}
							modalTitle={'Scénario de prospection'}
							id={'scenario'}>
							<div className='my-2 p-2'>
								<h1 className='blue-color text-white w-1/2 text-center py-2 font-bold uppercase mx-auto'>
									Scénario 1
								</h1>
								<p className='p-2'>
									Bonjour, je vous appelle au sujet de l'annonce que vous avez
									déposé sur{' '}
									<u>{Object.values(JSON.parse(pige.sources)[0])[1]}</u>.<br />
									Vous êtes bien le propriétaire ?
								</p>
								<p className='p-2'>L' {pige.bien} est toujours disponible ?</p>
								<p className='p-2'>
									Parfait, je me présente, je suis <b>{user.name}</b> de
									l'agence <b>{user.agency.nameAgency}</b>, et j'ai des clients
									qui recherchent {pige.bien} comme {pige.bien} votre, avec
									notamment {'un garage et qui soit équipé du chauffage gaz'},
									vous me confirmez que c'est bien le cas de votre {pige.bien} ?
								</p>
								<p className='p-2'>
									Seriez-vous disponible demain ou après demain, pour que je
									vienne voir {'test'} et m'assurer qu'il puisse leur convenir ?
									(si besoin: rassurez-vous, dans un premier temps, je viens
									sans mandat, le but est juste de prendre des photos et voir
									avec mes clients si cela pourrait leur convenir) Vous préférez
									10H ou 14 ? A quelle adresse se situe le bien ? Je vous
									remercie pour votre accueil, je vous souhaite une agréable
									journée.
								</p>
							</div>
						</Modal>
					</div>
				</>
			)) || <GeneralSpinner />}
		</>
	);
};
