export const registerForm = {
	completeText: 'Créer un nouveau compte',
	title: "S'inscrire",
	steps: [
		{
			title: 'Information personnelle',
			elements: [
				{
					type: 'text',
					name: 'name',
					className: 'w-full',
					title: 'Nom',
					validation: {
						required: {
							value: true,
							message: "Le champ 'nom' est requis",
						},
					},
				},
				{
					type: 'email',
					name: 'email',
					className: 'w-full',
					title: 'Adresse email',
					validation: {
						validate: (value) => {
							if (!value) {
								return "L'email est requis";
							}
							if (
								!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
							) {
								return "L'adresse email est invalide";
							}
							return true;
						},
					},
				},
				{
					type: 'password',
					name: 'password',
					className: 'w-full',
					title: 'Mot de passe',
					validation: {
						required: {
							value: true,
							message: "Le champ 'mot de passe' est requis",
						},
					},
				},
			],
		},
		{
			title: "Information sur l'agence",
			elements: [
				{
					type: 'text',
					name: 'nameAgency',
					className: 'w-full',
					title: "Nom de l'agence",
					validation: {
						required: {
							value: true,
							message: "Le champ 'nom de l'agence' est requis",
						},
					},
				},
				{
					type: 'text',
					name: 'nameCompany',
					className: 'w-full',
					title: 'Nom de la compagnie',
					validation: {
						required: {
							value: true,
							message: "Le champ 'compagnie' est requis",
						},
					},
				},
				{
					type: 'text',
					name: 'addressCompany',
					className: 'w-full',
					title: 'Adresse de la compagnie',
					validation: {
						required: {
							value: true,
							message: "Le champ 'adresse' est requis",
						},
					},
				},
				{
					type: 'tel',
					name: 'phoneAgency',
					className: 'w-full',
					title: "Numéro de téléphone de l'agence",
					validation: {
						required: {
							value: true,
							message: "Le champ 'téléphone' est requis",
						},
					},
				},
			],
		},
	],
};
