import { useEffect, useState } from 'react';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { NewsLoading } from './NewsLoading';
import style from './news.module.css';
import { NewsCard } from '../../components/generals/news/NewsCard';
import { useNewsService } from '../../service/news/useNewsService';

export const News = () => {
	const [news, setNews] = useState();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchNews();
		setLoading(false);
	}, []);


	const fetchNews = () => {
		useNewsService.fetchAllNews().then((response) => {
			setNews(response.data.data);
			setLoading(false);
		});
	};
	return (
		<>
			<Breadcumbs title={'Actualités'} />
			<div className={style.news__container}>
				{loading && [1, 2, 3].map((item, key) => <NewsLoading key={key} />)}
				{news && news.map((item, key) => <NewsCard news={item} key={key} />)}
			</div>
		</>
	);
};
