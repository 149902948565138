import { formatter } from '../../../helpers/formatter';

export const GeneralSection = ({ pige }) => {
	return (
		<div className='flex justify-between items-center'>
			<h1 className='capitalize text-5xl font-bold color-text'>
				{formatter.format(pige.prix)}
			</h1>
			<h2 className='my-4 text-gray-500'>
				<i className='fa-solid fa-street-view mr-5 color-text'></i> {pige.cp}{' '}
				{pige.ville}
			</h2>
		</div>
	);
};
