import { requiredField } from '../../../validation/validation';
export const RecentConstruct = [
	{
		//Assurance dommage ouvrage
		group: 'info_personnelle',
		className: 'w-full ',
		type: 'radio',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Assurance dommage ouvrage :',
		name: 'assurance',
		tagName:'recent_construct.assurance',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Garantie décennale
		group: 'info_personnelle',
		validation: {
			validate: {
				requiredField,
			},
		},
		className: 'w-full ',
		type: 'radio',
		title: 'Garantie décennale :',
		name: 'garantie',
		tagName:'recent_construct.garantie',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//D. Achat travaux
		group: 'info_personnelle',
		className: 'w-full ',
		validation: {
			validate: {
				requiredField,
			},
		},
		type: 'radio',
		title: 'D. Achat travaux :',
		name: 'achat',
		tagName:'recent_construct.achat',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		//Certificat de conformité
		group: 'info_personnelle',
		className: 'w-full ',
		validation: {
			validate: {
				requiredField,
			},
		},
		type: 'radio',
		title: 'Certificat de conformité :',
		name: 'certificat',
		tagName:'recent_construct.certificat',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
];
