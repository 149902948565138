import React from 'react';
import { BlocCard } from '../../components/generals/card/bloc';

export default function ContactPage() {
	return (
		<div className='grid sm:grid-cols-3 grid-cols-1 grid-rows-2 gap-5 border-2 border-solid border-[#2d5c6a] p-5'>
			<BlocCard
				className='w-full h-52   col-span-1 row-span-1 '
				link={'/mes-contacts/add/contact'}
				blocTitle={'Ajouter un Contact'}
			/>

			<BlocCard
				className='w-full h-52    col-span-1 row-span-1'
				link={'/mes-contacts/add'}
				blocTitle={'Gestion des contacts'}
			/>

			<BlocCard
				className='w-full h-52 sm:h-full row-span-2 col-span-1 '
				link={'/mes-contacts/add'}
				blocTitle={'Mes demandes'}
			/>

			<BlocCard
				className='w-full h-52    col-span-1 row-span-1'
				link={'/mes-contacts/active'}
				blocTitle={'Contact actifs'}
			/>

			<BlocCard
				className='w-full h-52    col-span-1 row-span-1'
				link={'/mes-contacts/archive'}
				blocTitle={'Contact archivés'}
			/>
		</div>
	);
}
