import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BienService from '../../service/bien/BienService';
import { Modal } from '../../components/commons/modal/modal';
import { Button } from '../../components/commons/button';
import ContactDropdown from '../../components/generals/card/contactDropdown';
import { SuiviFolder } from '../../components/generals/suivi/SuiviFolder';
import DetailsSection from '../../components/generals/biens/section/DetailsSection';
import { Spinner } from '../../components/commons/Spinner';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { useForm } from 'react-hook-form';
import { useSuiviDossier } from '../../service/suivi/useSuiviDossier';
import { GeneralSpinner } from '../../components/commons/general-spinner';

const DisplayButton = [
	{
		buttonText: 'Modifier le bien',
		link: '',
	},
	{
		buttonText: 'Suivi du dossier',
		link: '',
	},
	{
		buttonText: 'Impression',
		link: '',
	},
	{
		buttonText: 'Diffusion',
		link: '',
	},
	{
		buttonText: 'RAPPROCH.',
		link: '',
	},
	{
		buttonText: 'Contacts',
		link: '',
	},
];

export const DetailSuivi = () => {
	const { bien_id: bienId } = useParams();
	const [bien, setBien] = useState();
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();

	const [showLoading, setShowLoading] = useState(false);
	const {
		register,
		handleSubmit,
		unregister,
		formState: { errors },
	} = useForm();

	const updatedBien = async () => {
		setShowLoading(true);
		await BienService.getBienById(bienId)
			.then((res) => setBien(res.data))
			.finally(() => setShowLoading(false));
	};

	const submitElem = async (data) => {
		data.folder_type = bien.type_offert_id;
		data.agency_id = bien.agency_id;
		data.num_mandat = bien.num_folder;
		data.bien_id = bien.id_bien;
		setShowLoading(true);
		await useSuiviDossier.createFolder(data);
		await updatedBien();
		setShowLoading(false);

		setShowModal(false);
	};
	useEffect(() => {
		setShowLoading(true);
		BienService.getBienById(bienId)
			.then((res) => {
				setBien(res.data);
			})
			.finally(() => setShowLoading(false));
	}, [bienId]);
	return (
		<>
			{bien && (
				<>
					<div className='border-2 border-solid border-[#2d5c6a] p-2'>
						<div className='title-text blue-color my-2 p-4 relative'>
							<h1 className='text-center'>SUIVI DE MES DOSSIERS</h1>
							<div className='flex gap-2 justify-end w-full md:w-full lg:w-1/3 md:relative sm:relative lg:absolute right-0 top-2'>
								{!bien.folder && (
									<button
										className='button default-color'
										onClick={() => setShowModal(true)}>
										Créer un nouveau dossier
									</button>
								)}
								<button className='button' onClick={() => navigate(-1)}>
									Retour
								</button>
							</div>
						</div>
						<div className='title-text blue-color my-2'>
							ACCÈS : {bien.type_offert.designation} -{' '}
							{bien.type_offert_suite.designation}
						</div>
						<div className=''>
							<h1>
								<u className='font-bold'>Titre</u> : {bien.advertisement?.title}
							</h1>
							<h2>
								<u className='font-bold'>Numéro de dossier</u> :{' '}
								{bien.num_folder}
							</h2>

							<h3>
								<u className='font-bold'>Adresse du bien</u> : {bien.city},
								{bien.name_country} - {bien.zap_country}
							</h3>
						</div>

						<DetailsSection bien={bien}>
							<SuiviFolder bien={bien} updatedBien={updatedBien} />
						</DetailsSection>
					</div>

					<Modal
						isOpen={showModal}
						id={'new-dossier'}
						height='h-auto'
						allowBackdrop={true}
						modalTitle={'Créer un nouveau dossier'}
						handleClose={() => setShowModal(false)}>
						<form onSubmit={handleSubmit(submitElem)}>
							<div className='flex flex-wrap'>
								<label htmlFor='element_name' className='w-full'>
									Date de signature{' '}
									{(bien.type_offert_id == '2' && 'du bail ') || 'de l`offre'}
									(*)
								</label>
								<input
									type='date'
									id='element_name'
									min={0}
									className='border-[1px] border-solid w-full my-2 outline-none p-2 aria-[invalid]:border-red-500'
									aria-invalid={errors.date_signature}
									{...register('date_signature', {
										required: {
											value: true,
											message: 'La date de signature du bail  est requise',
										},
									})}
								/>
								<span className='text-red-500 w-full'>
									{errors.date_signature && errors.date_signature.message}
								</span>
							</div>
							{bien.type_offert_id == '2' && (
								<div className='flex flex-wrap'>
									<label htmlFor='debut_name' className='w-full'>
										Date de debut du bail (*)
									</label>
									<input
										type='date'
										id='debut_name'
										min={0}
										className='border-[1px] border-solid w-full my-2 outline-none p-2 aria-[invalid]:border-red-500'
										aria-invalid={errors.debut_bail}
										{...register('debut_bail', {
											required: {
												value: true,
												message: 'La date de début du bail  est requise',
											},
										})}
									/>
									<span className='text-red-500 w-full'>
										{errors.debut_bail && errors.debut_bail.message}
									</span>
								</div>
							)}

							<button
								type='submit'
								className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 p-4 mt-4 rounded flex items-center gap-2'>
								Enregistrer <ButtonSpinner showLoading={showLoading} />
							</button>
						</form>
					</Modal>
				</>
			)}
			{showLoading && <GeneralSpinner />}
		</>
	);
};
