const TOKEN_NAME = 'logicielMapimmo';

export const getStorageUser = () =>
	JSON.parse(localStorage.getItem(TOKEN_NAME));

export const isConnected = localStorage.getItem(TOKEN_NAME) || null;

export const setStorageUser = (data) =>
	localStorage.setItem(TOKEN_NAME, JSON.stringify(data));

export const clearStorage = () => localStorage.removeItem(TOKEN_NAME);

export const isAdmin = (() => {
	return getStorageUser()?.user.role === '777';
})();

export const updateStorageUser = (data) => {
	const storage = getStorageUser();
	storage.user = data;

	setStorageUser(storage);
};

export const updateStorageAgencyOnly = (agency) => {
	const storage = getStorageUser();
	storage.user.agency = agency;
	setStorageUser(storage);
};
