import { useEffect, useState } from 'react';
import { CardSuivi } from './cardSuivi';
import { GestionElement } from './GestionElement';
import { CreationElement } from './CreationElement';

export const SuiviFolder = ({ bien, updatedBien }) => {
	const DATE = window.moment ? window.moment : null;
	const { folder } = bien;
	const folderSteps =
		folder?.steps?.filter((item) => item.step_duration !== 0) || [];
	const step0 = folder?.steps?.filter((item) => item.step_duration === 0) || [];
	const [steps, setSteps] = useState([]);

	const [showModalGestion, setShowModalGestion] = useState(false);
	const [showModalCreation, setShowModalCreation] = useState(false);

	useEffect(() => {
		let newStep = new Map();
		const step = [];
		folderSteps
			.sort((a, b) => a.step_duration - b.step_duration)
			.forEach((item) => {
				if (!newStep.get(item.step_duration)) {
					newStep.set(item.step_duration, [item]);
				} else {
					newStep.get(item.step_duration).push(item);
				}
			});
		newStep.forEach((value, index) => {
			step.push({ id: index, value });
		});

		setSteps(step || []);
	}, [updatedBien]);
	return (
		(steps && (
			<div className='w-full'>
				<div className='w-full flex justify-center items-center gap-4 my-4 flex-wrap'>
					<button
						className='button blue-color'
						onClick={() => setShowModalGestion(true)}>
						Gestion des élements
					</button>
					<button
						className='button blue-color'
						onClick={() => setShowModalCreation(true)}>
						Nouvel element
					</button>
				</div>

				<div className='flex flex-wrap justify-center gap-y-8'>
					{step0.some((item) => item.active === 1) && (
						<CardSuivi
							validated={step0.every((item) => item.is_done === true)}
							updatedBien={updatedBien}>
							<div className='item bordered font-bold blue-color text-white'>
								JOUR J
							</div>
							<div className='my-4'>
								{folder?.steps
									?.filter((item) => item.step_duration === 0)
									.map((item, index) => {
										return (
											item.active === 1 && (
												<div key={index}>
													<div
														className={`item font-bold bordered ${
															item.is_done
																? 'default-color text-white'
																: 'bg-gray-300 text-black'
														}`}>
														{item.step_name}
													</div>
													<div className='item font-bold bordered'>
														{DATE(item.step_date).format('DD/MM/YYYY')}
													</div>
												</div>
											)
										);
									})}
							</div>
						</CardSuivi>
					)}
					{steps.map(
						(item, index) =>
							item.value.some((item) => item.active === 1) && (
								<CardSuivi
									key={index}
									step={item}
									folder={folder}
									updatedBien={updatedBien}
									validated={item.value.every((item) => item.is_done === true)}
								/>
							)
					)}
				</div>
				<GestionElement
					updatedBien={updatedBien}
					steps={folder?.steps || []}
					showModal={showModalGestion}
					handleClose={() => setShowModalGestion(false)}
				/>
				{showModalCreation && (
					<CreationElement
						updatedBien={updatedBien}
						folder={folder}
						showModal={showModalCreation}
						handleClose={() => setShowModalCreation(false)}
					/>
				)}
			</div>
		)) || (
			<div className='border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto'>
				<div className='animate-pulse flex space-x-4'>
					<div className='rounded-full bg-slate-200 h-10 w-10'></div>
					<div className='flex-1 space-y-6 py-1'>
						<div className='h-2 bg-slate-200 rounded'></div>
						<div className='space-y-3'>
							<div className='grid grid-cols-3 gap-4'>
								<div className='h-2 bg-slate-200 rounded col-span-2'></div>
								<div className='h-2 bg-slate-200 rounded col-span-1'></div>
							</div>
							<div className='h-2 bg-slate-200 rounded'></div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};
