import { Critaire } from '../Critaire';
import { TypeBien } from '../TypeBien';
import { TypeOffreContact } from './TypeOffreContact';
import { InteriorDetail } from '../Bien/InteriorDetail';
// import { requiredField } from '../../../validation/validation';
import { TypeBienContact } from './TypeBien';
import { CRITERE, CRITERE_OFFRE } from '../../type';

export const Information = [
	TypeOffreContact,
	TypeBienContact,
	//Critaire,
	{
		group: 'info_personnelle',
		type: 'select',
		name: 'criteria',
		className: 'w-full ',
		title: 'Critère des recherches :',
		tagName: 'preferency.criteria',
		choices: [
			{
				title: 'Maison',
				value: 'home',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Villa',
				value: 'villa',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Mas',
				value: 'mas',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Bastida',
				value: 'bastida',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Propriété',
				value: 'property',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Rez de villa',
				value: 'rez villa',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},

			{
				title: 'Longère',
				value: 'longère',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Ferme',
				value: 'farm',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['1'] },
				],
			},
			{
				title: 'Appartement',
				value: 'appartment',
				visible: [
					{ field: 'type_offert_id', value: ['3', '2', '1'] },
					{ field: 'type_estate_id', value: '2' },
				],
			},
			{
				title: 'Studio',
				value: 'studio',
				visible: [
					{ field: 'type_offert_id', value: ['3', '2', '1'] },
					{ field: 'type_estate_id', value: '2' },
				],
			},
			{
				title: 'Duplex',
				value: 'duplex',
				visible: [
					{ field: 'type_offert_id', value: ['3', '2', '1'] },
					{ field: 'type_estate_id', value: '2' },
				],
			},
			{
				title: 'Triplex',
				value: 'triplex',
				visible: [
					{ field: 'type_offert_id', value: ['3', '2', '1'] },
					{ field: 'type_estate_id', value: '2' },
				],
			},
			{
				title: 'Rez de jardin',
				value: 'rez de jardin',
				visible: [
					{ field: 'type_offert_id', value: ['3', '2', '1'] },
					{ field: 'type_estate_id', value: '2' },
				],
			},
			{
				title: 'Loft',
				value: 'loft',
				visible: [
					{ field: 'type_offert_id', value: ['3', '2', '1'] },
					{ field: 'type_estate_id', value: '2' },
				],
			},
			{
				title: 'Immeuble',
				value: 'immeuble',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Viager',
				value: 'viager',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Garage',
				value: 'garage',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Parking',
				value: 'parking',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Chalet',
				value: 'chalet',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Cabanon',
				value: 'cabanon',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Cave',
				value: 'cave',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Autre',
				value: 'autre',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '3'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Bureaux',
				value: 'bureau',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Entrepôt',
				value: 'entrepôts',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Murs commerciaux',
				value: 'mur commerciaux',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Local commercial',
				value: 'local commercial',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: "Local d'activité",
				value: 'local activité',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Local pro',
				value: 'local pro',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['4'] },
				],
			},
			{
				title: 'Terrain constructible',
				value: 'terrain_constructible',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '4'] },
					{ field: 'type_estate_id', value: ['3'] },
				],
			},
			{
				title: 'Terrain agricole',
				value: 'terrain_agricole',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '4'] },
					{ field: 'type_estate_id', value: ['3'] },
				],
			},
			{
				title: 'Terrain  viabilisé',
				value: 'terrain_viabilisé',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '4'] },
					{ field: 'type_estate_id', value: ['3'] },
				],
			},
			{
				title: 'Terrain naturel et forestier',
				value: 'terrain_naturel',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '4'] },
					{ field: 'type_estate_id', value: ['3'] },
				],
			},
			{
				title: 'Terrain en lotissement',
				value: 'terrain_lotissement',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '4'] },
					{ field: 'type_estate_id', value: ['3'] },
				],
			},
			{
				title: 'Terrain diffus',
				value: 'terrain_diffus',
				visible: [
					{ field: 'type_offert_id', value: ['1', '2', '4'] },
					{ field: 'type_estate_id', value: ['3'] },
				],
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3', '4'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full  sm:w-1/4 ',
		title: 'Dates:',
		visible: [
			{ field: 'type_offert_id', value: ['3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'date',
		validation: {
			validate: {
				// requiredField,
			},
		},
		title: 'Du :',
		name: 'start_date',
		tagName: 'preferency.start_date',
		visible: [
			{ field: 'type_offert_id', value: ['3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'date',
		validation: {
			validate: {
				// requiredField,
			},
		},
		title: 'Au :',
		name: 'end_date',
		tagName: 'preferency.end_date',
		visible: [
			{ field: 'type_offert_id', value: ['3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		validation: {
			validate: {
				// requiredField,
			},
		},
		title: 'Soit :',
		name: 'night_number',
		tagName: 'preferency.night_number',
		visible: [
			{ field: 'type_offert_id', value: ['3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full  sm:w-1/3 ',
		title: 'Budgets:',
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const maxBudget = parseInt(
		// 				document.getElementsByName('max_budgets')[0].value
		// 			);
		// 			if(!isNaN(maxBudget)){
		// 				return parseInt(value) < maxBudget
		// 				? true
		// 				: 'Le budget minimal doit être inférieur au budget maximal';
		// 			}
		// 		},
		// 		required: (value) => {
		// 			return value === '' ? 'Le budget minimale est requis' : true;
		// 		},
		// 	},
		// },
		title: 'Min :',
		name: 'min_budgets',
		tagName: 'preferency.min_budgets',
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const minBudgets = parseInt(
		// 				document.getElementsByName('min_budgets')[0].value
		// 			);
		// 			if(!isNaN(minBudgets)){
		// 				return parseInt(value) > minBudgets
		// 				? true
		// 				: 'Le budget maximal doit être supérieur au budget minimal';
		// 			}
		// 		},
		// 		required: (value) => {
		// 			return value === '' ? 'Le budget maximal est requis' : true;
		// 		},
		// 	},
		// },
		title: 'Max :',
		name: 'max_budgets',
		tagName: 'preferency.max_budgets',
	},

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/3',
		title: 'Surface Habitable :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const maxSurface = parseInt(
		// 				document.getElementsByName('max_surface')[0].value
		// 			);
		// 			if(!isNaN(maxSurface)){
		// 				return parseInt(value) < maxSurface
		// 				? true
		// 				: 'La surface minimale doit être inférieur à la surface maximale';
		// 			}
		// 		},

		// 		// requiredField,
		// 	},
		// },
		title: 'Min :',
		name: 'min_surface',
		tagName: 'preferency.min_surface',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const minSurface = parseInt(
		// 				document.getElementsByName('min_surface')[0].value
		// 			);
		// 			if(!isNaN(minSurface)){
		// 				return parseInt(value) > minSurface
		// 				? true
		// 				: 'La surface maximale doit être supérieur auà la surface minimale';
		// 			}
		// 		},

		// 		// requiredField,
		// 	},
		// },
		title: 'Max :',
		name: 'max_surface',
		tagName: 'preferency.max_surface',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [...CRITERE_OFFRE.PARKING_CAVE_GARAGE],
				condition: 'INEQUAL',
			},
		],
	},

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/3',
		title: 'Surface Terrain :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['3', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const maxSurface = parseInt(
		// 				document.getElementsByName('max_surface_ground')[0].value
		// 			);
		// 			if(!isNaN(maxSurface)){
		// 				return parseInt(value) < maxSurface
		// 				? true
		// 				: 'La surface minimale doit être inférieur à la surface maximale de terrain';
		// 			}
		// 		},

		// 		// requiredField,
		// 	},
		// },
		title: 'De :',
		name: 'min_surface_ground',
		tagName: 'preferency.min_surface_ground',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['3', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const minSurface = parseInt(
		// 				document.getElementsByName('min_surface_ground')[0].value
		// 			);
		// 			if(!isNaN(minSurface)){
		// 				return parseInt(value) > minSurface
		// 				? true
		// 				: 'La surface maximale doit être supérieur auà la surface minimale de terrain';
		// 			}
		// 		},

		// 		// requiredField,
		// 	},
		// },
		title: 'A :',
		name: 'max_surface_ground',
		tagName: 'preferency.max_surface_ground',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['3', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.IMMEUBLE],
				condition: 'EQUAL',
			},
		],
	},

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/3',
		title: 'Nombre de pieces :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
				condition: 'INEQUAL',
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const maxRoom = parseInt(
		// 				document.getElementsByName('max_room')[0].value
		// 			);
		// 			if(!isNaN(maxRoom)){
		// 				return parseInt(value) < maxRoom
		// 				? true
		// 				: 'Le nombre de pièces minimal doit être inférieur à la nombre de pièces maximal';
		// 			}
		// 		},

		// 		// requiredField,
		// 	},
		// },
		title: 'Min :',
		name: 'min_room',
		tagName: 'preferency.min_room',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
				condition: 'INEQUAL',
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const minRoom = parseInt(
		// 				document.getElementsByName('min_room')[0].value
		// 			);
		// 			if(!isNaN(minRoom)){
		// 				return parseInt(value) > minRoom
		// 				? true
		// 				: 'Le nombre de pièces maximal doit être supérieur au nombre de pièces minimal';
		// 			}
		// 		},

		// 		// requiredField,
		// 	},
		// },
		title: 'Max :',
		name: 'max_room',
		tagName: 'preferency.max_room',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
				condition: 'INEQUAL',
			},
		],
	},

	///Chambres

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/3',
		title: 'Nombre de chambre :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const maxBedroom = parseInt(
		// 				document.getElementsByName('max_bedroom')[0].value
		// 			);
		// 			if(!isNaN(maxBedroom)){
		// 				return parseInt(value) < maxBedroom
		// 				? true
		// 				: 'Le nombre de chambre minimal doit être inférieur à la nombre de chambre maximal';						
		// 			}
		// 		},
		// 		// requiredField,
		// 	},
		// },
		title: 'Min :',
		name: 'min_bedroom',
		tagName: 'preferency.min_bedroom',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const minRoom = parseInt(
		// 				document.getElementsByName('min_bedroom')[0].value
		// 			);
		// 			if(!isNaN(minRoom)){
		// 				return parseInt(value) > minRoom
		// 				? true
		// 				: 'Le nombre de chambre maximal doit être supérieur au nombre de chambre minimal';
		// 			}
		// 		},
		// 		// requiredField,
		// 	},
		// },
		title: 'Max :',
		name: 'max_bedroom',
		tagName: 'preferency.max_bedroom',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				field: CRITERE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
				condition: 'INEQUAL',
			},
		],
	},

	///Superficie
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/3',
		title: 'Superficie :',
		visible: [
			{ field: 'type_offert_id', value: ['4'] },
			{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'text',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const maxSuperficie = parseInt(
		// 				document.getElementsByName('min_superficie')[0].value
		// 			);
		// 			if(!isNaN(maxSuperficie)){
		// 				return parseInt(value) < maxSuperficie
		// 				? true
		// 				: 'Le nombre de superficie minimale doit être inférieur à la nombre de superficie maximale';
		// 			}
		// 		},
		// 		// requiredField,
		// 	},
		// },
		title: 'Min :',
		name: 'min_superficie',
		tagName: 'preferency.min_superficie',
		visible: [
			{ field: 'type_offert_id', value: ['4'] },
			{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/4',
		type: 'number',
		// validation: {
		// 	validate: {
		// 		greaterThan: (value) => {
		// 			const minRoom = parseInt(
		// 				document.getElementsByName('min_superficie')[0].value
		// 			);
		// 			if(!isNaN(minRoom)){
		// 				return parseInt(value) > minRoom
		// 				? true
		// 				: 'Le nombre de superficie maximale doit être supérieur au nombre de superficie minimale';
		// 			}
		// 		},
		// 		// requiredField,
		// 	},
		// },
		title: 'Max :',
		name: 'max_superficie',
		tagName: 'preferency.max_superficie',
		visible: [
			{ field: 'type_offert_id', value: ['4'] },
			{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
		],
	},
	{
		group: 'info_personnelle',
		type: 'select',
		name: 'activities',
		tagName: 'preferency.activities',
		className: 'w-full',
		// validation: {
		// 	validate: {
		// 		// requiredField,
		// 	},
		// },
		title: 'Activité:',
		choices: [
			{
				title: 'Alimentaire',
				value: 'alimentaire',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
				],
			},
			{
				title: 'Restauration',
				value: 'restauration',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
				],
			},
			{
				title: 'Esthétique',
				value: 'esthetique',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
				],
			},
			{
				title: 'Hôtel',
				value: 'hotel',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
				],
			},
			{
				title: 'Camping',
				value: 'camping',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
				],
			},
			{
				title: 'Divers',
				value: 'divers',
				visible: [
					{ field: 'type_offert_id', value: ['4'] },
					{ field: 'type_estate_id', value: ['3', '4', '5', '6'] },
				],
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['4'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},
	{
		group: 'info_personnelle',
		type: 'locality_city',
		name: 'localities',
		tagName: 'preferency.localities',
		validation: {
			validate: {
				// requiredField,
			},
		},
		className: 'w-full sm:w-1/2',
		title: 'Localités:',
	},
];
