import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Chevron from "../../../components/commons/Chevron";
import { imageAgent } from "../../../helpers/image.source.";
import { ALREADY_USED_CRITERIA, ATTRIBUTES } from "../../../helpers/attributes";




export const RapprochementSection = ({ contact, link }) => {
	const { filter, contactId } = useParams();
	let options = Object.keys(contact.preferency).filter(
		(item) => !ALREADY_USED_CRITERIA.includes(item)
	);
	options = Object.entries(contact.preferency).filter(
		(item) => options.includes(item[0]) && item[1]
	);

	options = options.map(
		(item) => ATTRIBUTES.filter((elem) => elem.attribut === item[0] && elem)[0]?.label 
	).filter((elem) =>elem !== undefined);

	console.log(contact);
	return (
		<div className='mb-8 border-2 border-solid'>
			<div className='w-full m-1 rounded flex sm:min-h-36 min-h-52 justify-center'>
				<div className='sm:w-[95%] w-[80%] border-2 flex rounded-l-xl border-solid p-1 sm:relative'>
					<div className='border-2 border-solid rounded mr-[5%] w-full flex flex-wrap border-black/60 p-1 relative'>
						<div className='w-[15%] sm:flex hidden items-center justify-center rounded'>
							<img
								src='/images/agents/8380015.jpg'
								alt='photos original'
								className='h-full object-cover rounded'
							/>
						</div>
						<div className='w-full pb-2 sm:w-[85%] sm:relative'>
							<div className='rounded-full w-16 h-16 absolute shadow-lg shadow-black z-10 border-gray-700 border-2 border-solid bottom-[25%] left-[-30px] sm:flex hidden items-center justify-center bg-gray-500'>
								<img
									src={
										contact.agent?.photo_url
											? imageAgent(
													`/${contact.agent.agency_id}/${contact.agent.photo_url}`
											  )
											: '/images/agents/8380015.jpg'
									}
									alt='photos original'
									className='h-full object-cover rounded-full'
								/>
							</div>
							{contact.rapprochements && (
							<div className='text-white font-bold rounded-full w-12 h-12 absolute shadow-lg shadow-black z-10 border-gray-700 border-2 border-solid bottom-[10%] right-[10px] top-[130px]  flex items-center justify-center bg-gray-500'>
								{contact.rapprochements.length}
							</div>
							)}

							<div className='sm:ml-20 sm:min-h-[80%]'>
								<div className='flex justify-between w-full flex-wrap'>
									<h1 className='sm:w-1/2 w-full break-words'>
										Nom et prénoms : <span className="uppercase"> {contact.lastname}</span> {contact.firstname}
									</h1>

									<h2 className='sm:w-1/2 w-full sm:text-right pr-2 pt-1'>
										Numéro mandat : 
								
											{ contact.mandate && contact.mandate && contact.mandate.map((elem, index)=> (
											<span key={index} className="text-black bg-gray-300 p-1 border rounded m-1">
												{elem.num_mandat}
											</span>
											)
											) }
										
									</h2>
									<div className='w-full'>
										<ul className=''>
											<li>
												Critères recherches :
												<span className="flex flex-wrap">

													{options.map((item, index) => (
														<small key={index}
															className={`px-2 py-1 border-2 border-solid border-gray-600 bg-green-600 text-white font-bold rounded-md cursor-pointer flex items-center gap-2 justify-center`}>
															{' '}
															<i className='fa fa-check-circle'></i> {item}
														</small>
													))}
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<hr className='border-solid border-[1px] border-black/70 my-2' />
							<div>
								<div className='pt-1 h-full flex-col justify-start sm:flex-row flex sm:justify-center sm:items-center gap-4'>
									<div className='flex sm:justify-start gap-2 items-start sm:w-1/2 w-fit'>
										<div className=''>
											Statut  du contact : {''}
											<span
												className={`uppercase text-white p-1 rounded font-bold shadow-sm mx-2 shadow-black ${contact.publish === 'active'
													? 'bg-green-500'
													: 'bg-yellow-500'
													}`}>
												{contact.publish}
											</span>

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				{!contactId && (
					<div className='sm:w-[5%] flex justify-center items-center w-[15%] rounded-r-xl border-2 border-solid bg-black cursor-pointer text-white hover:bg-gray-400 hover:text-black'>
						<Chevron
							link={ `/mes-rapprochements/rapprochement-contact/${contact.uuid}`}
							state={filter}
						/>
					</div>
				)}
			</div>

		</div>

	)
}