import { requiredField } from '../../../validation/validation';
export const Image = [
	{
		//photo original
		type: 'file',
		name: 'photos_original',
		tagName: 'photos.photos_couvert',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos original du bien:',
		validation: {
			validate: { requiredField },
		},
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_original_description',
		tagName: 'photos.photos_original_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',
		visible: [
			{
				field: 'photos_original',
			},
		],
	},

	{
		//Photos slide
		type: 'file',
		name: 'photos_slide1',
		tagName: 'photos.photos_slide1',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 1',
		validation: {
			validate: { requiredField },
		},
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide1_description',
		tagName: 'photos.photos_slide1_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',
		visible: [
			{
				field: 'photos_slide1',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide2',
		tagName: 'photos.photos_slide2',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 2',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide2_description',
		tagName: 'photos.photos_slide2_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',
		validation: {},
		visible: [
			{
				field: 'photos_slide2',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide3',
		tagName: 'photos.photos_slide3',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 3',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide3_description',
		tagName: 'photos.photos_slide3_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide3',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide4',
		tagName: 'photos.photos_slide4',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 4',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide4_description',
		tagName: 'photos.photos_slide4_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide4',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide5',
		tagName: 'photos.photos_slide5',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 5',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide5_description',
		tagName: 'photos.photos_slide5_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide5',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide6',
		tagName: 'photos.photos_slide6',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 6',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide6_description',
		tagName: 'photos.photos_slide6_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide6',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide7',
		tagName: 'photos.photos_slide7',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 7',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide7_description',
		tagName: 'photos.photos_slide7_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide7',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide8',
		tagName: 'photos.photos_slide8',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 8',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide8_description',
		tagName: 'photos.photos_slide8_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide8',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide9',
		tagName: 'photos.photos_slide9',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 9',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide9_description',
		tagName: 'photos.photos_slide9_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide9',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide10',
		tagName: 'photos.photos_slide10',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 10',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide10_description',
		tagName: 'photos.photos_slide10_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide10',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide11',
		tagName: 'photos.photos_slide11',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 11',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide11_description',
		tagName: 'photos.photos_slide11_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide11',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide12',
		tagName: 'photos.photos_slide12',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 12',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide12_description',
		tagName: 'photos.photos_slide12_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide12',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide13',
		tagName: 'photos.photos_slide13',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 13',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide13_description',
		tagName: 'photos.photos_slide13_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide13',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide14',
		tagName: 'photos.photos_slide14',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 14',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide14_description',
		tagName: 'photos.photos_slide14_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide14',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide15',
		tagName: 'photos.photos_slide15',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 15',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide15_description',
		tagName: 'photos.photos_slide15_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide15',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide16',
		tagName: 'photos.photos_slide16',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 16',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide16_description',
		tagName: 'photos.photos_slide16_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide16',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide17',
		tagName: 'photos.photos_slide17',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 17',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide17_description',
		tagName: 'photos.photos_slide17_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide17',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide18',
		tagName: 'photos.photos_slide18',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 18',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide18_description',
		tagName: 'photos.photos_slide18_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide18',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide19',
		tagName: 'photos.photos_slide19',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 19',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide19_description',
		tagName: 'photos.photos_slide19_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide19',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
	{
		//Photos slide
		type: 'file',
		name: 'photos_slide20',
		tagName: 'photos.photos_slide20',
		className: 'w-full sm:w-1/2 ',
		title: 'Photos 20',
	},
	{
		//Photos slide
		type: 'textarea',
		name: 'photos_slide20_description',
		tagName: 'photos.photos_slide20_description',
		className: 'w-full sm:w-1/2 ',
		title: 'Description',

		visible: [
			{
				field: 'photos_slide20',
				value: ['', null, false],
				condition: 'INEQUAL',
			},
		],
	},
];
