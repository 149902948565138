import NavItem from '../commons/nav/NavItem';
import Nav from '../commons/nav/Nav';
import { SearchBar } from './home/search-bar';
import { Logo } from './home/logo';
import { MobileMenu } from '../commons/nav/MobileMenu';
import MENU from '../../service/route/route';

// Header for Navbar Homepage
export default function HeaderHome() {
	return (
		<>
			<Logo />
			<MobileMenu menu={MENU} />
			<SearchBar />
			<Nav>
				{MENU.map(
					(item, index) =>
						item.is_menu && (
							<NavItem key={index} href={item.link}>
								{item.text}
							</NavItem>
						)
				)}
			</Nav>
		</>
	);
}
