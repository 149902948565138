import { useAgent } from '../../../../service/agency/useAgent';
import { requiredField, greaterThanZero } from '../../../validation/validation';
import { CRITERE, CRITERE_OFFRE, TYPE_BIEN, TYPE_OFFRE } from '../../type';
import { Critaire } from '../Critaire';

export const InformationFinanciere = [
	{
		//Prix
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'Prix :',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Mensuel:
		type: 'number',
		name: 'info_rent',
		tagName: 'info_financiere.info_rent',
		className: 'w-full sm:w-1/2 ',
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//BIEN SOUMIS À UN ENCADREMENT DU LOYER:
		type: 'radio',
		name: 'rentSupervision',
		tagName: 'info_financiere.info_rent_encadrement.rentSupervision',
		className: 'w-full sm:w-3/4  ',
		title: 'Bien soumis à un encadrement du loyer:',
		choices: [
			{
				title: 'Non',
				value: '1',
			},
			{
				title: 'Oui',
				value: '2',
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//LOYER DE BASE
		type: 'number',
		name: 'info_rent_default',
		tagName: 'info_financiere.info_rent_encadrement.info_rent_default',
		className: 'w-full sm:w-1/2 ',
		title: 'Loyer de base:',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					...CRITERE_OFFRE.TERRAIN,
					CRITERE.PARKING,
					CRITERE.CAVE,
					CRITERE.GARAGE,
				],
			},
		],
	},
	{
		//LOYER DE BASE
		type: 'number',
		name: 'info_rent_default',
		className: 'w-full sm:w-1/2 ',
		title: 'Loyer de base:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'rentSupervision', value: ['3'] }],
	},
	{
		//LOYER DE RÉFÉRENCE MAJORÉ
		type: 'number',
		name: 'info_rent_majored',
		tagName: 'info_financiere.info_rent_encadrement.info_rent_majored',
		className: 'w-full sm:w-1/2 ',
		title: 'Loyer de reference majoré:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'rentSupervision', value: ['3'] }],
	},
	{
		//COMPLÉMENT:
		type: 'number',
		name: 'info_rent_complement',
		tagName: 'info_financiere.info_rent_encadrement.rising',
		className: 'w-full sm:w-1/2 ',
		title: 'Complément:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'rentSupervision', value: ['3'] }],
	},
	{
		//HONORAIRES:
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'HONORAIRES: ',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//PART LOCATAIRE:
		type: 'radio',
		name: 'tenantShare',
		tagName: 'info_financiere.info_tenant_chare.tenantShare',
		className: 'w-full sm:w-3/4  ',
		title: 'Part locataire:',
		choices: [
			{
				title: 'Non',
				value: '1',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],

		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					...CRITERE_OFFRE.TERRAIN,
					CRITERE.PARKING,
					CRITERE.CAVE,
					CRITERE.GARAGE,
				],
			},
		],
	},
	{
		//Montant
		type: 'number',
		name: 'rising',
		tagName: 'info_financiere.info_tenant_chare.rising',
		className: 'w-full sm:w-1/2 ',
		title: 'Montant:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'tenantShare', value: ['3'] }],
	},
	{
		//%
		type: 'number',
		name: 'percent_owner',
		tagName: 'info_financiere.info_tenant_chare.ownersShare',
		className: 'w-full sm:w-1/2 ',
		title: '%:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'tenantShare', value: ['3'] }],
	},
	{
		//MONTANT M2:
		type: 'number',
		name: 'risingM2',
		tagName: 'info_financiere.info_tenant_chare.risingM2',
		className: 'w-full sm:w-1/2 ',
		title: 'Montant M2:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'tenantShare', value: ['3'] }],
	},
	{
		//DONT ÉTAT DES LIEUX:
		type: 'number',
		name: 'includingStatusOfPremises',
		tagName: 'info_financiere.info_tenant_chare.includingStatusOfPremises',
		className: 'w-full sm:w-1/2 ',
		title: 'Dont état des lieux:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'tenantShare', value: ['3'] }],
	},
	{
		//PART PROPRIETAIRE:
		type: 'radio',
		name: 'ownersShare',
		tagName: 'info_financiere.info_owner_share.ownersShare',
		className: 'w-full sm:w-3/4  ',
		title: 'Part proprietaire:',
		choices: [
			{
				title: 'Non',
				value: '1',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],

		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					...CRITERE_OFFRE.TERRAIN,
					CRITERE.PARKING,
					CRITERE.CAVE,
					CRITERE.GARAGE,
				],
			},
		],
	},
	{
		//Montant
		type: 'number',
		name: 'ownerRising',
		tagName: 'info_financiere.info_owner_share.ownerRising',
		className: 'w-full sm:w-1/2 ',
		title: 'Montant:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'ownersShare', value: ['3'] }],
	},
	{
		//%
		type: 'number',
		name: 'ownerPercent',
		tagName: 'info_financiere.info_owner_share.ownerPercent',
		className: 'w-full sm:w-1/2 ',
		title: '%:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'ownersShare', value: ['3'] }],
	},
	{
		//MONTANT M2:
		type: 'number',
		name: 'ownerRisingM2',
		tagName: 'info_financiere.info_owner_share.ownerRisingM2',
		className: 'w-full sm:w-1/2 ',
		title: 'Montant M2:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'ownersShare', value: ['3'] }],
	},
	{
		//DONT ÉTAT DES LIEUX:
		type: 'number',
		name: 'ownerIncludingStatusOfPremises',
		tagName: 'info_financiere.info_owner_share.ownerIncludingStatusOfPremises',
		className: 'w-full sm:w-1/2 ',
		title: 'Dont état des lieux:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [{ condition: 'EQUAL', field: 'ownersShare', value: ['3'] }],
	},
	{
		//CHARGES LOCATIVES::
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'CHARGES LOCATIVES:: ',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Total
		type: 'number',
		name: 'info_locative_charge_total',
		tagName: 'info_financiere.info_locative_charge_total',
		className: 'w-full sm:w-1/2 ',
		title: 'Total:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Format
		type: 'select',
		name: 'info_locative_charge_format',
		tagName: 'info_financiere.info_locative_charge_format',
		className: 'w-full sm:w-1/2 ',
		title: 'Format:',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Forfaitaires',
				value: 'Forfaitaires',
			},
			{
				title: 'Mensuelles',
				value: 'Mensuelles',
			},
			{
				title: 'Prévisionnelles mensuelles',
				value: 'Prévisionnelles mensuelles',
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Information
		type: 'number',
		name: 'info_locative_charge_information',
		tagName: 'info_financiere.info_locative_charge_information',
		className: 'w-full sm:w-1/2 ',
		title: 'Information:',
		// validation: {
		// 	validate: {
		// 		requiredField,
		// 	},
		// },
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Prix Net vendeur
		type: 'number',
		name: 'info_price',
		tagName: 'info_financiere.info_price',
		className: 'w-full  sm:w-3/4',
		title: 'Prix Net vendeur :',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},

	{
		className: 'sm:w-1/4',
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Estimation
		group: 'info_personnelle',
		className: 'w-full sm:w-2/12 !justify-start mt-4 sm:pl-4 underline',
		title: 'ESTIMATION',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Valeur d'estimation
		type: 'number',
		name: 'info_estimation_value',
		tagName: 'info_financiere.info_estimation_value',
		className: 'w-full sm:w-5/12 ',
		title: 'Valeur:',

		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Date esitimation
		type: 'date',
		name: 'info_estimation_date',
		tagName: 'info_financiere.info_estimation_date',
		className: 'w-full sm:w-5/12 ',
		title: 'Date:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Travaux à prevoir
		type: 'textarea',
		name: 'info_predicted_work',
		tagName: 'info_financiere.info_predicted_work',
		className: 'w-full sm:w-1/2 ',
		title: 'Travaux à prevoir:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Dépôt de garantie
		type: 'number',
		name: 'info_garantied_deposit',
		tagName: 'info_financiere.info_garantied_deposit',
		className: 'w-full sm:w-1/2 ',
		title: 'Dépôt de garantie:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Taxe d'habitation
		type: 'number',
		name: 'info_habitation_taxe',
		tagName: 'info_financiere.info_habitation_taxe',
		className: 'w-full sm:w-1/2 ',
		title: "Taxe d'habitation:",
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//TAXE ORDURES MÉNAGÈRES:
		type: 'number',
		name: 'info_ordure_menagere_taxe',
		tagName: 'info_financiere.info_ordure_menagere_taxe',
		className: 'w-full sm:w-1/2 ',
		title: 'Taxe ordure menagéres:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['2'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Taxe foncière
		type: 'number',
		name: 'info_fonciere_taxe',
		tagName: 'info_financiere.info_fonciere_taxe',
		className: 'w-full sm:w-1/2 ',
		title: 'Taxe foncière:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Charges mensuelles
		type: 'number',
		name: 'info_monthly_charge',
		tagName: 'info_financiere.info_monthly_charge',
		className: 'w-full sm:w-1/2 ',
		title: 'Charges mensuelles:',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
];
