import { useEffect, useState } from 'react';
import { CardPige } from '../../components/generals/pige/card';
import { PigeService } from '../../service/models/pige';
import { Pagination } from '../../components/commons/pagination';
import { FilterPige } from '../../components/generals/pige/filter';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { GeneralSpinner } from '../../components/commons/general-spinner';

export const DashboardPige = () => {
	const [biens, setBiens] = useState();
	const [showLoading, setShowLoading] = useState(false);
	const [filters, setFilters] = useState({});
	const [pagination, setPagination] = useState();
	const getPiges = (page) => {
		setShowLoading(true);
		const params = Object.entries(filters)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');
		PigeService.getPiges(page, params)
			.then((response) => {
				setBiens(response.data);
				setPagination({
					links: response.data.links,
					per_page: response.data.per_page,
					total: response.data.total,
				});
			})
			.catch(() => {})
			.finally(() => setShowLoading(false));
	};

	useEffect(() => {
		if (filters.cp) getPiges();
	}, [filters]);

	const handlePiecesChange = (pieces) => {
		setFilters((prev) => ({
			...prev,
			piece_min: pieces[0],
			piece_max: pieces[1],
		}));
	};

	const handleSurfaceChange = (surface) => {
		setFilters((prev) => ({
			...prev,
			surface_min: surface[0],
			surface_max: surface[1],
		}));
	};

	const handleChangePage = (page) => getPiges(page);
	const handleFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, values: filter.join(',') }));
	};
	const handleTypeFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, type: filter }));
	};
	const handleTypeBienFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, bien: filter }));
	};
	const handleLocalisationBienFilterChange = (filter) => {
		setFilters((prev) => ({ ...prev, cp: filter }));
	};

	const handleKeywordFilterChange = (keyword) => {
		setFilters((prev) => ({ ...prev, keyword }));
	};

	const handlePriceMaxFilterChange = (budget_max) => {
		setFilters((prev) => ({ ...prev, budget_max }));
	};

	const handlePriceMinFilterChange = (budget_min) => {
		setFilters((prev) => ({ ...prev, budget_min }));
	};

	return (
		<>
			{biens && (
				<h1 className={'font-bold text-2xl mb-4'}>{biens.total} annonces</h1>
			)}
			<FilterPige
				handleCriteriaFilterChange={handleFilterChange}
				handleTypeFilterChange={handleTypeFilterChange}
				handleTypeBienFilterChange={handleTypeBienFilterChange}
				handlePriceMinFilterChange={handlePriceMinFilterChange}
				handlePiecesChange={handlePiecesChange}
				handleSurfaceChange={handleSurfaceChange}
				handleLocalisationBienFilterChange={handleLocalisationBienFilterChange}
				handlePriceMaxFilterChange={handlePriceMaxFilterChange}
				handleKeywordFilterChange={handleKeywordFilterChange}
			/>
			{biens && (
				<>
					<div className='flex flex-wrap justify-center gap-20'>
						{Object.entries(biens.data).map((bien, index) => {
							return <CardPige pige={bien[1]} key={index} index={bien} />;
						})}
					</div>
					{biens.data.length === 0 && (
						<div className='text-center font-bold text-4xl mt-10'>
							Il n'y a pas d'annonce disponible
						</div>
					)}
					{biens.data.length !== 0 &&
						pagination.links &&
						pagination.links.length !== 0 && (
							<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
								{pagination.links
									.slice(1, pagination.links.length)
									.slice(0, -1)
									.map((link, index) => {
										return (
											<Pagination
												changePage={handleChangePage}
												key={index}
												link={link}></Pagination>
										);
									})}
							</div>
						)}
				</>
			)}
			{showLoading && <GeneralSpinner />}
		</>
	);
};
