import { useNavigate } from 'react-router-dom';
import { CommentPige } from './comment';
import { getStorageUser } from '../../../service/localStorage';

export const CardPige = ({ pige, index }) => {
	const user = getStorageUser().user;
	const agencyComs = pige.commentaires.filter(
		(elem) => elem.user.agency_id === user.agency_id
	);

	let commentaire = 'A prospecter';
	if (agencyComs.length !== 0)
		commentaire = `Attribué à ${agencyComs[0].user.name}`;

	const navigate = useNavigate();
	const formatter = new Intl.NumberFormat('fr-fr', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 0,
	});
	return (
		<>
			<div className='w-full lg:w-1/4 md:w-1/3 sm:w-1/3 rounded-2xl shadow-lg bg-white'>
				<div className='relative'>
					<img
						onClick={() =>
							(window.location.href = `/ma-pige/details/${pige.id}`)
						}
						className='w-full object-cover rounded-t-xl h-80 cursor-pointer'
						src={pige['img']}
						alt={pige.vile}
					/>
					<CommentPige comment={commentaire} />
					{pige.tel_1 && (
						<div className='absolute bottom-2 right-2 bg-green-500 rounded-full px-4 py-5 text-center cursor-pointer'>
							<i className='fa-solid fa-2xl text-white fa-circle-check'></i>
						</div>
					)}
				</div>
				<div className='p-4'>
					<div className='flex justify-between items-center'>
						<h1 className='capitalize text-xl font-bold'>{pige.bien}</h1>
						<h2 className='capitalize text-xl font-bold color-text'>
							{formatter.format(pige.prix)}
						</h2>
					</div>
					<h1 className='my-4 text-gray-500'>
						<i className='fa-solid fa-street-view mr-5 color-text'></i>{' '}
						{pige.cp} {pige.ville}
					</h1>
					<div
						className='p-4 bg-[#fbfcff] w-full flex flex-wrap justify-between items-center'
						style={{ border: '1px solid #E9F1FF' }}>
						<div className='w-1/2 font-bold'>
							<i className='fa-solid fa-expand mr-5 color-text'></i>{' '}
							{pige.surface} m<sup>2</sup>
						</div>
						<div className='w-1/2 font-bold'>
							<i className='fa-solid fa-border-all  mr-5 color-text'></i>{' '}
							{pige.pieces} pièce
							{pige.pieces > 1 && 's'}
						</div>
					</div>
					<div className='my-4 text-gray-500'>
						<i className='fa-solid fa-calendar-check color-text mr-5'></i>{' '}
						Publiée le {new Date(pige.date).toLocaleDateString('fr-FR')}
					</div>
				</div>
			</div>
		</>
	);
};
