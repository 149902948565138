import { useEffect, useState } from 'react';

export const FileInput = ({ element, register, style, control }) => {
	const [file, setFile] = useState();
	const previewImage = (event) => {
		setFile(document.getElementById(element.name));
		const output = document.getElementById('output-' + element.name);
		const container = document.getElementById(`container-${element.name}`);
		container.style.display = 'flex';
		output.src = URL.createObjectURL(event.target.files[0]);
		output.onload = function () {
			URL.revokeObjectURL(output.src); // free memory
		};
	};

	const removeUrl = () => {
		const output = document.getElementById('output-' + element.name);
		const input = document.getElementById(element.name);
		const container = document.getElementById(`container-${element.name}`);
		input.value = '';
		output.src = '';
		container.style.display = 'none';
	};
	useEffect(() => {
		setFile(document.getElementById(element.name));
	}, []);
	return (
		<div className='w-full'>
			<input
				type='file'
				required={element.validation?.validate.requiredField ? true : false}
				name={element.name}
				id={element.name}
				{...register(element.name, {
					onChange: previewImage,
				})}
			/>

			<div
				style={{ display: 'none' }}
				id={`container-${element.name}`}
				className='flex items-start gap-x-2'>
				<img
					id={`output-${element.name}`}
					alt='Preview'
					src=''
					className='w-24 h-24'
				/>
				<i className='fa fa-close cursor-pointer' onClick={removeUrl}></i>
			</div>
		</div>
	);
};
