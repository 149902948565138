import { useAnnonce } from '../../../../service/annonce/useAnnonce';
import {
	successToast,
	technicalErrorToast,
} from '../../../../service/toast/showToast';
import { requiredField } from '../../../validation/validation';
let annonceDescription = '';
let annonceText = '';
let isAnnonce = false;

const getAnnonces = async (watchAllFields, fieldTextArea, fieldText) => {
	isAnnonce = true;
	await useAnnonce
		.createAnnonce(watchAllFields)
		.then((response) => {
			console.log(response);
			const textareaElement = document.querySelector(
				`textarea[name=${fieldTextArea}]`
			);
			const textElement = document.querySelector(
				`input[name=${fieldText}]`
			)
			 
			isAnnonce = false;
			annonceDescription = response.data.description;
		    annonceText = response.data.titre;
			if (textareaElement && textElement ) {
				 document.getElementsByClassName(`${fieldTextArea}`).value = annonceDescription ;
				 document.getElementsByClassName(`${fieldText}`).value = annonceText;
				textareaElement.value = annonceDescription;
				textElement.value = annonceText;
			}
		})
		.catch((data) => {
			console.log(data);
			technicalErrorToast();
		});
};

export const Annonces = [
	{
		//Titre de l'annonce
		type: 'text',
		name: 'title',
		tagName:'advertisement.title',
		className: 'w-full sm:w-1/2 ',
		validation: {
			validate: { requiredField },
		},
		title: "Titre de l'annonce:",
		
	},
	{
		//Description de l'annonce
		type: 'textarea',
		name: 'description',
		tagName:'advertisement.description',
		className: 'w-full sm:w-1/2 ',
		validation: {
			validate: { requiredField },
		},
		title: 'Annonce:',
		
	},
	{
		//Envoyer l'annonce
		type: 'button',
		name: 'annonce_button',
		loading: isAnnonce,
		style:
			'text-white font-bold py-5 px-4 rounded w-[50%]  flex flex-wrap items-center justify-center',
		click: async (data) => await getAnnonces(data, 'description','title'),
		children: 'Génerer une annonce',

	},
];
