import React, { useEffect, useState } from 'react';
import { getStorageUser } from '../../../service/localStorage';
import { dateFormater } from '../../../helpers/formatter';
import style from './contactDropdown.module.css';
import { ALREADY_USED_CRITERIA, ATTRIBUTES } from '../../../helpers/attributes';
import { useNavigate } from 'react-router-dom';

function ContactDropdown({ rapprochement, bien }) {
	const [detail, setDetail] = useState(false);
	const userConnected = getStorageUser().user;
	const navigate = useNavigate();
	const ShowDetails = () => {
		setDetail((prev) => !prev);
	};
	let rapprochmentOptions = JSON.parse(rapprochement.options);
	let options = Object.keys(rapprochmentOptions).filter(
		(item) => !ALREADY_USED_CRITERIA.includes(item)
	);
	options = Object.entries(rapprochmentOptions).filter(
		(item) => options.includes(item[0]) && item[1]
	);

	options = options.map(
		(item) => ATTRIBUTES.filter((elem) => elem.attribut === item[0])[0]?.label
	);
	return (
		<>
			<div className='grid grid-cols-10 gap-2 items-center rounded border-2 border-r-0 border-solid border-[#2d5c6a2f]'>
				<div className='py-4 col-span-4'>
					<div className='flex items-center gap-5 pl-5'>
						<i
							className={`fa-solid fa-fire fa-2xl ${
								rapprochement.value >= 85
									? 'text-red-700'
									: rapprochement.value < 85 && rapprochement.value > 81
									? 'text-orange-500'
									: 'text-yellow-500'
							}`}></i>
						<h1
							className='uppercase text-center cursor-pointer'
							onClick={() =>
								navigate(
									'/mes-contacts/details-contact/' + rapprochement.contact.uuid
								)
							}>
							{' '}
							{rapprochement.contact.civility == 'sir' ? 'M.' : 'Mme.'}{' '}
							{rapprochement.contact.lastname} {rapprochement.contact.firstname}
						</h1>
					</div>
				</div>
				<div className='py-4 col-span-2'>
					{dateFormater(rapprochement.created_at)}
				</div>
				<div className='py-4 col-span-2'>+{rapprochement.contact.phone}</div>
				<div className='col-span-2 flex justify-between'>
					<p className='py-4'>{rapprochement.value}%</p>
					<div className='rounded blue-color '>
						<button
							className='py-4 px-2 w-7 text-white text-xl'
							onClick={ShowDetails}>
							{detail === true ? '-' : '+'}
						</button>
					</div>
				</div>
			</div>

			{detail === true && (
				<div className='mb-2 px-5 pb-2 rounded border-2 border-solid border-[#2d5c6a2f]'>
					<h2 className='py-2 font-semibold'>L'ACQUEREUR :</h2>

					<table className='table-fixed'>
						<tbody>
							<tr>
								<td className={`${style.title}`}> PAYS :</td>
								<td className={`${style.content}`}>
									{rapprochement.contact.country_contact}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> TELEPHONE :</td>
								<td className={`${style.content}`}>
									+{rapprochement.contact.phone}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> EMAIL :</td>
								<td className={`${style.content} text-blue-500 underline`}>
									{rapprochement.contact.mail}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> TYPE DE BIEN :</td>
								<td className={`${style.content}`}>
									{' '}
									{bien.type_estate.designation}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> SECTEUR :</td>
								<td className={`${style.content}`}>
									{rapprochement.contact.adress_contact}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> SURFACE :</td>
								<td className={`${style.content}`}>
									{' '}
									Entre {rapprochement.contact.preferency.min_surface} et{' '}
									{rapprochement.contact.preferency.max_surface}m²{' '}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> NOMBRES DES PIECES :</td>
								<td className={`${style.content}`}>
									{rapprochement.contact.preferency.max_room}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> NOMBRES DE CHAMBRES :</td>
								<td className={`${style.content}`}>
									{rapprochement.contact.preferency.max_bedroom}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> PRIX :</td>
								<td className={`${style.content}`}>
									{' '}
									Entre {rapprochement.contact.preferency.min_budgets} et{' '}
									{rapprochement.contact.preferency.max_budgets} €{' '}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> AUTRES :</td>
							</tr>
							<tr>
								<td></td>
								<td className={`${style.content} inline-flex gap-1 flex-wrap`}>
									{options.map((item, index) => (
										<small
											className={`${style.option} flex items-center gap-2 justify-center`}
											key={index}>
											{' '}
											<i className='fa fa-check-circle'></i> {item}
										</small>
									))}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</>
	);
}

export default ContactDropdown;
