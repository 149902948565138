import { useEffect, useState } from 'react';

export const RadioInputUpdate = ({
	element,
	style,
	register,
	control,
	errors,
	setValue,
	dataUpdate,
}) => {
	const [selectedValue, setSelectedValue] = useState(null);
	const [initialRender, setInitialRender] = useState(0);
	const shouldRender = (choice) => {
		let shouldShow = [];
		if (choice.visible) {
			choice.visible.forEach((visible) => {
				// Si c'est un radio, alors mettre le checked dans le querySelector

				let doc = document.querySelector(`[name=${visible.field}]`);
				if (doc?.type === 'radio')
					doc = document.querySelector(`[name=${visible.field}]:checked`);

				if (doc) {
					if (visible.condition === 'INEQUAL') {
						shouldShow.push(!visible.value.includes(doc?.value));
					} else {
						shouldShow.push(visible.value.includes(doc?.value));
					}
					// TRAITER LES VALEURS AVEC UNE CONDITION AND
				}

				// LA CONDITION DE VISIBILITÉ EST FAIT DE SORTE QUE LA VALEUR DU FIELD SOIT EGALE À LA VALEUR DONNÉE
			});
		}

		return shouldShow.every((val) => val === true);
	};

	useEffect(() => {
		if (dataUpdate && dataUpdate[element.name] !== undefined) {
			setSelectedValue(dataUpdate[element.name] + '');
		}
	}, [dataUpdate, element.name]);

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};
	// Watch all fields

	useEffect(() => {
		if (initialRender === 0) setInitialRender(1);
		if (initialRender === 1) setInitialRender(2);
	}, [control, setInitialRender]);
	return (
		initialRender === 2 && (
			<>
				{element.choices.map((choice, index) => {
					const isChecked = selectedValue == choice.value;
					return (
						shouldRender(choice) && (
							<div key={index} className='flex items-center gap-4'>
								<input
									type='radio'
									className='pt-0 mt-0'
									value={choice.value}
									checked={isChecked}
									onClick={handleChange}
									{...register(element.name, { ...element.validation })}
								/>
								<span>{choice.title}</span>
							</div>
						)
					);
				})}
			</>
		)
	);
};
