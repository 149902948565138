import { useEffect } from 'react';
import { useState } from 'react';
import style from './dpe.module.css';

const DPE_COLOR = {
	A: { class: 'row-start-1', name: 'A' },
	B: { class: 'row-start-2', name: 'B' },
	C: { class: 'row-start-3', name: 'C' },
	D: { class: 'row-start-4', name: 'D' },
	E: { class: 'row-start-5', name: 'E' },
	F: { class: 'row-start-6', name: 'F' },
	G: { class: 'row-start-7', name: 'G' },
};

export const Dpe = ({ bien, model }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);

		if (0 <= ges && 6 >= ges && consommation <= 69 && 0 <= consommation) {
			return DPE_COLOR.A;
		}
		if (7 <= ges && 10 >= ges && consommation <= 109 && 70 <= consommation) {
			return DPE_COLOR.B;
		}
		if (11 <= ges && 29 >= ges && consommation <= 179 && 110 <= consommation) {
			return DPE_COLOR.C;
		}
		if (30 <= ges && 49 >= ges && consommation <= 249 && 180 <= consommation) {
			return DPE_COLOR.D;
		}
		if (50 <= ges && 69 >= ges && consommation <= 329 && 250 <= consommation) {
			return DPE_COLOR.E;
		}
		if (70 <= ges && 99 >= ges && consommation <= 429 && 330 <= consommation) {
			return DPE_COLOR.F;
		}
		if (100 <= ges && 430 <= consommation) {
			return DPE_COLOR.G;
		}
	};
	const ges = calculateDPE();
	const [currentGes, setCurrentGes] = useState(ges);
	return (
		currentGes && (
			<>
				{(bien.diagnostic.dpe === 'DPE Vièrge' && (
					<img
						className='w-full'
						src='/images/logo_DPE_vierge.png'
						alt='DPE Vierge'
					/>
				)) || (
					<>
						<div
							className='w-full grid grid-cols-10 gap-1 p-4'
							style={{
								gridTemplateRows: 'repeat(7, 50px)',
							}}>
							<div
								className='col-span-4'
								style={{
									gridRow: 'span 7 / span 7',
								}}>
								<div
									className={`grid gap-1`}
									style={{
										gridTemplateRows: 'repeat(7,50px)',
									}}>
									<div
										className={`flex col-span-1 row-span-1 items-center ${currentGes.class}`}
										style={{}}>
										<div className='rounded-tl-lg rounded-bl-lg border-[2px] border-solid border-black/50  p-0 w-1/2'>
											<small className='font-bold'>
												{bien.diagnostic.dpe_consommation}
												<sup style={{ fontSize: '6px' }}>
													kWh/m<sup>2</sup>/an
												</sup>
											</small>
										</div>
										<div className='border-[2px] border-solid border-black/50  p-0  w-1/2'>
											<small className='font-bold'>
												{bien.diagnostic.dpe_ges}
												<sup style={{ fontSize: '6px' }}>
													kg CO<sub>2</sub>/m<sup>2</sup>/an
												</sup>
											</small>
										</div>
									</div>
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'A' && style.bordered) || '-ml-14'
									} ${style.dpe}  bg-[#14673d]`}>
									A
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'B' && style.bordered) || '-ml-12'
									} ${style.dpe} bg-[#078f51] `}>
									B
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'C' && style.bordered) || '-ml-10'
									} ${style.dpe} bg-[#a2c553]`}>
									C
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'D' && style.bordered) || '-ml-8'
									} ${style.dpe} bg-[#f8ea4c]`}>
									D
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'E' && style.bordered) || '-ml-6'
									} ${style.dpe} bg-[#ec9f40]`}>
									E
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'F' && style.bordered) || '-ml-4'
									} ${style.dpe}  bg-[#df5e36]`}>
									F
								</div>
							</div>
							<div className={style.content}>
								<div
									className={`${
										(currentGes.name === 'G' && style.bordered) || '-ml-2'
									} ${style.dpe}  bg-[#da3832]`}>
									G
								</div>
							</div>
						</div>
					</>
				)}
			</>
		)
	);
};
