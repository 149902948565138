import { useEffect } from 'react';

export const RadioInput = ({ element, style, register, control, errors }) => {
	const shouldRender = (choice) => {
		let shouldShow = [];
		if (choice.visible) {
			choice.visible.forEach((visible) => {
				// Si c'est un radio, alors mettre le checked dans le querySelector
				let doc = document.querySelector(`[name=${visible.field}]`);
				if (doc?.type === 'radio')
					doc = document.querySelector(`[name=${visible.field}]:checked`);

				// LA CONDITION DE VISIBILITÉ EST FAIT DE SORTE QUE LA VALEUR DU FIELD SOIT EGALE À LA VALEUR DONNÉE
				// TRAITER LES VALEURS AVEC UNE CONDITION AND

				if (visible.condition === 'INEQUAL') {
					shouldShow.push(!visible.value.includes(doc?.value));
				} else {
					shouldShow.push(visible.value.includes(doc?.value));
				}
			});
		}
		return shouldShow.every((val) => val === true);
	};

	// Watch all fields

	useEffect(() => {}, [control]);
	return (
		<>
			{element.choices.map((choice, index) => {
				return (
					shouldRender(choice) && (
						<div key={index} className='flex items-center gap-4'>
							<input
								type='radio'
								className='pt-0 mt-0'
								value={choice.value}
								{...register(element.name, { ...element.validation })}
							/>
							<span>{choice.title}</span>
						</div>
					)
				);
			})}
		</>
	);
};
