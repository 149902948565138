import React from 'react';

export const FooterBlock = React.memo(() => {
	return (
		<div className='blue-color text-white mt-2'>
			<div className='w-full text-center p-2'>
				<h3 className='font-bold'>MAPIM IMMO</h3>
				<h6>SOLUTION IMMOBILIÈRE</h6>
			</div>
			<div className='w-full p-2'>
				<ul className='flex justify-between'>
					<li>NOUS CONTACTER</li>
					<li>-</li>
					<li>TUTORIELS</li>
					<li>-</li>
					<li>SUIVEZ-NOUS</li>
				</ul>
			</div>
		</div>
	);
});
