import { useState } from 'react';
import { dateFormater } from '../../../helpers/formatter';
import style from './contactDropdown.module.css';
import { ALREADY_USED_CRITERIA, ATTRIBUTES } from '../../../helpers/attributes';
import { useNavigate } from 'react-router-dom';

function BienDropdown({ bien }) {
	const [detail, setDetail] = useState(false);

	const navigate = useNavigate();
	const ShowDetails = () => {
		setDetail((prev) => !prev);
	};

	let rapprochmentOptions = JSON.parse(bien.options);
	let options = Object.keys(rapprochmentOptions).filter(
		(item) => !ALREADY_USED_CRITERIA.includes(item)
	);
	options = Object.entries(rapprochmentOptions).filter(
		(item) => options.includes(item[0]) && item[1]
	);

	options = options.map(
		(item) => ATTRIBUTES.filter((elem) => elem.attribut === item[0])[0]?.label
	);

	return (
		<>
			<div className='grid grid-cols-10 gap-2 items-center rounded border-2 border-r-0 border-solid border-[#2d5c6a2f]'>
				<div className='py-4 col-span-4'>
					<div className='flex items-center gap-5 pl-5'>
						<i
							className={`fa-solid fa-fire fa-2xl ${
								bien.value >= 85
									? 'text-red-700'
									: bien.value < 85 && bien.value > 81
									? 'text-orange-500'
									: 'text-yellow-500'
							}`}></i>
						<h1
							className='uppercase text-center cursor-pointer'
							onClick={() =>
								navigate('/mes-biens/details-bien/' + bien.bien.uuid)
							}>
							{bien.bien.advertisement.title}
						</h1>
					</div>
				</div>
				<div className='py-4 col-span-2'>{dateFormater(bien.created_at)}</div>
				<div className='py-4 col-span-2'>{bien.bien.mandate.num_mandat}</div>
				<div className='col-span-2 flex justify-between'>
					<p className='py-4'>{bien.value}%</p>
					<div className='rounded blue-color '>
						<button
							className='py-4 px-2 w-7 text-white text-xl'
							onClick={ShowDetails}>
							{detail === true ? '-' : '+'}
						</button>
					</div>
				</div>
			</div>
			{detail === true && (
				<div className='mb-2 px-5 pb-2 rounded border-2 border-solid border-[#2d5c6a2f]'>
					<h2 className='py-2 font-semibold'>L'ACQUEREUR :</h2>

					<table className='table-fixed'>
						<tbody>
							<tr>
								<td className={`${style.title}`}> PAYS :</td>
								<td className={`${style.content}`}>{bien.city}</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> TYPE DE BIEN :</td>
								<td className={`${style.content}`}>
									{' '}
									{bien.bien.type_estate.designation}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> SECTEUR :</td>
								<td className={`${style.content}`}>
									{bien.bien.sector.property_address}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> SURFACE HABITABLE:</td>
								<td className={`${style.content}`}>
									{' '}
									{bien.bien.living_area} m2
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> NOMBRES DES PIECES :</td>
								<td className={`${style.content}`}>{bien.bien.number_room}</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> NOMBRES DE CHAMBRES :</td>
								<td className={`${style.content}`}>
									{bien.bien.number_bedroom}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}>
									{' '}
									{bien?.type_offert_id === 1 ? 'PRIX' : 'LOYER'} :
								</td>
								<td className={`${style.content}`}>
									{bien?.type_offert_id === 1
										? `${bien?.selling_price}`
										: `${bien?.rent}`}
								</td>
							</tr>
							<tr>
								<td className={`${style.title}`}> AUTRES :</td>
							</tr>
							<tr>
								<td></td>
								<td className={`${style.content} inline-flex gap-1 flex-wrap`}>
									{options.map((item, index) => (
										<small
											className={`${style.option} flex items-center gap-2 justify-center`}>
											{' '}
											<i className='fa fa-check-circle'></i> {item}
										</small>
									))}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</>
	);
}

export default BienDropdown;
