import { requiredField } from '../../../validation/validation';

export const InfosComplementaire = [
	{
		type: 'selectInput',
		title: 'Adresse :',
		className: 'w-1/2 readonly',
		validation: {
			validate: {
				requiredField,
			},
		},
		name: 'adress_contact',
		visible: [
			{
				field: 'target',
				value: ['single person', 'corporation'],
			},
		],
		target: [
			{ country: 'country_contact', title: 'Pays' },
			{ city: 'city_contact', title: 'Ville' },
			{ zip: 'zip_contact', title: 'Code postal' },
			// { adress: 'adress_contact', title: 'Addresse exacte' },
		],
	},
	{
		type: 'textarea',
		title: 'Note :',
		className: 'w-1/2',
		// validation: {
		// 	validate: {
		// 		requiredField,
		// 	},
		// },
		name: 'note',
		visible: [
			{
				field: 'target',
				value: ['single person', 'corporation'],
			},
		],
	},

	///COUPLE
	{
		title: "L'un(e) ",
		className: 'w-full font-bold',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},

	{
		type: 'selectInput',
		title: 'Adresse :',
		className: 'w-1/2',
		validation: {
			validate: {
				equalTo: (value, values) => {
					const required = values.target === 'couple' ? true : false;
					return value === '' || required === false
						? "L'adresse est requis"
						: true;
				},
			},
		},
		name: 'manAdress',
		tagName: 'man_info_compl.adress',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		target: [
			{
				country: 'manCountry',
				title: 'Pays',
				tagName: 'man_info_compl.country',
			},
			{ zip: 'manZip', title: 'Code postal', tagName: 'man_info_compl.zip' },
			{ city: 'manCity', title: 'Ville', tagName: 'man_info_compl.city' },
		],
	},
	{
		type: 'text',
		title: 'Note :',
		className: 'w-1/2',
		tagName: 'man_info_compl.note',
		name: 'manNote',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},

	{
		title: "L'autre ",
		className: 'w-full font-bold',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},

	{
		type: 'selectInput',
		tagName: 'woman_info_compl.adress',
		title: 'Adresse :',
		className: 'w-1/2',
		validation: {
			validate: {
				requiredField,
			},
		},
		name: 'womanAdress',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		target: [
			{
				country: 'womanCountry',
				title: 'Pays',
				tagName: 'woman_info_compl.country',
			},
			{
				zip: 'womanZip',
				title: 'Code postal',
				tagName: 'woman_info_compl.zip',
			},
			{ city: 'womanCity', title: 'Ville', tagName: 'woman_info_compl.city' },
		],
	},
	{
		type: 'text',
		title: 'Note :',
		className: 'w-1/2',
		name: 'womanNote',
		tagName: 'woman_info_compl.note',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},
];
