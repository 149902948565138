import ReactSlider from 'react-slider';
import { useState } from 'react';
import './range.css';

export const RangeInput = ({ min, max, handleChange, textForShow }) => {
	const [minValue, setMinValue] = useState(min);
	const [maxValue, setMaxValue] = useState(max);
	const handleValueChange = (result) => {
		setMinValue(result[0]);
		setMaxValue(result[1]);
		handleChange(result);
	};
	return (
		<>
			<div className='relative h-12'>
				<ReactSlider
					className='horizontal-slider cursor-pointer'
					thumbClassName='grey-color border-2 border-solid border-black font-bold text-[13px] rounded-full p-4 w-2 h-2 flex justify-center items-center'
					onAfterChange={handleValueChange}
					ariaLabel={['Lower thumb', 'Upper thumb']}
					ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
					defaultValue={[min, max]}
					pearling
					max={max}
					min={min}
					minDistance={1}
					renderTrack={(props, state) => (
						<div
							{...props}
							className='blue-color h-[0.5px] absolute top-[15px]'></div>
					)}
					renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
				/>
			</div>
			{textForShow && (
				<div className='relative'>
					De {minValue} à {maxValue} {maxValue === max && textForShow} et plus
				</div>
			)}
		</>
	);
};
