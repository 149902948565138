import { imageAgent } from '../../../helpers/image.source.';
import { getStorageUser } from '../../../service/localStorage';

export const UserCard = ({ agent, agents, updateAgent, removeAgent }) => {
	const userConnected = getStorageUser().user;
	return (
		<div className='shadow-lg w-full border-[1px] border-solid rounded-t-xl sm:w-1/3 mx-2'>
			<div className='rounded-xl flex flex-wrap relative h-full'>
				<img
					src={
						agent.photo_url
							? imageAgent(`/${agent.agency_id}/${agent.photo_url}`)
							: '/images/agents/8380015.jpg'
					}
					alt=''
					className='rounded-tl-xl w-full lg:w-1/4 object-cover'
				/>
				<div className='p-4 border-[1px] border-solid sm:w-3/4 w-full break-words'>
					<div>
						<u className='font-medium'>Nom</u> : {agent.name}
					</div>
					<div>
						<u className='font-medium'>Email</u> : {agent.email}
					</div>
					<div>
						<u className='font-medium'>Télephone</u> : {agent.phone}
					</div>
					<div>
						<u className='font-medium'>Rôle</u> :{' '}
						{agent.role === '777' ? 'ADMIN' : 'AGENT'}
					</div>
					<div>
						<u className='font-medium'>Bien(s)</u> : {agent.biens.length}
					</div>
					<div>
						<u className='font-medium'>Contact(s)</u> : {agent.contacts.length}
					</div>
					<div>
						<u className='font-medium'>Codes postaux</u> :<br />
						{(agent.role === '777' &&
							agents.configurations
								.map((item, index) => item.code_postal)
								.join(' - ')) ||
							agent.configurations.map((item, index) => {
								return (
									<div key={index} className='mx-2'>
										{item.code_postal} -
									</div>
								);
							})}
					</div>
				</div>
				{userConnected.role === '777' && (
					<div className='absolute top-0 right-0 p-1 border-solid border-[1px] border-black rounded-md bg-white'>
						<i
							className='fa fa-eye text-blue-500 mr-3 cursor-pointer'
							title='Voir cet agent'></i>
						<i
							className='fa fa-pencil text-blue-500 mr-3 cursor-pointer'
							onClick={() => updateAgent(agent)}
							title='Modifier cet agent'></i>
						<i
							className='fa fa-trash text-orange-500 cursor-pointer'
							title='Supprimer cet agent'
							onClick={() => removeAgent(agent)}></i>
					</div>
				)}
			</div>
		</div>
	);
};
