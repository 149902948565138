import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchService } from '../../service/search/search.service';
import style from './search.module.css';
import { SearchContactResult } from '../../components/generals/contact/section/SearchContactResult';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { SearchEstateResult } from '../../components/generals/biens/section/SearchEstateResult';

export const ResearchPage = () => {
	const [useLoading, setUseLoading] = useState(false);
	const { search } = useLocation();
	const query = search.split('=')[1];
	const [results, setResults] = useState({});
	useEffect(() => {
		setUseLoading(true);
		SearchService.searchData(query)
			.then((response) => setResults(response.data.results))
			.finally(() => setUseLoading(false));
	}, [search]);

	return (
		<div className={style.container__search__page}>
			<h1 className={`${style.title__search}`}>
				Resultat de recherche pour : <b>"{query}"</b>
			</h1>
			{results.contacts && <SearchContactResult contacts={results.contacts} />}
			{results.estates && <SearchEstateResult estates={results.estates} />}
			{useLoading && <GeneralSpinner />}
		</div>
	);
};
