import { useBien } from "../../../../service/bien/useBien";
import { successToast, technicalErrorToast } from "../../../../service/toast/showToast";



function StatusSection({ location, bien }) {
	const updateStatusPropety = async (bien, status) => {
		let solds = status === 'archive' || status === 'sold' ? status : null;
		let published =
			status === 'inactive' || status === 'active' ? status : null;
		let data = {};

		if (status === 'archive') {
			data = {
				published: 'inactive',
				solds: solds,
			};
		} else if (published !== null) {
			data = {
				published: published,
				solds: solds,
			};
		} else {
			data = {
				solds: solds,
			};
		}
		console.log(data);
		await useBien
			.updateStatus(bien.id_bien, data)
			.then((response) => {
				console.log(response);
				setTimeout(
					() => (window.location.href = `/mes-biens/${location.state}`),
					200
				);
				successToast(response.data.message);
			})
			.catch(() => {
				technicalErrorToast();
			});
	};
	return (
		<div className='px-5 py-1 blue-color items-center flex justify-between'>
			<p className='text-white'>
				<span className='uppercase'>
					{' '}
					{bien.type_estate.designation}{' '}
				</span>{' '}
				-
				<span className='uppercase'>{bien.type_offert.designation} </span>{' '}
				- Commune
			</p>

			{location.state && location.state === 'inactive' && (
				<div className='flex gap-1'>
					<button
						className={`px-5 rounded cursor-pointer ${bien.published === 'inactive'
								? 'bg-green-600 text-white'
								: 'bg-white'
							}`}
						onClick={() => {
							updateStatusPropety(bien, 'active');
						}}>
						Actif
					</button>
					<button
						className={`px-5 rounded ${bien.published !== "inactive" ? 'bg-green-600 text-white' : 'bg-white'
							}`}
						disabled>
						Inactif
					</button>
				</div>
			)}
			{location.state && location.state === 'active' && (
				<div className='flex gap-1'>
					{
						bien.solds === '' ? (
							<>
								<button
									className={`px-5 rounded cursor-pointer ${bien.published === "active" ? 'bg-green-600 text-white' : 'bg-white'
										}`}
									onClick={() => {
										updateStatusPropety(bien, 'sold');
									}}>
									Vendus/Loués
								</button>

								<button
									className={`px-5 rounded  ${bien.published !== "active" ? 'bg-green-600 text-white' : 'bg-white'
										}`}
									disabled>
									Active
								</button>
							</>
						) : (
							<>
								<button
									className={`px-5 rounded cursor-pointer ${bien.solds === "sold" ? 'bg-green-600 text-white' : 'bg-white'
										}`}
									onClick={() => updateStatusPropety(bien, 'archive')}>
									Archivés
								</button>
								<button
									className={`px-5 rounded  ${bien.solds !== "sold" ? 'bg-green-600 text-white' : 'bg-white'
										}`}
									disabled>
									Vendus/Loués
								</button></>
						)
					}

				</div>
			)}


		</div>
	)

}



export default StatusSection;