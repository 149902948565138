import { useForm } from 'react-hook-form';
import { Modal } from '../../commons/modal/modal';
import { useSuiviDossier } from '../../../service/suivi/useSuiviDossier';
import {
	errorToast,
	successToast,
	technicalErrorToast,
} from '../../../service/toast/showToast';
import { useState } from 'react';
import { Spinner } from '../../commons/Spinner';
import { ButtonSpinner } from '../../commons/buttonSpinner';

export const CreationElement = ({
	folder,
	handleClose,
	showModal,
	updatedBien,
}) => {
	const [showLoading, setShowLoading] = useState(false);
	const {
		register,
		handleSubmit,
		unregister,
		formState: { errors },
	} = useForm();
	const submitElem = (data) => {
		data.folder_id = folder.id;
		data.active = true;
		setShowLoading(true);
		useSuiviDossier
			.createStep(data)
			.then(() => {
				successToast('Création du nouvel élement réussi');
				updatedBien();
				handleClose();
			})
			.catch(() => technicalErrorToast())
			.finally(() => setShowLoading(false));
	};

	return (
		<>
			<Modal
				width='w-11/12 sm:w-10/12 md:w-2/3 xl:w-1/3 '
				height='min-h-2/3 sm:min-h-1/2'
				isOpen={showModal}
				handleClose={handleClose}
				allowForceClose={true}
				modalTitle={'Nouvel élement'}
				allowBackdrop={false}>
				<div className='my-4'>
					<form onSubmit={handleSubmit(submitElem)}>
						<div className='flex flex-wrap'>
							<label htmlFor='element_name' className='w-full'>
								Titre de l'élement (*)
							</label>
							<input
								type='text'
								id='element_name'
								className='border-[1px] border-solid w-full my-2 outline-none p-2 aria-[invalid]:border-red-500'
								aria-invalid={errors.step_name}
								{...register('step_name', {
									required: {
										value: true,
										message: "Le titre de l'élement est requis",
									},
								})}
							/>
							<span className='text-red-500 w-full'>
								{errors.step_name && errors.step_name.message}
							</span>
						</div>
						<div className='flex flex-wrap'>
							<label htmlFor='element_name' className='w-full'>
								Délai (*)
							</label>
							<input
								type='number'
								id='element_name'
								min={0}
								className='border-[1px] border-solid w-full my-2 outline-none p-2 aria-[invalid]:border-red-500'
								aria-invalid={errors.step_duration}
								{...register('step_duration', {
									valueAsNumber: true,
									validate: (value) => {
										return value >= 0
											? true
											: 'Le délai doit être supérieur ou égal à 0';
									},
									required: {
										value: true,
										message: "Le délai de l'élement est requis",
									},
								})}
							/>
							<span className='text-red-500 w-full'>
								{errors.step_duration && errors.step_duration.message}
							</span>
						</div>

						<button
							type='submit'
							className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 p-4 mt-4 rounded flex items-center gap-2'>
							Enregistrer <ButtonSpinner showLoading={showLoading} />
						</button>
					</form>
				</div>
			</Modal>
		</>
	);
};
