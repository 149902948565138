import { requiredField } from '../../../validation/validation';

export const Availabilities =
[
    {
        //Dossier
        group: 'info_personnelle',
        className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
        title: 'Dossier :',
        visible: [
          { field: "type_offert_id", value: "1" },
          { field: "type_offert_id", value: "2" },
          { field: "type_offer_suite_id", value: "1" },
          { field: "type_offer_suite_id", value: "2" },
          { field: "type_offer_suite_id", value: "3" },
          { field: "type_estate_id", value: "1" },
          { field: "type_estate_id", value: "2" },
          { field: "type_estate_id", value: "3" },
          { field: "type_estate_id", value: "4" },
          ],
    },
    {
        //N°
        type: "text",
        name: "num_folder",
    		tagName:'availabilities.num_folder',
        className: "w-full sm:w-1/2 ",
        title: "N° :",
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		]
      },
      {
        //creer le
        type: "date",
        name: "created_folder",
    		tagName:'availabilities.created_folder',
        className: "w-full sm:w-1/2 ",
        title: "immediat :",
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Crée le :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},
	{
		//Modifié le
		type: 'date',
		name: 'updated_folder',
    tagName:'availabilities.updated_folder',
		className: 'w-full sm:w-1/2 ',
		title: 'Modifié le :',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},
	{
		className: 'sm:w-1/2',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},
	{
		//Disponibilité
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'Disponibilité :',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		]
      },
      {
        className : 'sm:w-1/2',
        visible: [
          { field: "type_offert_id", value: "1" },
          { field: "type_offert_id", value: "2" },
          { field: "type_offer_suite_id", value: "1" },
          { field: "type_offer_suite_id", value: "2" },
          { field: "type_offer_suite_id", value: "3" },
          { field: "type_estate_id", value: "1" },
          { field: "type_estate_id", value: "2" },
          { field: "type_estate_id", value: "3" },
          { field: "type_estate_id", value: "4" },
          ],
      },
      {
        //Disponibilité
        group: 'info_personnelle',
        className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
        title: 'Disponibilité :',
        visible: [
          { field: "type_offert_id", value: "1" },
          { field: "type_offert_id", value: "2" },
          { field: "type_offer_suite_id", value: "1" },
          { field: "type_offer_suite_id", value: "2" },
          { field: "type_offer_suite_id", value: "3" },
          { field: "type_estate_id", value: "1" },
          { field: "type_estate_id", value: "2" },
          { field: "type_estate_id", value: "3" },
          { field: "type_estate_id", value: "4" },
          ],
    },
    {
        //Immédiate
        type: "radio",
        name: "immediat",
        tagName:'availabilities.immediat',
        className: "w-full sm:w-1/2 ",
        title: "Imédiate :",
        choices: [
            {
              title: "Non précisé",
              value: "1",
            },
            {
              title: "Non",
              value: "2",
            },
            {
              title: "Oui",
              value: "3",
            },
          ],
		  validation: {
			validate: { requiredField },
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		]
      },
      {
        //Liberé le 
        type: "date",
        name: "release_it",
    		tagName:'availabilities.release_it',
        className: "w-full sm:w-1/2 ",
        title: "Liberé le  :",
        validation: {
          validate: { requiredField },
        },
        visible: [
          { field: 'type_offert_id', value: ['1', '2'] },
          { field: 'type_offer_suite_id', value: ['1', '2', '3'] },
          { field: 'type_estate_id', value: ['1', '2', '3', '4'] },
        ]
      },
      {
        //Disponible le 
        type: "date",
        name: "available_on",
    		tagName:'availabilities.available_on',
        className: "w-full sm:w-1/2 ",
        title: "Disponible le  :",
        visible: [
          { field: 'type_offert_id', value: ['1', '2'] },
          { field: 'type_offer_suite_id', value: ['1', '2', '3'] },
          { field: 'type_estate_id', value: ['1', '2', '3', '4'] },
        ]
      },
]