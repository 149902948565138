import axios from 'axios';
import { clearStorage, getStorageUser } from '../service/localStorage';
import { errorToast, technicalErrorToast } from '../service/toast/showToast';

const softwareToken = getStorageUser()?.token;

export const createHttp = () => {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_BACKEND_URL,
		headers: {
			Authorization: `Bearer ${softwareToken}`,
		},
	});
	// instance.interceptors.request.use((response) => {
	// 	console.log(response);
	// });

	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			const status = error.response.status;
			const errors = error.response.data.errors;
			const errorData = error.response.data.errors
				? Object.keys(error.response.data?.errors)[0]
				: error.response.data.message;
			switch (status) {
				case 400:
					errorToast(errors[errorData]);
					return Promise.reject(error);
				case 404:
					errorToast("L'action que vous essayer de faire n'est pas disponible");
					return Promise.reject(error);
				case 401:
					setTimeout(() => {
						clearStorage();
						if (
							!window.location.href.includes('/login') &&
							!window.location.href.includes('/register')
						) {
							errorToast(error.response.data.message);
							window.location.href = '/login';
						}
					}, 2500);
					return Promise.reject(error);
				case 403:
					errorToast(error.response.data.message);
					return Promise.reject(error);
				case 500:
					if (error.response.data.message)
						errorToast(error.response.data.message);
					return Promise.reject(error);
				case 422:
					errorToast(error.response.data.message);
					return Promise.reject(error);
				default:
					technicalErrorToast();
					return Promise.reject(error);
			}
		}
	);

	return instance;
};
