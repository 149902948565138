import { useEffect, useState } from 'react';
import { GeneralSpinner } from '../../../components/commons/general-spinner';
import { usePublication } from '../../../service/annonce/useAnnonce';
import { PortalCard } from '../../../components/generals/portals/PortalCard';
import { Breadcumbs } from '../../../components/generals/headers/breadcumbs';

export const Portals = () => {
	const [portals, setPortals] = useState();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		usePublication.retrievePortals().then((res) => {
			setPortals(res.data);
			setLoading(false);
		});
	}, []);
	return (
		<>
			<Breadcumbs title={'Portails actuels'} />
			{portals && (
				<div className='flex gap-x-3 gap-y-12 items-stretch justify-center sm:justify-between flex-wrap py-10'>
					{portals.map((portal, index) => (
						<PortalCard portal={portal} key={index} />
					))}
				</div>
			)}

			{loading && <GeneralSpinner />}
		</>
	);
};
