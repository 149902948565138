import { useState } from "react"
import { useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BienService from "../../service/bien/BienService";
import { BienSection } from "../../components/generals/biens/section/bien.section";
import StatusSection from "../../components/generals/biens/section/status.section";
import BienContactSection from "./details/BienContactSection";

export const BienContactPage = () => {
    const location = useLocation ();
    const [bien, setBien] = useState();
    const {bienId} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if(!bien) {
            BienService.getBienById(bienId)
            .then((response) => { setBien(response.data) })
            .catch(()=>{})
        }

    }, [bienId, location, bien])


    return (
       bien && (
        <>
        <div>
            <BienSection bien={bien} />
        </div>

        <div className="border border-2 border-[#2d5c6a]">
            <StatusSection location={location} bien={bien}/>

            <BienContactSection  bien={bien} navigate={navigate} />
        </div>
        </>
       )
    );
} 