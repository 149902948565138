import { useNavigate } from 'react-router-dom';
import { imageBien } from '../../../../helpers/image.source.';

function ImagesSection({ contact }) {
	const navigate = useNavigate();
	return (
		<>
			<div className='flex flex-row shadow-lg justify-center py-2 gap-5 overflow-x-hidden'>
				{contact.rapprochements &&
					contact.rapprochements.map((rapproche, index) => (
						<div key={index} className='relative'>
							<div className='group'>
								{rapproche.bien.photos &&
									rapproche.bien.photos.photos_couvert.map((image, key) => (
										<div
											onClick={() =>
												navigate(
													'/mes-biens/details-bien/' + rapproche.bien.uuid
												)
											}
											className='h-72 w-64 py-3 bg-gray-200 relative shrink-0 rounded cursor-pointer'
											key={key}>
											<img
												className=' w-96 object-contain rounded-t-xl h-64 transition duration-300 ease-in-out transform hover:scale-105'
												src={imageBien(
													encodeURI(`photos_couvert${image.photo}`)
												)}
												alt={image.description}
											/>
											<div className='absolute inset-0 items-center justify-center hidden group-hover:flex bg-gray-200 transition duration-300 ease-in-out'>
												<h1 className='font-semibold text-black text-lg'>
													{rapproche.value} %
												</h1>
											</div>
										</div>
									))}
							</div>
						</div>
					))}
			</div>
		</>
	);
}

export default ImagesSection;
