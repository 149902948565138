import { createHttp } from '../../http';
import { clearStorage, getStorageUser } from '../localStorage';

const http = createHttp();

export const PigeService = {
	getPiges: async (page, params) => {
		const { user } = getStorageUser();
		if (!user.agency_id) {
			clearStorage();
			window.location.href = '/auth/login';
		}
		const url = page
			? '/piges?page=' + page + '&' + params
			: '/piges?' + params;
		return await http.get(url).catch(() => {});
	},

	getPigeById: async (pigeId) => http.get('/piges/details/' + pigeId),
};
