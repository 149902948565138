export const Pagination = ({ link, changePage }) => {
	return (
		<div
			onClick={() => (!link.active ? changePage(link.label) : '')}
			className={`${
				!link.active ? 'blue-color text-white' : 'text-black grey-color'
			} p-4 rounded text-center cursor-pointer`}>
			{link.label}
		</div>
	);
};
