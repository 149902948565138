import { Breadcumbs } from './../../components/generals/headers/breadcumbs';
import './estimation.css';
import { SectionPige } from '../../components/generals/pige/section';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { EstimationService } from '../../service/estimation';
import { successToast } from '../../service/toast/showToast';
import { LocalisationService } from '../../service/models/localisation';
export const SubmitEstimationPage = () => {
	const [showLoading, setShowLoading] = useState(false);
	const [showAddress, setShowAddress] = useState(false);
	const [address, setAddress] = useState([]);
	const cpRef = useRef(null);
	const villeRef = useRef(null);
	const addressRef = useRef(null);

	const {
		register,
		handleSubmit,
		unregister,
		formState: { errors },
	} = useForm();

	const searchAddress = (query) => {
		LocalisationService.searchLocalisation(query)
			.then(({ data }) => setAddress(data))
			.catch(() => {});
	};

	const submitEstimationHandler = (data) => {
		setShowLoading(true);
		data.address_bien = addressRef.current.value;
		data.cp_bien = cpRef.current.value;
		data.ville_bien = villeRef.current.value;
		data.details_bien = JSON.stringify({
			surface_habitable: data.surface_habitable,
			nb_pieces: data.nb_pieces,
			nb_chambre: data.nb_chambre,
			nb_salle_bain: data.nb_salle_bain,
			nb_salle_eau: data.nb_salle_eau,
		});

		EstimationService.createEstimation(data)
			.then(() => successToast("Enregistrement de l'estimation avec succès"))
			.finally(() => setShowLoading(false));
	};
	const affectAdress = (item) => {
		cpRef.current.value = item.properties.postcode;
		addressRef.current.value = item.properties.label;
		villeRef.current.value = item.properties.city;
	};

	return (
		<>
			<Breadcumbs title={'Ajouter une nouvelle estimation'} />
			<div className='container-view'>
				<div className='w-full shadow-2xl rounded-xl'>
					<SectionPige title={"Ajout d'une nouvelle estimation"}>
						<div className='px-4 py-2'>
							<form
								className='w-full'
								onSubmit={handleSubmit(submitEstimationHandler)}>
								<h1 className='text-xl blue-color text-white w-full text-center py-4 rounded-md my-2'>
									Type du bien
								</h1>
								<div className='flex gap-x-8 items-center w-full justify-center flex-wrap'>
									<div className='flex relative justify-center cursor-pointer w-full sm:w-1/4'>
										<input
											type='radio'
											{...register('bien', {
												required: {
													value: true,
													message: 'Le type du bien est requis',
												},
											})}
											value={'maison'}
										/>
										<div className='p-2 text-xl'>
											<i className='fa-solid fa-house'></i> <span>Maison</span>
										</div>
									</div>

									<div className='flex relative justify-center cursor-pointer  w-full sm:w-1/4'>
										<input
											type='radio'
											{...register('bien', {
												required: {
													value: true,
													message: 'Le type du bien est requis',
												},
											})}
											value={'appartement'}
										/>
										<div className='p-2 text-xl'>
											<i className='fa-solid fa-building'></i>{' '}
											<span>Appartement</span>
										</div>
									</div>
									<div className='flex relative justify-center cursor-pointer  w-full sm:w-1/4'>
										<input
											type='radio'
											{...register('bien', {
												required: {
													value: true,
													message: 'Le type du bien est requis',
												},
											})}
											value={'local'}
										/>
										<div className='p-2 text-xl'>
											<i className='fa-solid fa-shop'></i> <span>Local</span>
										</div>
									</div>
								</div>
								{errors.bien && (
									<span className='text-red-600 text-center block my-4'>
										{errors.bien.message}
									</span>
								)}

								<h2 className='text-xl blue-color text-white w-full text-center py-4 rounded-md my-2'>
									Information du demandeur
								</h2>
								<div className='w-full'>
									<div className='item-container'>
										<label
											htmlFor='demandeur_firstname'
											className='w-full sm:w-1/4'>
											Nom (*) :
										</label>
										<input
											type='text'
											id='demandeur_firstname'
											className='input'
											{...register('demandeur_firstname', {
												required: {
													value: true,
													message: 'Le nom du demandeur est requis',
												},
											})}
										/>
										{errors.demandeur_firstname && (
											<span className='error-message'>
												{errors.demandeur_firstname.message}
											</span>
										)}
									</div>
									<div className='item-container'>
										<label
											htmlFor='demandeur_lastname'
											className='w-full sm:w-1/4'>
											Prénoms (*) :
										</label>
										<input
											type='text'
											id='demandeur_lastname'
											className='input'
											{...register('demandeur_lastname', {
												required: {
													value: true,
													message: 'Le(s) prénom(s) du demandeur est requis',
												},
											})}
										/>
										{errors.demandeur_lastname && (
											<span className='error-message'>
												{errors.demandeur_lastname.message}
											</span>
										)}
									</div>
									<div className='item-container'>
										<label
											htmlFor='demandeur_email'
											className='w-full sm:w-1/4'>
											Adresse mail (*) :
										</label>
										<input
											type='text'
											name=''
											id='demandeur_email'
											className='input'
											{...register('demandeur_email', {
												validate: {
													validateEmail: (value) => {
														if (!value) {
															return "L'email du demandeur est requis";
														}
														if (
															!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
																value
															)
														) {
															return "L'adresse email est invalide";
														}
														return true;
													},
												},
											})}
										/>
										{errors.demandeur_email && (
											<span className='error-message'>
												{errors.demandeur_email.message}
											</span>
										)}
									</div>
								</div>

								<h3 className='text-xl blue-color text-white w-full text-center py-4 rounded-md my-2'>
									Information sur le bien
								</h3>

								<div className='w-full'>
									<div className='item-container'>
										<label htmlFor='adresse_bien' className='w-full sm:w-1/4'>
											Adresse du bien (*) :
										</label>
										<input
											type='text'
											{...register('adresse_bien', {
												onChange: (e) => {
													if (e.target.value.length > 3) {
														searchAddress(e.target.value);
														setShowAddress(true);
													} else setShowAddress(false);
												},
											})}
											ref={addressRef}
											id='adresse_bien'
											className='input'
										/>

										{showAddress && (
											<>
												<div className='w-full sm:w-2/6'></div>
												<div className='w-full sm:w-3/6 z-20 bg-black/10'>
													<ul>
														{address.map((item, index) => {
															return (
																<li
																	onClick={() => {
																		setShowAddress(false);
																		affectAdress(item);
																	}}
																	className='blue-color text-white my-2 text-center rounded-sm mx-1 py-2 cursor-pointer hover:bg-black/40 font-bold'
																	key={index}>
																	{item.properties.label}
																</li>
															);
														})}
													</ul>
												</div>
											</>
										)}

										{errors.adresse_bien && (
											<span className='error-message'>
												{errors.adresse_bien.message}
											</span>
										)}
									</div>

									<div className='item-container'>
										<label htmlFor='cp_bien' className='w-full sm:w-1/4'>
											Code postal (*) :
										</label>
										<input
											readOnly
											type='text'
											{...register('cp_bien')}
											ref={cpRef}
											id='cp_bien'
											className='input read-only:bg-black/10'
										/>
									</div>

									<div className='item-container'>
										<label htmlFor='ville_bien' className='w-full sm:w-1/4'>
											Ville (*) :
										</label>
										<input
											type='text'
											readOnly
											{...register('ville_bien')}
											ref={villeRef}
											id='ville_bien'
											className='input read-only:bg-black/10'
										/>
									</div>

									<div className='item-container'>
										<label
											htmlFor='surface_habitable'
											className='w-full sm:w-1/4'>
											Surface habitable en m<sup>2</sup> (*) :
										</label>
										<input
											type='number'
											min={0}
											id='surface_habitable'
											{...register('surface_habitable', {
												required: {
													value: true,
													message: 'La surface habitable est requise',
												},
											})}
											className='input'
										/>

										{errors.surface_habitable && (
											<span className='error-message'>
												{errors.surface_habitable.message}
											</span>
										)}
									</div>
									<div className='item-container'>
										<label htmlFor='nb_pieces' className='w-full sm:w-1/4'>
											Nombre de pièces (*) :
										</label>
										<input
											type='number'
											id='nb_pieces'
											min={0}
											className='input'
											{...register('nb_pieces', {
												required: {
													value: true,
													message: 'Le nombre de pièces est requis',
												},
											})}
										/>
										{errors.nb_pieces && (
											<span className='error-message'>
												{errors.nb_pieces.message}
											</span>
										)}
									</div>
									<div className='item-container'>
										<label htmlFor='nb_chambre' className='w-full sm:w-1/4'>
											Nombre de chambres (*) :
										</label>
										<input
											type='number'
											min={0}
											id='nb_chambre'
											className='input'
											{...register('nb_chambre', {
												required: {
													value: true,
													message: 'Le nombre de chambres est requis',
												},
											})}
										/>
										{errors.nb_chambre && (
											<span className='error-message'>
												{errors.nb_chambre.message}
											</span>
										)}
									</div>
									<div className='item-container'>
										<label htmlFor='nb_salle_bain' className='w-full sm:w-1/4'>
											Nombre de salle de bain (*) :
										</label>
										<input
											type='number'
											id='nb_salle_bain'
											className='input'
											min={0}
											{...register('nb_salle_bain', {
												required: {
													value: true,
													message: 'Le nombre de salle de bain est requis',
												},
											})}
										/>
										{errors.nb_salle_bain && (
											<span className='error-message'>
												{errors.nb_salle_bain.message}
											</span>
										)}
									</div>
									<div className='item-container'>
										<label htmlFor='nb_salle_eau' className='w-full sm:w-1/4'>
											Nombre de salle d'eau (*) :
										</label>
										<input
											type='number'
											id='nb_salle_eau'
											className='input'
											{...register('nb_salle_eau', {
												required: {
													value: true,
													message: "Le nombre de salle d'eau est requis",
												},
											})}
										/>
										{errors.nb_salle_eau && (
											<span className='error-message'>
												{errors.nb_salle_eau.message}
											</span>
										)}
									</div>
								</div>
								<div className='w-full text-center'>
									<span className='w-full block my-4 sm:text-center'>
										Les champs marqués par * sont requis
									</span>
									<button
										type='submit'
										className='mx-auto flex gap-4 bg-[#459032] transition-all hover:bg-[#458587] px-6 py-2  rounded-md text-white uppercase font-bold'>
										Soumettre <ButtonSpinner showLoading={showLoading} />
									</button>
								</div>
							</form>
						</div>
					</SectionPige>
				</div>
			</div>
		</>
	);
};
