import { requiredField } from '../../../validation/validation';
const validateVisible = (field, visible) => {
  const array = [];
  if(field.visible.length >= 1){
     for (let index = 0; index < field.visible.length; index++) {
         const element = document.querySelector(`input[name=${field.visible[index].field}]:checked`);
         const found = visible.includes(element.value);
          array.push(found);
     }
     return array;
  }
};



export  const PlusCriteriaTerrain = [

    {
        //Emprise  sol
        group: "terrain",
        className: "w-full sm:w-1/2 ",
        type: "text",

        title: "Emprise au sol :",
        name: "ground_surface",
        tagName: 'preferency.ground_surface',
        visible: [
        { field: "type_offert_id", value: ['1', '2', '3' ]},
          { field: "type_estate_id", value: ['1', '3', ]},
          
        ],
      },
      {
        //Piscinable
        group: "info_personnelle",
        className: "w-full sm:w-1/2 ",
        type: "radio",
       
        title: "Piscinable :",
        name: "poolable",
        tagName: 'preferency.poolable',
        choices: [
          {
            title: "Non précisé",
            value: "1",
          },
          {
            title: "Non",
            value: "2",
          },
          {
            title: "Oui",
            value: "3",
          },
        ],
        visible: [
          { field: "type_offert_id", value: ['1', '2', '3' ]},
          { field: "type_estate_id", value: ['1', '3', ]},
          
          
        ],
      },
      {
        //Arboré
        group: "info_personnelle",
        className: "w-full sm:w-1/2 ",
        type: "radio",
      
        title: "Arboré :",
        name: "wooded",
        tagName: 'preferency.wooded',
        choices: [
          {
            title: "Non précisé",
            value: "1",
          },
          {
            title: "Non",
            value: "2",
          },
          {
            title: "Oui",
            value: "3",
          },
        ],
        visible: [
        { field: "type_offert_id", value: ['1', '2', '3' ]},
          { field: "type_estate_id", value: ['1', '3', ]},
        ],
      },
      {
        //Divisible
        group: "info_personnelle",
        className: "w-full sm:w-1/2 ",
        type: "radio",
        tagName: 'preferency.divisible',
        title: "Divisible :",
        name: "divisible",
        choices: [
          {
            title: "Non précisé",
            value: "1",
          },
          {
            title: "Non",
            value: "2",
          },
          {
            title: "Oui",
            value: "3",
          },
        ],
        visible: [
        { field: "type_offert_id", value: ['1', '2', '3' ]},
          { field: "type_estate_id", value: ['1', '3', ]},
          
          
        ],
      },

      {
        //Raccordement
        group: 'info_personnelle',
        className: 'w-full  !justify-start sm:pl-4 underline',
        title: 'Raccordement :',
        visible: [
          { field: 'type_offert_id', value: ['1', '2', '3'] }, 
        {field: 'type_estate_id' , value: ['3']}
       ],
       
      },
      {
        //Eau
        type: 'radio',
        name: 'water',
        tagName: 'preferency.water',
        className: 'w-full sm:w-1/2 ',
        title: 'Eau :',
        choices: [
          {
            title: 'Non précisé',
            value: '1',
          },
          {
            title: 'Non',
            value: '2',
          },
          {
            title: 'Oui',
            value: '3',
          },
        ],
        visible: [
          { field: 'type_offert_id', value: ['1', '2', '3'] }, 
        {field: 'type_estate_id' , value: ['3']}
       ],
      },
      {
        //gaz
        type: 'radio',
        name: 'gaz',
        tagName: 'preferency.gaz',
        className: 'w-full sm:w-1/2 ',
        title: 'Gaz:',
        choices: [
          {
            title: 'Non précisé',
            value: '1',
          },
          {
            title: 'Non',
            value: '2',
          },
          {
            title: 'Oui',
            value: '3',
          },
        ],
        visible: [
          { field: 'type_offert_id', value: ['1', '2', '3'] }, 
        {field: 'type_estate_id' , value: ['3']}
       ],
      },
      {
        //Elec
        type: 'radio',
        name: 'elec',
        tagName: 'preferency.elec',
        className: 'w-full sm:w-1/2 ',
        title: 'Elec :',
        choices: [
          {
            title: 'Non précisé',
            value: '1',
          },
          {
            title: 'Non',
            value: '2',
          },
          {
            title: 'Oui',
            value: '3',
          },
        ],
        visible: [
          { field: 'type_offert_id', value: ['1', '2', '3'] }, 
        {field: 'type_estate_id' , value: ['3']}
       ],
      },
      {
        //tel
        type: 'radio',
        name: 'tel',
        tagName: 'preferency.tel',
        className: 'w-full sm:w-1/2 ',
        title: 'tel :',
        choices: [
          {
            title: 'Non précisé',
            value: '1',
          },
          {
            title: 'Non',
            value: '2',
          },
          {
            title: 'Oui',
            value: '3',
          },
        ],
        visible: [
          { field: 'type_offert_id', value: ['1', '2', '3'] }, 
        {field: 'type_estate_id' , value: ['3']}
       ],
      },
]