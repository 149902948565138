import { createHttp } from '../../http/index';
import { getStorageUser } from '../localStorage';

const http = createHttp();

export const LocalisationService = {
	searchLocalisation: async (query) => {
		return http.get('/match-address?query=' + query);
	},
	searchCity: async (query) => {
		return http.get('/match-address-city?query=' + query.replace(' ', '_'));
	},
};

export const LocalisationGlobalService = {
	searchLocallisationGlobal: async (name) => {
		return http.get('/match-address-country-global?name=' + name);
	},
};
