import { useAgent } from '../../../../service/agency/useAgent';
import { requiredField, greaterThanZero } from '../../../validation/validation';
import { CRITERE, CRITERE_OFFRE, TYPE_BIEN, TYPE_OFFRE } from '../../type';
import { CritaireBien } from '../CritaireBien';

const agentOptions = async () => {
	console.log(15);
	try {
		const isAuthUrl = [
			'register',
			'login',
			'reset-password',
			'reset-password-pass',
			'two-factor',
		].includes(
			window.location.href.split('/')[
				window.location.href.split('/').length - 1
			]
		);

		if (!isAuthUrl) {
			const response = await useAgent.getAllAgents();
			const agents = response.data.user;
			return agents.map((agent) => ({
				title: agent.name,
				value: agent.id,
			}));
		}

		return [];
	} catch (error) {
		console.log(error);
	}
};

export const Bien = [
	{
		//Agent
		group: 'info_personnelle',
		type: 'select',
		name: 'user_id',
		tagName: 'agent_id',
		is_admin_only: true,
		className: 'w-full ',
		title: 'Agent ',
		onInit: async (element) => {
			element.choices = [...(await agentOptions())];
			return element;
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	CritaireBien,
	{
		//Prix
		group: 'info_personnelle',
		className: 'w-full sm:w-2/12 md:mt-4  ',
		title: 'Prix:',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//HONORAIRES:
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'Honoraires: ',
		name: 'honoraire',
		type: 'radio',
		tagName: 'info_financiere.honoraire',
		choices: [
			{
				title: 'À la charge du vendeur',
				value: 'À la charge du vendeur',
				cible: 'vendeur',
			},
			{
				title: "À la charge de l'aquéreur",
				value: "À la charge de l'aquéreur",
				cible: 'aquéreur',
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: [TYPE_OFFRE.VENTE] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},

	{
		type: 'pricing',

		elements: [
			{
				//Prix Public
				group: 'info_personnelle',
				className: 'w-full sm:w-5/12 ',
				type: 'number',
				title: 'Prix Public :',
				order: '1',
				validation: {
					validate: { requiredField },
				},
				cible: 'publish_price',
				name: 'publish_price',
				tagName: 'publish_price',
				visible: [
					{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
					{
						condition: 'EQUAL',
						field: 'type_offer_suite_id',
						value: ['1', '2'],
					},
					{
						condition: 'EQUAL',
						field: 'type_estate_id',
						value: ['1', '2', '3', '4'],
					},
				],
			},
			{
				//Prix Vendeur
				group: 'info_personnelle',
				className: 'w-full sm:w-5/12',
				type: 'number',
				title: 'Prix vendeur :',
				order: '2',
				validation: {
					validate: { requiredField },
				},
				cible: 'selling_price',
				name: 'selling_price',
				tagName: 'selling_price',
				visible: [
					{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
					{
						condition: 'EQUAL',
						field: 'type_offer_suite_id',
						value: ['1', '2'],
					},
					{
						condition: 'EQUAL',
						field: 'type_estate_id',
						value: ['1', '2', '3', '4'],
					},
				],
			},
			{
				//Montant
				type: 'number',
				name: 'rising',
				order: '3',
				validation: {
					validate: {
						requiredField,
					},
				},
				className: 'w-full sm:w-1/2 ',
				title: 'Montant:',
				cible: 'risingSelling',
				tagName: 'info_financiere.info_tenant_chare.rising',
				visible: [
					{ field: 'type_offert_id', value: [TYPE_OFFRE.LOCATION] },
					{ field: 'honoraire', value: ['À la charge du vendeur'] },
					{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
					{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
				],
			},
			{
				//%
				type: 'text',
				order: '4',
				name: 'percent',
				className: 'w-full sm:w-1/2 ',
				tagName: 'info_financiere.info_tenant_chare.percent',
				title: 'Pourcentage (%):',
				visible: [
					{ field: 'type_offert_id', value: [TYPE_OFFRE.LOCATION] },
					{ field: 'honoraire', value: ['À la charge du vendeur'] },
					{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
					{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
				],
				validation: {
					validate: {
						requiredField,
					},
				},
			},
		],
		visible: [
			{
				field: 'honoraire',
				value: ['À la charge du vendeur', "À la charge de l'aquéreur"],
			},
			{
				field: TYPE_OFFRE.formName,
				value: [TYPE_OFFRE.VENTE],
			},
		],
	},
	{
		//MONTANT M2:
		type: 'number',
		name: 'risingM2',
		tagName: 'info_financiere.info_tenant_chare.risingM2',
		className: 'w-full sm:w-1/2 ',
		title: 'Montant (m2):',
		visible: [
			{ field: 'type_offert_id', value: [TYPE_OFFRE.LOCATION] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		//DONT ÉTAT DES LIEUX:
		type: 'number',
		name: 'includingStatusOfPremises',
		tagName: 'info_financiere.info_tenant_chare.includingStatusOfPremises',
		className: 'w-full sm:w-1/2 ',
		title: 'État des lieux:',
		visible: [
			{ field: 'type_offert_id', value: [TYPE_OFFRE.LOCATION] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		//Etat des lieux M2
		type: 'number',
		name: 'inventoryM2',
		tagName: 'info_financiere.info_tenant_chare.inventoryM2',
		className: 'w-full sm:w-1/2 ',
		title: 'Etat des lieux M2:',
		visible: [
			{ field: 'type_offert_id', value: [TYPE_OFFRE.LOCATION] },
			{ field: 'type_offer_suite_id', value: ['10'], condition: 'INEQUAL' },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Loyer
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		title: 'Loyer :',
		name: 'rent',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['1', '3'] },
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//dureé bail
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Durrée bail ( Nombre de semaine ):',
		name: 'duration_lease',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: '1' },
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//Surface habitable
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface habitable (m2) ',
		name: 'living_area',
		error: 'la surface habitable est requise',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
			},
		],
	},
	{
		//Nombre de pièces
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Nombre de pièces :',
		validation: {
			validate: { requiredField },
		},
		name: 'number_room',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{ condition: 'INEQUAL', field: 'criteria', value: ['studio'] },
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
			},
		],
	},
	{
		//Nombre de chambres
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Nombre de chambres :',
		name: 'number_bedroom',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{ condition: 'INEQUAL', field: 'criteria', value: ['studio'] },
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
			},
		],
	},
	{
		//Nombre de niveaux
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Nombre de niveaux :',
		name: 'number_level',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{ condition: 'INEQUAL', field: 'criteria', value: ['studio'] },
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					CRITERE.CAVE,
					CRITERE.GARAGE,
					CRITERE.PARKING,
					CRITERE.IMMEUBLE,
				],
			},
		],
	},
	{
		//Jardin
		type: 'radio',
		name: 'garden_exist',
		className: 'w-full ',
		title: 'Jardin :',
		validation: {
			validate: { requiredField },
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [
					...CRITERE_OFFRE.TERRAIN,
					CRITERE.PARKING,
					CRITERE.CAVE,
					CRITERE.GARAGE,
				],
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface (m2)',
		name: 'garden_exist_area',
		visible: [{ condition: 'EQUAL', field: 'garden_exist', value: ['3'] }],
	},
	{
		//Privatif
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'select',
		title: 'Privatif:',
		name: 'garden_exist_private',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'garden_exist', value: ['3'] }],
	},
	{
		//Surface de terrain
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface du terrain:',
		name: 'land_area',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//Piscine
		type: 'radio',
		name: 'swim',
		className: 'w-full ',
		title: 'Piscine :',
		validation: {
			validate: { requiredField },
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: CRITERE.formName,
				value: [CRITERE.CAVE, CRITERE.PARKING, CRITERE.GARAGE],
			},
		],
	},
	{
		//STATUT
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',

		title: 'Statut :',
		name: 'swim_exist_statut',
		choices: [
			{
				title: 'Non précisé',
				value: 'unprecised',
			},
			{
				title: 'Privative',
				value: 'private',
			},
			{
				title: 'Collective',
				value: 'collective',
			},
			{
				title: 'Surveillée',
				value: 'survey',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},
	{
		//Nature
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'select',

		title: 'Nature :',
		name: 'swim_exist_nature',
		choices: [
			{
				title: 'Coque',
				value: 'coque',
			},
			{
				title: 'Traditionnelle',
				value: 'traditinnal',
			},
			{
				title: 'Hors_sol',
				value: 'hors-sol',
			},
			{
				title: 'Semi-entérrée',
				value: 'semi-entérrée',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},
	{
		//POOL HOUSE
		type: 'radio',
		name: 'swim_pool_house',
		className: 'w-full ',
		title: 'Pool House :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},
	{
		//CHAUFFEE
		type: 'radio',
		name: 'swim_heat',
		validation: {
			validate: { requiredField },
		},
		className: 'w-full ',
		title: 'Chauffée :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},
	{
		//COUVERTE
		type: 'radio',
		name: 'swim_couvert',
		className: 'w-full ',
		validation: {
			validate: { requiredField },
		},
		title: 'Couverte :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [{ condition: 'EQUAL', field: 'swim', value: ['3'] }],
	},
	{
		type: 'number',
		name: 'number_cave',
		title: 'Nombre de cave',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{
				condition: 'EQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE],
			},
		],
	},
	{
		//Nombre
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'number',
		title: 'Nombre de garage :',
		name: 'number_garage',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{
				condition: 'EQUAL',
				field: TYPE_OFFRE.formName,
				value: [TYPE_OFFRE.VENTE, TYPE_OFFRE.LOCATION],
			},
			{
				condition: 'EQUAL',
				field: TYPE_BIEN.formName,
				value: [
					TYPE_BIEN.MAISON,
					TYPE_BIEN.CESSION,
					TYPE_BIEN.APPARTEMENT,
					TYPE_BIEN.TERRAIN,
					TYPE_BIEN.AUTRES,
					TYPE_BIEN.CESSION,
					TYPE_BIEN.FOND_COMMERCE,
				],
			},
			{
				condition: 'INEQUAL',
				field: TYPE_BIEN.formName,
				value: [TYPE_BIEN.TERRAIN],
			},
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.PARKING],
			},
		],
	},

	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
		],
	},

	{
		// Parking Intérieur
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		title: 'Parking disponible :',

		name: 'parking',
		visible: [
			{
				condition: 'EQUAL',
				field: TYPE_BIEN.formName,
				value: [TYPE_BIEN.AUTRES],
			},
			{
				condition: 'EQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.PARKING, CRITERE.TERRAIN],
			},
		],
		validation: {
			validate: { requiredField },
		},
	},

	{
		// Parking Intérieur
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		title: 'Parking Intérieur :',
		name: 'indoor_parking',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
			},
			{
				condition: 'EQUAL',
				field: TYPE_OFFRE.formName,
				value: [TYPE_OFFRE.VENTE, TYPE_OFFRE.LOCATION],
			},
			{
				condition: 'EQUAL',
				field: TYPE_BIEN.formName,
				value: [
					TYPE_BIEN.MAISON,
					TYPE_BIEN.CESSION,
					TYPE_BIEN.APPARTEMENT,
					TYPE_BIEN.AUTRES,
					TYPE_BIEN.CESSION,
					TYPE_BIEN.FOND_COMMERCE,
				],
			},
		],
	},
	{
		// Parking Extèrieur
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'number',
		title: ' Parking Exterieur :',
		name: 'outdoor_parking',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
			},
			{
				condition: 'EQUAL',
				field: TYPE_OFFRE.formName,
				value: [TYPE_OFFRE.VENTE, TYPE_OFFRE.LOCATION],
			},
			{
				condition: 'EQUAL',
				field: TYPE_BIEN.formName,
				value: [
					TYPE_BIEN.MAISON,
					TYPE_BIEN.CESSION,
					TYPE_BIEN.APPARTEMENT,
					TYPE_BIEN.AUTRES,
					TYPE_BIEN.CESSION,
					TYPE_BIEN.FOND_COMMERCE,
				],
			},
		],
	},

	{
		//Situation
		//Exposition
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'select',
		tagName: 'status.exposition',
		title: 'Exposition :',
		name: 'exposition',
		choices: [
			{
				title: 'N',
				value: 'N',
			},
			{
				title: 'NE',
				value: 'NE',
			},
			{
				title: 'E',
				value: 'E',
			},
			{
				title: 'SE',
				value: 'SE',
			},
			{
				title: 'S',
				value: 'S',
			},
			{
				title: 'SO',
				value: 'SO',
			},
			{
				title: 'O',
				value: 'O',
			},
			{
				title: 'NO',
				value: 'NO',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
			},
		],
	},
	{
		// Situation
		//Vue
		group: 'info_personnelle',
		className: 'w-full sm:w-5/12 ',
		type: 'select',

		choices: [
			{
				title: 'Rue',
				value: 'rue',
			},
			{
				title: 'Champs',
				value: 'Champs',
			},
			{
				title: 'Montagne',
				value: 'Montagne',
			},
			{
				title: 'Mer',
				value: 'Mer',
			},
			{
				title: 'Lac',
				value: 'Lac',
			},
			{
				title: 'Dégagée',
				value: 'Dégagée',
			},
			{
				title: 'Rivière',
				value: 'Rivière',
			},
			{
				title: 'Immeubles',
				value: 'Immeubles',
			},
		],
		title: 'Vue :',
		name: 'view',
		tagName: 'status.view',
		validation: {
			validate: { requiredField },
		},
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'INEQUAL',
				field: CRITERE_OFFRE.formName,
				value: [CRITERE.CAVE, CRITERE.GARAGE, CRITERE.PARKING],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//Pays
		group: 'info_personnelle',
		type: 'select',
		name: 'city',
		className: 'w-full ',
		title: 'Pays  :',
		choices: [
			{
				title: 'France',
				value: 'France',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//Ville
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: ' Ville:',
		name: 'name_country',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
	{
		//code postal
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		title: 'Code postal :',
		name: 'zap_country',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{
				condition: 'EQUAL',
				field: 'type_estate_id',
				value: ['1', '2', '3', '4'],
			},
		],
	},
];
