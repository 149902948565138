import style from './form-builder.module.css';

import { FormProvider, useForm } from 'react-hook-form';

import { StepFormUpdate } from './builder/StepFormUpdate';
import { useState } from 'react';
export const CustomFormBuilderUpdate = ({
	form,
	onComplete,
	dataUpdate,
	title = 'Modifier un contact',
}) => {
	const [currentStep, setCurrentStep] = useState(0);
	const methods = useForm();
	const {
		handleSubmit,
		control,
		watch,
		formState: { errors, isDirty },
	} = methods;

	const submitForm = () => {
		onComplete();
	};
	const [formData, setFormData] = useState();

	const appendForm = (data) => {
		setFormData((prev) => ({ ...prev, ...data }));
		if (currentStep === form.steps.length - 1) {
			const forms = { ...formData, ...data };
			onComplete(forms);
		} else setCurrentStep((prev) => prev + 1);
	};

	return (
		<>
			<h1 className={`${style.title}`}>{title ?? form.title}</h1>

			<div className='p-4'>
				<div className={style.pagination}>
					{form.steps?.map((item, index) => {
						return (
							<div
								className={`${style.pagination__link} ${
									index <= currentStep && 'default-color'
								}`}
								key={index}>
								{index + 1}
							</div>
						);
					})}
				</div>
				<>
					<FormProvider {...methods}>
						{form.steps?.map(
							(step, index) =>
								index <= currentStep && (
									<form
										onSubmit={handleSubmit(appendForm, (errors) =>
											console.log({ errors })
										)}
										className={`${currentStep !== index && 'hidden'}`}
										key={index}>
										<StepFormUpdate
											step={step}
											errors={errors}
											formState={isDirty}
											watch={watch}
											control={control}
											currentStep={currentStep}
											index={index}
											dataUpdate={dataUpdate}
										/>
										<div className='flex justify-between'>
											{currentStep !== form.steps.length - 1 &&
												form.steps.length > 1 && (
													<button
														type='submit'
														className={`blue-color-i ${style.button__handling}`}>
														Suivant
													</button>
												)}

											{currentStep === form.steps.length - 1 && (
												<button
													type='submit'
													className={`${style.button__handling} default-color`}>
													{title ?? form.completeText}
												</button>
											)}

											{currentStep !== 0 && form.steps.length > 1 && (
												<button
													className={`blue-color-i ${style.button__handling}`}
													onClick={() => setCurrentStep((prev) => prev - 1)}>
													Précedent
												</button>
											)}
										</div>
									</form>
								)
						)}
					</FormProvider>
				</>
			</div>
		</>
	);
};
