import { CIVILITY, PARSE_CONTACT_NAME } from '../../../helpers/optionsContact';

export const NewEntity = ({ entity, news, action }) => {
	const content = JSON.parse(news.content);
	return (
		<>
			{entity === EntityType.Contact && (
				<>
					{CIVILITY[content.civility]} {PARSE_CONTACT_NAME(content)}
				</>
			)}
			{entity === EntityType.Bien && (
				<>
					N° {content.mandate?.num_mandat ?? content.num_folder} situé à{' '}
					{content.city} - {content.zap_country}{' '}
					{action === 'associed' && <>à l'agent {news.agent.name}</>}
				</>
			)}
			{entity === EntityType.User && <> {content.name}</>}
			{entity === EntityType.Association && <></>}
		</>
	);
};

const EntityType = {
	Contact: 'Contact',
	Bien: 'Bien',
	User: 'User',
	Estimation: 'Estimation',
	Association: 'Association',
};
