import { RangeInput } from '../../../components/commons/form/range';
import { Input } from '../../../components/commons/input';
import DetailsSection from '../../../components/generals/biens/section/DetailsSection';
import ContactDropdown from '../../../components/generals/card/contactDropdown';

function BienContactSection({ bien, navigate }) {
	return (
		<>
			<div className='px-5 py-1 mt-5 blue-color flex items-center justify-between'>
				<p className='text-white'>Gestion des rapprochements</p>
				<button className='text-white underline' onClick={() => navigate(-1)}>
					Retour
				</button>
			</div>

			<DetailsSection bien={bien}>
				<div className='flex'>
					<p className='text-[#2d5c6a] text-lg '>Proprio/Bailleurs</p>
				</div>
				{bien.rapprochements.map((rapprochement) => (
					<ContactDropdown
						key={rapprochement.id}
						rapprochement={rapprochement}
						bien={bien}
					/>
				))}
			</DetailsSection>
		</>
	);
}

export default BienContactSection;
