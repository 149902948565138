import { BienSection } from './bien.section';

export const SearchEstateResult = ({ estates }) => {
	return (
		<>
			<h1>Resultat pour les biens : {estates.length}</h1>
			{estates.map((estate, key) => (
				<BienSection key={key} bien={estate} />
			))}
		</>
	);
};
