import React from 'react';
function DetailsSection({ children, bien }) {
	const imageurl = process.env.REACT_APP_BASE_URL_IMAGE + 'photos_couvert';
	const DisplayButton = [
		{
			buttonText: 'Modifier le bien',
			link: '/mes-biens/bien-update/',
		},
		{
			buttonText: 'Suivi du dossier',
			link: '/mes-suivis/details/',
		},
		{
			buttonText: 'Impression',
			link: '/mes-biens/impression/',
		},
		{
			buttonText: 'Diffusion',
			link: '',
		},
		{
			buttonText: 'RAPPROCH.',
			link: '/mes-biens/rapprochement-bien/',
		},
		{
			buttonText: 'Contacts',
			link: bien.mandate?.contact
				? `/mes-contacts/details-contact/${bien.mandate?.contact.uuid}`
				: '/mes-biens/contacts-bien/',
		},
	];

	return (
		<>
			<div className='p-5 pr-5 mt-5 border-t-2 border-solid border-[#2d5c6a] grid grid-cols-10 gap-5'>
				<div className='col-span-10 md:col-span-8 text-justify  order-2 md:order-1'>
					{children}
				</div>

				<div className='col-span-10 md:col-span-2 md:justify-self-end order-1 md:order-2'>
					<div className='flex gap-2 justify-between md:flex-col md:items-end'>
						<div className='rounded border-2 border-solid border-[#2d5c6a]'>
							{bien &&
								bien.photos.photos_couvert.map((image, index) => (
									<img
										className='w-48 h-48 object-cover'
										src={imageurl + image.photo}
										key={index}
										alt=''
									/>
								))}
						</div>

						<div className='flex justify-between md:flex-col gap-1 md:mt-5 w-full flex-wrap'>
							{DisplayButton.map((item, index) => {
								if (item.buttonText === 'Contacts') {
									return (
										<a
											key={index}
											href={item.link}
											className='px-4 py-2 blue-color text-white rounded uppercase text-center  cursor-pointer'>
											{item.buttonText}
										</a>
									);
								} else
									return (
										<a
											key={index}
											href={item.link + bien.uuid}
											className='px-4 py-2 blue-color text-white rounded uppercase text-center  cursor-pointer'>
											{item.buttonText}
										</a>
									);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DetailsSection;
