import { BienForm } from '../../helpers/forms/bien';
import {
	successToast,
	technicalErrorToast,
} from '../../service/toast/showToast';
import { useBien } from '../../service/bien/useBien';
import { CustomFormBuilder } from '../../components/commons/form/CustomFormBuilder';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { useState } from 'react';

export const BienAddPage = () => {
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (data) => {
		const newBiens = {};
		newBiens.biens = {
			publish_price: data.publish_price ?? '',
			selling_price: data.selling_price ?? '',
			rent: data.rent ?? '',
			agent_id: data.user_id ?? '',
			duration_lease: data.duration_lease ?? '',
			living_area: data.living_area ?? '',
			number_room: data.number_room ?? '',
			number_bedroom: data.number_bedroom ?? '',
			number_level: data.number_level ?? '',
			garden_exist: data.garden_exist ?? '',
			garden_exist_area: data.garden_exist_area ?? '',
			garden_exist_private: data.garden_exist_private ?? '',
			land_area: data.land_area ?? '',
			swim: data.swim ?? '',
			swim_exist: { ...(data.swim_exist ?? []) },
			number_garage: data.number_garage ?? '',
			indoor_parking: data.indoor_parking ?? '',
			outdoor_parking: data.outdoor_parking ?? '',
			published: data.published ?? '',
			status: {
				view: data.view ?? '',
				exposition: data.exposition ?? '',
			},
			city: data.city ?? '',
			name_country: data.name_country ?? '',
			zap_country: data.zap_country ?? '',
			num_folder: data.num_folder ?? '',
			date_folder: data.date_folder ?? '',
			publish_property: data.publish_property ?? 1,
			equipment: {
				format: data.format ?? '',
				type: data.type ?? '',
				energie: data.energie ?? '',
				ascensceur: data.ascensceur ?? '',
				acces_handicapes: data.acces_handicapes ?? '',
				climatisation: data.climatisation ?? '',
				chemine: data.chemine ?? '',
				byciclette: data.byciclette ?? '',
				water_alimentation: data.water_alimentation ?? '',
				water_clean: data.water_clean ?? '',
				electric: data.electric_volet ?? '',
				portal_electric: data.portal_electric ?? '',
				garden: data.garden ?? '',
				vitrage: data.vitrage ?? '',
				internet: data.internet ?? '',
				portals: data.portals ?? '',
				interphone: data.interphone ?? '',
				visiophone: data.visiophone ?? '',
				alarme: data.alarme ?? '',
				digicode: data.digicode ?? '',
			},
			recent_construct: {
				assurance: data.assurance ?? '',
				garantie: data.garantie ?? '',
				achat: data.achat ?? '',
				certificat: data.certificat ?? '',
			},
		};
		newBiens.exteriorDetail = {
			semiOwnership: data.semiOwnership ?? '',
			floor: data.floor ?? '',
			basement: data.basement + '',
			withbasement: data.withbasements ?? '',
			trueCellar: data.trueCellars ?? '',
			cellar: data.cellar ?? '',
			balcony: data.balcony ?? '',
			withBalcony: data.withBalcony ?? '',
			terrace: data.terrace ?? '',
			withTerrace: data.withTerrace ?? '',
			varangue: data.varangue ?? '',
			withVeranda: data.withVeranda ?? '',
			loggia: data.loggia ?? '',
			veranda: data.veranda ?? '',
			singleStorey: data.singleStorey ?? '',
			typeResidence: data.typeResidence ?? '',
			formatResidence: data.formatResidence ?? '',
			barbecue: data.barbecue ?? '',
			jacuzzi: data.jacuzzi ?? '',
			bikes: data.bikes ?? '',
			tennis: data.tennis ?? '',
			summerKitchen: data.summerKitchen ?? '',
		};

		newBiens.terrain = {
			ground: [
				{
					swimming: data.swimming ?? '',
					tree: data.tree ?? '',
					divisible: data.divisible ?? '',
					serviced: data.serviced ?? '',
					water: data.water ?? '',
					gaz: data.gaz_ground ?? '',
					elec: data.elec ?? '',
					tel: data.tel ?? '',
				},
			],
			ground_print: data.ground_print ?? '',
			ground_print_residual: data.ground_print_residual ?? '',
			shon: data.shon ?? '',
			ces: data.ces ?? '',
			pos: data.pos ?? '',
			codification_plu: data.codification_plu ?? '',
			right_way: data.right_way ?? '',
			cadastral_ref: data.cadastral_ref ?? '',
			serviced: data.serviced ?? '',
			connection: [
				{
					water: data.water ?? '',
					gaz: data.gaz ?? '',
					elec: data.elec ?? '',
					tel: data.tel ?? '',
				},
			],
		};

		newBiens.interiorDetail = {
			category: {
				category: data.category ?? '',
			},
			nbOfSleeping: data.nbOfSleeping ?? '',
			nbOfLots: data.nbOfLots ?? '',
			nbOfBathroom: data.nbOfBathroom ?? '',
			nbOfRoomWater: data.nbOfRoomWater ?? '',
			nbOfBathtubs: data.nbOfBathtubs ?? '',
			nbOfShowers: data.nbOfShowers ?? '',
			nbOfWc: data.nbOfWc ?? '',
			TypeOfKitchen: data.TypeOfKitchen ?? '',
			StateOfKitchen: data.StateOfKitchen ?? '',
			caracteristique: {
				attic: data.attic ?? '',
				cellar: data.cellar ?? '',
				furniture: data.furniture ?? '',
			},
			bouti: data.bouti ?? '',
			surfaceSquare: data.surfaceSquare ?? '',
			surfaceStay: data.surfaceStay ?? '',
			kitchenEquipment: {
				hotplate: data.hotplate ?? '',
				oven: data.oven ?? '',
				microwave: data.microwave ?? '',
				freezer: data.freezer ?? '',
				fridge: data.fridge ?? '',
				dishwasher: data.dishwasher ?? '',
				coffeeMaker: data.coffeeMaker ?? '',
				toaster: data.toaster ?? '',
			},
			multimedia: {
				tv: data.tv,
				internet: data.internet ?? '',
				hifi: data.hifi ?? '',
			},
			othersEquipment: {
				washingMachine: data.washingMachine ?? '',
				dryer: data.dryer ?? '',
				iron: data.iron ?? '',
				babyEquipment: data.babyEquipment ?? '',
			},
		};
		newBiens.InfoCopropriete = {
			lot_number: `${data.lot_number}` ?? '',
			total_unit: data.total_unit ?? '',
			annual_charges: `${data.annual_charges}` ?? '',
			amount_fund: data.amount_fund ?? '',
			thousands_copropriete: data.thousands_copropriete ?? '',
			backupPlan: data.backupPlan ?? '', //intrus
			unionStatus: data.unionStatus ?? '',
			property_copropriete: {
				property_copropriete: data.property_copropriete ?? '',
				status_syndicat: data.status_syndicat ?? '',
			},
		};

		newBiens.diagnostique = {
			year_construction: data.year_construction ?? '',
			dpe_date_realization: data.dpe_date_realization ?? '',
			dpes: data.dpes ?? '',
			dpe_consommation: data.dpe_consommation ?? '',
			dpe_ges: data.dpe_ges ?? '',
			cost_estimate: data.cost_estimate ?? '',
			ref_year: data.ref_year ?? '',
			amiante: data.amiante ?? '',
			amiante_yes_date: data.amiante_yes_date ?? '',
			amiante_yes_comments: data.amiante_yes_comments ?? '',
			electric: data.electric ?? '',
			electric_yes_date: data.electric_yes_date ?? '',
			electric_yes_comments: data.electric_yes_comments ?? '',
			gaz: data.gaz ?? '',
			gaz_yes_date: data.gaz_yes_date ?? '',
			gaz_yes_comments: data.gaz_yes_comments ?? '',
			plomb: data.plomb ?? '',
			plomb_yes_date: data.plomb_yes_date ?? '',
			plomb_yes_comments: data.plomb_yes_comments ?? '',
			loi_carrez: data.loi_carrez ?? '',
			loi_carrez_yes_date: data.loi_carrez_yes_date ?? '',
			loi_carrez_yes_comments: data.loi_carrez_yes_comments ?? '',
			erp: data.erp ?? '',
			erp_yes_date: data.erp_yes_date ?? '',
			erp_yes_comments: data.erp_yes_comments ?? '',
			state_parasitaire: data.state_parasitaire ?? '',
			state_parasitaire_yes_date: data.state_parasitaire_yes_date ?? '',
			state_parasitaire_yes_comments: data.state_parasitaire_yes_comments ?? '',
			assainissement: data.assainissement ?? '',
			assainissement_yes_date: data.assainissement_yes_date ?? '',
			assainissement_yes_comments: data.assainissement_yes_comments ?? '',
			additional_surfaces: data.additional_surfaces ?? '',
		};
		newBiens.rentalInvests = {
			estimated_rent: data.estimated_rent ?? '',
			monthly_rent: data.monthly_rent ?? '',
			yeld: data.yeld ?? '',
			occupancy: data.occupancy ?? '',
		};
		newBiens.InfoFinanciere = {
			info_rent: data.info_rent ?? '',
			info_rent_encadrement: {
				rentSupervision: data.rentSupervision ?? '',
				info_rent_default: data.info_rent_default ?? '',
				info_rent_majored: data.info_rent_majored ?? '',
				info_rent_complement: data.info_rent_complement ?? '',
			},
			info_tenant_chare: {
				tenantShare: data.tenantShare ?? '',
				rising: data.rising ?? '',
				percent: data.percent ?? '',
				risingM2: data.risingM2 ?? '',
				includingStatusOfPremises: data.includingStatusOfPremises ?? '',
				inventoryM2: data.inventoryM2 ?? '',
			},
			info_owner_share: {
				ownersShare: data.ownersShare ?? '',
				ownerRising: data.ownerRising ?? '',
				ownerPercent: data.ownerPercent ?? '',
				ownerRisingM2: data.ownerRisingM2 ?? '',
				ownerIncludingStatusOfPremises:
					data.ownerIncludingStatusOfPremises ?? '',
				ownerInventoryM2: data.ownerInventoryM2 ?? '',
			},

			// vente
			honoraire: data.honoraire ?? '',
			info_price: data.info_price ?? '',
			info_honoraire_charge:
				data.info_honoraire_charge != null ? data.info_honoraire_charge : 0,
			info_honoraire_locataire_part:
				data.info_honoraire_locataire_part != null
					? data.info_honoraire_locataire_part
					: 0,

			info_locative_charge_total: data.info_locative_charge_total ?? '',
			info_locative_charge_format: data.info_locative_charge_format ?? '',

			info_locative_charge_information:
				data.info_locative_charge_information ?? '',

			info_estimation_value: data.info_estimation_value ?? '',
			info_estimation_date: data.info_estimation_date ?? '',
			info_predicted_work: data.info_predicted_work ?? '',
			info_garantied_deposit: data.info_garantied_deposit ?? '',
			info_habitation_taxe: data.info_habitation_taxe ?? '',
			info_ordure_menagere_taxe: data.info_ordure_menagere_taxe ?? '',
			info_fonciere_taxe: data.info_fonciere_taxe ?? '',
			info_monthly_charge: data.info_monthly_charge ?? '',
		};

		newBiens.photos = {
			photos_couvert: {
				photos_original: data.photos_original ?? '',
				photos_original_description: data.photos_original_description ?? '',
			},
			photos_slide: {
				photos_slide1: {
					photo: data.photos_slide1,
					description: data.photos_slide1_description,
				},
				photos_slide2: {
					photo: data.photos_slide2,
					description: data.photos_slide2_description,
				},
				photos_slide3: {
					photo: data.photos_slide3,
					description: data.photos_slide3_description,
				},
				photos_slide4: {
					photo: data.photos_slide4,
					description: data.photos_slide4_description,
				},
				photos_slide5: {
					photo: data.photos_slide5,
					description: data.photos_slide5_description,
				},
				photos_slide6: {
					photo: data.photos_slide6,
					description: data.photos_slide6_description,
				},
				photos_slide7: {
					photo: data.photos_slide7,
					description: data.photos_slide7_description,
				},
				photos_slide8: {
					photo: data.photos_slide8,
					description: data.photos_slide8_description,
				},
				photos_slide9: {
					photo: data.photos_slide9,
					description: data.photos_slide9_description,
				},
				photos_slide10: {
					photo: data.photos_slide10,
					description: data.photos_slide10_description,
				},
				photos_slide11: {
					photo: data.photos_slide11,
					description: data.photos_slide11_description,
				},
				photos_slide12: {
					photo: data.photos_slide12,
					description: data.photos_slide12_description,
				},
				photos_slide13: {
					photo: data.photos_slide13,
					description: data.photos_slide13_description,
				},
				photos_slide14: {
					photo: data.photos_slide14,
					description: data.photos_slide14_description,
				},
				photos_slide15: {
					photo: data.photos_slide15,
					description: data.photos_slide15_description,
				},
				photos_slide16: {
					photo: data.photos_slide16,
					description: data.photos_slide16_description,
				},
				photos_slide17: {
					photo: data.photos_slide17,
					description: data.photos_slide17_description,
				},
				photos_slide18: {
					photo: data.photos_slide18,
					description: data.photos_slide18_description,
				},
				photos_slide19: {
					photo: data.photos_slide19,
					description: data.photos_slide19_description,
				},
				photos_slide20: {
					photo: data.photos_slide20,
					description: data.photos_slide19_description,
				},
			},
		};
		newBiens.Mandate = {
			num_mandat: data.num_mandat ?? '',
			person: data.person,
			contact_id_contact: data.contact_id_contact ?? '',
		};

		newBiens.advertissement = {
			title: data.title ?? '',
			description: data.description ?? '',
		};
		newBiens.availabilities = {
			num_folder: data.num_folder ?? '',
			created_folder: data.created_folder ?? '',
			updated_folder: data.updated_folder ?? '',
			immediat: data.immediat ?? '',
			release_it: data.release_it ?? '',
			available_on: data.available_on ?? '',
		};

		newBiens.type_offert_id = data.type_offert_id;
		newBiens.type_offert_suite_id = data.type_offer_suite_id;
		newBiens.type_estate_id = data.type_estate_id;
		newBiens.classification_estate_id = data.criteria;

		newBiens.sectors = {
			advertised_country: data.advertised_country ?? '',
			public_zap: data.public_zap ?? '',
			public_country: data.public_country ?? '',
			private_zap: data.private_zap ?? '',
			private_country: data.private_country ?? '',
			property_address: data.property_address ?? '',
			address_completed: data.address_completed ?? '',
			building: data.building ?? '',
			prox_service: data.prox_service ?? '',
			environment: data.environment ?? '',
		};
		try {
			setIsLoading(true);
			const formData = convertJsonToFormData(newBiens);
			await useBien.createBien(formData).finally(() => {
				setIsLoading(false);
			});
			successToast('Bien ajouté avec succès');
			setTimeout(() => window.location.reload(), 2000);
		} catch (error) {
			setIsLoading(false);
			technicalErrorToast();
		}
	};
	const convertJsonToFormData = (data) => {
		const formData = new FormData();

		const entries = Object.entries(data);

		for (const [key, value] of entries) {
			if (value instanceof File) {
				formData.append(key, value);
			} else if (value instanceof Object && !(value instanceof Array)) {
				convertJsonToFormDataHelper(formData, value, key);
			} else if (Array.isArray(value)) {
				for (let j = 0; j < value.length; j++) {
					if (value[j] instanceof File) {
						formData.append(`${key}[${j}]`, value[j]);
					} else if (value[j] instanceof Object) {
						convertJsonToFormDataHelper(formData, value[j], `${key}[${j}]`);
					} else {
						formData.append(key, value[j]);
					}
				}
			} else {
				formData.append(key, value);
			}
		}

		return formData;
	};

	const convertJsonToFormDataHelper = (formData, data, parentKey = '') => {
		const entries = Object.entries(data);

		for (const [key, value] of entries) {
			const newKey = parentKey ? `${parentKey}[${key}]` : key;

			if (value instanceof File) {
				formData.append(newKey, value);
			} else if (value instanceof Object && !(value instanceof Array)) {
				convertJsonToFormDataHelper(formData, value, newKey);
			} else if (Array.isArray(value)) {
				for (let j = 0; j < value.length; j++) {
					const arrayKey = `${newKey}[${j}]`;
					if (value[j] instanceof File) {
						formData.append(arrayKey, value[j]);
					} else if (value[j] instanceof Object) {
						convertJsonToFormDataHelper(formData, value[j], arrayKey);
					} else {
						formData.append(newKey, value[j]);
					}
				}
			} else {
				formData.append(newKey, value);
			}
		}
	};

	return (
		<div className='border-2 border-solid border-[#2d5c6a]'>
			<CustomFormBuilder form={BienForm} onComplete={handleSubmit} />
			{isLoading && <GeneralSpinner />}
		</div>
	);
};
