import { useState } from 'react';
import { dateFormater } from '../../../helpers/formatter';
import Chevron from '../../commons/Chevron';
import { ContactEstimation } from './ContactEstimation';
import { InfoEstimation } from './InfoEstimation';
import { getStorageUser, isAdmin } from '../../../service/localStorage';
import { Modal } from '../../commons/modal/modal';
import { ButtonSpinner } from '../../commons/buttonSpinner';
import { EstimationService } from '../../../service/estimation';
import { successToast } from '../../../service/toast/showToast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const CardEstimation = ({
	estimation,
	setEstimationUpdated,
	agents,
}) => {
	const [showAction, setShowAction] = useState(false);
	const [showAgents, setShowAgents] = useState(false);
	const [showAttribution, setShowAttribution] = useState(false);
	const [showLoadingAttribution, setShowLoadingAttribution] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const attributeTo = (attribute, formData) => {
		const data = {};
		setShowLoadingAttribution(true);
		data.estimation_id = estimation.id;
		data.agency_id = getStorageUser().user.agency_id;
		if (attribute === 'me') {
			data.user_id = getStorageUser().user.id;
		} else {
			data.user_id = formData.agent;
		}
		EstimationService.attributeTo(data)
			.then(() => {
				successToast("Attribution réussie pour l'estimation");
				setEstimationUpdated(Math.floor(Math.random() * 100));
				setShowAttribution(false);
			})
			.catch(() => {})
			.finally(() => setShowLoadingAttribution(false));
	};
	return (
		<div className='w-full border-2 border-solid flex items-stretch mb-3 justify-center gap-x-1 rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md'>
			<div className='w-full p-1 rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md'>
				<div className='border-2 p-2 gap-1 border-solid border-black/40 flex items-stretch justify-between rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md'>
					<ContactEstimation estimation={estimation} />
					<InfoEstimation estimation={estimation} />
					<div>Date : {dateFormater(estimation.created_at)}</div>
				</div>
			</div>

			<div className='relative inline-block'>
				<i
					className='fa-solid fa-ellipsis cursor-pointer'
					onClick={() => setShowAction(!showAction)}></i>

				<div
					className={`absolute right-0 z-10 p-2 mt-2 min-h-full w-56 origin-top-right rounded-md bg-gray-300 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
						showAction ? '' : 'hidden'
					}`}
					role='menu'
					aria-orientation='vertical'
					aria-labelledby='menu-button'
					tabIndex='-1'>
					<div className='w-full flex flex-wrap gap-y-2 py-4 px-2'>
						{!estimation.user_id &&
							((isAdmin && (
								<button
									className='w-full text-white blue-color rounded-md py-2 px-4r'
									onClick={() => setShowAttribution(true)}>
									Attribuer à un agent{' '}
								</button>
							)) || (
								<button
									className='w-full text-white blue-color rounded-md py-2 px-4  flex flex-wrap items-center justify-center'
									disabled={showLoadingAttribution}
									onClick={() => attributeTo('me')}>
									M'attribuer cette estimation{' '}
									<ButtonSpinner showLoading={showLoadingAttribution} />
								</button>
							))}
						<button className='w-full text-white blue-color rounded-md py-2 px-4'>
							Archiver
						</button>
						<button className='w-full text-white blue-color rounded-md py-2 px-4'>
							Convertir en bien
						</button>
						<button className='w-full text-white blue-color rounded-md py-2 px-4'>
							Convertir en contact
						</button>
					</div>
				</div>
			</div>
			<div
				className='bg-black/25 rounded-tr-md rounded-br-md flex items-center'
				onClick={() =>
					navigate('/mes-estimations/details/' + estimation.estimation_uuid)
				}>
				<Chevron />
			</div>
			{showAttribution && (
				<Modal
					modalTitle={"Attribuer l'estimation à un agent"}
					height='h-auto'
					width='w-11/12 sm:w-1/3'
					isOpen={showAttribution}
					allowBackdrop={false}
					handleClose={() => setShowAttribution(false)}>
					<div className='my-4 relative'>
						<form
							action=''
							onSubmit={handleSubmit((data) => attributeTo('agent', data))}>
							<button
								type='button'
								className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
								id='menu-button'
								aria-expanded='true'
								aria-haspopup='true'
								onClick={() => setShowAgents(!showAgents)}>
								Attribuer à un agent
								<svg
									className='-mr-1 h-5 w-5 text-gray-400'
									viewBox='0 0 20 20'
									fill='currentColor'
									aria-hidden='true'>
									<path
										fillRule='evenodd'
										d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
										clipRule='evenodd'
									/>
								</svg>
							</button>
							<div
								className={`absolute right-0 z-10 p-2 mt-2 min-h-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full ${
									showAgents ? '' : 'hidden'
								}`}
								role='menu'
								aria-orientation='vertical'
								aria-labelledby='menu-button'
								tabIndex='-1'>
								<select
									{...register('agent')}
									id='select'
									className='w-full p-4'>
									{agents.map((item, index) => (
										<option value={item.id} key={index}>
											{item.name}
										</option>
									))}
								</select>
								{errors.agents && (
									<span className='text-red-600'>{errors.agents.message}</span>
								)}
							</div>
							<button
								type='submit'
								className='default-color px-4 py-2 text-white uppercase rounded-md my-4 font-bold flex gap-1 flex-wrap items-center justify-center'
								disabled={showLoadingAttribution}>
								Attribuer <ButtonSpinner showLoading={showLoadingAttribution} />
							</button>
						</form>
					</div>
				</Modal>
			)}
		</div>
	);
};
