import { CardPige } from './card';

export const MorePige = ({ piges }) => {
	return (
		<div className='w-full text-black p-4'>
			<h1 className='text-2xl font-bold my-4'>
				Des biens qui pourraient vous interessez
			</h1>
			<div className='flex flex-wrap gap-4 justify-around'>
				{piges.map((item, index) => (
					<CardPige key={index} pige={item} />
				))}
			</div>
		</div>
	);
};
