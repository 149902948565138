import { requiredField } from '../../../validation/validation';
export const EnvoisMail = [
	{
		type: 'radio',
		className: 'w-full',
		tagName: 'space_perso_activate',
		name: 'space_perso_activate',
		title:
			"Envoyer l'email d'activation de son Espace personnel (obligation RGPD) :",
		choices: [
			{
				title: 'Oui',
				value: '1',
			},
			{
				title: 'Non',
				value: '0',
			},
		],
		validation: {
			validate: { requiredField },
		},
	},
	{
		type: 'radio',
		className: 'w-full',
		name: 'space_proprio_activate',
		title: "Activer l'espace propriétaire (obligation loi Alur) :",
		choices: [
			{
				title: 'Oui',
				value: '1',
			},
			{
				title: 'Non',
				value: '0',
			},
		],
		validation: {
			validate: { requiredField },
		},
	},
];
