import { useEffect, useState } from 'react';
import { EstimationService } from '../../service/estimation';
import { configService } from '../../service/api/config';

const typeBien = ['maison', 'appartement', 'local'];

export const FIlterEstimation = ({ handleChangeTypeBien }) => {
	const [cp, setCp] = useState();
	const [agents, setAgents] = useState();

	useEffect(() => {
		EstimationService.getCp().then(({ data }) => setCp(data));
		configService.getConfiguration().then(({ data }) => setAgents(data.users));
	}, []);
	return (
		cp &&
		agents && (
			<div className='p-2'>
				<h1 className='uppercase font-bold w-full blue-color text-white px-auto py-2 text-center'>
					Filtrer les estimations
				</h1>
				<div className='my-2 border-[1px] border-solid border-black/10 p-4'>
					<div className='flex flex-wrap'>
						<label htmlFor='cp' className='w-full'>
							Code postal :
						</label>
						<select
							className='w-full p-4 my-1'
							id='cp'
							onChange={(e) =>
								handleChangeTypeBien({ cp_bien: e.target.value })
							}>
							<option value=''>Tous</option>
							{cp.map((item, index) => {
								return (
									<option value={item.code_postal} key={index}>
										{item.code_postal}
									</option>
								);
							})}
						</select>
					</div>
					<div className='flex flex-wrap'>
						<label htmlFor='agent' className='w-full'>
							Négociateur :
						</label>
						<select
							className='w-full p-4 my-1'
							id='agent'
							onChange={(e) =>
								handleChangeTypeBien({ user_id: e.target.value })
							}>
							<option value=''>Tous</option>
							{agents.map((item, index) => {
								return (
									<option value={item.id} key={index}>
										{item.name}
									</option>
								);
							})}
						</select>
					</div>
					<div className='flex flex-wrap'>
						<label htmlFor='type_bien' className='w-full'>
							Type du bien :
						</label>
						<select
							className='w-full p-4 my-1'
							id='type_bien'
							onChange={(e) =>
								handleChangeTypeBien({ type_bien: e.target.value })
							}>
							<option value=''>Tous</option>
							{typeBien.map((item, index) => {
								return (
									<option value={item} key={index} className='uppercase'>
										{item.toLocaleUpperCase()}
									</option>
								);
							})}
						</select>
					</div>

					<div className='flex flex-wrap'>
						<label htmlFor='surface_habitable'>
							Surface habitable ( m<sup>2</sup> ):
						</label>
						<input
							type='number'
							onChange={(e) =>
								handleChangeTypeBien({ surface_habitable: e.target.value })
							}
							id='surface_habitable'
							min={0}
							className='w-full border-[1px] border-solid border-black/20 my-2 p-2'
						/>
					</div>
					<div className='flex flex-wrap'>
						<label htmlFor='nb_piece'>Nombre de pièces :</label>
						<input
							type='number'
							id='nb_piece'
							onChange={(e) =>
								handleChangeTypeBien({ nb_pieces: e.target.value })
							}
							min={0}
							className='w-full border-[1px] border-solid border-black/20 my-2 p-2'
						/>
					</div>

					<div className='flex flex-wrap'>
						<label htmlFor='nb_chambres'>Nombre de chambres :</label>
						<input
							type='number'
							onChange={(e) =>
								handleChangeTypeBien({ nb_chambres: e.target.value })
							}
							id='nb_chambres'
							min={0}
							className='w-full border-[1px] border-solid border-black/20 my-2 p-2'
						/>
					</div>

					<div className='flex flex-wrap'>
						<label htmlFor='nb_salle_bain'>Nombre de salle de bain :</label>
						<input
							type='number'
							id='nb_salle_bain'
							onChange={(e) =>
								handleChangeTypeBien({ nb_salle_bain: e.target.value })
							}
							min={0}
							className='w-full border-[1px] border-solid border-black/20 my-2 p-2'
						/>
					</div>

					<div className='flex flex-wrap'>
						<label htmlFor='nb_salle_eau'>Nombre de salle d'eau' :</label>
						<input
							type='number'
							onChange={(e) =>
								handleChangeTypeBien({ nb_salle_eau: e.target.value })
							}
							id='nb_salle_eau'
							min={0}
							className='w-full border-[1px] border-solid border-black/20 my-2 p-2'
						/>
					</div>
				</div>
			</div>
		)
	);
};
