import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BienService from '../../../service/bien/BienService';
import { BienSection } from '../../../components/generals/biens/section/bien.section';
import { Button } from '../../../components/commons/button';
import { usePublication } from '../../../service/annonce/useAnnonce';
import { GeneralSpinner } from '../../../components/commons/general-spinner';
import { PublicationCard } from '../../../components/generals/portals/PublicationCard';
import { successToast } from '../../../service/toast/showToast';

export const EstateDetails = () => {
	const [createNew, setCreateNew] = useState(false);
	const [loading, setLoading] = useState(true);
	const [bien, setBien] = useState();
	const { bienUuid } = useParams();
	const [adPublications, setAdPublications] = useState();

	useEffect(() => {
		setLoading(true);
		BienService.getBienById(bienUuid)
			.then(async (res) => {
				setBien(res.data);
				const hasAlreadyPublished = res.data.publication_id ? true : false;
				if (hasAlreadyPublished) {
					const response = await usePublication.getAdPublications(bienUuid);
					setAdPublications(response.data);
				}
			})
			.catch(() => {})
			.finally(() => setLoading(false));
	}, []);

	const createAd = async () => {
		setLoading(true);
		await usePublication
			.postAd(bienUuid)
			.then(() => {
				successToast('Publication enregistrée');
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		bien && (
			<>
				<div className=''>
					<BienSection bien={bien} />
				</div>
				<div>
					<h1 className='text-center blue-color text-white py-4 text-2xl font-bold'>
						DIFFUSION DU BIEN
					</h1>
					<div className='min-h-[200px] flex items-start justify-center gap-2 my-4'>
						{adPublications &&
							adPublications.map((pub, index) => (
								<PublicationCard
									publication={pub}
									key={index}
									bien={bienUuid}
								/>
							))}
						{!adPublications && (
							<button
								className='default-color text-white p-4 rounded-md font-bold'
								type='button'
								onClick={createAd}>
								CRÉER UNE PUBLICATION
							</button>
						)}
					</div>
				</div>

				{loading && <GeneralSpinner />}
			</>
		)
	);
};
