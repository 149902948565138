import { useEffect, useState } from 'react';

import { Breadcumbs } from '../../../components/generals/headers/breadcumbs';
import { CardImpression } from '../../../components/generals/biens/impression/CardImpression';
import { Modal } from '../../../components/commons/modal/modal';
import { useParams } from 'react-router-dom';
import BienService from '../../../service/bien/BienService';
import { Model } from './models/Model';

const MODELS = [
	{
		title: 'Modèle 1 - Par défaut',
		name: 'model1',
		disposition: 'landscape',
	},
	{
		title: "Modèle 2 - Visite d'agent",
		name: 'model2',
		disposition: 'portrait',
	},
	{
		title: 'Modèle 3 - Visite client',
		name: 'model3',
		disposition: 'portrait',
	},
	{
		title: 'Modèle 4 - Vitrine photo',
		name: 'model4',
        disposition: 'landscape',
	},
	{
		title: 'Modèle 5 - Vitrine photos et texte',
		name: 'model5',
        disposition: 'landscape',
	},
];

export const ImpressionEstatePage = () => {
	const [currentModel, setCurrentModel] = useState();
	const [showModal, setShowModal] = useState(false);
	const { bienUuid } = useParams();
	const [bien, setBien] = useState();

	useEffect(() => {
		BienService.getBienById(bienUuid).then(({ data }) => setBien(data));
	}, []);

	return (
		<>
			<Breadcumbs title={'Impression du bien'} />
			<div className='border-2 border-solid border-[#2d5c6a] p-4'>
				<p></p>
				<div className='w-full flex gap-4 flex-wrap items-stretch justify-center'>
					{MODELS.map((model, index) => (
						<CardImpression
							key={index}
							model={model}
							onClick={() => {
								setCurrentModel(model);
								setShowModal(true);
							}}
						/>
					))}
				</div>
			</div>
			{currentModel && (
				<Modal
					modalTitle={currentModel.title}
					width='w-10/12'
					height='h-full'
					isOpen={showModal}
					allowBackdrop={false}
					handleClose={() => {
						setCurrentModel();
						setShowModal(false);
					}}>
					<div className='p-4'>
						<Model bien={bien} model={currentModel} />
					</div>
				</Modal>
			)}
		</>
	);
};
