import { useEffect, useState } from 'react';
import { Button } from '../../components/commons/button';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';
import { Modal } from '../../components/commons/modal/modal';
import { configService } from '../../service/api/config';
import { UserCard } from '../../components/generals/users/card';
import { useForm } from 'react-hook-form';
import { useAgent } from '../../service/agency/useAgent';
import { successToast } from '../../service/toast/showToast';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { getStorageUser, updateStorageUser } from '../../service/localStorage';

export const Users = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenUpdate, setIsOpenUpdate] = useState(false);
	const [isOpenDelete, setIsOpenDelete] = useState(false);

	const [currentAgent, setCurrentAgent] = useState({});
	const [showPostalcode, setShowPostalcode] = useState(false);
	const [agents, setAgents] = useState();

	const [validatePassword, setValidatePassword] = useState({});
	const [validateName, setValidateName] = useState({});
	const [validateEmail, setValidateEmail] = useState({});

	const user = getStorageUser().user;

	const {
		register,
		handleSubmit,
		unregister,
		formState: { errors },
	} = useForm();

	const updateAgent = (agent) => {
		setIsOpenUpdate(true);

		setCurrentAgent(agent);
	};

	const deleteRecord = async () => {
		setShowLoading(true);
		await useAgent
			.removeAgent(currentAgent.id)
			.then(() => {
				setIsOpenDelete(false);
				successToast("Suppression de l'agent réussi avec succès");
				setCurrentAgent({});
			})
			.catch((e) => console.log(e))
			.finally(() => setShowLoading(false));
	};
	const [showLoading, setShowLoading] = useState(false);
	const onSubmit = async (data) => {
		setShowLoading(true);
		data.image = data.image[0] || null;
		const formData = new FormData();
		for (let property in data) {
			if (data.hasOwnProperty(property) && data[property]) {
				formData.append(property, data[property]);
			}
		}
		if (!Object.hasOwn(currentAgent, 'email')) {
			await useAgent
				.createAgent(formData)
				.then(() => {
					setIsOpen(false);
					setCurrentAgent({});
					successToast("Enregistrement et création de l'agent avec succès");
				})
				.catch((e) => console.log(e))
				.finally(() => setShowLoading(false));
		} else {
			formData.append('user_id', currentAgent.id);
			await useAgent
				.updateAgent(formData)
				.then(({ data }) => {
					setIsOpenUpdate(false);
					if (currentAgent.id === user.id) {
						updateStorageUser(data);
					}

					successToast("Modification de l'agent  réussie avec succès");
					setCurrentAgent({});
				})
				.catch((e) => console.log(e))
				.finally(() => setShowLoading(false));
		}
	};

	useEffect(() => {
		unregister('name');
		unregister('email');
		unregister('password');
		unregister('code_postal');
		unregister('image');
		unregister('phone');

		const validationPassword = {
			validate: {
				validationPassword: (v) => {
					if (currentAgent?.email && v.length === 0) return true;
					const regex =
						/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
					const message =
						'Le mot de passe doit contenir au moins 1 miniscule a-z, 1 majuscule A-Z, un chiffre 0-9, un caractère spécial et au moins 8 caractères';

					return !regex.test(v) ? message : true;
				},
			},
		};

		const validationName = {
			validate: {
				required: (v) => {
					return v === '' && !currentAgent?.name
						? "Le nom de l'agent est requis"
						: true;
				},
				checkValidity: async (v) => {
					if (v.length > 4 && currentAgent.name !== v) {
						const available = await useAgent
							.checkAvailability(v)
							.then(({ data }) => data);
						return available === true
							? true
							: "Le nom de l'agent n'est plus disponible";
					}
					return true;
				},
				greaterThanFour: (v) =>
					v.length < 4
						? "Le nom de l'agent doit être plus de 4 caractères"
						: true,
			},
		};

		const validationEmail = {
			validate: {
				required: (v) =>
					v === '' && currentAgent?.email
						? "Le mail de l'agent est requis"
						: true,
				greaterThanFour: (v) =>
					v.length < 4
						? "Le mail de l'agent doit être plus de 4 caractères"
						: true,

				valideEmail: (v) =>
					!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)
						? "Le mail de l'agent doit être valide"
						: true,
				checkValidity: async (v) => {
					if (v.length > 4 && !currentAgent?.email) {
						const available = await useAgent
							.checkAvailability(v)
							.then(({ data }) => data);
						return available === true
							? true
							: "Le mail de l'agent n'est plus disponible";
					}
				},
			},
		};

		configService.getConfiguration().then(({ data }) => {
			setAgents(data);
		});

		setValidateName((prev) => ({ ...prev, ...validationName }));
		setValidateEmail((prev) => ({ ...prev, ...validationEmail }));
		setValidatePassword((prev) => ({ ...prev, ...validationPassword }));
	}, [isOpen, isOpenUpdate, currentAgent.email, setCurrentAgent]);

	return (
		(agents && (
			<>
				<Breadcumbs title={'Agents'} />
				<div className='border-[1px] border-solid border-blue-300 rounded-sm p-4'>
					<div className='w-full flex justify-between items-center'>
						<h1 className='font-bold underline w-1/2'>
							Liste des utilisateurs :
						</h1>
						<Button
							onClick={() => setIsOpen(true)}
							className={
								'py-4 bg-green-700 text-white rounded-xl px-8 font-bold'
							}>
							Nouveau
						</Button>
					</div>
					<div className='w-full mt-8'>
						<div className='flex flex-wrap justify-around gap-4'>
							{agents &&
								agents.users.map((agent, index) => (
									<UserCard
										agent={agent}
										agents={agents}
										key={index}
										removeAgent={(agent) => {
											setIsOpenDelete(true);
											setCurrentAgent(agent);
										}}
										updateAgent={updateAgent}
									/>
								))}
						</div>
					</div>
				</div>

				{isOpen && (
					<Modal
						title={Object.hasOwn(currentAgent, 'email')}
						id={'agent_modal'}
						width='w-3/4 sm:w-1/2'
						height=''
						isOpen={isOpen}
						handleClose={() => {
							setCurrentAgent({});
							setIsOpen(false);
						}}
						modalTitle={
							Object.hasOwn(currentAgent, 'email')
								? 'Modifier cet agent'
								: 'Créer un agent'
						}
						allowBackdrop={false}
						allowForceClose={true}>
						<div className='p-4'>
							<form
								className='my-6'
								onSubmit={handleSubmit(onSubmit)}
								content='multipart/form-data'>
								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor='name' className='w-full mb-4 text-black/70'>
										Nom de l'agent <span className='text-red-400'>*</span> :
									</label>
									<div className=' w-full'>
										<input
											id='name'
											aria-invalid={errors.name ? 'true' : 'false'}
											type='text'
											className={`p-2 border-[1px] border-solid ${
												errors.name ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('name', {
												...validateName,
												value: currentAgent?.name || '',
											})}
										/>
										{errors.name && (
											<span className='text-red-600'>
												{errors.name.message}
											</span>
										)}
									</div>
								</div>

								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor='email' className='w-full mb-4 text-black/70'>
										Email <span className='text-red-400'>*</span> :
									</label>
									<div className='w-full'>
										<input
											readOnly={currentAgent?.email}
											id='email'
											aria-invalid={errors.email ? 'true' : 'false'}
											type='email'
											className={`p-2 border-[1px] border-solid read-only:bg-gray-500 read-only:text-white ${
												errors.email ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('email', {
												...validateEmail,
												value: currentAgent?.email || '',
											})}
										/>
										{errors.email && (
											<span className='text-red-600'>
												{errors.email.message}
											</span>
										)}
									</div>
								</div>

								<div className='w-full flex flex-wrap my-2'>
									<label
										htmlFor='password'
										className='w-full mb-4 text-black/70'>
										Mot de passe <span className='text-red-400'>*</span> :<br />
										{currentAgent?.email && (
											<span className='text-sm'>
												Laissez vide si vous ne voulez pas changer de mot de
												passe
											</span>
										)}
									</label>

									<div className=' w-full'>
										<div className='flex flex-wrap items-center'>
											<input
												id='password'
												aria-invalid={errors.password ? 'true' : 'false'}
												type='password'
												className={`p-2 border-[1px] border-solid ${
													errors.password ? 'border-red-600' : 'border-black/40'
												} outline-none w-11/12`}
												{...register('password', validatePassword)}
											/>
											<span
												className='py-3 w-1/12 flex items-center justify-center cursor-pointer'
												onClick={() => {
													const element = document.getElementById('password');

													element.setAttribute(
														'type',
														element.getAttribute('type') === 'password'
															? 'text'
															: 'password'
													);
												}}>
												{' '}
												<i className='fa fa-eye'></i>
											</span>
										</div>

										{errors.password && (
											<span className='text-red-600'>
												{errors.password.message}
											</span>
										)}
									</div>
								</div>

								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor="phone" className='w-full mb-4 text-black/70'>
										Télephone :
									</label>
									<div className='w-full'>
										<input
											id='phone'
											aria-invalid={errors.phone ? 'true' : 'false'}
											className={`p-2 border-[1px] border-solid ${
												errors.name ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`} 
											{...register('phone')}
											type="number" 
										/>
										{errors.phone && (
											<span className='text-red-600'>
												{errors.phone.message}	
											</span>
										)}
									</div>
								</div>

								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor='image' className='w-full mb-4 text-black/70'>
										Photo de l'agent <span className='text-red-400'>*</span> :
										<br />
										{currentAgent?.photo_url && (
											<span className='text-sm'>
												Laissez vide si vous ne voulez pas changer la photo
											</span>
										)}
									</label>
									<div className=' w-full'>
										<input
											id='image'
											aria-invalid={errors.image ? 'true' : 'false'}
											type='file'
											className={`p-2 border-[1px] border-solid ${
												errors.image ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('image', {
												validate: {
													required: (value) =>
														!value ? "L'image pour l'agent est requis" : true,
												},
											})}
										/>
										{errors.image && (
											<span className='text-red-600'>
												{errors.image.message}
											</span>
										)}
									</div>
								</div>

								<div className='mt-4 relative'>
									<button
										type='button'
										className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
										id='menu-button'
										aria-expanded='true'
										aria-haspopup='true'
										onClick={() => setShowPostalcode(!showPostalcode)}>
										Attribuer un ou plusieurs codes postaux
										<svg
											className='-mr-1 h-5 w-5 text-gray-400'
											viewBox='0 0 20 20'
											fill='currentColor'
											aria-hidden='true'>
											<path
												fillRule='evenodd'
												d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
												clipRule='evenodd'
											/>
										</svg>
									</button>
									<div
										className={`absolute right-0 z-10 p-2 mt-2 min-h-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full ${
											showPostalcode ? '' : 'hidden'
										}`}
										role='menu'
										aria-orientation='vertical'
										aria-labelledby='menu-button'
										tabIndex='-1'>
										<select
											{...register('code_postal')}
											id='select'
											multiple
											className='w-full'>
											{agents.configurations.map((item, index) => (
												<option value={item.code_postal} key={index}>
													{item.code_postal}
												</option>
											))}
										</select>
									</div>
								</div>

								<span className='text-sm'>
									Les champs marqués avec * sont obligatoires
								</span>
								<div className='w-full text-right my-2 flex items-center justify-end'>
									<button
										type='submit'
										className='cursor-pointer default-color text-white font-bold py-2 px-4 rounded-sm flex gap-3 items-center'>
										Enregistrer <ButtonSpinner showLoading={showLoading} />
									</button>
								</div>
							</form>
						</div>
					</Modal>
				)}

				{isOpenUpdate && (
					<Modal
						title={Object.hasOwn(currentAgent, 'email')}
						id={'agent_modal'}
						width='w-3/4 sm:w-1/2'
						height=''
						isOpen={isOpenUpdate}
						handleClose={() => {
							setCurrentAgent({});
							setIsOpenUpdate(false);
						}}
						modalTitle={'Modifier cet agent'}
						allowBackdrop={false}
						allowForceClose={true}>
						<div className='p-4'>
							<form
								className='my-6'
								onSubmit={handleSubmit(onSubmit)}
								content='multipart/form-data'>
								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor='name' className='w-full mb-4 text-black/70'>
										Nom de l'agent <span className='text-red-400'>*</span> :
									</label>
									<div className=' w-full'>
										<input
											id='name'
											aria-invalid={errors.name ? 'true' : 'false'}
											type='text'
											className={`p-2 border-[1px] border-solid ${
												errors.name ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('name', {
												...validateName,
												value: currentAgent?.name || '',
											})}
										/>
										{errors.name && (
											<span className='text-red-600'>
												{errors.name.message}
											</span>
										)}
									</div>
								</div>

								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor='email' className='w-full mb-4 text-black/70'>
										Email <span className='text-red-400'>*</span> :
									</label>
									<div className='w-full'>
										<input
											readOnly={currentAgent?.email}
											id='email'
											aria-invalid={errors.email ? 'true' : 'false'}
											type='email'
											className={`p-2 border-[1px] border-solid read-only:bg-gray-500 read-only:text-white ${
												errors.email ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('email', {
												...validateEmail,
												value: currentAgent?.email || '',
											})}
										/>
										{errors.email && (
											<span className='text-red-600'>
												{errors.email.message}
											</span>
										)}
									</div>
								</div>

								<div className='w-full flex flex-wrap my-2'>
									<label
										htmlFor='password'
										className='w-full mb-4 text-black/70'>
										Mot de passe <span className='text-red-400'>*</span> :<br />
										{currentAgent?.email && (
											<span className='text-sm'>
												Laissez vide si vous ne voulez pas changer de mot de
												passe
											</span>
										)}
									</label>

									<div className=' w-full'>
										<div className='flex flex-wrap items-center'>
											<input
												id='password'
												aria-invalid={errors.password ? 'true' : 'false'}
												type='password'
												className={`p-2 border-[1px] border-solid ${
													errors.password ? 'border-red-600' : 'border-black/40'
												} outline-none w-11/12`}
												{...register('password', validatePassword)}
											/>
											<span
												className='py-3 w-1/12 flex items-center justify-center cursor-pointer'
												onClick={() => {
													const element = document.getElementById('password');

													element.setAttribute(
														'type',
														element.getAttribute('type') === 'password'
															? 'text'
															: 'password'
													);
												}}>
												{' '}
												<i className='fa fa-eye'></i>
											</span>
										</div>

										{errors.password && (
											<span className='text-red-600'>
												{errors.password.message}
											</span>
										)}
									</div>
								</div>
								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor="phone" className='w-full mb-4 text-black/70'>
										Telephone :
									</label>
									<div className='w-full'>
										<input
											id='phone'
											aria-invalid={errors.phone ? 'true' : 'false'}
											type='number'
											className={`p-2 border-[1px] border-solid ${
												errors.name ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('phone', {
												value: currentAgent?.phone || '',
											})}
										/>
										{errors.phone && (
											<span className='text-red-600'>
												{errors.phone.message}
											</span>
										)}
									</div>		
									
								</div>
								<div className='w-full flex flex-wrap my-2'>
									<label htmlFor='image' className='w-full mb-4 text-black/70'>
										Photo de l'agent <span className='text-red-400'>*</span> :
										<br />
										{currentAgent?.photo_url && (
											<span className='text-sm'>
												Laissez vide si vous ne voulez pas changer la photo
											</span>
										)}
									</label>
									<div className=' w-full'>
										<input
											id='image'
											aria-invalid={errors.image ? 'true' : 'false'}
											type='file'
											className={`p-2 border-[1px] border-solid ${
												errors.image ? 'border-red-600' : 'border-black/40'
											} outline-none w-full`}
											{...register('image', {
												validate: {
													required: (value) =>
														!value ? "L'image pour l'agent est requis" : true,
												},
											})}
										/>
										{errors.image && (
											<span className='text-red-600'>
												{errors.image.message}
											</span>
										)}
									</div>
								</div>

								<div className='mt-4 relative'>
									<button
										type='button'
										className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
										id='menu-button'
										aria-expanded='true'
										aria-haspopup='true'
										onClick={() => setShowPostalcode(!showPostalcode)}>
										Attribuer un ou plusieurs codes postaux
										<svg
											className='-mr-1 h-5 w-5 text-gray-400'
											viewBox='0 0 20 20'
											fill='currentColor'
											aria-hidden='true'>
											<path
												fillRule='evenodd'
												d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
												clipRule='evenodd'
											/>
										</svg>
									</button>
									<div
										className={`absolute right-0 z-10 p-2 mt-2 min-h-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full ${
											showPostalcode ? '' : 'hidden'
										}`}
										role='menu'
										aria-orientation='vertical'
										aria-labelledby='menu-button'
										tabIndex='-1'>
										<select
											{...register('code_postal', {
												value: currentAgent?.configurations.map(
													(item) => item.code_postal
												),
											})}
											id='select'
											multiple
											defaultValue={currentAgent?.configurations.map(
												(item) => item.code_postal
											)}
											className='w-full'>
											{agents.configurations.map((item, index) => (
												<option value={item.code_postal} key={index}>
													{item.code_postal} {JSON.stringify()}
												</option>
											))}
										</select>
									</div>
								</div>

								<span className='text-sm'>
									Les champs marqués avec * sont obligatoires
								</span>
								<div className='w-full text-right my-2 flex items-center justify-end'>
									<button
										type='submit'
										className='cursor-pointer default-color text-white font-bold py-2 px-4 rounded-sm flex gap-3 items-center'>
										Enregistrer <ButtonSpinner showLoading={showLoading} />
									</button>
								</div>
							</form>
						</div>
					</Modal>
				)}

				<Modal
					id={'agent_modal'}
					width='w-1/2 sm:w-1/2 h-auto'
					handleClose={() => {
						setCurrentAgent({});
						setIsOpenDelete(false);
					}}
					isOpen={isOpenDelete}
					allowBackdrop={false}
					allowForceClose={true}>
					<div className='flex flex-wrap justify-center items-center w-full'>
						Êtes-vous sûr de vouloir supprimer cet agent ?
					</div>
					<div className='flex flex-wrap items-center justify-end gap-4 mt-8'>
						<Button
							className={'py-2 px-4  blue-color text-white rounded-md'}
							onClick={() => {
								setCurrentAgent({});
								setIsOpenDelete(false);
							}}>
							Non
						</Button>

						<Button
							className={
								'py-2 px-4  default-color text-white rounded-md flex gap-3 items-center'
							}
							onClick={() => deleteRecord()}>
							<span>Oui</span> <ButtonSpinner showLoading={showLoading} />
						</Button>
					</div>
				</Modal>
			</>
		)) || <GeneralSpinner />
	);
};
