import { RangeInput } from '../../../components/commons/form/range';
import DetailsSection from '../../../components/generals/biens/section/DetailsSection';
import ContactDropdown from '../../../components/generals/card/contactDropdown';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input } from '../../../components/commons/input';
import { useEffect, useState } from 'react';
import { imageBien } from '../../../helpers/image.source.';

const Statut = ({ bien }) => {
	const sold = bien.solds;
	const type_offert = bien.type_offert_id;
	const [statut, setStatut] = useState();
	useEffect(() => {
		if (sold === 'sold' || sold === 'archive') {
			type_offert === 1 ? setStatut('vendu') : setStatut('loué');
		} else {
			type_offert === 1 ? setStatut('à vendre') : setStatut('à louer');
		}
	}, [statut]);
	return (
		<>
			<span>{statut}</span>
		</>
	);
};
export const BienInfoSection = ({ bien }) => {
	const navigate = useNavigate();

	return (
		<>
			<div className='px-5 py-1 mt-5 blue-color flex  items-center justify-between'>
				<p className='text-white'>Info sur le bien</p>
				<button className='text-white underline' onClick={() => navigate(-1)}>
					Retour
				</button>
			</div>

			<DetailsSection bien={bien}>
				<div className='grid grid-row md:grid-cols-2 gap-5 '>
					<div className=' grid gap-5  '>
						<div className='flex  items-center justify-between  '>
							<p className='text-[#2d5c6a] w-1/4 w-1/4'>Annonce :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 '>
								{bien?.advertisement.title}
							</div>
						</div>

						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4 '>Infos :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 break-all'>
								{bien?.advertisement.description}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Lien :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'></div>
						</div>

						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Diffusion :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'></div>
						</div>
					</div>

					<div className=' grid gap-5'>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>
								{' '}
								{bien.type_offert_id === 1 ? 'Prix' : 'Loyer'} :
							</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.type_offert_id === 1 ? bien?.publish_price : bien.rent} €
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Honoraires :</p>
							<div className='w-[70%] mx-auto border border-[rgb(45,92,106)] rounded p-3 h-12'>
								{bien?.info_financiere?.honoraire}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Montant :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{JSON.parse(bien.info_financiere?.info_tenant_chare).rising} €
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Négo :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.agent?.name}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Agence :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.agency && bien.agency?.nameAgency}
							</div>
						</div>{' '}
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>N° Mandat :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								{bien.mandate && bien.mandate?.num_mandat}
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Statut :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								<Statut bien={bien} />
							</div>
						</div>
						<div className='flex items-center justify-between '>
							<p className='text-[#2d5c6a] w-1/4'>Action :</p>
							<div className='w-[70%] mx-auto border border-[#2d5c6a] rounded p-3 h-12'>
								Action
							</div>
						</div>
					</div>
				</div>
			</DetailsSection>
			{bien.photos && (
				<>
					<div
						className={`flex flex-row gap-5 shadow-lg justify-center items-center mx-auto max-w-[95%] py-3 overflow-y-auto`}>
						{bien.photos.photos_slide.map((image, index) => (
							<div
								className='h-72 w-64 py-3 bg-gray-200 relative shrink-0'
								key={index}>
								<img
									className=' w-96 object-contain rounded-t-xl h-64 cursor-pointer'
									src={imageBien(encodeURI(`photos_slide${image.photo}`))}
									alt={image.description}
								/>
							</div>
						))}
					</div>
				</>
			)}
		</>
	);
};
