import { requiredField } from '../../../validation/validation';
export const Diagnostics = [
	{
		//Année de construction
		group: 'info_personnelle',
		className: 'w-full sm:w-3/4',
		type: 'number',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Année de construction :',
		name: 'year_construction',
		tagName: 'diagnostic.year_construction',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
		],
	},
	{
		//Surfaces Annexes
		group: 'info_personnelle',
		className: 'w-full',
		type: 'number',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Surfaces Annexes :',
		name: 'additional_surfaces',
		tagName: 'diagnostic.additional_surfaces',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['3'] },
		],
	},
	{
		//DPE
		group: 'info_personnelle',
		className: 'w-full  !justify-start mt-4 sm:pl-4 underline',
		title: 'DPE :',
		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},

	{
		//Bien soumis au DPE
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'radio',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'DPE :',
		name: 'dpes',
		tagName: 'diagnostic.dpes',
		choices: [
			{
				title: 'DPE Vièrge',
				value: 'DPE Vièrge',
			},
			{
				title: 'Bien soumis au DPE',
				value: 'Bien soumis au DPE',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},

	{
		//Date de réalisation
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'date',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Date de réalisation :',
		name: 'dpe_date_realization',
		tagName: 'diagnostic.dpe_date_realization',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},

	{
		// Consommation
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Consommation :',
		name: 'dpe_consommation',
		tagName: 'diagnostic.dpe_consommation',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		// GES
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'GES :',
		name: 'dpe_ges',
		tagName: 'diagnostic.dpe_ges',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		// Estimation coûts annuels Moyens (€)
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Estimation coûts annuels Moyens (€) :',
		name: 'cost_estimate',
		tagName: 'diagnostic.cost_estimate',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		// Année de réference
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2 ',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Année de référence :',
		name: 'ref_year',
		tagName: 'diagnostic.ref_year',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
		],
	},
	{
		className: 'sm:w-1/2',
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//Amiante
		type: 'radio',
		name: 'amiante',
		tagName: 'diagnostic.amiante',
		className: 'w-full sm:w-1/2 ',
		title: 'Amiante :',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Date :',
		name: 'amiante_yes_date',
		tagName: 'diagnostic.amiante_yes_date',
		visible: [{ field: 'amiante', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Commentaire :',
		name: 'amiante_yes_comments',
		tagName: 'diagnostic.amiante_yes_comments',
		visible: [{ field: 'amiante', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Electrique
		type: 'radio',
		name: 'electric',
		tagName: 'diagnostic.electric',
		className: 'w-full sm:w-1/2 ',
		title: 'Electrique :',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},

	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		title: 'Date :',
		visible: [{ field: 'electric', value: ['3'], condition: 'EQUAL' }],
		name: 'electric_yes_date',
		tagName: 'diagnostic.electric_yes_date',
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Commentaire :',
		name: 'electric_yes_comments',
		tagName: 'diagnostic.electric_yes_comments',
		visible: [{ field: 'electric', value: ['3'], condition: 'EQUAL' }],
	},

	{
		//Gaz
		type: 'radio',
		name: 'gaz',
		tagName: 'diagnostic.gaz',
		validation: {
			validate: {
				requiredField,
			},
		},
		className: 'w-full sm:w-1/2 ',
		title: 'Gaz :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		title: 'Date :',
		validation: {
			validate: {
				requiredField,
			},
		},
		name: 'gaz_yes_date',
		tagName: 'diagnostic.gaz_yes_date',
		visible: [{ field: 'gaz', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Commentaire :',
		validation: {},
		name: 'gaz_yes_comments',
		tagName: 'diagnostic.gaz_yes_comments',
		visible: [{ field: 'gaz', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Plomb
		type: 'radio',
		name: 'plomb',
		tagName: 'diagnostic.plomb',
		className: 'w-full sm:w-1/2 ',
		title: 'Plomb :',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Date :',
		name: 'plomb_yes_date',
		tagName: 'diagnostic.plomb_yes_date',
		visible: [{ field: 'plomb', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {},
		title: 'Commentaire :',
		name: 'plomb_yes_comments',
		tagName: 'diagnostic.plomb_yes_comments',
		visible: [{ field: 'plomb', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Loi Carrez
		type: 'radio',
		name: 'loi_carrez',
		tagName: 'diagnostic.loi_carrez',
		className: 'w-full sm:w-1/2 ',
		title: 'Loi Carrez :',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Date :',
		name: 'loi_carrez_yes_date',
		tagName: 'diagnostic.loi_carrez_yes_date',
		visible: [{ field: 'loi_carrez', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Commentaire :',
		validation: {},
		name: 'loi_carrez_yes_comments',
		tagName: 'diagnostic.loi_carrez_yes_comments',
		visible: [{ field: 'loi_carrez', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//ERP
		type: 'radio',
		validation: {
			validate: {
				requiredField,
			},
		},
		name: 'erp',
		tagName: 'diagnostic.erp',
		className: 'w-full sm:w-1/2 ',
		title: 'ERP :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},

	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		title: 'Date :',
		validation: {
			validate: {
				requiredField,
			},
		},
		name: 'erp_yes_date',
		tagName: 'diagnostic.erp_yes_date',
		visible: [{ field: 'erp', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {},
		title: 'Commentaire :',
		name: 'erp_yes_comments',
		tagName: 'diagnostic.erp_yes_comments',
		visible: [{ field: 'erp', value: ['3'], condition: 'EQUAL' }],
	},

	{
		//Etat parasitaire
		type: 'radio',
		name: 'state_parasitaire',
		tagName: 'diagnostic.state_parasitaire',
		className: 'w-full sm:w-1/2 ',
		title: 'Etat parasitaire :',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		validation: {
			validate: {
				requiredField,
			},
		},
		type: 'date',
		title: 'Date :',
		name: 'state_parasitaire_yes_date',
		tagName: 'diagnostic.state_parasitaire_yes_date',
		visible: [{ field: 'state_parasite', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		validation: {},
		type: 'text',
		title: 'Commentaire :',
		name: 'state_parasitaire_yes_comments',
		tagName: 'diagnostic.state_parasitaire_yes_comments',
		visible: [{ field: 'state_parasite', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Assainissement
		type: 'radio',
		name: 'assainissement',
		tagName: 'diagnostic.assainissement',
		validation: {
			validate: {
				requiredField,
			},
		},
		className: 'w-full sm:w-1/2 ',
		title: 'Assainissement :',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_offer_suite_id', value: ['1', '2'], condition: 'EQUAL' },
			{ field: 'type_estate_id', value: ['1', '2', '4'], condition: 'EQUAL' },
			{ field: 'dpes', value: ['Bien soumis au DPE'] },
		],
	},
	{
		//date
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'date',
		title: 'Date :',
		validation: {
			validate: {
				requiredField,
			},
		},
		name: 'assainissement_yes_date',
		tagName: 'diagnostic.assainissement_yes_date',
		visible: [{ field: 'assainissement', value: ['3'], condition: 'EQUAL' }],
	},
	{
		//Commentaire
		group: 'info_personnelle',
		visible: [{ field: 'assainissement', value: ['3'], condition: 'EQUAL' }],
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {},
		title: 'Commentaire :',
		name: 'assainissement_yes_comments',
		tagName: 'diagnostic.assainissement_yes_comments',
	},
];
