import { useEffect, useState } from 'react';
import './../../styles/suivi.css';
import BienService from '../../service/bien/BienService';
import { FilterSection } from '../../components/generals/biens/section/filter.section';
import { BienSection } from '../../components/generals/biens/section/bien.section';
import { Spinner } from '../../components/commons/Spinner';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../components/commons/pagination';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { useSuiviDossier } from '../../service/suivi/useSuiviDossier';
import { useParams } from 'react-router-dom';

export const SuiviDossier = () => {
	const navigate = useNavigate();
	const [biens, setBiens] = useState();
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);
	const { filter } = useParams();

	const handleChangePage = (pageNumber) => setCurrentPage(pageNumber);
	useEffect(() => {
		fetchBiens();
	}, [currentPage, filter, search]);

	const fetchBiens = (page) => {
		setLoading(true);

		const params = Object.entries(search)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');
		const filterParams = filter ? `filter=${filter}` : '';
		useSuiviDossier
			.getBiens(page, filterParams, params)
			.then((response) => {
				setBiens(response.data.data);
				setPagination({
					links: response.data.links,
					per_page: response.data.per_page,
					total: response.data.total,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleFilterChange = (filterValue) => {
		setSearch(filterValue);
	};
	return (
		biens && (
			<>
				<div className='solidBorder mb-4'>
					<div className='title-text blue-color my-2'>
						MES SUIVIS DE DOSSIER
					</div>
					<div className='title-text blue-color'>
						Accès vente / Location / Location saisonnière
					</div>
				</div>

				<div className='main-suivi'>
					<div className='flex flex-wrap justify-between border-2 border-solid border-black p-2 min-h-[200px]'>
						<FilterSection onFilterChange={handleFilterChange} />
						<div className='w-full sm:w-full border-[1px] border-solid border-black m-1 p-4'>
							{biens &&
								biens.length !== 0 &&
								biens.map((bien, index) => {
									return (
										<BienSection
											key={index}
											bien={bien}
											link={`/mes-suivis/details/`}
										/>
									);
								})}
							{loading ? (
								<div className='flex items-center justify-center w-full'>
									<ButtonSpinner dimension='w-24 h-24' showLoading={loading} />
								</div>
							) : (
								biens.length === 0 && (
									<div className='flex flex-wrap justify-center h-full items-center'>
										<h1 className='text-4xl'>Aucun bien n'est disponible</h1>
									</div>
								)
							)}
							{biens.length !== 0 &&
								pagination.links &&
								pagination.links.length !== 0 && (
									<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
										{pagination.links
											.slice(1, pagination.links.length)
											.slice(0, -1)
											.map((link, index) => {
												return (
													<Pagination
														changePage={handleChangePage}
														key={index}
														link={link}></Pagination>
												);
											})}
									</div>
								)}
						</div>
					</div>
				</div>
			</>
		)
	);
};
