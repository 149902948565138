import { useEffect, useState } from 'react';
import {
	imageBienCouverture,
	imageBienSlide,
	slideImageFromProxy,
} from '../../../../../helpers/image.source.';

export const FileInputUpdate = ({
	element,
	register,
	style,
	control,
	dataUpdate,
}) => {
	const previewImage = (event) => {
		const output = document.getElementById('output-' + element.name);
		const container = document.getElementById(`container-${element.name}`);
		container.style.display = 'flex';
		output.src = URL.createObjectURL(event.target.files[0]);
		output.onload = function () {
			URL.revokeObjectURL(output.src); // free memory
		};
	};

	const resetInputValue = () => {
		const input = document.getElementById(element.name);
		input.value = '';
	};

	useEffect(() => {
		resetInputValue();
	}, [dataUpdate]);
	useEffect(() => {
		const fetchImages = async () => {
			if (dataUpdate.photos.photos_couvert) {
				if (element.name === 'photos_original') {
					// Vérifiez si c'est l'image de couverture
					const imageUrlCouverture = await imageBienCouverture(
						dataUpdate.photos.photos_couvert[0].photo
					);

					const output = document.getElementById(`output-${element.name}`);
					const container = document.getElementById(
						`container-${element.name}`
					);
					container.style.display = 'flex';
					output.src = imageUrlCouverture;
				}
			}
			if (dataUpdate.photos.photos_slide) {
				dataUpdate.photos.photos_slide.forEach(async (photoObj, index) => {
					if (element.name === `photos_slide${index + 1}`) {
						// Vérifiez si c'est une image de slide
						const imageUrlSlide = await imageBienSlide(photoObj[`photo`]);
						// Utilisez imageUrlSlide pour afficher l'image de slide dans l'input correspondant
						const output = document.getElementById(`output-${element.name}`);
						const container = document.getElementById(
							`container-${element.name}`
						);
						container.style.display = 'flex';
						output.src = imageUrlSlide;
					}
				});
			}
		};

		fetchImages();
	}, []);

	const removeUrl = () => {
		const output = document.getElementById('output-' + element.name);
		const input = document.getElementById(element.name);
		const container = document.getElementById(`container-${element.name}`);
		input.value = '';
		output.src = '';
		container.style.display = 'none';
	};

	return (
		<div className='w-full'>
			<input
				type='file'
				name={element.name}
				id={element.name}
				{...register(element.name, {
					onChange: previewImage,
				})}
			/>

			<div
				style={{ display: 'none' }}
				id={`container-${element.name}`}
				className='flex items-start gap-x-2'>
				<img
					id={`output-${element.name}`}
					alt='Preview'
					src=''
					className='w-24 h-24'
				/>
				<i className='fa fa-close cursor-pointer' onClick={removeUrl}></i>
			</div>
		</div>
	);
};
