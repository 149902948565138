import { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { SectionPige } from '../generals/pige/section';
import { formatter } from '../../helpers/formatter';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const LAYER = [
	{ value: `mapbox://styles/mapbox/standard`, label: 'Standard' },
	{ value: `mapbox://styles/mapbox/streets-v12`, label: 'Street V12' },
	{ value: `mapbox://styles/mapbox/outdoors-v12`, label: 'Outdoors V12' },
	{ value: `mapbox://styles/mapbox/light-v11`, label: 'Light V11' },
	{ value: `mapbox://styles/mapbox/dark-v11`, label: 'Dark V11' },
	{ value: `mapbox://styles/mapbox/satellite-v9`, label: 'Satellite V9' },
	{
		value: `mapbox://styles/mapbox/satellite-streets-v12`,
		label: 'Satellite Streets V12',
	},
	{
		value: `mapbox://styles/mapbox/navigation-day-v1`,
		label: 'Navigation Day V1',
	},
	{
		value: `mapbox://styles/mapbox/navigation-night-v1`,
		label: 'Navigation Night V1',
	},
];

export const Mapbox = ({
	longitude,
	latitude,
	correspondants = [],
	defaulLayer = 0,
	showLayer = true,
}) => {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [zoom, setZoom] = useState(12);
	const [showAlentours, setShowAlentours] = useState(false);
	const [layer, setLayer] = useState(LAYER[defaulLayer].value);

	useEffect(() => {
		if (longitude && latitude) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: layer,
				center: [longitude, latitude],
				zoom: zoom,
			});

			new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map.current);
			correspondants.map((item, index) => {
				const div = document.createElement('div');
				div.innerHTML =
					'<div class="blue-color text-white rounded p-2 font-bold"><i class="fa-solid fa-location-dot mr-2"></i>' +
					parseInt(index + 1) +
					'</div>';
				return new mapboxgl.Marker(div)
					.setLngLat([item.longitude, item.latitude])
					.addTo(map.current);
			});
		}
	}, [layer, longitude, latitude]);
	return (
		<div>
			{(longitude && latitude && (
				<>
					<div className='w-full h-[600px] mb-4'>
						<div
							ref={mapContainer}
							className='map-container h-full rounded w-full'
						/>
					</div>{' '}
					<div className='w-full my-4'>
						{showLayer && (
							<div className='w-full my-4'>
								<h1>Changer de layer pour la géolocalisation</h1>
								<select
									onChange={(e) => setLayer(e.target.value)}
									defaultValue={layer}
									className='h-12 rounded p-2 w-full sm:w-1/4 mt-2'>
									{LAYER.map((layerOption, index) => (
										<option key={index} value={layerOption.value}>
											{layerOption.label}
										</option>
									))}
								</select>
							</div>
						)}
						{correspondants.length != 0 && (
							<SectionPige title={'Biens aux alentours'}>
								<div className='flex flex-wrap justify-center'>
									<i
										className={`fa ${
											showAlentours ? 'fa-chevron-up' : 'fa-chevron-down'
										} font-bold text-black cursor-pointer`}
										onClick={() => setShowAlentours(!showAlentours)}></i>
								</div>
								<div className={`${showAlentours ? '' : 'hidden'}`}>
									<ul className='px-4'>
										{correspondants.map((item, index) => {
											return (
												<div
													className='flex items-center gap-2 my-2 text-black/80 p-4 w-full border-2 border-solid border-black/20 cursor-pointer'
													onClick={() =>
														window.location.replace(
															'/ma-pige/details/' + item.id
														)
													}
													key={index}>
													<span className='blue-color p-4 text-white font-bold rounded-md'>
														{parseInt(index + 1)}
													</span>
													<h1 className='capitalize font-bold'>
														{item.bien} en {item.type} - {item.adresse}
														<br />
														{item.surface}m<sup>2</sup> - {item.pieces} pièce
														{item.pieces > 1 ? 's' : ''} -{' '}
														{formatter.format(item.prix)}
													</h1>
												</div>
											);
										})}
									</ul>
								</div>
							</SectionPige>
						)}
					</div>
				</>
			)) || (
				<div className='blue-color text-white p-4'>
					Impossible de géolocaliser votre bien en l'absence des coordonnées
					GPS.
				</div>
			)}
		</div>
	);
};
