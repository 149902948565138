import { createHttp } from '../../http/index';
import { getStorageUser } from '../localStorage';

const http = createHttp();

export const CalendarService = {
	getAllEvents: async () => http.get('/calendar'),
	getAuthUrl: async () =>
		http.post('/google/data', { agency_id: getStorageUser().user.agency_id }),
	removeEvent: async (id) => http.delete('/google/remove/' + id),
	createEvent: async (data) => http.post('/calendar/create-event', data),
};
