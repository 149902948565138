import React from 'react';

const PopUp = ({ isPopUpVisible, popUpRef, children }) => {
    return (
        <>
            {isPopUpVisible && (
                <div ref={popUpRef} className="fixed sm:absolute top-0 right-0 bg-white border border-gray-200 p-4 rounded shadow">
                    {children}
                </div>
            )}
        </>
    );
};

export default PopUp;
