import React, { useState, useEffect } from 'react';
import { useContact } from '../../../../service/contact/useContact';
import { useAgent } from '../../../../service/agency/useAgent';

export const QuickPopUp = ({ contact, reload }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeStatus, setActiveStatus] = useState(
		contact.publish === 'active'
	);
	const [ agents, setAgents ] = useState([]);

	const fetchDataAgent = async() => {
		try {
		    const response = await useAgent.getAllAgents()
			setAgents(response.data.user)
		}  catch (error) {
			console.error(
				`Erreur de la recuperation de l'agent`
			);
		} finally {
			reload();
		}
	}
	useEffect(() => {
		setActiveStatus(contact.publish === 'active');
	}, [contact.publish]);
	useEffect(() => {
		fetchDataAgent();
	}, [])
	const handleActionClick = async (actionType) => {
		setIsLoading(true);
		try {
			await useContact.activeAndArchiveContact(contact.id_contact, actionType);

			setActiveStatus(actionType === 'active');
		} catch (error) {
			console.error(
				`Erreur lors de l'${
					actionType === 'active' ? 'activation' : 'archivage'
				} du contact :`,
				error
			);
		} finally {
			setIsLoading(false);
			reload();
		}
	};

	const negociation = async(selectedOption) => {
		setIsLoading(true);
		try {
			await useContact.negociationContact(contact.id_contact, selectedOption).then((res) => {
				setIsLoading(false);
			reload();
			})
		} catch (error) {
			console.error('error du negoctiation', error);
		}finally {
			reload();
		}
	};

	return (
		<>
			<button
				className={`px-4 rounded-l-full py-2 ${
					activeStatus ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'
				}`}
				onClick={() => handleActionClick('active')}
				disabled={isLoading || activeStatus}>
				{isLoading ? 'Chargement...' : 'Active'}
			</button>
			<button
				className={`px-4 py-2 ${
					!activeStatus
						? 'bg-green-500 text-white'
						: 'bg-gray-300 text-gray-700'
				} rounded-r-full`}
				onClick={() => handleActionClick('archive')}
				disabled={isLoading || !activeStatus}>
				{isLoading ? 'Chargement...' : 'Archivé'}
			</button>
			<br />
			{agents.length === 0 && <p>Aucun agent n'est disponible pour le moment.</p>}
            {agents.length > 0 && (
                <select className="mt-2 rounded-md w-full" onChange={(e) => negociation(e.target.value)}>
					<option value="" disabled>Choisir un agent</option>
                    {agents.map((agent) => (
                        <option key={agent.id} value={agent.id} disabled={contact.user_id === agent.id}>
                            {agent.name}
                        </option>
                    ))}
                </select>
            )}
		</>
	);
};
