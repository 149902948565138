import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { reset } from '../../service/auth/authentication';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';

export const ChangePassword = () => {
	const [params] = useSearchParams();
	const [password, setPassword] = useState('');
	const [userValide, setUserValide] = useState(false);
	const [passwordError, setPasswordError] = useState(null);
	const [showLoading, setShowLoading] = useState(false);
	const user = params.get('params');

	const message =
		'Le mot de passe doit contenir au moins 1 miniscule a-z, 1 majuscule A-Z, un chiffre 0-9, un caractère spécial et au moins 8 caractères';
	const validationPassword = (v) => {
		const regex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

		return !regex.test(v) ? false : true;
	};

	const [submited, setSubmited] = useState(false);
	const submitForm = async (e) => {
		setShowLoading(true);
		e.preventDefault();
		const password = e.target.elements[0].value;
		if (validationPassword(password)) {
			setPasswordError(null);
			await reset({ password, email: user })
				.then(() => setSubmited(true))
				.catch(() => {});
		} else {
			setPasswordError(message);
		}
		setShowLoading(false);
	};

	useEffect(() => {
		if (user) {
			setUserValide(true);
		}
	}, []);
	return (
		<>
			<div className='flex items-center justify-around gap-1 flex-wrap pt-20'>
				<div className='basis-full lg:basis-1/2'>
					<div className='flex flex-col w-full mx-auto gap-6 p-4 lg:p-20 bg-slate-200 rounded-lg justify-center'>
						{(userValide && (
							<>
								{!submited && (
									<div>
										<img
											src='/favicon.ico'
											className='w-1/4 mx-auto rounded-full'
											alt='Logo'
										/>
										<p className='my-4 text-center'>
											Modifier votre mot de passe
										</p>
										<form action='' onSubmit={submitForm}>
											<input
												type='password'
												name=''
												onChange={(e) => setPassword(e.target.value)}
												required
												className='w-full border-[2px] border-solid p-2 rounded-xl outline-none focus:border-[#104278]'
											/>
											{passwordError && (
												<span className='text-red-600'>
													<small>{passwordError}</small>
												</span>
											)}
											<button className='w-full text-center mt-2 default-color py-2 rounded-xl text-white flex justify-center gap-2 items-center'>
												CHANGER LE MOT DE PASSE
												<ButtonSpinner showLoading={showLoading} />
											</button>
										</form>
									</div>
								)}
								{submited && (
									<div className='flex flex-wrap justify-center'>
										<i className='fa-6x fa-solid fa-circle-check text-green-500'></i>
										<p className='text-center my-4 text-2xl w-full'>
											Votre mot de passe a été changé avec succès.
										</p>
										<button
											onClick={() => (window.location.href = '/login')}
											className='w-full text-center mt-2 default-color py-2 rounded-xl text-white flex justify-center gap-2 items-center'
											type='button'>
											Revenir à la page de connexion
										</button>
									</div>
								)}
							</>
						)) || (
							<>
								<p className='text-center w-full'>Le lien est erroné</p>
								<button
									onClick={() => (window.location.href = '/login')}
									className='w-full text-center mt-2 default-color py-2 rounded-xl text-white flex justify-center gap-2 items-center'
									type='button'>
									Revenir à la page de connexion
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
