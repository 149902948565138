import Api from '../api/api.js';
import { createHttp } from '../../http';

const http = createHttp();
const BienService = {
    listBien: async (page, filter, params) => {
        const url = page
            ? `/bien?page=${page}&${filter}&${params}`
            : `/bien?${filter}&${params}`; 
        return await http.get(url).catch(() => {});
    },
	getBien(bien) {
		return http.get(Api.bien.getById(bien.id));
	},
	getBienById(bienId) {
		return http.get(Api.bien.getById(bienId));
	},
};

export default BienService;
