export const requiredField = (value) => {
	return value === '' || value === undefined ? 'Le champ est requis' : true;
};
export const greaterThanZero = (value) => {
	return value <= 0 ? 'La valeur doit être supérieur ou égale à 0' : true;
};

export const lessThanHundred = (value) => {
	return value > 100 ? 'La valeur doit être inférieur ou égale à 100%' : true;
};

export const validateEmail = (value) => {
	if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
		return "L'adresse email est invalide";
	}
	return true;
};
