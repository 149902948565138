import { Mapbox } from '../../../components/commons/mapbox';

export const GeolocalisationSection = ({ pige }) => {
	return (
		<div className='my-4'>
			<h1 className='text-2xl font-bold mb-4'>
				Géolocalisation du bien et prix de vente aux alentours
			</h1>
			<Mapbox
				longitude={pige.longitude}
				latitude={pige.latitude}
				correspondants={pige.correspondants}
			/>
		</div>
	);
};
