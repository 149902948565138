import {
	coverImageFromProxy,
	slideImageFromProxy,
} from '../../../../helpers/image.source.';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';

export const Model5 = ({ bien }) => {
	console.log(bien);
	return (
		<>
			<div className='flex justify-between font-bold'>
				<div className='w-1/4 uppercase'>Statut : {bien.solds}</div>
				<div className='w-1/4 text-right'>
					<Pricing bien={bien} />
				</div>
			</div>
			<div className='flex justify-between font-bold mt-10'>
				<div className='w-full'>Annonce : {bien.advertisement.title}</div>
			</div>
			<div className='grid grid-cols-4 grid-rows-3 gap-2 my-4'>
				<div
					className={`border-2 border-solid col-span-3 row-span-2 flex items-center justify-center`}>
					<img
						src={coverImageFromProxy(bien.photos.photos_couvert[0].photo)}
						alt='Cover'
						className={`w-full`}
					/>
				</div>
				<div className='flex justify-between font-bold mt-10 col-span-1 row-span-2'>
					<div className='w-full break-all'>
						{bien.advertisement.description}
					</div>
				</div>

				{bien.photos.photos_slide &&
					[0, 1, 2].map((item, index) => (
						<div
							className='border-2 border-solid col-span-1 row-span-1 flex items-center justify-center'
							key={index}>
							{bien.photos.photos_slide[index] && (
								<img
									src={slideImageFromProxy(
										bien.photos.photos_slide[index].photo
									)}
									alt={'Slide ' + index}
									className='w-1/2'
								/>
							)}
						</div>
					))}
				<div className='border-2 border-solid col-span-1 row-span-1 flex items-center justify-center'>
					<Dpe bien={bien} />
				</div>
			</div>
		</>
	);
};
