import React from 'react';

export const CardHome = ({
	children,
	rowStart = 1,
	colStart = 1,
	className,
	photo = null,
	color = 'white',
}) => {
	const colSpanArray = {
		1: 'lg:col-span-1',
		2: 'lg:col-span-2',
		3: 'lg:col-span-3',
		4: 'lg:col-span-4',
	};
	const rowSpanArray = {
		1: 'lg:row-span-1',
		2: 'lg:row-span-2',
		3: 'lg:row-span-3',
		4: 'lg:row-span-4',
	};
	return (
		<div
			className={`text-${color} relative text-center ${rowSpanArray[rowStart]} ${colSpanArray[colStart]} cursor-pointer sm:col-span-full sm:row-span-2 rounded-md`}>
			{(photo && (
				<>
					<div
						className='absolute top-0 left-0 w-full h-full rounded-md bg-cover bg-center'
						style={{ backgroundImage: `url('${photo}')` }}></div>
					<div className={`${className} h-full  opacity-90 rounded-md`}>
						{children}
					</div>
				</>
			)) || (
				<div className={`${className} h-full  opacity-90 rounded-md`}>
					{children}
				</div>
			)}
		</div>
	);
};
