import { createHttp } from '../../http';

const http = createHttp();

export const useSuiviDossier = {
	updateOrRemoveStep: async (item, action) =>
		http.get(`/bien/folder/step/${action}/${item.id}`),
	createStep: async (item) => http.post(`/bien/folder/step`, item),
	updateStep: async (item) => http.patch(`/bien/folder/step`, item),
	validateStep: async (item) => http.post(`/bien/folder/step/validate`, item),
	getBiens: async (page, filter, params) => {
		const url = page
			? `/bien/folder/list?page=${page}&${filter}&${params}`
			: `/bien/folder/list?${filter}&${params}`;
		return await http.get(url).catch(() => {});
	},
	createFolder: async (data) => {
		return await http.post('/bien/folder/register', data);
	},
};
