import { createHttp } from '../../http';

const http = createHttp();

export const useContact = {
	createContact: async (contact) => await http.post('/contact', contact),
	allContact: async (page, filter, params) => {
		const url = page
			? `/contact?page=${page}&${filter}&${params}`
			: `/contact?${filter}&${params}`;
		return await http.get(url).catch(() => {});
	},
	contactForBien: async() => await http.get('/contact/free'),
	activeAndArchiveContact: async (id, publish) =>
		await http.put(`/contact/activation-archive/${id}`, { publish }),
	updateContact: async (uuid, data) =>
		await http.post(`/contact/update/${uuid}`, data),
	negociationContact: async (id, user_id) =>
		await http.put(`/contact/negociation-contact/${id}`, { user_id }),
	getContactByUuid: async (uuid) => await http.get(`/contact/${uuid}`),
};
