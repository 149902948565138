import { TYPE_BIEN } from '../../type';

export const ExteriorDetail = [
	{
		//Mitoyenneté
		group: 'info_personnelle',
		className: 'w-full',
		type: 'select',
		title: 'Mitoyenneté :',
		name: 'semiOwnership',
		tagName: 'exterior_detail.semiOwnership',
		choices: [
			{
				title: 'Indépendant',
				value: 'Indépendant',
			},
			{
				title: '1 côté',
				value: '1 côté',
			},
			{
				title: '2 côtés',
				value: '2 côtés',
			},
			{
				title: '3 côtés',
				value: '3 côtés',
			},
			{
				title: '4 côtés',
				value: '4 côtés',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Etages
		type: 'number',
		title: 'Etages :',
		className: 'w-full',
		name: 'floor',
		tagName: 'exterior_detail.floor',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Sous sol
		group: 'info_personnelle',
		className: 'w-full',
		type: 'select',
		title: 'Sous sol :',
		name: 'basement',
		tagName: 'exterior_detail.basement',
		choices: [
			{
				title: 'Non précisé',
				value: 'Non précisé',
			},
			{
				title: 'Sans',
				value: 'Sans',
			},
			{
				title: 'Avec',
				value: 'Avec',
			},
			{
				title: 'Aménagé',
				value: 'Aménagé',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Surface Sous-sol si avec ou aménagé
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface :',
		name: 'withbasements',
		tagName: 'exterior_detail.withbasements',
		visible: [
			{ condition: 'EQUAL', field: 'basement', value: ['Avec', 'Aménagé'] },
			{
				condition: 'INEQUAL',
				field: TYPE_BIEN.formName,
				value: [TYPE_BIEN.TERRAIN],
			},
		],
	},
	{
		//Cave
		type: 'radio',
		name: 'cellar',
		className: 'w-full sm:w-1/2 ',
		title: 'Cave:',
		tagName: 'exterior_detail.cellar',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface :',
		name: 'trueCellar',
		tagName: 'exterior_detail.trueCellar',
		visible: [{ condition: 'EQUAL', field: 'cellar', value: ['3'] }],
	},
	{
		//Balcon
		type: 'radio',
		name: 'balcony',
		className: 'w-full sm:w-1/2',
		title: ' Balcon:',
		tagName: 'exterior_detail.balcony',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface :',
		tagName: 'exterior_detail.withBalcony',
		name: 'withBalcony',
		visible: [{ condition: 'EQUAL', field: 'balcony', value: ['3'] }],
	},
	{
		//Terrasse
		type: 'radio',
		name: 'terrace',
		className: 'w-full sm:w-1/2',
		tagName:'exterior_detail.terrace',
		title: 'Terrasse:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface :',
		tagName: 'exterior_detail.withTerrace',
		name: 'withTerrace',
		visible: [{ condition: 'EQUAL', field: 'terrace', value: ['3'] }],
	},
	{
		//Varangue
		type: 'radio',
		name: 'varangue',
		className: 'w-full sm:w-1/2',
		tagName: 'exterior_detail.varangue',
		title: 'Varangue:',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Loggia
		type: 'radio',
		name: 'loggia',
		className: 'w-full sm:w-1/2',
		title: 'Loggia:',
		tagName: 'exterior_detail.loggia',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Veranda
		type: 'radio',
		name: 'veranda',
		className: 'w-full sm:w-1/2',
		title: 'Veranda:',
		tagName: 'exterior_detail.veranda',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Surface
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Surface :',
		tagName: 'exterior_detail.withVeranda',
		name: 'withVeranda',
		visible: [{ condition: 'EQUAL', field: 'veranda', value: ['3'] }],
	},
	{
		//Plain Pied
		type: 'radio',
		name: 'singleStorey',
		className: 'w-full sm:w-1/2',
		title: 'Plain Pied:',
		tagName: 'exterior_detail.singleStorey',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Residence
		group: 'info_personnelle',
		className: 'w-full sm:w-1/6 items-center',
		title: 'Résidence',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Format
		group: 'info_personnelle',
		className: 'w-full sm:w-2/6 ',
		type: 'select',
		title: 'Format :',
		name: 'formatResidence',
		tagName: 'exterior_detail.formatResidence',
		choices: [
			{
				title: 'Non Précisé',
				value: 'Non Précisé',
			},
			{
				title: 'Ouverte',
				value: 'Ouverte',
			},
			{
				title: 'Fermée',
				value: 'Fermée',
			},
			{
				title: 'Sécurisée',
				value: 'Sécurisée',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Type
		group: 'info_personnelle',
		className: 'w-full sm:w-2/6',
		type: 'select',
		title: 'Type :',
		name: 'typeResidence',
		tagName: 'exterior_detail.typeResidence',
		choices: [
			{
				title: 'Non Précisé',
				value: 'Non Précisé',
			},
			{
				title: 'Ancien',
				value: 'Ancien',
			},
			{
				title: 'Neuf',
				value: 'Neuf',
			},
			{
				title: 'Standing',
				value: 'Standing',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/6',
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['1', '2'] },
			{
				condition: 'EQUAL',
				field: 'type_offer_suite_id',
				value: ['1', '2', '3'],
			},
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Barbecue
		type: 'radio',
		name: 'barbecue',
		className: 'w-full sm:w-1/2',
		title: 'Barbecue:',
		tagName: 'exterior_detail.barbecue',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Jacuzzi
		type: 'radio',
		name: 'jacuzzi',
		className: 'w-full sm:w-1/2',
		title: 'Jacuzzi:',
		tagName: 'exterior_detail.jacuzzi',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Vélos
		type: 'radio',
		name: 'bikes',
		className: 'w-full sm:w-1/2',
		title: 'Vélos:',
		tagName: 'exterior_detail.bikes',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//Tennis
		type: 'radio',
		name: 'tennis',
		className: 'w-full sm:w-1/2',
		title: 'Tennis:',
		tagName: 'exterior_detail.tennis',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
	{
		//cuisine d'été
		type: 'radio',
		name: 'summerKitchen',
		className: 'w-full sm:w-1/2',
		title: "Cuisine d'été:",
		tagName: 'exterior_detail.summerKitchen',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Non',
				value: '2',
			},
			{
				title: 'Oui',
				value: '3',
			},
		],
		visible: [
			{ condition: 'EQUAL', field: 'type_offert_id', value: ['2'] },
			{ condition: 'EQUAL', field: 'type_offer_suite_id', value: ['3'] },
			{ condition: 'EQUAL', field: 'type_estate_id', value: ['1', '2', '4'] },
			{
				condition: 'INEQUAL',
				field: 'criteria',
				value: ['cave', 'garage', 'parking'],
			},
		],
	},
];
