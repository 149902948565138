import toast from 'react-hot-toast';

export const successToast = (message) => {
	toast.success(message);
};

export const errorToast = (message) => {
	toast.error(message);
};

export const technicalErrorToast = () => {
	toast.error(
		"Il y a eu une erreur, veuillez contacter le support du logiciel s'il vous plaît !"
	);
};
