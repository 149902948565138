import { useAgent } from '../../../../service/agency/useAgent';
import { requiredField, validateEmail } from '../../../validation/validation';

const agentOptions = async () => {
	console.log(45);
	try {
		const isAuthUrl = [
			'register',
			'login',
			'reset-password',
			'reset-password-pass',
			'two-factor',
		].includes(
			window.location.href.split('/')[
				window.location.href.split('/').length - 1
			]
		);

		if (!isAuthUrl) {
			const response = await useAgent.getAllAgents();
			const agents = response.data.user;
			return agents.map((agent) => ({
				title: agent.name,
				value: agent.id,
			}));
		}

		return [];
	} catch (error) {
		console.log(error);
	}
};

export const InfosPrincipale = [
	{
		type: 'radio',
		name: 'contact_type',
		className: 'w-full ',
		title: 'Type de contact :',
		choices: [
			{
				title: 'Propriétaire',
				value: 'owner',
				checked: true,
			},
			{
				title: 'Acquéreur',
				value: 'buyer',
			},
			{
				title: 'Locataire',
				value: 'tenant',
			},
		],
	},

	{
		type: 'radio',
		name: 'target',
		className: 'w-full ',
		title: 'Type de personne',
		choices: [
			{
				title: 'Personne seule',
				value: 'single person',
				checked: true,
			},
			{
				title: 'Couple',
				value: 'couple',
			},
			{
				title: 'Personne morale',
				value: 'corporation',
			},
		],
	},
	{
		group: 'info_personnelle',
		type: 'select',
		name: 'civility',
		tagName: 'civility',
		className: 'w-full sm:w-1/2',
		title: 'Civilité :',
		visible: [
			{ field: 'target', condition: 'INEQUAL', value: ['corporation'] },
		],
		choices: [
			{
				title: 'Monsieur',
				value: 'sir',
				visible: [
					{
						field: 'target',
						value: ['single person'],
					},
				],
			},
			{
				title: 'Madame',
				value: 'madam',
				visible: [
					{
						field: 'target',
						value: ['single person'],
					},
				],
			},
			{
				title: 'Monsieur-Madame',
				value: 'sir/madam',
				visible: [
					{
						field: 'target',
						value: ['couple'],
					},
				],
			},
			{
				title: 'Monsieur-Monsieur',
				value: 'sir/sir',
				visible: [
					{
						field: 'target',
						value: ['couple'],
					},
				],
			},
			{
				title: 'Madame-Madame',
				value: 'madam/madam',
				visible: [
					{
						field: 'target',
						value: ['couple'],
					},
				],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',

		title: 'Nom :',
		name: 'lastname',
		visible: [
			{
				field: 'target',
				value: 'single person',
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {
			validate: { requiredField },
		},
		title: 'Prénoms :',
		name: 'firstname',
		visible: [
			{
				field: 'target',
				value: ['single person'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Télephone :',
		name: 'phone',
		visible: [
			{
				field: 'target',
				value: 'single person',
			},
		],
		validation: {
			validate: { requiredField },
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'email',

		validation: {
			validate: {
				requiredField,
				validateEmail,
			},
		},
		title: 'Mail :',
		name: 'mail',
		visible: [
			{
				field: 'target',
				value: ['single person'],
			},
		],
	},

	{
		group: 'info_personnelle',
		type: 'select',
		name: 'contact_source',
		className: 'w-full sm:w-1/2',
		title: 'Source de contact :',
		choices: [
			{
				title: 'Site web',
				value: 'web',
			},
			{
				title: 'Agence',
				value: 'agence',
			},
			{
				title: 'Bouche à oreille',
				value: 'bouche',
			},
			{
				title: 'Publicité',
				value: 'pub',
			},
			{
				title: 'Autre',
				value: 'other',
			},
		],

		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{
				field: 'target',
				value: ['single person'],
			},
		],
	},

	///COUPLE
	//HOMME

	//HOMMME
	{
		className: 'w-1/2',
	},
	{
		title: "L'un(e)",
		className: 'w-full font-bold',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Nom :',
		tagName: 'man_info.lastname',
		name: 'manLastname',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Prénoms :',
		tagName: 'man_info.firstname',
		name: 'manFirstname',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Télephone :',
		tagName: 'man_info.phone',
		name: 'manPhonenumber',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'email',
		validation: {
			validate: {
				requiredField,
				validateEmail,
			},
		},
		title: 'Mail :',
		tagName: 'man_info.mail',
		name: 'manEmail',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},

	///FEMME
	{
		title: " L'autre",
		className: 'w-full font-bold',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Nom :',
		tagName: 'woman_info.firstname',
		name: 'womanFirstname',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		validation: {
			requiredField,
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		title: 'Prénoms :',
		name: 'womanLastname',
		tagName: 'woman_info.lastname',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		title: 'Télephone :',
		name: 'womanPhonenumber',
		tagName: 'woman_info.phone',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
		validation: {
			validate: {
				requiredField,
			},
		},
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'email',
		tagName: 'woman_info.mail',
		validation: {
			validate: {
				requiredField,
				validateEmail,
			},
		},
		title: 'Mail :',
		name: 'womanEmail',
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},

	{
		group: 'info_personnelle',
		type: 'select',
		name: 'contact_source',
		className: 'w-full sm:w-1/2',
		title: 'Source de contact :',
		choices: [
			{
				title: 'Site web',
				value: 'web',
			},
			{
				title: 'Agence',
				value: 'agence',
			},
			{
				title: 'Bouche à oreille',
				value: 'bouche',
			},
			{
				title: 'Publicité',
				value: 'pub',
			},
			{
				title: 'Autre',
				value: 'other',
			},
		],

		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{
				field: 'target',
				value: ['couple'],
			},
		],
	},

	///PERSONNE MORALE
	{
		group: 'info_personnelle',
		type: 'select',
		name: 'legal_form',
		className: 'w-full sm:w-1/2',
		title: 'Forme juridique :',
		choices: [
			{
				title: 'EI',
				value: 'ei',
			},
			{
				title: 'EURL',
				value: 'eurl',
			},
			{
				title: 'SARL',
				value: 'sarl',
			},
			{
				title: 'SASU',
				value: 'sasu',
			},
			{
				title: 'SAS',
				value: 'sas',
			},
			{
				title: 'SA',
				value: 'sa',
			},
			{
				title: 'SNC',
				value: 'snc',
			},
			{
				title: 'SCS',
				value: 'scs',
			},
			{
				title: 'SCA',
				value: 'sca',
			},
			{
				title: 'Autre',
				value: 'autre',
			},
		],
		visible: [
			{
				field: 'target',
				value: ['corporation'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Raison sociale :',
		name: 'company_name',
		visible: [
			{
				field: 'target',
				value: ['corporation'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'text',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'SIRET :',
		name: 'siret',
		visible: [
			{
				field: 'target',
				value: ['corporation'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'number',
		validation: {
			validate: {
				requiredField,
			},
		},
		title: 'Téléphone :',
		name: 'phone',
		visible: [
			{
				field: 'target',
				value: ['corporation'],
			},
		],
	},
	{
		group: 'info_personnelle',
		className: 'w-full sm:w-1/2',
		type: 'email',
		validation: {
			validate: {
				requiredField,
				validateEmail,
			},
		},
		title: 'Mail :',
		name: 'mail',
		visible: [
			{
				field: 'target',
				value: ['corporation'],
			},
		],
	},

	{
		group: 'info_personnelle',
		type: 'select',
		name: 'negociator',
		tagName: 'user_id',
		className: 'w-full sm:w-1/2',
		title: 'Negociateur :',
		onInit: async (element) => {
			element.choices = [...(await agentOptions())];
			return element;
		},
	},
	{
		group: 'info_personnelle',
		type: 'select',
		name: 'contact_source',
		className: 'w-full sm:w-1/2',
		title: 'Source de contact :',
		choices: [
			{
				title: 'Site web',
				value: 'web',
			},
			{
				title: 'Agence',
				value: 'agence',
			},
			{
				title: 'Bouche à oreille',
				value: 'bouche',
			},
			{
				title: 'Publicité',
				value: 'pub',
			},
			{
				title: 'Autre',
				value: 'other',
			},
		],

		validation: {
			validate: {
				requiredField,
			},
		},
		visible: [
			{
				field: 'target',
				value: ['corporation'],
			},
		],
	},
];
