import { useEffect, useState } from 'react';
import {
	coverImageFromProxy,
	imageFromProxy,
} from '../../../../helpers/image.source.';
import { getStorageUser } from '../../../../service/localStorage';

export const Model1 = ({ bien }) => {
	const [imageBien, setImageBien] = useState();

	useEffect(() => {
		setImageBien(coverImageFromProxy(bien.photos.photos_couvert[0].photo));
	}, []);

	return (
		imageBien && (
			<>
				<div className='flex gap-2 justify-between items-center flex-wrap'>
					<div className='w-full sm:w-1/2'>{bien.advertisement.title}</div>
					<div className='w-full sm:w-1/3 flex gap-2 items-center justify-end'>
						<div className='w-1/2'>
							<ul>
								<li>
									{bien.agency.nameAgency}, {bien.agency.addressCompany}
								</li>
							</ul>
						</div>
						<div className='w-1/4'>
							<img
								src={
									getStorageUser().user.agency.agency_logo
										? imageFromProxy(getStorageUser().user.agency.agency_logo)
										: '/images/LOGO_MAPIM_IMMO_BLANC.jpg'
								}
								alt='Model1 du bien'
							/>
						</div>
					</div>
				</div>
				<div className='w-full flex justify-center mt-5'>
					<img src={imageBien} alt='' />
				</div>
			</>
		)
	);
};
