import React from 'react';
import './input.css';
export const Input = React.forwardRef(
	({ type, name, className, value, focus, reference, ...rest }, ref) => {
		return (
			<input
				type={type}
				autoFocus={focus}
				name={name}
				className={className}
				value={value}
				ref={reference}
				{...rest}
			/>
		);
	}
);
