import { createHttp } from './../../../http/index';
const http = createHttp();

export const configService = {
	getConfiguration: async () => http.get('/agency'),
	postConfiguration: async (postal_code) =>
		http.post('/piges/postal-code', { postal_code }),
	removeConfiguration: async (configuration_id) =>
		http.delete('/piges/postal-code/' + configuration_id),
	isSubscribed: async () =>
		await http.get('/subscription/subscribed').then(({ data }) => data.result),
	subscribe: async (agencyId) =>
		(window.location.href =
			process.env.REACT_APP_BACKEND_URL +
			'/subscription/subscribe?id=' +
			agencyId),
};
