export const DetailsSectionPige = ({ title, pige }) => {
	return (
		<div className='my-4'>
			<h1 className='text-2xl font-bold mb-4'>{title}</h1>
			<div className='p-6 flex flex-wrap justify-center'>
				<div className=' sm:w-1/2md:w-1/3 lg:w-1/3 xl:w-1/4  my-2 w-1/2 flex justify-center items-center gap-2 capitalize'>
					<div className='w-10 h-10 grey-color flex justify-center items-center rounded-full'>
						<i
							className={`fa-solid ${
								pige.bien ? ' text-blue-600 fa-check' : 'text-red-600 fa-xmark'
							} `}></i>
					</div>
					{pige.type}
				</div>
				<div className='sm:w-1/2md:w-1/3 lg:w-1/3 xl:w-1/4  my-2 w-1/2 flex justify-center items-center gap-2 capitalize'>
					<div className='w-10 h-10 grey-color flex justify-center items-center rounded-full'>
						<i
							className={`fa-solid ${
								pige.bien ? 'text-blue-600 fa-check' : 'text-red-600 fa-xmark'
							} `}></i>
					</div>
					{pige.bien}
				</div>
				<div className=' sm:w-1/2md:w-1/3 lg:w-1/3 xl:w-1/4  my-2 w-1/2 flex justify-center items-center gap-2 capitalize'>
					<div className='w-10 h-10 grey-color flex justify-center items-center rounded-full'>
						<i
							className={`fa-solid ${
								pige.meuble ? 'text-blue-600 fa-check' : 'text-red-600 fa-xmark'
							} `}></i>
					</div>
					Meuble
				</div>
				<div className=' sm:w-1/2md:w-1/3 lg:w-1/3 xl:w-1/4  my-2 w-1/2 flex justify-center items-center gap-2 capitalize'>
					<div className='w-10 h-10 grey-color flex justify-center items-center rounded-full'>
						<i
							className={`fa-solid ${
								pige.parking
									? ' text-blue-600 fa-check'
									: 'text-red-600 fa-xmark'
							} `}></i>
					</div>
					Parking
				</div>
			</div>
		</div>
	);
};
