import { useMandate } from '../../../service/mandate/useMandate';
import { requiredField } from '../../validation/validation';

const mandatOptions = async () => {
	try {
		const isAuthUrl = [
			'register',
			'login',
			'reset-password',
			'reset-password-pass',
			'two-factor',
		].includes(
			window.location.href.split('/')[
				window.location.href.split('/').length - 1
			]
		);

		if (!isAuthUrl) {
			const response = await useMandate.allMandate();
			const mandats = response.data;

			mandats.unshift({
				bien_id_bien: 0,
			});
			return mandats.map((mandat, index) => ({
				title: index === 0 ? 'choisir un mandat' : mandat.num_mandat,
				value: mandat.bien_id_bien,
			}));
		}
	} catch (error) {
		console.log(error);
	}
};

export const Mandat = [
	{
		//Ajouter  une mandat
		className: 'w-full sm:w-1/2',
		title: 'Associer à un mandat',
		type: 'select',
		name: 'bien_id_bien',
		onInit: async (element) => {
			element.choices = await mandatOptions();
			return element;
		},
		tagName: 'mandate.bien_id_bien',
	},
	{
		//ajouter un mandat
		group: 'info_personnelle',
		type: 'number',
		name: 'num_mandat',
		className: 'w-full sm:w-1/2',
		tagName: 'mandate.num_mandat',
		title: 'Ajouter un mandat:',
		visible: [{ field: 'bien_id_bien', value: ['0'], condition: 'EQUAL' }],
	},
	{
		//choix
		type: 'select',
		name: 'person',
		className: 'w-full sm:w-1/2 ',
		tagName: 'mandate.person',
		title: 'Choix:',
		choices: [
			{
				title: 'Une personne seule',
				value: 'Une personne seule',
			},
			{
				title: 'Un couple',
				value: 'Un couple',
			},
			{
				title: 'Une personne morale',
				value: 'Une personne morale',
			},
		],

		validation: {
			validate: {
				requiredField,
			},
		},
	},
];
