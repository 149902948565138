import {
	coverImageFromProxy,
	slideImageFromProxy,
} from '../../../../helpers/image.source.';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';

export const Model2 = ({ bien }) => {
	return (
		<>
			<div className='flex justify-between font-bold flex-wrap'>
				<div className='w-1/4 uppercase'>Statut : {bien.solds}</div>
				<div className='w-1/4 text-right'>
					<Pricing bien={bien} />
				</div>
			</div>
			<div className='flex justify-between font-bold mt-10'>
				<div className='w-full'>{bien.advertisement.title}</div>
			</div>
			<div className='grid grid-cols-3 grid-rows-3 gap-2 my-4'>
				<div
					className={`border-2 border-solid ${
						bien.photos.photos_slide.length !== 0
							? 'col-span-2'
							: 'col-span-full'
					}  row-span-2 flex items-center justify-center`}>
					<img
						src={coverImageFromProxy(bien.photos.photos_couvert[0].photo)}
						alt='Cover'
						className={`w-full`}
					/>
				</div>
				{bien.photos.photos_slide &&
					[0, 1, 2, 3, 4].map(
						(item, index) =>
							bien.photos.photos_slide[index] && (
								<div
									className='border-2 border-solid col-span-1 row-span-1 flex items-center justify-center'
									key={index}>
									<img
										src={slideImageFromProxy(
											bien.photos.photos_slide[index].photo
										)}
										alt={'Slide ' + index}
									/>
								</div>
							)
					)}
			</div>
			<div className='grid grid-cols-3 grid-rows-3 gap-2 my-4'>
				<div className='col-span-2 row-span-2 flex items-center justify-center border-2 border-solid'>
					<div className='p-4'>
						<h1 className='mb-4 font-bold text-3xl uppercase'>
							Caractèristiques principales du bien
						</h1>
						<ul className='font-bold'>
							<li>
								Surface du terrain : {bien.land_area} m<sup>2</sup>
							</li>
							{bien.type_estate_id !== 3 && (
								<li>
									Surface habitable : {bien.living_area} m<sup>2</sup>
								</li>
							)}
						</ul>
					</div>
				</div>
				<div className='col-span-1 row-span-1 border-2 border-solid p-4'>
					<h1>Coordonnées du vendeur</h1>
				</div>
				<div className='col-span-1 row-span-1 border-2 border-solid p-4 flex items-center justify-center'>
					<Dpe bien={bien} />
				</div>
			</div>
		</>
	);
};
