import { requiredField } from '../../../validation/validation';
export const Diffusion = [
	{
		//publié le bien
		type: 'radio',
		name: 'published',
		className: 'w-full ',
		title: 'Publication du bien',
		validation: {
			validate: {
				requiredField,
			},
		},
		choices: [
			{ title: ' Publier', value: 'active' },
			{ title: 'Brouillon', value: 'inactive' },
		],
		visible: [
			{ field: 'type_offert_id', value: ['1', '2'] },
			{ field: 'type_offer_suite_id', value: ['1', '2', '3'] },
			{ field: 'type_estate_id', value: ['1', '2', '3', '4'] },
		],
	},
];
