import style from './membership.module.css';
import { Button } from '../../commons/button';
import { configService } from '../../../service/api/config';
import { useState } from 'react';
import { GeneralSpinner } from '../../commons/general-spinner';
import { getStorageUser } from '../../../service/localStorage';
export const Membership = () => {
	const user = getStorageUser().user;
	const [isLoading, setIsLoading] = useState(false);
	const subscribe = async () => {
		setIsLoading(true);
		configService.subscribe(user.agency.id).finally(() => setIsLoading(false));
	};
	return (
		<>
			<div className={style.membership__container}>
				<img
					src='/images/payment.png'
					alt='Payment Card'
					className={style.image__payment}
				/>
				<div>
					<div className={style.indication__container}>
						Vous utilisez la version gratuite de la logicielle Mapim Immo, pour
						accèder à toutes les fonctionnalités, veuillez vous souscrire à un
						abonnement mensuel sur notre plateforme.
					</div>
					<div className={style.button__container}>
						<Button
							onClick={subscribe}
							className={
								'blue-color text-white px-4 py-2 rounded w-1/3 mx-auto'
							}>
							Se souscrire
						</Button>
					</div>
				</div>
			</div>
			{isLoading && <GeneralSpinner />}
		</>
	);
};
