import { requiredField } from '../../validation/validation';
export const TypeOffert = {
	///Type d'offre
	type: 'radio',
	name: 'type_offert_id',
	className: 'w-full ',
	validation: {
		validate: {
			requiredField,
		},
	},
	title: "Type d'offre(s) :",
	choices: [
		{
			title: 'Vente',
			value: '1',
			checked: true,
		},
		{
			title: 'Location',
			value: '2',
		},
	],
};
