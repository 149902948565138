import { ContactSection } from './contact.section';
export const SearchContactResult = ({ contacts }) => {
	return (
		<>
			<h1>Resultat pour les contacts : {contacts.length}</h1>
			{contacts.map((contact, key) => (
				<ContactSection key={key} contact={contact} reload={() => {}} />
			))}
		</>
	);
};
