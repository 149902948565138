import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocal from '@fullcalendar/core/locales/fr';
import { useEffect, useRef, useState } from 'react';
import './agenda.css';
import { CalendarService } from '../../service/models/calendar';
import { Modal } from '../../components/commons/modal/modal';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { dateFormater } from '../../helpers/formatter';
import { errorToast, successToast } from '../../service/toast/showToast';
import { configService } from '../../service/api/config';
import { useForm } from 'react-hook-form';
import { getStorageUser } from '../../service/localStorage';
import { useContact } from '../../service/contact/useContact';
import BienService from '../../service/bien/BienService';
import { Editor } from '@tinymce/tinymce-react';
import {
	TEMPLATE,
	VARIABLES,
	changeVariable,
	changeVariableForClient,
	changeVariableForEstate,
} from '../../helpers/templates/template';
export const Agenda = () => {
	const [events, setEvents] = useState();
	const [showLoading, setShowLoading] = useState(false);
	const [contacts, setContacts] = useState([]);
	const [biens, setBiens] = useState([]);
	const [agents, setAgents] = useState();

	const addEvent = (event) => {
		setShowLoading(true);
		CalendarService.createEvent(event)
			.then(({ data }) => {
				successToast("Création d'évènement réussi avec succès");
				setTimeout(() => window.location.reload(), 750);
			})
			.finally(() => setShowLoading(false));
	};

	const removeEvent = (event) => {
		setShowLoading(true);
		CalendarService.removeEvent(event)
			.then(({ data }) => {
				successToast("Suppression de l'évènement réussie avec succès");
				setTimeout(() => window.location.reload(), 750);
			})
			.catch(() => {})
			.finally(() => setShowLoading(false));
	};
	useEffect(() => {
		setShowLoading(true);
		useContact.allContact(null, 'paginated=false', null).then((res) => {
			setContacts(res.data);
		});
		BienService.listBien(null, 'paginated=false', null).then((res) => {
			setBiens(res.data);
		});

		CalendarService.getAllEvents().then((res) => {
			setEvents(res.data.items);
			configService
				.getConfiguration()
				.then(({ data }) => {
					setAgents(data.users.filter((agent) => agent.role !== '777'));
				})
				.finally(() => setShowLoading(false));
		});
	}, []);
	return (
		<>
			{events && (
				<div className='border-[1px] border-solid border-black p-4'>
					<div className=''>
						<p className='default-color text-white py-2 px-4 rounded-sm mb-4 text-center'>
							Pour pouvoir ajouter un nouvel évènement, cliquer sur la date et
							un popup va s'ouvrir
						</p>
						<Calendar
							events={events}
							contacts={contacts}
							removeEvent={removeEvent}
							agents={agents}
							createEvent={addEvent}
							biens={biens}
						/>
					</div>
				</div>
			)}
			{showLoading && <GeneralSpinner />}
		</>
	);
};

function Calendar({
	events,
	removeEvent,
	agents,
	createEvent,
	contacts,
	biens,
}) {
	const moment = window.moment;
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const editorRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [showModalDetails, setShowModalDetails] = useState(false);
	const [showAgents, setShowAgents] = useState(false);
	const [showContacts, setShowContacts] = useState(false);
	const [currentEvent, setCurrentEvent] = useState();
	const [eventInfo, setEventInfo] = useState();
	const [description, setDescription] = useState('');
	const [currentContact, setCurrentContact] = useState(null);
	const [currentBien, setCurrentBien] = useState(null);

	const connectedUser = getStorageUser().user;
	const userAgency = connectedUser.agency;
	const removeCurrentEvent = () => {
		setShowModalDetails(false);
		removeEvent(currentEvent.extendedProps.eventId);
	};

	const updateCurrentContact = ({ target }) => {
		const currentValue = JSON.parse(target.value);
		if (currentContact) setCurrentContact((prev) => [prev[1], currentValue]);
		else setCurrentContact([currentValue, currentValue]);
	};

	const onChangeData = (value) => {
		setDescription(value);
	};

	const onSubmit = async (data) => {
		data.start_date = moment(eventInfo.start).format('YYYY-MM-DD HH:mm');
		data.end_date = moment(eventInfo.end).format('YYYY-MM-DD HH:mm');
		data.contact_id = JSON.parse(data.contact_id).id_contact;
		data.bien_id = JSON.parse(data.bien_id).id_bien;
		data.description = description;
		createEvent(data);
		setShowModal(false);
	};

	const selectTemplate = (value) => {
		const element = document.querySelector('[name=sms_template]');
		const titleMail = document.getElementById('title_mail');
		let valueTemplateMail = changeVariable(
			TEMPLATE[value].mail,
			'[Nom_Agence]',
			`${userAgency.nameAgency}`
		);

		valueTemplateMail = changeVariable(
			valueTemplateMail,
			'[Nom_Agent]',
			`${connectedUser.name}`
		);

		if (!editorRef.current) {
			errorToast("Veuillez attendre que l'éditeur se charge svp");
			return;
		}
		if (currentContact) {
			valueTemplateMail = changeVariable(
				valueTemplateMail,
				`[Nom_du_Client]`,
				`${currentContact[1].firstname} ${currentContact[1].lastname}`
			);
			valueTemplateMail = changeVariable(
				valueTemplateMail,
				`${currentContact[0].firstname} ${currentContact[0].lastname}`,
				`${currentContact[1].firstname} ${currentContact[1].lastname}`
			);
		}

		editorRef.current.setContent(valueTemplateMail);

		value = changeVariable(value, '[Nom_Agent]', `${connectedUser.name}`);

		element.setAttribute('value', TEMPLATE[value].sms);
		element.value = TEMPLATE[value].sms;
		setValue(element.name, TEMPLATE[value].sms);

		titleMail.setAttribute('value', TEMPLATE[value].title);
		titleMail.value = TEMPLATE[value].title;
		setValue(titleMail.name, TEMPLATE[value].title);
	};

	const updateBien = ({ target }) => {
		const currentValue = JSON.parse(target.value);
		if (currentBien) setCurrentBien((prev) => [prev[1], currentValue]);
		else setCurrentBien([currentValue, currentValue]);
	};

	useEffect(() => {
		const element = document.querySelector('[name=sms_template]');
		const titleMail = document.querySelector('[id=title_mail]');
		if (element && titleMail) {
			const valuesOfVariables = [
				`${moment(eventInfo.start).format('DD-MM-YYYY')}`,
				`${moment(eventInfo.start).format('HH:mm')}`,
				`${userAgency.addressCompany}`,
				`${connectedUser.name}`,
				`${userAgency.nameAgency}`,
			];

			if (!editorRef.current) {
				errorToast("Veuillez attendre que l'éditeur se charge svp");
				return;
			}
			const content = editorRef.current.getContent();

			/**
			 * Change title of the data
			 */
			let titleTemplate = changeVariable(
				titleMail.value,
				'[Nom_Agence]',
				`${userAgency.nameAgency}`
			);

			let valueTemplateMail = changeVariable(
				content,
				'[Nom_Agence]',
				`${userAgency.nameAgency}`
			);

			let value = changeVariable(
				element.value,
				'[Nom_Agence]',
				`${userAgency.nameAgency}`
			);

			for (let i = 0; i < VARIABLES.length; i++) {
				titleTemplate = changeVariable(
					titleTemplate,
					VARIABLES[i],
					valuesOfVariables[i]
				);

				valueTemplateMail = changeVariable(
					valueTemplateMail,
					VARIABLES[i],
					valuesOfVariables[i]
				);

				value = changeVariable(value, VARIABLES[i], valuesOfVariables[i]);
			}

			if (currentContact) {
				titleTemplate = changeVariableForClient(titleTemplate, currentContact);
				value = changeVariableForClient(value, currentContact);
				valueTemplateMail = changeVariableForClient(
					valueTemplateMail,
					currentContact
				);
			}

			if (currentBien) {
				value = changeVariableForEstate(value, currentBien);

				titleTemplate = changeVariableForEstate(titleTemplate, currentBien);

				valueTemplateMail = changeVariableForEstate(
					valueTemplateMail,
					currentBien
				);
			}
			editorRef.current.setContent(valueTemplateMail);
			element.setAttribute('value', value);
			element.value = value;
			setValue(element.name, value);
			titleMail.setAttribute('value', titleTemplate);
			titleMail.value = titleTemplate;
			setValue(titleMail.name, titleTemplate);
		}
	}, [selectTemplate, setCurrentContact, setCurrentBien]);

	return (
		<>
			{showModal && (
				<Modal
					isOpen={showModal}
					width='w-11/12 sm:w-2/3'
					height='h-full'
					modalTitle={'Ajouter un évènement'}
					allowBackdrop={false}
					handleClose={() => setShowModal(false)}>
					<div className='p-4'>
						<form className='my-6' onSubmit={handleSubmit(onSubmit)}>
							<div className='my-2'>
								<label htmlFor='title' className='w-full mr-4'>
									Nom de l'évènement :
								</label>
								<input
									type='text'
									className='w-full outline-none border-b-[1px] border-solid p-2 border-b-black/50 rounded-none bg-gray-200/50'
									{...register('title', {
										validate: {
											required: (value) =>
												value ? true : "Le champ Nom de l'évènement est requis",
										},
									})}
									id='title_mail'
								/>
								{errors.title && (
									<span className='w-full text-red-600'>
										{errors.title.message}
									</span>
								)}
							</div>
							<div className='my-2 flex items-start flex-wrap'>
								<label htmlFor='description' className='w-full mr-4'>
									Description de l'évènement :
								</label>
								<small className=''>
									(*) Ceci sera utilisé en tant que template mail
								</small>
							</div>
							<div className='w-full z-[151020000]'>
								<Editor
									onInit={(_event, editor) => {
										editorRef.current = editor;
									}}
									onEditorChange={onChangeData}
									apiKey='oi9v2e5ugnjwrfhq5ok0dkj35bwc5kr9olaqxa65ri8cvgx5'
									init={{
										font_size_formats:
											'8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
										menubar: false,

										plugins:
											'anchor autolink charmap codesample emoticons image link lists media searchreplace visualblocks checklist mediaembed casechange export formatpainter pageembed permanentpen powerpaste advtable advcode editimage advtemplate mentions tableofcontents footnotes typography inlinecss markdown',
										toolbar:
											'undo redo | blocks fontsize | bold italic underline strikethrough | link image mergetags | addcomment | typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap',
									}}
								/>
							</div>
							<small>
								(*) Vous pouvez choisir une template ici aussi pour
								auto-completer la description
							</small>
							<select
								className='py-4 w-full'
								onChange={(e) => selectTemplate(e.target.value)}>
								<option value='aucune'></option>
								<option value='confirmation_visite'>
									Confirmation de Visite Immobilière{' '}
								</option>
								<option value='confirmation_rdv_signature_achat'>
									Confirmation de Rendez-vous pour Signature de l'Offre d'Achat
								</option>
								<option value='confirmation_rdv_signature_compromis_vente'>
									Confirmation de Rendez-vous pour Signature de Compromis de
									Vente
								</option>
								<option value='confirmation_rdv_signature_bail_location'>
									Confirmation de Rendez-vous pour Signature de Bail de Location
								</option>
							</select>
							<label className='w-full block'>Template du sms</label>
							<textarea
								{...register('sms_template', {
									validate: {
										required: (value) =>
											value ? true : 'Veuillez éditer le template de message',
									},
								})}
								className='w-full outline-none border-b-[1px] border-solid p-2 border-b-black/50 rounded-none bg-gray-200/50'
								rows={4}></textarea>

							{connectedUser.role === '777' && (
								<div className='my-4 relative'>
									<button
										type='button'
										className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
										id='menu-button'
										aria-expanded='true'
										aria-haspopup='true'
										onClick={() => setShowAgents(!showAgents)}>
										Inviter des agents
										<svg
											className='-mr-1 h-5 w-5 text-gray-400'
											viewBox='0 0 20 20'
											fill='currentColor'
											aria-hidden='true'>
											<path
												fillRule='evenodd'
												d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
												clipRule='evenodd'
											/>
										</svg>
									</button>
									<div
										className={`absolute right-0 z-10 p-2 mt-2 min-h-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full ${
											showAgents ? '' : 'hidden'
										}`}
										role='menu'
										aria-orientation='vertical'
										aria-labelledby='menu-button'
										tabIndex='-1'>
										<select
											{...register('invites')}
											id='select'
											multiple
											className='w-full'>
											{agents.map((item, index) => (
												<option value={item.id} key={index}>
													{item.name}
												</option>
											))}
										</select>
									</div>
								</div>
							)}

							<div className='my-4 relative'>
								<button
									type='button'
									className='h-12 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
									id='menu-button-contact'
									aria-expanded='true'
									aria-haspopup='true'
									onClick={() => setShowContacts(!showContacts)}>
									Choisir le contact à associer
									<svg
										className='-mr-1 h-5 w-5 text-gray-400'
										viewBox='0 0 20 20'
										fill='currentColor'
										aria-hidden='true'>
										<path
											fillRule='evenodd'
											d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
											clipRule='evenodd'
										/>
									</svg>
								</button>
								<div
									className={`absolute right-0 z-10 p-2 mt-2 min-h-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full ${
										showContacts ? '' : 'hidden'
									}`}
									role='menu'
									aria-orientation='vertical'
									aria-labelledby='menu-button'
									tabIndex='-1'>
									<select
										{...register('contact_id')}
										id='select_agent'
										onChange={updateCurrentContact}
										className='w-full py-4'>
										{contacts.map((item, index) => (
											<option value={JSON.stringify(item)} key={index}>
												{item.firstname} {item.lastname}
											</option>
										))}
									</select>
								</div>
							</div>
							<h1>Selectionner le bien</h1>
							<select
								{...register('bien_id', {
									validate: {
										required: (value) => (value ? true : 'Le bien est requis'),
									},
								})}
								onChange={updateBien}
								id='select_bien'
								className='w-full py-4 my-4'>
								{biens.map((item, index) => (
									<option value={JSON.stringify(item)} key={index}>
										{item.advertisement.title}
									</option>
								))}
							</select>
							{errors.description && (
								<span className='w-full text-red-600'>
									{errors.bien_id.message}
								</span>
							)}
							<ul>
								<li>
									<u className='font-bold'>Date de début</u> :{' '}
									{moment(new Date(eventInfo.start)).format(
										'DD/MM/YYYY à HH:mm'
									)}
								</li>
								<li>
									<u className='font-bold'>Date de fin</u> :{' '}
									{moment(new Date(eventInfo.end)).format('DD/MM/YYYY à HH:mm')}
								</li>
								<li className='flex items-center gap-x-5'>
									<span className='font-bold'>Envoyer un rappel par sms</span>
									<input type='checkbox' {...register('send_sms_reminder')} />
								</li>
								<li className='flex items-center gap-x-5'>
									<span className='font-bold'>Envoyer un rappel par mail</span>
									<input type='checkbox' {...register('send_mail_reminder')} />
								</li>
							</ul>

							<div className='my-3'>
								<label>Rappel (exprimé en minutes )</label>
								<input
									type='number'
									min={0}
									{...register('reminderer_laps')}
									className='w-full outline-none border-b-[1px] border-solid p-2 border-b-black/50 rounded-none bg-gray-200/50'
								/>
							</div>

							<button
								type='submit'
								className='cursor-pointer default-color text-white font-bold py-2 px-4 rounded-sm flex gap-3 items-center mt-8'>
								Enregistrer
							</button>
						</form>
					</div>
				</Modal>
			)}

			{showModalDetails && (
				<Modal
					isOpen={showModalDetails}
					modalTitle={currentEvent.title}
					height='h-full'
					allowBackdrop={false}
					handleClose={() => {
						setCurrentEvent({});
						setShowModalDetails(false);
					}}>
					<div className='p-4 w-full text-lg'>
						<div className='my-4'>
							<ul>
								<li>
									{' '}
									<i className='fa fa-circle-info mr-2'></i>
									<b>Titre de l'évènement</b> : {currentEvent.title}
								</li>
								<li>
									{' '}
									<i className='fa fa-circle-info mr-2'></i>
									<b>Description de l'évènement</b> :{' '}
								</li>
								<li className='p-2 border border-gray-300 border-solid'>
									<div
										id='description_container'
										dangerouslySetInnerHTML={{
											__html: currentEvent.extendedProps.description,
										}}></div>
								</li>
								<li>
									{' '}
									<i className='fa fa-circle-info mr-2'></i>
									<b>Début</b> :{' '}
									{dateFormater(currentEvent.extendedProps.startDate)}
								</li>
								<li>
									{' '}
									<i className='fa fa-circle-info mr-2'></i>
									<b>Fin</b> :{' '}
									{dateFormater(currentEvent.extendedProps.endDate)}
								</li>
								{currentEvent.extendedProps.reminderer_laps && (
									<li>
										<i className='fa fa-circle-info mr-2'></i>
										<b>Rappel :</b>{' '}
										{moment(
											currentEvent.extendedProps.reminderer_laps,
											'm'
										).format('m')}{' '}
										minutes avant
									</li>
								)}

								<li>
									<i className='fa fa-circle-info mr-2'></i>
									<b>Invités</b>{' '}
									{currentEvent.extendedProps.invites.length === 0 && (
										<span> : Aucun</span>
									)}
								</li>
								{currentEvent.extendedProps.invites.length !== 0 && (
									<li>
										<ul>
											{currentEvent.extendedProps.invites.map((item, index) => (
												<li key={index}>
													{index + 1} - {item.user.name} ({item.user.email})
												</li>
											))}
										</ul>
									</li>
								)}
								<li>
									<i className='fa fa-circle-info mr-2'></i>
									<b>Bien concerné</b>
									<br />
									<b>{currentEvent.extendedProps.bien.advertisement.title}</b>
								</li>
							</ul>
						</div>
						<button
							className='bg-orange-400 text-white px-4 py-2 rounded-md hover:opacity-80'
							onClick={removeCurrentEvent}>
							Supprimer <i className='fa fa-trash'></i>
						</button>
					</div>
				</Modal>
			)}
			<div>
				<FullCalendar
					events={events}
					eventDataTransform={(event) => {
						if (event.status !== 'cancelled') {
							const receivedData = event;
							return {
								title: receivedData.title,
								description: receivedData.description,
								start: receivedData.start_date,
								eventId: receivedData.id,
								end: receivedData.end_date,
								startDate: receivedData.start_date,
								endDate: receivedData.end_date,
								host: receivedData.host,
								invites: receivedData.invites,
								bien: receivedData.bien,
								reminderer_laps: receivedData.reminderer_laps,
							};
						}
					}}
					eventClassNames={'text-white blue-color cursor-pointer'}
					nowIndicatorClassNames={'blue-color'}
					dayHeaderClassNames={'blue-color text-white capitalize'}
					plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
					slotDuration={'00:15:00'}
					slotLabelInterval={'01:00:00'}
					initialView='timeGridDay'
					locale={frLocal}
					headerToolbar={{
						left: 'prev,next',
						center: 'title',
						right: 'timeGridDay,dayGridWeek,dayGridMonth',
					}}
					selectable={true}
					select={(info) => {
						setShowModal(true);
						setEventInfo(info);
					}}
					eventClick={(event) => {
						setCurrentEvent(event.event._def);
						setShowModalDetails(true);
					}}
				/>
			</div>
		</>
	);
}
