export const DimensionSection = ({ pige }) => {
	return (
		<div
			className='p-4 bg-[#fbfcff] w-full flex flex-wrap justify-between items-center text-xl'
			style={{ border: '1px solid #E9F1FF' }}>
			<div className='w-1/2 font-bold'>
				<h1 className='color-text w-full'>Surface</h1>
				<i className='fa-solid fa-expand mr-5 color-text'></i> {pige.surface} m
				<sup>2</sup>
			</div>
			<div className='w-1/2 font-bold'>
				<h1 className='color-text w-full'>
					{' '}
					Pièce
					{pige.pieces > 1 && 's'}
				</h1>
				<i className='fa-solid fa-border-all  mr-5 color-text'></i>{' '}
				{pige.pieces}
			</div>
		</div>
	);
};
