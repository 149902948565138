import { requiredField } from '../../validation/validation';
export const TypeOffertSuite = {
	//Type d'offre suite
	type: 'radio',
	name: 'type_offer_suite_id',
	className: 'w-full ',
	title: 'Type',
	validation: {
		validate: {
			requiredField,
		},
	},
	choices: [
		{
			title: 'Classique',
			value: '1',
			checked: true,
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['1', '2'],
				},
			],
		},
		{
			title: 'Programme Neuf',
			value: '2',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['1'],
				},
			],
		},
		{
			title: 'Saisonnière',
			value: '3',
			visible: [
				{
					condition: 'EQUAL',
					field: 'type_offert_id',
					value: ['2'],
				},
			],
		},
	],
};
