import { useNavigate, useLocation } from 'react-router-dom';
import { imageAgent, imageBien } from '../../../../helpers/image.source.';
import { useEffect, useState } from 'react';
import { Modal } from '../../../commons/modal/modal';
import Chevron from '../../../commons/Chevron';
import { useParams } from 'react-router-dom';
import { QuickView } from './QuickView';

export const BienSection = ({ bien, link = null }) => {
	const navigate = useNavigate();

	const { filter, bienId } = useParams();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSold, setIsSold] = useState('');
	const [isPublished, setIsPublished] = useState('');
	const handleClose = () => setIsModalOpen(false);

	useEffect(() => {
		if (bien) {
			const isSold = bien.solds === 'sold' || bien.solds === 'archive';
			const isOffert = bien.type_offert_id === 1;
			const soldStatus = isSold
				? isOffert
					? 'Vendu'
					: 'Loué'
				: isOffert
				? 'A vendre'
				: 'A louer';
			setIsSold(soldStatus);

			bien.published === 'active'
				? setIsPublished('Publié')
				: setIsPublished('Brouillon');
		}
	}, [isSold]);
	return (
		<div className='mb-8 border-2 border-solid'>
			<div className='w-[98%] m-1 h-full sm:hidden flex items-center justify-center rounded'>
				<img
					src={imageBien(
						encodeURI(`photos_couvert${bien.photos?.photos_couvert[0].photo}`)
					)}
					alt='photos original'
					className='h-full object-cover w-full rounded'
				/>
			</div>
			<div className='w-full m-1 rounded flex sm:min-h-36 min-h-52 justify-center'>
				<div className='sm:w-[95%] w-[80%] border-2 flex rounded-l-xl border-solid p-1 sm:relative'>
					<div className='border-2 border-solid rounded mr-[5%] w-full flex flex-wrap border-black/60 p-1 relative'>
						<div className='w-[15%] sm:flex hidden items-center justify-center rounded'>
							<img
								src={imageBien(
									encodeURI(
										`photos_couvert${bien.photos?.photos_couvert[0].photo}`
									)
								)}
								alt='photos original'
								className='h-full object-cover rounded'
							/>
						</div>
						<div className='w-full pb-2 sm:w-[85%] sm:relative'>
							<div className='rounded-full w-16 h-16 absolute shadow-lg shadow-black z-10 border-gray-700 border-2 border-solid bottom-[25%] left-[-30px] sm:flex hidden items-center justify-center bg-gray-500'>
								<img
									src={
										bien.agent?.photo_url
											? imageAgent(
													`/${bien.agent.agency_id}/${bien.agent.photo_url}`
											  )
											: '/images/agents/8380015.jpg'
									}
									alt='photos original'
									className='h-full object-cover rounded-full'
								/>
							</div>
							{bien.rapprochements && (
								<div className='text-white font-bold rounded-full w-12 h-12 absolute shadow-lg shadow-black z-10 border-gray-700 border-2 border-solid bottom-[1%] right-[10px] top-[140px] sm:right-[-30px] sm:top-[75px] flex items-center justify-center bg-gray-500'>
									{bien.rapprochements.length}
								</div>
							)}

							<div className='sm:ml-20 sm:min-h-[80%]'>
								<div className='flex justify-between w-full flex-wrap'>
									<h1 className='sm:w-1/2 w-full break-words'>
										Titre de l'annonce : {bien.advertisement?.title}
									</h1>

									<h2 className='sm:w-1/2 w-full sm:text-right pr-2'>
										Numéro du dossier : {bien?.num_folder}
									</h2>
									<div className='w-full'>
										<ul className=''>
											<li>
												Type d'offre :
												<span
													className={`uppercase text-white p-1 rounded font-bold shadow-sm mx-2 shadow-black ${
														bien.type_offert.designation === 'vente'
															? 'bg-green-500'
															: 'bg-yellow-500'
													}`}>
													{bien.type_offert.designation} -{' '}
													{bien.type_offert_suite.designation}
												</span>
											</li>
											<li>
												Type du bien :
												<span className={`uppercase p-1 rounded font-bold`}>
													{bien.type_estate.designation}
												</span>
											</li>
											<li>
												{bien?.type_offert_id === 1
													? `Prix :${bien?.selling_price}`
													: `Loyer : ${bien?.rent}`}
												€
											</li>
										</ul>
									</div>
								</div>
							</div>
							<hr className='border-solid border-[1px] border-black/70' />
							<div>
								<div className='pt-1 h-full flex-col justify-start sm:flex-row flex sm:justify-center sm:items-center gap-4'>
									<div className='flex sm:justify-center gap-2 items-start sm:w-1/2 w-fit'>
										<div className=''>
											Etat du bien : {''}
											{
												<span
													className={`${
														isSold === 'Vendu' ? 'bg-red-500' : 'bg-green-500'
													} text-white p-1 rounded font-bold shadow-lg cursor-pointer mr-2`}>
													{isSold}
												</span>
											}
											{bien.published && (
												<span
													className={`${
														isPublished === 'Publié'
															? 'bg-blue-500'
															: 'bg-gray-500'
													} text-white p-1 rounded font-bold shadow-lg cursor-pointer`}>
													{isPublished}
												</span>
											)}
										</div>
									</div>
									<div
										onClick={() => setIsModalOpen(true)}
										className='border-solid border-[1px] text-white rounded bg-gray-600 cursor-pointer px-1 w-fit'>
										Vue rapide <i className='fa-solid fa-eye'></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{!bienId && (
					<div className='sm:w-[5%] flex justify-center items-center w-[15%] rounded-r-xl border-2 border-solid bg-black cursor-pointer text-white hover:bg-gray-400 hover:text-black'>
						<Chevron
							link={
								link ? link + bien.uuid : `/mes-biens/details-bien/${bien.uuid}`
							}
							state={filter}
						/>
					</div>
				)}
			</div>
			<Modal
				isOpen={isModalOpen}
				handleClose={handleClose}
				id={'modal-link'}
				modalTitle={'Vue rapide'}
				height='h-full'
				allowBackdrop={false}>
				<div>
					<QuickView
						bien={bien}
						closeModal={handleClose}
						allowBackdrop={false}
					/>
				</div>
			</Modal>
		</div>
	);
};
