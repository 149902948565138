import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Modal = ({
	id,
	isOpen,
	handleClose,
	children,
	modalTitle,
	allowForceClose = true,
	allowBackdrop = true,
	width = 'w-2/3',
	height = 'h-1/2',
}) => {
	const [domReady, setDomReady] = useState(false);

	useEffect(() => {
		setDomReady(true);
	}, []);

	const closeModal = () => {
		handleClose();
	};

	const handleOverlayClick = (e) => {
		const elementClicked = e.target;
		const parent = document.getElementById(id);

		if (!isDescendant(parent, elementClicked) && allowBackdrop) {
			closeModal();
		}
	};

	function isDescendant(parent, child) {
		let node = child.parentNode;
		while (node != null) {
			if (node === parent) {
				return true;
			}
			node = node.parentNode;
		}
		return false;
	}

	const [selectedImage, setSelectedImage] = useState(null);

	const images = ['/images/agents/8380015.jpg'];

	const handleImageClick = (image) => {
		if (allowBackdrop) {
			// Seulement fermer le modal si allowBackdrop est vrai
			setSelectedImage(image);
			closeModal();
		} else {
			// Ne pas fermer le modal si allowBackdrop est faux
			setSelectedImage(image);
		}
	};

	const handleCloseModal = () => {
		setSelectedImage(null);
	};
	return (
		domReady && (
			<>
				{selectedImage && (
					<div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75'>
						<div className='max-w-3xl mx-auto relative z-50 items-center justify-center text-center '>
							<img
								src={selectedImage}
								alt='Image agrandie'
								className=' max-w-full max-h-full sm:max-w-[90%] sm:max-h-[90%] mx-auto rounded-md'
							/>
							<button
								onClick={handleCloseModal}
								className='relative top-0 right-0 m-4 text-white font-bold text-lg cursor-pointer'>
								Fermer
							</button>
						</div>
					</div>
				)}
				{createPortal(
					<div
						id={id}
						tabIndex='-1'
						aria-hidden='true'
						style={{ zIndex: 10000 }}
						className={`${
							isOpen ? 'block' : 'hidden'
						} fixed inset-0 flex items-center justify-center z-500`}
						onClick={handleOverlayClick}>
						<div className='absolute inset-0 bg-gray-800 opacity-70 '></div>

						<div
							className={`bg-white p-6 rounded shadow-md z-10  ${width} ${height} overflow-y-scroll`}>
							<div className='w-full flex justify-between p-4'>
								<h1 className='text-2xl text-center w-11/12  pb-8 border-solid border-b-2 border-black '>
									{modalTitle}
								</h1>
								{allowForceClose && (
									<i
										className='fa fa-close cursor-pointer blue-color rounded-lg text-white p-2 font-bold h-1/2'
										onClick={closeModal}></i>
								)}
							</div>
							{children}
						</div>
					</div>,
					document.getElementById('root')
				)}
			</>
		)
	);
};
