import {
	coverImageFromProxy,
	slideImageFromProxy,
} from '../../../../helpers/image.source.';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';

export const Model4 = ({ bien }) => {
	console.log(bien);
	return (
		<>
			<div className='flex justify-between font-bold'>
				<div className='w-1/4 uppercase'>Statut : {bien.solds}</div>
				<div className='w-1/4 text-right'>
					<Pricing bien={bien} />
				</div>
			</div>
			<div className='flex justify-between font-bold mt-10'>
				<div className='w-full'>{bien.advertisement.title}</div>
			</div>
			<div
				className='relative border-2 border-solid p-4 mt-10'
				style={{
					minHeight: '750px',
					backgroundPosition: 'center',
					backgroundSize: '100%',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${coverImageFromProxy(
						bien.photos.photos_couvert[0].photo
					)})`,
				}}>
				<div className='text-white w-1/3 h-auto absolute bottom-0 right-0 bg-gray-600 flex items-center justify-center'>
					<Dpe model={true} bien={bien} />
				</div>
			</div>
		</>
	);
};
