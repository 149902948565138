export const InfoEstimation = ({ estimation }) => {
	const detailsBien = JSON.parse(estimation.details_bien);
	return (
		<div className='w-full lg:w-1/3 sm:w-1/2'>
			<h1 className='font-bold underline'>Critère d'estimation</h1>
			<span className='block'>
				<u className='font-bold'>Adresse du bien</u> :{' '}
				<i className='uppercase'>
					{estimation.address_bien} - {estimation.ville_bien}
				</i>
			</span>
			{/* <span className='block'>
				<u className='font-bold'>Type du bien</u> :{' '}
				<i className='uppercase'>{estimation.bien}</i>
			</span> */}
			<span className='block'>
				<u className='font-bold'>Type du bien</u> :{' '}
				<i className='uppercase'>{estimation.bien}</i>
			</span>
			<span className='block'>
				<u className='font-bold'>Surface habitable</u> :
				{detailsBien.surface_habitable} m<sup>2</sup>
			</span>
			<span className='block'>
				<u className='font-bold'>Nombre de pièces</u> :{detailsBien.nb_pieces}
			</span>
			<span className='block'>
				<u className='font-bold'>Nombre de chambres</u> :
				{detailsBien.nb_chambre}
			</span>
			<span className='block'>
				<u className='font-bold'>Nombre de salle d'eau</u> :
				{detailsBien.nb_salle_eau}
			</span>
			<span className='block'>
				<u className='font-bold'>Nombre de salle de bain</u> :
				{detailsBien.nb_salle_bain}
			</span>
		</div>
	);
};
