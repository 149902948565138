import { useNavigate } from 'react-router-dom';

export const Breadcumbs = ({ title }) => {
	const navigate = useNavigate();
	return (
		<div className='font-bold h-20 flex items-center text-2xl w-1/2 gap-6'>
			<h1>{title}</h1>
			<i
				className='fa-solid fa-chevron-right cursor-pointer'
				onClick={() => navigate(-1)}></i>
		</div>
	);
};
