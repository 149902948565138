// rental_invests
export const InvestissementLocatif = [
	{
		//Loyer estimé
		className: 'w-full sm:w-1/2 ',
		title: 'Loyer estimé:',
		name: 'estimated_rent',
		tagName:'rental_invest.estimated_rent',
		type: 'text',
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Charges Mensuelles locatives
		className: 'w-full sm:w-1/2 ',
		title: 'Charges mensuelles locatives:',
		name: 'monthly_rent',
		tagName:'rental_invest.monthly_rent',
		type: 'text',
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//Rendement
		className: 'w-full sm:w-1/2 ',
		title: 'Rendement:',
		name: 'yeld',
		tagName:'rental_invest.yeld',
		type: 'text',
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
	{
		//occupation
		className: 'w-full sm:w-1/2 ',
		title: 'Occupation:',
		name: 'occupancy',
		tagName:'rental_invest.occupancy',
		type: 'radio',
		choices: [
			{
				title: 'Non précisé',
				value: '1',
			},
			{
				title: 'Libre',
				value: '2',
			},
			{
				title: 'Loué',
				value: '3',
			},
		],
		visible: [
			{ field: 'type_offert_id', value: ['1'] },
			{ field: 'type_offer_suite_id', value: ['1', '2'] },
			{ field: 'type_estate_id', value: ['10'], condition: 'INEQUAL' },
		],
	},
];
