import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { LoginPage } from '../../pages/auth/Login.page';
import { RegisterPage } from '../../pages/auth/Register.page';
import { Footer } from '../../components/generals/footer/footer';
import HeaderHome from '../../components/generals/header-home';
import { isConnected } from '../../service/localStorage';
import { Outlet, Navigate } from 'react-router-dom';
import MENU from '../../service/route/route';
import { ResetPassword } from '../../pages/auth/ResetPassword';
import { ChangePassword } from '../../pages/auth/ChangePassword';
import { RapprochementRoute } from './RapprochementRoute';
import { configService } from '../../service/api/config';
import { Membership } from '../../components/generals/membership/Membership';

const PrivateRoutes = ({ isPro = false }) => {
	if (isConnected) {
		return isPro ? <Outlet /> : <Membership isPro />;
	} else {
		// Redirect to the login page if not connected
		return <Navigate to='/login' />;
	}
};

export const AuthenticationRoute = () => {
	const [isPro, setIsPro] = useState(null);

	useEffect(() => {
		const isAuthUrl = [
			'register',
			'login',
			'reset-password',
			'reset-password-pass',
			'two-factor',
		].includes(
			window.location.href.split('/')[
				window.location.href.split('/').length - 1
			]
		);
		if (isAuthUrl) setIsPro(true);
		else
			configService.isSubscribed().then((value) => {
				setIsPro(value);
			});
	}, []);
	return (
		isPro != null && (
			<HelmetProvider>
				<div className='container w-full mx-auto'>
					{isConnected && <HeaderHome />}
					<Routes>
						<Route element={<PrivateRoutes isPro={isPro} />}>
							{MENU.map((item, index) => {
								if (item.is_pro) {
									return (
										isPro && (
											<Route
												key={index}
												path={item.link}
												element={item.component}>
												{item.children &&
													item.children.map((children, indexChild) => {
														if (children.is_pro) {
															return (
																isPro && (
																	<Route
																		key={indexChild}
																		path={children.link}
																		element={children.component}></Route>
																)
															);
														} else {
															return (
																<Route
																	key={indexChild}
																	path={children.link}
																	element={children.component}></Route>
															);
														}
													})}
											</Route>
										)
									);
								} else {
									return (
										<Route
											key={index}
											path={item.link}
											element={item.component}>
											{item.children &&
												item.children.map((children, indexChild) => (
													<Route
														key={indexChild}
														path={children.link}
														element={children.component}></Route>
												))}
										</Route>
									);
								}
							})}
							<Route
								path='/mes-rapprochements/*'
								element={<RapprochementRoute />}
							/>
						</Route>
						<Route path='/login' element={<LoginPage />} />
						<Route path='/register' element={<RegisterPage />} />
						<Route path='/reset-password' element={<ResetPassword />} />
						<Route path='/reset-password-pass' element={<ChangePassword />} />
					</Routes>
					<Footer />
				</div>
			</HelmetProvider>
		)
	);
};
