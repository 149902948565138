import { useCallback, useEffect } from 'react';
import {
	lastModified,
	retrievePrice,
	storeLastModified,
	storePrice,
} from '../../../../../helpers/pricing';

export const PricingInput = ({
	style,
	element,
	errors,
	register,
	control,
	setValue,
	formState,
}) => {
	// Retourne les elements associés
	const elementsData = useCallback(() => {
		const elements = [];
		element.elements.forEach((elem) => {
			elements.push(document.querySelector(`input[name=${elem.name}]`));
		});
		return elements;
	});
	// const lastPrice = retrievePrice();
	// const value = control(element.depend);
	const [publicPrice, vendorPrice, montant, percent] = elementsData();

	const calculate = (e) => {
		e.preventDefault();

		const publicPriceValue = parseInt(publicPrice.value || 0);
		const vendorPriceValue = parseInt(vendorPrice.value || 0);
		const montantValue = parseInt(montant.value || 0);
		const percentValue = parseFloat(percent.value || 0);

		if (publicPriceValue !== 0 && vendorPriceValue !== 0) {
			montant.value = parseInt(publicPriceValue) - parseInt(vendorPrice.value);
			setValue(montant.name, montant.value);
			percent.value = parseFloat(
				parseInt(montant.value) / parseInt(vendorPrice.value)
			).toFixed(3);
			setValue(percent.name, percent.value);
		} else if (publicPriceValue !== 0 && montantValue !== 0) {
			vendorPrice.value = parseInt(publicPriceValue) - parseInt(montantValue);
			setValue(vendorPrice.name, vendorPrice.value);
			percent.value = parseFloat(montant.value / vendorPrice.value).toFixed(3);
			setValue(percent.name, percent.value);
		} else if (vendorPriceValue !== 0 && montantValue !== 0) {
			publicPrice.value = parseInt(vendorPriceValue) + parseInt(montantValue);
			setValue(publicPrice.name, publicPrice.value);
		} else if (vendorPriceValue !== 0 && percentValue !== 0.0) {
			montant.value = parseInt(vendorPriceValue) * parseFloat(percentValue);
			setValue(montant.name, montant.value);
			publicPrice.value = parseInt(montant.value) + parseInt(vendorPriceValue);
			setValue(publicPrice.name, publicPrice.value);
		} else if (publicPriceValue !== 0 && percentValue !== 0.0) {
			montant.value = publicPriceValue * percentValue;
			setValue(montant.name, montant.value);
			vendorPrice.value = parseInt(publicPriceValue) - parseInt(montant.value);
			setValue(vendorPrice.name, vendorPrice.value);
		}
	};

	const reinitialized = (e) => {
		e.preventDefault();

		publicPrice.value = 0;
		vendorPrice.value = 0;
		montant.value = 0;
		percent.value = 0;
	};

	return (
		<div className='p-0 m-0 w-full'>
			{element.elements.map((elem, index) => {
				return (
					<div key={index}>
						<label htmlFor={elem.name}>{elem.title} (*)</label>
						<input
							id={elem.name}
							step={0.1}
							type={elem.type}
							className={style.simple__input}
							{...register(elem.name, {
								...elem.validation,
							})}
						/>
					</div>
				);
			})}
			<button
				onClick={calculate}
				type='button'
				className='!px-4 !py-2 !text-white blue-color-i !rounded-lg !mr-4'>
				CALCULER AUTOMATIQUEMENT
			</button>
			<button
				onClick={reinitialized}
				type='button'
				className='!px-4 !py-2 !text-white blue-color-i !rounded-lg'>
				REINITIALISER
			</button>
		</div>
	);
};
