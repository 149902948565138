import { register } from '../../service/auth/authentication';
import { Spinner } from '../../components/commons/Spinner';
import { Modal } from '../../components/commons/modal/modal';
import { FormBuilder } from '../../components/commons/form/FormBuilder';
import { registerForm } from '../../helpers/forms/register';
import { useState } from 'react';
import { successToast } from '../../service/toast/showToast';
import { Link } from 'react-router-dom';
import { CustomFormBuilder } from '../../components/commons/form/CustomFormBuilder';

export const RegisterPage = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [errors, setErrors] = useState();
	const handleSubmit = async (formData) => {
		setIsOpen(true);
		await register(formData)
			.then((res) => {
				if (res.data) {
					successToast(
						"Votre inscription s'est bien passée, veuillez vous confirmer votre email pour pouvoir vous connecter sur votre compte !"
					);
					window.location.href = res.data.url;
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setIsOpen(false));
	};

	return (
		<>
			<Modal
				allowBackdrop={false}
				allowForceClose={false}
				isOpen={isOpen}
				modalTitle={''}>
				<div className='flex flex-wrap items-center justify-center w-full min-h-full text-black'>
					<div className='lds-ellipsis'>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</Modal>
			<div className='flex items-center justify-center flex-wrap'>
				<div className='basis-1/2 hidden lg:flex' style={{ height: '100vh' }}>
					<img
						alt='Login illustration'
						src='/images/logo-login.jpeg'
						className='object-contain'
					/>
				</div>
				<div className='basis-full lg:basis-1/2'>
					<CustomFormBuilder onComplete={handleSubmit} form={registerForm} />
					<div className='px-5'>
						<h1 className='inline'>Avez-vous déjà un compte? </h1>
						<Link
							to='/login'
							className='underline font-bold hover:text-blue-500 flex items-center gap-3'>
							Se connecter
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};
