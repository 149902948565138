import { useEffect, useState } from 'react';
import { BackgroundColor } from '../../../../helpers/backgroundcolor';
import { ColorText } from './BienText';

export const UserText = (actus) => {
	const [contentText, setContentText] = useState('');

	useEffect(() => {
		const dataNew = actus.actus;
		switch (dataNew.action) {
			case 'created':
				var text = `Ajout du nouveau agent ${dataNew.agent.name}`;
				var bgColor = 'green';
				return setContentText({ text, bgColor });

			case 'updated':
				var text = `Mise à jour de l'agent  par ${dataNew.agent.name}`;
				var bgColor = 'orange';
				return setContentText({ text, bgColor });

			default:
				break;
		}
	}, []);
	return (
		contentText && (
			<div className='flex items-stretch justify-between py-2 mx-2'>
				<div className='text-center'>{contentText.text}</div>
				<ColorText color={BackgroundColor(contentText.bgColor)} />
			</div>
		)
	);
};
