import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../commons/modal/modal';
import { useSuiviDossier } from '../../../service/suivi/useSuiviDossier';
import { technicalErrorToast } from '../../../service/toast/showToast';
import { ButtonSpinner } from '../../commons/buttonSpinner';

export const CardSuivi = ({
	step,
	children,
	folder,
	validated,
	updatedBien,
}) => {
	const DATE = window.moment ? window.moment : null;

	const [showModal, setShowModal] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const {
		register,
		handleSubmit,
		unregister,
		formState: { errors },
	} = useForm();

	const [currentStep, setCurrentStep] = useState();
	const updateElement = (data) => {
		data.step_id = currentStep.id;
		setShowLoading(true);
		useSuiviDossier
			.updateStep(data)
			.then(() => {
				setCurrentStep();
				setShowModal(false);
				updatedBien();
				unregister(['step_name', 'step_duration']);
			})
			.catch(() => {})
			.finally(() => setShowLoading(false));
	};
	return (
		<div
			className={`w-full md:w-full sm:w-full lg:w-1/3 cursor-pointer p-4 rounded-md border-4 border-solid border-black/25 shadow-2xl ${
				validated ? 'grey-color' : ''
			}`}>
			{(children && children) ||
				(step.value.some((item) => item.active === 1) && (
					<>
						<div className='item bordered font-bold blue-color text-white'>
							J + {step.id}
						</div>
						{step.value.map(
							(item, index) =>
								item.active === 1 && (
									<div key={index} className={`my-4`}>
										<div
											className={`item font-bold bordered ${
												item.is_done === 0
													? 'bg-gray-300 text-black'
													: 'default-color text-white'
											}`}>
											{item.step_name}{' '}
											{item.is_done === 1 && (
												<i
													className='far fa-check-circle ml-[20px]'
													title='Etape validée'></i>
											)}
											<i
												onClick={() => {
													setCurrentStep(item);
													setShowModal(true);
												}}
												className='fa fa-pencil ml-[20px]'
												title="Modification de l'étape"></i>
										</div>
										<div className='item font-bold bordered'>
											{DATE(folder.date_signature)
												.add(item.step_duration, 'days')
												.format('DD/MM/YYYY')}
										</div>
									</div>
								)
						)}
					</>
				))}

			{currentStep && (
				<Modal
					width='w-11/12 sm:w-10/12 md:w-2/3 xl:w-1/3 '
					height='min-h-2/3 sm:min-h-1/2'
					isOpen={showModal}
					handleClose={() => setShowModal(false)}
					allowForceClose={true}
					modalTitle={"Modification de l'élement"}
					allowBackdrop={false}>
					<div className='my-4'>
						<form onSubmit={handleSubmit(updateElement)}>
							<div className='flex flex-wrap'>
								<label htmlFor='element_name' className='w-full'>
									Titre de l'élement (*)
								</label>
								<input
									type='text'
									id='element_name'
									className='border-[1px] border-solid w-full my-2 outline-none p-2 aria-[invalid]:border-red-500'
									aria-invalid={errors.step_name}
									{...register('step_name', {
										value: currentStep.step_name,
										required: {
											value: true,
											message: "Le titre de l'élement est requis",
										},
									})}
								/>
								<span className='text-red-500 w-full'>
									{errors.step_name && errors.step_name.message}
								</span>
							</div>
							<div className='flex flex-wrap'>
								<label htmlFor='element_name' className='w-full'>
									Délai (*)
								</label>
								<input
									type='number'
									id='element_name'
									min={0}
									className='border-[1px] border-solid w-full my-2 outline-none p-2 aria-[invalid]:border-red-500'
									aria-invalid={errors.step_duration}
									{...register('step_duration', {
										value: currentStep.step_duration,
										valueAsNumber: true,
										validate: (value) => {
											return value >= 0
												? true
												: 'Le délai doit être supérieur ou égal à 0';
										},
										required: {
											value: true,
											message: "Le délai de l'élement est requis",
										},
									})}
								/>
								<span className='text-red-500 w-full'>
									{errors.step_duration && errors.step_duration.message}
								</span>
							</div>
							<button
								type='submit'
								className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 p-4 mt-4 rounded flex gap-3 items-center'>
								Modifier <ButtonSpinner showLoading={showLoading} />
							</button>
						</form>
					</div>
				</Modal>
			)}
		</div>
	);
};
