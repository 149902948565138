import { Outlet } from 'react-router-dom';
import AddContactPage from '../../pages/contact/AddContactPage';
import ContactPage from '../../pages/contact/Contact.page';
import Home from '../../pages/home/Home';
import { Suivi } from '../../pages/partials/Suivi';
import { BienPage } from '../../pages/biens/BienPage';
import { EstimationPage } from '../../pages/estimations/EstimationPage';
import { SubmitEstimationPage } from '../../pages/estimations/SubmitEstimationPage';
import { HomePige } from '../../pages/pige/home';
import { DashboardPige } from '../../pages/pige/dashboard';
import { BienActionPage } from '../../pages/biens/BienActionPage';
import { BienAddPage } from '../../pages/biens/BienAddPage';
import { Profile } from '../../pages/partials/Profile';
import { PigeConfiguration } from '../../pages/configuration/Pige';
import { PigeDetails } from '../../pages/pige/details';
import { Users } from '../../pages/profil/Users';
import { SuiviDossier } from '../../pages/suivi/Suivi';
import BienDetailsPage from '../../pages/biens/BienDetailsPage';
import { DetailSuivi } from '../../pages/suivi/Detail';
import { ListEstimationPage } from '../../pages/estimations/ListEstimation';
import { Agenda } from '../../pages/agenda/agenda';
import { BienRapprochementPage } from '../../pages/biens/BienRapprochementPage';
import { BienContactPage } from '../../pages/biens/BienContactPage';
import { ContactAction } from '../../pages/contact/ContactAction';
import ContactDetailsPage from '../../pages/contact/ContactDetailsPage';
import { ImpressionEstatePage } from '../../pages/biens/impression/ImpressionEstatePage';
import { BienUpdatePage } from '../../pages/biens/BienUpdatePage';
import { FilterFolder } from '../../pages/suivi/Filter';
import { News } from '../../pages/news/News';
import { MyExportsPage } from '../../pages/exports/MyExportsPage';
import { ExportDashboard } from '../../pages/exports/ExportDashboard';
import { Portals } from '../../pages/exports/portals/Portals';
import { EstateList } from '../../pages/exports/portals/EstateList';
import { EstateDetails } from '../../pages/exports/portals/EstateDetails';
import { UpdateContactPage } from '../../pages/contact/UpdateContactPage';
import { ResearchPage } from '../../pages/search/Research.page';
import { EstimationDetail } from '../../pages/estimations/EstimationDetail';

const MENU = [
	{
		link: '/',
		text: 'ACCUEIL',
		component: <Home />,
		is_menu: true,
	},
	{
		link: '/mes-biens',
		text: 'MES BIENS',
		component: <Outlet />,
		is_menu: true,
		children: [
			{
				link: '',
				text: 'MES BIENS',
				component: <BienPage />,
			},
			{
				link: ':filter',
				text: 'Biens actifs',
				component: <BienActionPage />,
				is_pro: true,
			},
			{
				link: ':filter',
				text: 'Biens vendus',
				component: <BienActionPage />,
				is_pro: true,
			},
			{
				link: ':filter',
				text: 'Biens inactifs',
				component: <BienActionPage />,
				is_pro: true,
			},
			{
				link: ':filter',
				text: 'Biens archivés',
				component: <BienActionPage />,
				is_pro: true,
			},
			{
				link: 'new/:filter',
				text: 'Nouveau bien',
				component: <BienAddPage />,
				is_pro: true,
			},
			{
				link: 'my-network',
				text: 'Mon réseau',
				component: <BienActionPage />,
				is_pro: true,
			},
			{
				link: 'details-bien/:bienId',
				text: '',
				component: <BienDetailsPage />,
				is_pro: true,
			},
			{
				link: 'rapprochement-bien/:bienId',
				text: '',
				component: <BienRapprochementPage />,
				is_pro: true,
			},
			{
				link: 'contacts-bien/:bienId',
				text: '',
				component: <BienContactPage />,
				is_pro: true,
			},
			{
				link: 'impression/:bienUuid',
				text: '',
				component: <ImpressionEstatePage />,
				is_pro: true,
			},
			{
				link: 'bien-update/:filter',
				text: '',
				component: <BienUpdatePage />,
				is_pro: true,
			},
		],
	},
	{
		link: '/ma-pige',
		text: 'MA PIGE',
		component: <Outlet />,
		is_menu: true,
		children: [
			{
				link: '',
				text: 'Accueil',
				component: <HomePige />,
			},
			{
				link: 'dashboard',
				text: 'Dashboard',
				component: <DashboardPige />,
				is_pro: true,
			},
			{
				link: 'details/:pigeId',
				text: 'Details',
				component: <PigeDetails />,
				is_pro: true,
			},
		],
	},
	{
		link: '/mes-contacts',
		text: 'MES CONTACTS',
		is_menu: true,
		component: <Outlet />,
		children: [
			{
				link: '',
				text: 'MES CONTACTS',
				component: <ContactPage />,
			},
			{
				link: ':filter',
				text: 'Contact actifs',
				component: <ContactAction />,
				is_pro: true,
			},
			{
				link: ':filter',
				text: 'Contact archivés',
				component: <ContactAction />,
				is_pro: true,
			},
			{
				link: 'add/:filter',
				text: 'AJOUT DE CONTACT',
				component: <AddContactPage />,
				is_pro: true,
			},
			{
				link: 'details-contact/:contactId',
				text: '',
				component: <ContactDetailsPage />,
				is_pro: true,
			},
			{
				link: 'update/:filter',
				text: '',
				component: <UpdateContactPage />,
				is_pro: true,
			},
		],
	},

	{
		link: '/mes-estimations',
		is_menu: true,
		text: 'MES ESTIMATIONS',
		component: <Outlet />,
		children: [
			{
				text: 'MES ESTIMATIONS',
				link: '',
				component: <EstimationPage />,
			},
			{
				text: 'MES ESTIMATIONS',
				link: 'new',
				component: <ListEstimationPage />,
				is_pro: true,
			},
			{
				text: 'MES ESTIMATIONS',
				link: 'create',
				component: <SubmitEstimationPage />,
				is_pro: true,
			},
			{
				text: 'MES ESTIMATIONS',
				link: 'details/:estimationUuid',
				component: <EstimationDetail />,
				is_pro: true,
			},
		],
	},
	{
		link: '/mes-suivis',
		text: 'MES SUIVIS',
		component: <Outlet />,
		is_menu: true,
		children: [
			{
				link: '',
				text: 'Dashboard',
				component: <Suivi />,
			},
			{
				link: 'index',
				text: 'Mes suivis de dossier',
				component: <SuiviDossier />,
				is_pro: true,
			},
			{
				link: 'details/:bien_id',
				text: 'Details de dossier du bien',
				component: <DetailSuivi />,
				is_pro: true,
			},
			{
				link: 'filter/:filter',
				text: 'Details de dossier du bien',
				component: <FilterFolder />,
			},
		],
	},
	{
		link: '/ma-formation',
		text: 'MA FORMATION LOI ALUR',
		is_menu: true,
		is_pro: true,
	},
	{
		link: '/profil',
		text: 'MON PROFIL',
		is_menu: true,
		component: <Outlet />,
		children: [
			{
				link: '',
				text: 'Mon profil',
				component: <Profile />,
			},
			{
				link: 'pige',
				text: 'Pige',
				component: <PigeConfiguration />,
				is_pro: true,
			},
			{
				link: 'users',
				text: 'Agents',
				component: <Users />,
			},
		],
	},
	{
		link: '/agenda',
		text: 'AGENDA',
		is_menu: true,
		component: <Agenda />,
		is_pro: true,
	},
	{
		link: 'mes-actus',
		text: 'ACTUALITÉS',
		is_menu: true,
		component: <News />,
		is_pro: true,
	},
	{
		link: 'mes-exports',
		text: 'Mes Exports',
		is_menu: false,
		component: <MyExportsPage />,
		children: [
			{
				link: '',
				text: 'Mes Exports',
				component: <ExportDashboard />,
				is_pro: true,
			},
			{
				link: 'portals',
				text: 'Portails actifs',
				component: <Portals />,
				is_pro: true,
			},
			{
				link: 'estate/:bienUuid',
				text: 'Detail de bien',
				component: <EstateDetails />,
				is_pro: true,
			},
			{
				link: 'lists',
				text: 'Biens à diffuser',
				component: <EstateList />,
			},
		],
	},
	{
		link: '/search',
		text: 'Recherche',
		component: <ResearchPage />,
		is_menu: false,
	},
];

export default MENU;

const proRoute = (route) => route.is_pro;
