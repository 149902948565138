import { useParams } from 'react-router-dom';
import Chevron from '../../../commons/Chevron';
import { useEffect, useRef, useState } from 'react';
import PopUp from '../../../commons/form/PopUp';
import { QuickPopUp } from './QuickPopUp';
import { ContactIndicator } from './contact-indicator';

export const ContactSection = ({ contact, reload, link = null }) => {
	const { filter, contactId } = useParams();
	const [isPopUpVisible, setPopUpVisible] = useState(false);
	const [isActive, setActive] = useState(false);
	const popUpRef = useRef(null);

	const handleClickOutside = (event) => {
		if (popUpRef.current && !popUpRef.current.contains(event.target)) {
			setPopUpVisible(null);
		}
	};

	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		const date = new Date(dateString);
		return date.toLocaleDateString('fr-FR', options);
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const togglePopUp = () => {
		setPopUpVisible(!isPopUpVisible);
	};

	const typeContact = (contactType) => {
		var type = '';
		switch (contactType) {
			case 'owner':
				type = 'Propriétaire';
				break;
			case 'buyer':
				type = 'Acquéreur';
				break;
			case 'tenant':
				type = 'Locataire';
				break;

			default:
				break;
		}
		return type;
	};

	return (
		<div className='mb-8 border-2 border-solid'>
			<div className='w-[98%] m-1 h-full sm:hidden flex items-center justify-center rounded'></div>
			<div className='w-full m-1 rounded flex sm:min-h-36 min-h-52 justify-center'>
				<div className='sm:w-[95%] w-[80%] border-2 flex rounded-l-xl border-solid p-1 sm:relative'>
					<div className='border-2 border-solid rounded mr-[2%] w-full flex flex-wrap border-black/60 p-1 relative'>
						<div className='w-[23%] sm:flex items-center lg:rounded lg:border-2 lg:border-solid p-1 '>
							<ul className=''>
								<ContactIndicator contact={contact} />
								<li className=''>
									<b>Nom negociateur : </b>
									{contact?.negociator?.name}
								</li>
							</ul>
						</div>
						<hr className='sm:hidden border-solid border-[1px] border-black/70 w-full' />
						<div className='w-full pb-2 sm:w-[72%] sm:relative'>
							<div className='sm:ml-20 sm:min-h-[80%]'>
								<div className='flex justify-between w-full flex-wrap'>
									<h1 className='sm:w-1/2 w-full break-words'>
										Type de contact : {typeContact(contact.contact_type)}
									</h1>

									<h2 className='sm:w-1/2 w-full sm:text-right pr-2'>
										{/* Pays du contact du dossier : {contact.country_contact} */}
									</h2>
									<div className='w-full'>
										<ul className=''>
											<li>
												Type d'offre :
												<span
													className={`uppercase text-white p-1 rounded font-bold shadow-sm mx-2 shadow-black ${
														contact.preferency.type_offert?.designation ===
														'vente'
															? 'bg-green-500'
															: 'bg-yellow-500'
													}`}>
													{contact?.preferency?.type_offert?.designation &&
														contact.preferency.type_offert?.designation}
												</span>
											</li>
											<li>
												Type de bien :
												<span className={`uppercase p-1 rounded font-bold`}>
													{contact?.preferency?.type_estate?.designation &&
														contact.preferency.type_estate.designation}
												</span>
											</li>
											<li>
												Date de création :{formatDate(contact.created_at)}
											</li>
											<li>Localité: {contact?.preferency?.localities}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className='flex flex-row mr-4 pb-5 mb-4 font-bold cursor-pointer '
						onClick={togglePopUp}>
						<div className='badge badge-primary badge-xs font-bold text-4xl'>
							.
						</div>
						<div className='badge badge-primary badge-xs font-bold text-4xl'>
							.
						</div>
						<div className='badge badge-primary badge-xs font-bold text-4xl'>
							.
						</div>
					</div>
					<PopUp isPopUpVisible={isPopUpVisible} popUpRef={popUpRef}>
						<QuickPopUp contact={contact} reload={reload} />
					</PopUp>
				</div>
				{!contactId && (
					<div className='sm:w-[5%] flex justify-center items-center w-[15%] rounded-r-xl border-2 border-solid bg-black cursor-pointer text-white hover:bg-gray-400 hover:text-black'>
						<Chevron
							link={
								link ? link : `/mes-contacts/details-contact/${contact.uuid}`
							}
							state={filter}
						/>
					</div>
				)}
			</div>
			{/* <Modal
            isOpen={isModalOpen}
            handleClose={handleClose}
            id={'modal-link'}
            modalTitle={'Vue rapide'}
            allowBackdrop={false}
            children={contact}
        /> */}
		</div>
	);
};
