import React from 'react';
import { FooterBlock } from './footer-bock';

export const Footer = React.memo(() => {
	return (
		<>
			<FooterBlock />
			<footer className='text-center mx-auto w-full'>
				{new Date().getFullYear()} © | Tous droits réservés | Contactez le
				Support| CGV | Politique de confidentialité
			</footer>
		</>
	);
});
