import { useEffect, useState } from 'react';
import { Geocoding } from '../../../../../service/api/geocoding';
import { Mapbox } from '../../../mapbox';

export const MapInput = ({ currentAddress }) => {
	const [showMap, setShowMap] = useState(false);

	useEffect(() => {}, [showMap]);
	return (
		<div className='w-full'>
			<button
				type='button'
				className='w-full text-white default-color py-4 font-bold rounded-xl my-2'
				onClick={() => setShowMap(!showMap)}>
				{(showMap && 'Fermer le map') ||
					"Afficher sur la map l'adresse du bien"}
			</button>
			<p className='p-4 border-[1px] border-solid my-4 bg-gray-600/70 text-white'>
				La géolocalisation ici peut avoir quelques restrictions vis à vis de la
				récuperation des données sur l'API Gov de France
			</p>
			{showMap && (
				<Mapbox
					longitude={currentAddress[0]}
					latitude={currentAddress[1]}
					showLayer={false}
					defaulLayer={6}
				/>
			)}
		</div>
	);
};
