import { Link } from 'react-router-dom';
import { Button } from '../../components/commons/button';
import {
	login,
	validateRegistrationCode,
} from '../../service/auth/authentication';
import { isConnected, setStorageUser } from '../../service/localStorage';
import {
	errorToast,
	successToast,
	technicalErrorToast,
} from '../../service/toast/showToast';
import { useForm } from 'react-hook-form';
import { Badge } from '../../components/commons/badge';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { useState } from 'react';

export const LoginPage = () => {
	const [isValideuser, setIsValideUser] = useState(false);
	const [currentUser, setCurrentUser] = useState();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [showLoading, setShowLoading] = useState(false);

	if (isConnected) {
		window.location.href = '/';
		return;
	}

	const validateEmail = (value) => {
		if (!value) {
			return "L'email est requis";
		}
		if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
			return "L'adresse email est invalide";
		}
		return true;
	};

	const emailValidation = {
		validate: validateEmail,
	};
	const passwordValidation = {
		required: { value: true, message: 'Le mot de passe est requis' },
		minLength: {
			value: 4,
			message: 'Le mot de passe doit au moins être 4 caractères',
		},
	};

	const sendTwoFactorCode = async (user) => {
		setShowLoading(true);
		await validateRegistrationCode(user)
			.then((response) => {
				successToast('Bienvenu sur MAPIM IMMO Logiciel ');
				setStorageUser(response.data);
				setTimeout(() => (window.location.href = '/'), 1000);
			})
			.catch((e) => {})
			.finally(() => setShowLoading(false));
	};

	const sendSubmit = async (data) => {
		setShowLoading(true);
		await login(data)
			.then((response) => {
				const { user } = response.data;
				setCurrentUser(user);
				setIsValideUser(true);
				setShowLoading(false);
			})
			.catch((e) => {})
			.finally(() => setShowLoading(false));
	};

	return (
		<>
			<div
				className='flex items-center justify-around gap-1 flex-wrap sm:pt-52 sm:pb-52 bg-top bg-no-repeat'
				style={{
					backgroundSize: '100% 100%',
					backgroundImage: "url('/images/login_image.png')",
				}}>
				<div className='w-full'>
					<div className='flex flex-col w-full lg:w-1/2 mx-auto sm:mx-0 gap-6 p-4 lg:p-20 bg-slate-200/60 sm:bg-slate-200/80 rounded-lg'>
						<div>
							<img
								src='/favicon.ico'
								className='w-1/4 mx-auto rounded-full'
								alt='Logo'
							/>
						</div>
						{(!isValideuser && (
							<>
								<div className='lg:flex lg:items-center lg:justify-between'>
									<label className='lg:w-1/3 sm:w-full sm:pb-2 sx:pb-2'>
										Adresse email :
									</label>
									<input
										{...register('email', emailValidation)}
										type={'email'}
										name={'email'}
										autoFocus={true}
										className={'w-full p-2'}
									/>
								</div>
								{errors.email && (
									<Badge textColor={'text-white'} bgColor={'bg-red-500'}>
										{errors.email.message}
									</Badge>
								)}
								<div className='lg:flex items-center justify-between'>
									<label className='lg:w-1/3 sm:w-full sm:pb-2 sx:pb-2'>
										Mot de passe :
									</label>
									<input
										type={'password'}
										name={'password'}
										{...register('password', passwordValidation)}
										className={'w-full p-2'}
									/>
								</div>
								{errors.password && (
									<Badge textColor={'text-white'} bgColor={'bg-red-500'}>
										{errors.password.message}
									</Badge>
								)}
								<div className='w-full mx-auto bg-green-500 py-2 text-center rounded-full'>
									<Button
										className='text-white font-bold flex items-center gap-3 justify-center w-full'
										disabled={Object.keys(errors).length !== 0}
										onClick={handleSubmit((data) => sendSubmit(data))}>
										<span>CONNEXION</span>{' '}
										<ButtonSpinner showLoading={showLoading} />
									</Button>
								</div>
								<div className='flex justify-between'>
									<Link
										to='/register'
										className='text-blue-700 font-bold hover:underline'>
										Créer un compte{' '}
									</Link>
									<Link
										to='/reset-password'
										className='text-blue-700 hover:underline'>
										Mot de passe oublié ?{' '}
									</Link>
								</div>
							</>
						)) || (
							<>
								<p className='text-center'>
									Un email vous a été envoyé avec la code de validation,
									veuillez le retrouver et vous connecter ici.
								</p>
								<input
									{...register('validation_code', {
										validate: {
											required: (value) =>
												value && value.length === 6
													? true
													: 'La code de validation doit être de 6 caractéres et est requis',
										},
									})}
									type={'text'}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											handleSubmit((data) => sendTwoFactorCode(data));
										}
									}}
									name={'validation_code'}
									autoFocus={true}
									className={'w-full p-2'}
								/>
								{errors.validation_code && (
									<Badge textColor={'text-white'} bgColor={'bg-red-500'}>
										{errors.validation_code.message}
									</Badge>
								)}

								<div className='w-full mx-auto bg-green-500 py-2 text-center rounded-full'>
									<Button
										className='text-white font-bold flex items-center gap-3 justify-center w-full'
										disabled={Object.keys(errors).length !== 0}
										onClick={handleSubmit((data) => sendTwoFactorCode(data))}>
										<span>VALIDER</span>{' '}
										<ButtonSpinner showLoading={showLoading} />
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
