function StatusSection({ location, contact, navigate }) {
  const updateStatusPropety = () => { };



  return (
    <>
      <div className="px-5 py-1 blue-color items-center rounded flex justify-between">
        <p className="text-white">

          <span className="uppercase"> {contact.lastname}</span>  {contact.firstname}
        </p>

        {location.state && location.state === "inactive" && (
          <div className="flex gap-1">
            <button
              className={`px-5 rounded cursor-pointer ${contact.published === "active"
                  ? "bg-green-600 text-white"
                  : "bg-white"
                }`}
              onClick={() => {
                updateStatusPropety(contact, "active");
              }}
            >
              Actif
            </button>
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish !== 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              disabled
            >
              Inactif
            </button>
          </div>
        )}
        {location.state && location.state === "active" && (
          <div className="flex gap-1">
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish === 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              onClick={() => {
                updateStatusPropety(contact, "sold");
              }}
            >
              Vendus/Loués
            </button>
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish !== 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              onClick={() => {
                updateStatusPropety(contact, "sold");
              }}
            >
              Active
            </button>
          </div>
        )}
        {location.state === null && (
          <div className="flex gap-1">
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish === 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              onClick={() => {
                updateStatusPropety(contact, "sold");
              }}
            >
              Vendus/Loués
            </button>
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish !== 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              onClick={() => {
                updateStatusPropety(contact, "sold");
              }}
            >
              Active
            </button>
          </div>
        )}
        {location.state && location.state === "sold" && (
          <div className="flex gap-1">
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish === 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              onClick={() => updateStatusPropety(contact, "archive")}
            >
              Archivés
            </button>
            <button
              className={`px-5 rounded cursor-pointer ${contact.publish !== 1 ? "bg-green-600 text-white" : "bg-white"
                }`}
              onClick={() => {
                updateStatusPropety(contact, "archive");
              }}
            >
              Vendus/Loués
            </button>
          </div>
        )}
        <div className="flex flex-wrap gap-3">

          <button className="text-color border rounded p-2 bg-white hover:bg-green-700 hover:text-white " onClick={() => {
              window.location.href =  `/mes-contacts/update/${contact.uuid}`;
          }}>
            Modifier
          </button>
          <button className="text-white underline" onClick={() => navigate(-1)}>
            Retour
          </button>
        </div>
      </div>
    </>
  );
}

export default StatusSection;
