import { useNavigate } from 'react-router-dom';

export default function NavItem({ href, isActive, children }) {
	const navigate = useNavigate();
	return (
		<div
			onClick={() => navigate(href)}
			className='flex items-center justify-center cursor-pointer blue-color p-6 mb-2 lg:mb-0 lg:w-3/4 sm:w-full sm:gap-1 text-white text-center'>
			{children}
		</div>
	);
}
