import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContact } from "../../../service/contact/useContact";
import StatusSection from "../../../components/generals/contact/section/status.section";
import { GeneralSpinner } from "../../../components/commons/general-spinner";
import { DetailsContactSection } from "./DetailsContactSection";
import BienDropdown from "../../../components/generals/card/bienDropdown";

export const ContactRapprochementPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [contact, setContact] = useState();
    const { contactId } = useParams();
    useEffect(() => {
        if (!contact) {
            useContact.getContactByUuid(contactId)
                .then((response) => {
                    setContact(response.data)
                })
                .catch(() => { })
        }

    }, [contact, contactId, location])

    return (
        (contact && (
            <div className="border-2 border-solid border-[#2d5c6a]">

                <StatusSection contact={contact} location={location} navigate={navigate} />

                <div className='px-5 py-2 my-3 rounded blue-color items-center flex justify-between'>
                    <p className='text-white uppercase block'> - GESTION DES RAPPROCHEMENTS </p>
                </div>

                <DetailsContactSection contact={contact} >
                <div className='grid grid-cols-10 mt-10'>
					<div className='col-span-4'></div>
					<div className='col-span-2'>Créer le</div>
					<div className='col-span-2'>N° Mandat</div>
					<div className='col-span-2'>Correspondance</div>
				</div>
                {contact.rapprochements.map((rapprochement)=> (
                    <BienDropdown
                    key={rapprochement.id}
                    bien={rapprochement}
                    />
                ))
                }
                </DetailsContactSection>


            </div>
        )) || <GeneralSpinner />
    )
}