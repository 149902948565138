import { BlocCard } from "../../components/generals/card/bloc"

export const  RapprochementPage =() =>{

    return (

        <div className='flex flex-wrap gap-8 justify-between items-center  border-2 border-solid border-[#2d5c6a] p-10'>
        <BlocCard
            className='h-52 sm:w-1/3 w-3/4 '
            link={'/mes-rapprochements/biens/rapprochements'}
            blocTitle={'Mes Biens'}
        />

        <BlocCard
            className='h-52 sm:w-1/3 w-3/4'
            link={'/mes-rapprochements/contacts'}
            blocTitle={' Mes Contacts'}
        />
        </div>
    )
}