export const Pricing = ({ bien }) => {
	return (
		<ul>
			<li>Prix : {bien.publish_price} €</li>
			<li>
				Honoraire :{' '}
				{parseInt(bien.publish_price) - parseInt(bien.selling_price)} €
			</li>
		</ul>
	);
};
