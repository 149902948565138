import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const MobileMenu = ({ menu }) => {
	const [isOpen, setIsOpen] = useState(false);

	const navigate = useNavigate();

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div
			className={`sm:relative md:fixed fixed block lg:hidden blue-color sm:z-0 sm:mb-2 sm:p-0 p-4 md:top-12 md:p-4 md:block top-12 ${
				!isOpen && 'md:w-14'
			}`}
			style={{ zIndex: '1000' }}>
			<button onClick={toggleMenu} className=''>
				<i className='fa-solid fa-list text-white'></i>
			</button>
			{isOpen && (
				<div
					className='fixed inset-0 bg-gray-900 bg-opacity-85'
					style={{ zIndex: '1000' }}>
					<div className='flex justify-end p-4'>
						<button onClick={toggleMenu} className='text-white'>
							<i className='fa-solid fa-xmark'></i>
						</button>
					</div>
					<div className='flex justify-center h-screen w-full'>
						<ul className='text-white w-10/12'>
							{menu.map(
								(item, index) =>
									item.is_menu && (
										<li
											onClick={() => {
												setIsOpen(false);
												navigate(item.link);
											}}
											key={index}
											className='py-2 cursor-pointer'>
											{item.text}
										</li>
									)
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};
