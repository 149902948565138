import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContact } from '../../service/contact/useContact';
import { ContactSection } from '../../components/generals/contact/section/contact.section';
import { ButtonSpinner } from '../../components/commons/buttonSpinner';
import { FilterSection } from '../../components/generals/contact/section/filter.section';
import { Pagination } from '../../components/commons/pagination';
import { BlocInfosContact } from '../../components/generals/contact/bloc/BlocInfosContact';
import { Breadcumbs } from '../../components/generals/headers/breadcumbs';

const FILTERS = {
	add: 'Gestion de mes contacts',
	archive: 'Contacts archivés',
	active: 'Contacts actifs',
};

export const ContactAction = () => {
	const [contacts, setContact] = useState([]);
	const [shouldRerender, setShouldRerender] = useState(false);
	const [pagination, setPagination] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const { filter } = useParams();
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);

	const fetchContact = () => {
		setLoading(true);
		const params = Object.entries(search)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join('&');
		const filterParams = filter ? `filter=${filter}` : '';
		useContact
			.allContact(currentPage, filterParams, params)
			.then((response) => {
				setContact(response.data.data);
				setPagination({
					links: response.data.links,
					per_page: response.data.per_page,
					total: response.data.total,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const reload = () => {
		setShouldRerender(true);
	};

	useEffect(() => {
		fetchContact();
	}, [currentPage, filter, search, shouldRerender]);

	const handleChangePage = (page) => setCurrentPage(page);

	const handleFilterChange = (filterValue) => {
		setSearch(filterValue);
	};

	return (
		<>
			<Breadcumbs title={FILTERS[filter]} />
			<div className='flex flex-wrap justify-between border-2 border-solid border-black p-2 min-h-[200px]'>
				{/* <BlocInfosContact /> */}
				<FilterSection onFilterChange={handleFilterChange} />

				<div className='w-full border-1 border-solid border-black m-1'>
					{contacts &&
						contacts.length !== 0 &&
						contacts.map((contact, index) => {
							return (
								<ContactSection contact={contact} key={index} reload={reload} />
							);
						})}
					{loading ? (
						<div className='flex items-center justify-center w-full'>
							<ButtonSpinner dimension='w-24 h-24' showLoading={loading} />
						</div>
					) : (
						contacts.length === 0 && (
							<div className='flex flex-wrap justify-center h-full items-center'>
								<h1 className='text-4xl'>Aucun contact n'est disponible</h1>
							</div>
						)
					)}

					{contacts.length !== 0 &&
						pagination.links &&
						pagination.links.length !== 0 && (
							<div className='h-12 mt-10 flex flex-wrap w-full gap-1 justify-center items-center'>
								{pagination.links
									.slice(1, pagination.links.length)
									.slice(0, -1)
									.map((link, index) => {
										return (
											<Pagination
												changePage={handleChangePage}
												key={index}
												link={link}></Pagination>
										);
									})}
							</div>
						)}
				</div>
			</div>
		</>
	);
};
