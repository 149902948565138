export const ContactEstimation = ({ estimation }) => {
	return (
		<div className='w-full lg:w-1/3 sm:w-1/2'>
			<div className='h-full rounded-tr-md rounded-br-md rounded-tl-md rounded-bl-md border-[1px] border-solid border-black/60 px-2 text-sm break-words'>
				<span className='block'>
					<u className='font-bold'>Nom et prénom(s)</u> :{' '}
					{estimation.demandeur_firstname} {estimation.demandeur_lastname}
				</span>
				<span className='block'>
					<u className='font-bold'>Email</u> : {estimation.demandeur_email}
				</span>
				<div className='flex items-center'>
					<div className='bg-black/70 text-white font-bold w-11 p-1 h-10 rounded-full flex items-center justify-center'></div>
					<hr className='border-[1px] border-solid border-black/40 w-full' />
				</div>
				{estimation.user && (
					<div className='flex flex-wrap'>
						<u className='font-bold'>Négociateur</u> : {estimation.user.name}
					</div>
				)}
			</div>
		</div>
	);
};
